import { all, takeEvery, put, fork,select } from 'redux-saga/effects';
import actions from 'actions';
import { getConsumers } from 'services/consumer-services';
import { notification } from 'antd';
const { consumerActions, authActions } = actions;

export function* getConsumerSaga() {
  yield takeEvery(consumerActions.GET_CONSUMERS, function*({pageNumber,searchQuery, params}) {
    try{
      const token = yield select(state => state.auth.accessToken);
      const response = yield getConsumers(pageNumber,searchQuery, {token},params);
        const { data } = response;
        yield put({
          type: consumerActions.GET_CONSUMERS_SUCCESS,
          data
        });
    } catch(error){
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getConsumerSaga)]);
}
