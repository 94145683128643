import React, { useState, useEffect } from 'react';
import { Table, Popover, Input } from 'antd';
import { view_log, triple_dots } from 'helper/constant';
// import EditePartner from './editePartner';
import EditeInfluencer from "./EditeInfluencer"
import ViewReports from './ViewReports';
import { green_dot, red_dot } from 'helper/constant';
import actions from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { ConsoleSqlOutlined, EditTwoTone } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import { AudioOutlined } from '@ant-design/icons';
import ReferedUser from './influencerView'
const { Search } = Input;
const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: '#1890ff',
    }}
  />
);


const { master } = actions;

const Pharmacies = ({ newcity, onGetarea, newareas }) => {
  const dispatch = useDispatch();
  const [visible, setvisible] = useState(false);
  const [reportsvisible, setreportsvisible] = useState(false);
  const [editvisible, setEditvisible] = useState('');
  const [user, setuser] = useState(null);
  console.log(user, "user")
  const [data, setData] = useState("")
  const Pharma = useSelector((state) => state.pharmacies);
  console.log(Pharma && Pharma.pharmacies && Pharma.pharmacies.data, "phar")
  const PharmaData = Pharma && Pharma.pharmacies && Pharma.pharmacies.data;
  const Auth = useSelector((state) => state.auth);
  const masterPartner = useSelector((state) => state.master);
  const [search, setSearch] = useState(" ")
  const [influencerRefrealCode, setInfluencerRefrealCode] = useState("")
  console.log(influencerRefrealCode, "influencerRefrealCode")
  console.log(masterPartner.influencerData.data, "555")
  const [influencerViewData, setInfluencerViewData] = useState([])
  const [influencerViewData1, setInfluencerViewData1] = useState()
  const [count, setCount] = useState()

  const [referdUsersVisible, setreferdUsersVisible] = useState(false);

  const getRefferalCode = (row) => {
    axios.get(`${process.env.REACT_APP_WEB_APP}/user/wallethistory/wallet/${row && row.uuid}`, {
      headers: {
        "x-auth-token": Auth.accessToken
      }
    })
      .then((res) => {
        console.log(res, "res")
        // data.push(res)
        setInfluencerRefrealCode(res.data)
      })
  }
  // useEffect(() => {
  //   dispatch( MasterAction && MasterAction.getPartnersDta());
  // }, []);

  useEffect(() => {
    dispatch(master.getInfluencerData());

  }, []);


  const getInfluencerDataView = (row, pagenumber) => {
    console.log(row, pagenumber,"pagenumber")
    let page ;
    if(pagenumber === undefined){
      page = 0;
    }
    else{
      page = pagenumber
    }
    setInfluencerViewData1(row)
    axios.get(`${process.env.REACT_APP_WEB_APP}/user/auth/influencer/register/user/list?influencer_uuid=${row && row.uuid}&pagenum=${page}&limit=10`, {

      headers: {
        "x-auth-token": Auth.accessToken
      }
    })
      .then((res) => {
        console.log(res, "resapi")
        // data.push(res)
        setInfluencerViewData(res && res.data && res.data.data)
        setCount(res && res.data && res.data.count)
      })
  }


  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'User Type',
      dataIndex: 'influencer_type',
      key: 'influencer_type'
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile',
      key: 'mobile:',
    },
    // {
    //   title: 'active',  
    //   dataIndex: 'active',
    //   key: 'active'
    // },


    {
      title: 'View',
      dataIndex: 'eye',
      key: 'eye',
      render: (text, row) => (
        <img
          className='ml--10 cursor-pointer'
          src={view_log && view_log.default?view_log.default:view_log}
          alt='view'
          onClick={() => {
            console.log('view');
            setuser(row);
            getRefferalCode(row);
            setvisible(true);
            setEditvisible('view')
          }}
        />
      )
    },
    {
      title: 'Followers',
      dataIndex: 'eye',
      key: 'eye',
      render: (text, row) => (
        <img
          className='ml--10 cursor-pointer'
          src={view_log && view_log.default?view_log.default:view_log}
          alt='view'
          onClick={() => {
            // console.log('view');
            setuser(row);
            getInfluencerDataView(row);
            setreferdUsersVisible(true);
            // setEditvisible('view')
          }}
        />
      )
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, row) => (
        <div className='ml--20'>
          <EditTwoTone
            onClick={() => {
              setuser(row);
              getRefferalCode(row);
              setvisible(true);
              setEditvisible('edit')
            }}
          />
          {/* <Popover
            trigger='hover'
            placement='bottom'
            content={
              <div>
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    setuser(row);
                    setvisible(true);
                    setEditvisible('edit')
                  }}
                >
                  Edit
                </div>
              </div>
            }
          >
            <img src={triple_dots} alt='action' />
          </Popover> */}
        </div>
      )
    }
  ];
  let pageNumber = ''
  // console.log(masterPartner.NationalPathalogy, "hgjkl")
  const onChangesearch = (searchData) => {
    setSearch(searchData)
    dispatch(master.getInfluencerData(0, searchData))
  }



  return (
    <div>
      {/* <Input 
    onChange={ e => onChangesearch(e.target.value)}
    type="text"
    placeholder='Search'  
/> */}
      <Search
        style={{ width: "30%" }}
        placeholder="Search Influencer"
        allowClear
        // enterButton="Search"
        size="large"
        // onSearch={onSearch}
        onChange={e => onChangesearch(e.target.value)}
        enterButton
      />

      {/* <Search
style={{width:"30%"}}
      placeholder="input search text"
      allowClear
      enterButton="Search"
      size="large"
      // onSearch={onSearch}
      onChange={ e => onChangesearch(e.target.value)}
    /> */}

      <br /><br />
      <Table
        columns={columns}
        dataSource={masterPartner.influencerData.data}
        size='middle'
        pagination={{
          total: masterPartner.influencerData.count,
          showSizeChanger: false
        }}

        onChange={(pagination) => {

          if (pagination.current === 1) {
            pageNumber = 0
          } else {
            pageNumber = pagination.current - 1
          }
          dispatch(master.getInfluencerData(pageNumber))
        }}
      />
      <EditeInfluencer
        visible={visible}
        diff={editvisible}
        newcity={newcity}
        onGetarea={onGetarea}
        newareas={newareas}
        loader={false}
        handleOk={() => setvisible(false)}
        handleCancel={() => { setvisible(false); window.location.reload(false) }}
        user={user}
        influencerRefrealCode={influencerRefrealCode}
      />
      {/* <ViewReports
      visible={reportsvisible}
      loader={false}
      handleOk={() => setreportsvisible(false)}
      handleCancel={() => {setreportsvisible(false); window.location.reload(false)}}
      data={data}
      >
      </ViewReports> */}

      <ReferedUser
        visible={referdUsersVisible}
        loader={false}
        handleOk={() => setreferdUsersVisible(false)}
        handleCancel={() => { setreferdUsersVisible(false) }}
        influencerViewData2={influencerViewData}
        influencerViewData1={influencerViewData1}
        count={count}
        fun={getInfluencerDataView}
        auth={Auth.accessToken}
      >
      </ReferedUser>
    </div>
  );
};

export default Pharmacies;