const POST_APPOINTMENT = {
  POST_APPOINTMENT_ACTION: "POST_APPOINTMENT_ACTION",
  postAppoint: (data) => {
    console.log(data, "actiondataPOST");
    return {
      type: POST_APPOINTMENT.POST_APPOINTMENT_ACTION,
      data,
    };
  },
};

export default POST_APPOINTMENT;
