const ORDERACTION = {
  GET_OREDER: "GET_OREDER",
  GET_OREDER_SUCCESS: "GET_OREDER_SUCCESS",
  CHANGE_OREDER_STATUS: "CHANGE_OREDER_STATUS",
  CHANGE_OREDER_STATUS_SUCCESS: "CHANGE_OREDER_STATUS_SUCCESS",

  getorderbysuperadmin: (query, queryText) => {
    return {
      type: ORDERACTION.GET_OREDER,
      query,
      queryText
    };
  },

  updateOrderStatus: (orderId, status, itemId) => {
    return {
      type: ORDERACTION.CHANGE_OREDER_STATUS,
      orderId,
      status,
      itemId
    };
  },
};

export default ORDERACTION;
