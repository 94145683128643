import { Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  Tabs,
  notification,
} from 'antd';

import { Spin } from 'antd';
import { green_dot, red_dot } from 'helper/constant';
import { useDispatch } from 'react-redux';
import actions from 'actions';
import axios from "axios"
import { useForm } from "react-hook-form";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Helmet } from 'react-helmet';

const { pharmacyActions } = actions;

const ViewPharmacy = ({ user, visible, loader, handleOk, handleCancel, diff, newcity, onGetarea, newareas }) => {
  console.log('user', user);
  const dispatch = useDispatch()

  let uuid = user && user.uuid;

  const errorstyle = {
    border: "1px solid #ADC2C2",
    outline: "none !important",
    borderRadius: "5px",
    width: '10px'
  };

  console.log(uuid, "uuid")
  const [hideSubmit, setHideSubmit] = useState(false);
  const [tabIndex, setTabindex] = useState(1);
  const [newcityuuid, setnewcityuuid] = useState('');
  const [partnercity, setpartnercity] = useState('');
  const [partnerarea, setpartnerarea] = useState('');
  const [areaId, setareaId] = useState();
  const [commList, setCommList] = useState();
  const [amounts, setAmount] = useState("");
  const [amounts1, setAmount1] = useState("");
  const [amounts2, setAmount2] = useState("");
  const [amounts3, setAmount3] = useState("");
  const [amounts4, setAmount4] = useState("");
  const [amounts5, setAmount5] = useState("");
  const [amounts6, setAmount6] = useState("");
  const [amounts7, setAmount7] = useState("");
  const [amounts8, setAmount8] = useState("");
  const [amounts9, setAmount9] = useState("")
  const [amounts10, setAmount10] = useState("")
  const [amounts11, setAmount11] = useState("")
  const [amounts12, setAmount12] = useState("")
  const [amounts13, setAmount13] = useState("")

  console.log(amounts,amounts1,amounts2,amounts3,amounts4,amounts5,amounts6, amounts7, amounts8, amounts9, amounts10,amounts11, amounts12,amounts13,"test321")
  console.log(commList,"commList")
  const [country, setCountry] = useState();

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((res) => {
        setCountry(res.country_code.toLowerCase());
      })
      .catch((data, status) => {
        console.log("Request failed:", data);
      });
  }, []);

  const { handleSubmit } = useForm();

  const items = []


  const registeramount = (event) => {
    setAmount(event.target.value)
  }
  const  HealthProfileamount = (event) => {
    setAmount1(event.target.value)
  }
  const dmpamount = (event) => {
    setAmount2(event.target.value)
  }
  const sadnamount = (event) => {
    setAmount3(event.target.value)
  }
  const  Hairloss = (event) => {
    setAmount4(event.target.value)
  }
  const  PrematureEjaculation = (event) => {
    setAmount5(event.target.value)
  }
  const  Diabetes = (event) => {
    setAmount6(event.target.value)
  }
  const fadnamount = (event) => {
    setAmount7(event.target.value)
  }
  const  HeartAndHypertension = (event) => {
    setAmount8(event.target.value)
  }
  const  ErectileDysfunction = (event) => {
    setAmount9(event.target.value)
  }
  
 
  const treatmentamount = (event) => {
    setAmount10(event.target.value)
  }

  const  LabPackegeamount = (event) => {
    setAmount11(event.target.value)
  }

 

   const  MonthlyLimitsamount = (event) => {
    setAmount12(event.target.value)
  }
  const  Thyroid = (event) => {
    setAmount13(event.target.value)
  }
 



  console.log("area", commList, items)

  let aId = user && user.profile && user.profile.address && user.profile.address.area;

  console.log(aId, "aid")
  const areasubmit = values => {
    console.log("area submit", values)
    onGetarea(values)
  }
  useEffect(() => {
    getCommission()
  }, [])

  let registerType1 = commList && commList.data && commList.data[0] && commList.data[0];
  let registerType = Object.assign({}, registerType1);
  console.log(registerType, "obj")

  const { Option } = Select;
  const { TabPane } = Tabs;

  const handleTabs = (event) => {
    console.log(event, "event")
    setTabindex(event)
    if (event == "4") {
      getCommission()
    }
    // if (event === "6") {
    //   setHideSubmit(true)
    // } else {
    //   setHideSubmit(false)
    // }
  }
  const handlechange = (event) => {
    console.log(event.target.value, "valuesvalues")
    setAmount(event.target.value)
  }
  const handleComm = values => {
    values.preventDefault();
    // alert(values)
    console.log(values, "valuesvalues")
    var payLoad = {
      //   // "pharmacy_uuid": values.pharm_id,
      //   // "type": values.type,
      //   "amount": amounts

      "data": [
        {
          "pharmacy_uuid": uuid,
          "type": "register",
          "amount": amounts
        },
        {
          "pharmacy_uuid": uuid,
          "type": "health_profile",
          "amount": amounts1
        },
        {
          "pharmacy_uuid": uuid,
          "type": "dmp_order",
          "amount": amounts2
        },
        {
          "pharmacy_uuid": uuid,
          "type": "sadn",
          "amount": amounts3
        },
        {
          "pharmacy_uuid": uuid,
          "type": "Hairloss",
          "amount": amounts4
        },
        {
          "pharmacy_uuid": uuid,
          "type": "Premature Ejaculation",
          "amount": amounts5
        },
        {
          "pharmacy_uuid": uuid,
          "type": "Diabetes",
          "amount": amounts6
        },
        {
          "pharmacy_uuid": uuid,
          "type": "fadn",
          "amount": amounts7
        },
        {
          "pharmacy_uuid": uuid,
          "type": "Heart and Hypertension",
          "amount": amounts8
        },
        {
          "pharmacy_uuid": uuid,
          "type": "Erectile dysfunction",
          "amount": amounts9
        },
        {
          "pharmacy_uuid": uuid,
          "type": "treatment_plan",
          "amount": amounts10
        },
        {
          "pharmacy_uuid": uuid,
          "type": "lab_package",
          "amount": amounts11
        },
        {
          "pharmacy_uuid": uuid,
          "type": "monthly_limit",
          "amount": amounts12
        },      
        {
          "pharmacy_uuid": uuid,
          "type": "Thyroid",
          "amount": amounts13
        },
      ]
    }
    console.log(payLoad,"valuesvalues1")

    axios.put(`${process.env.REACT_APP_WEB_APP}/user/pharmacyCommission`, payLoad)
      .then((res) => {
        console.log(res,payLoad, "res")
        if (res.status == 200 || res.status == 201) {
          notification.open({
            message: 'Success',
            description: res.data.message
          })
          // handleCancel();
          // window.location.reload(false);
          // getCommission()
        }
        else {
          notification.open({
            message: 'Error',
            description: "Something is Wrong"
          })
        }
      })
      .catch((err) => {
        notification.open({
          message: 'Error',
          description: "Something is Wrong"
        })
      })
  }
  const handleSubmit1 = values => {
    console.log(tabIndex, values, "values")
    let type1;
    var payLoad;
    if (tabIndex === 1 || tabIndex === "1") {
      type1 = "General"
      payLoad = {
        "pharmacy_name": values.pharmacy_name,
        "firstName": values.firstName,
        "lastName": values.lastName
      }

    }
    if (tabIndex == "2") {
      type1 = "address"
      payLoad = {
        "address": values.address,
        "area": values.area,
        "city": values.city,
        "country": values.country
      }
    }
    if (tabIndex === "3") {
      type1 = "bank"
      payLoad = {
        "account_name": values.acc_name,
        "branch_name": values.branch,
        "bank_name": values.bank_name,
        "account_type": values.acc_type,
        "account_number": values.acc_number
      }
    }
    dispatch(pharmacyActions.editPharma(uuid, payLoad, type1, handleOk));
  }

  const getCommission = () => {
    axios.get(`${process.env.REACT_APP_WEB_APP}/user/pharmacyCommission/${uuid && uuid}`)
      .then((res) => {
        console.log(res, "res")
        setCommList(res)

        // res.data.map((item) => {
        //   setAmount(item.amount) 
        // })
        setAmount(res && res.data && res.data[0] && res.data[0].amount)
        setAmount1(res && res.data && res.data[1] && res.data[1].amount)
        setAmount2(res && res.data && res.data[2] && res.data[2].amount)
        setAmount3(res && res.data && res.data[3] && res.data[3].amount)
        setAmount4(res && res.data && res.data[4] && res.data[4].amount)
        setAmount5(res && res.data && res.data[5] && res.data[5].amount)
        setAmount6(res && res.data && res.data[6] && res.data[6].amount)
        setAmount7(res && res.data && res.data[7] && res.data[7].amount)
        setAmount8(res && res.data && res.data[8] && res.data[8].amount)
        setAmount9(res && res.data && res.data[9] && res.data[9].amount)
        setAmount10(res && res.data && res.data[10] && res.data[10].amount)
        setAmount11(res && res.data && res.data[11] && res.data[11].amount)
        setAmount12(res && res.data && res.data[12] && res.data[12].amount)
        setAmount13(res && res.data && res.data[13] && res.data[13].amount)

      })
  }


  return(
  <> 
  <Helmet>
    <title>Doctall Super Admin Pharmacist Partners
</title>
<meta 
            name='description' 
            content='View pharmacy partners, locations, roles, and status. Add new partners'
         

   />
  </Helmet>
  <Modal
    title={`Pharmacy Details`}
    visible={visible}
    onOk={handleOk}
    onCancel={handleCancel}
    width={850}
    footer={[
      <>
        {/* {diff === "edit" &&
        <Button key='submit' type='primary' onClick={handleSubmit}>
          Ok
        </Button>
      } */}
      </>
    ]}
  >
    {/* <Spin spinning={loader}>
        <div className='flex-y align-left'>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Pharmacy Name
            </div>
            <div className='read-form-value'>{user && user.pharmacyName}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>Email</div>
            <div className='read-form-value'>{user && user.email}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Phone Number
            </div>
            <div className='read-form-value'>{user && user.phoneNo}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Inauguration Year
            </div>
            <div className='read-form-value'>
              {user && user.inaugurationYear}
            </div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>PAN</div>
            <div className='read-form-value'>{user && user.pan}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>GST</div>
            <div className='read-form-value'>{user && user.gst}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Licence No
            </div>
            <div className='read-form-value'>{user && user.licenceNo}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>Door No</div>
            <div className='read-form-value'>{user && user.doorNo}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>Street</div>
            <div className='read-form-value'>{user && user.street}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>Area</div>
            <div className='read-form-value'>{user && user.area}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>City</div>
            <div className='read-form-value'>{user && user.city}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>State</div>
            <div className='read-form-value'>{user && user.state}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>PinCode</div>
            <div className='read-form-value'>{user && user.pincode}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Status&nbsp;:&nbsp;
              {user && user.status ? (
                <img src={green_dot}  alt="active" />
              ) : (
                <img src={red_dot} alt="inactive" />
              )}
            </div>
          </div>
        </div>
      </Spin> */}
    <Tabs defaultActiveKey="1" onChange={handleTabs}>
      <TabPane tab="General" key="1">
        <Form onFinish={handleSubmit1} className='gx-form-row0' layout='vertical'>
          <Row className="space-between">
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Pharmacy Name"
                name="pharmacy_name"
                initialValue={user && user.pharmacy_name}
                rules={[{ required: true, message: "Please enter name!" }]}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="First Name"
                name="firstName"
                initialValue={user && user.firstName}
                rules={[{ required: true, message: "Please enter name!" }]}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Last Name"
                name="lastName"
                initialValue={user && user.lastName}
                rules={[{ required: true, message: "Please enter name!" }]}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              {/* <Form.Item
                label="Phone Number"
                name="mobile"
                rules={[{ required: true, message: "Please enter phone!" }]}
                initialValue={user && user.mobile}
              >
                <Input disabled={true} size="large" />
              </Form.Item> */}
            <Form.Item
              label='Mobile Number'
              name='mobile'
              // rules={[{ required: true, message: 'Please enter phone number' }]}
              initialValue={user && user.mobile}
            >
              <PhoneInput
                // style={errorstyle}
                inputStyle={{width :"230px"}}
                disabled={diff == "view"}
                className="inputPhone"
                country={country}
                countryCodeEditable={true}
              />
            </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Email ID"
                name="email"
                rules={[{ required: true, message: "Please enter email!" }]}
                initialValue={user && user.email}
              >
                <Input disabled={true} size="large" initialValue={user && user.email} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Group"
                name="group"
                rules={[
                  {
                    required: true,
                    message: "Please enter practice_started_year!",
                  },
                ]}
                initialValue={user && user.group}
              >
                <Input size="large" disabled={true} initialValue={user && user.group} />
              </Form.Item>
            </Col>
          </Row>
          {diff === "edit" &&
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button ml--10'>
              Submit
            </Button>
          }
        </Form>
      </TabPane>
      <TabPane tab="Address" key="2">
        <Form onFinish={handleSubmit1} className='gx-form-row0' layout='vertical'>
          <Row className="space-between">
            <Col span={24} xs={24} md={24}>
              <Form.Item
                label="Address"
                name="address"
                //rules={[{ required: true, message: "Please enter houseNumber!" }]}
                initialValue={user && user.profile && user.profile.address && user.profile.address.address}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="City"
                name="city"
                // rules={[{ required: true, message: "Please enter houseNumber!" }]}
                initialValue={user && user.profile && user.profile.address && user.profile.address.city}
              >
                <Select
                  showSearch
                  autoClearSearchValue
                  allowClear
                  optionFilterProp="children"
                  disabled={diff == 'view'}
                  onChange={(value, secondvalue) => {
                    console.log("cityyy", value
                    )
                    setnewcityuuid(value)
                    areasubmit(value)
                    // setareaId(value)

                    if (value && secondvalue)
                      setpartnercity(secondvalue.key)

                  }}
                  // value={city}
                  value={newcityuuid}
                >
                  {newcity.map(city => (

                    <Option key={city._id} value={city.uuid}>
                      {city.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Area"
                name="area"
              // rules={[{ required: true, message: "Please enter houseNumber!" }]}
              initialValue={user && user.profile && user.profile.address && user.profile.address.area}
              >
                <Select
                  showSearch
                  autoClearSearchValue
                  allowClear
                  optionFilterProp="children"
                  disabled={diff == 'view'}
                  onChange={(value, secondvalue) => {
                    console.log("cityyy", value
                    )
                    setareaId(value)
                    areasubmit(user && user.profile && user.profile.address && user.profile.address.city)

                    if (value && secondvalue)
                    setpartnerarea(secondvalue.key)

                  }}
                  value={areaId}
                >
                   {newareas.map(area => (
                    <Option key={area._id} value={area.name}>
                      {area.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Country"
                name="country"
                //rules={[{ required: true, message: "Please enter houseNumber!" }]}
                initialValue={user && user.profile && user.profile.address && user.profile.address.country}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>

          </Row>
          {diff === "edit" &&
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button ml--10'>
              Submit
            </Button>
          }
        </Form>
      </TabPane>
      <TabPane tab="Bank Details" key="3">
        <Form onFinish={handleSubmit1} className='gx-form-row0' layout='vertical'>
          <Row className="space-between">
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Account Name"
                name="acc_name"
                // rules={[{ required: true, message: "Please enter Account Name!" }]}
                initialValue={user && user.profile && user.profile.bank && user.profile.bank.account_name}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Account Number"
                name="acc_number"
                // rules={[{ required: true, message: "Please enter Account Number!" }]}
                initialValue={user && user.profile && user.profile.bank && user.profile.bank.account_number}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Bank Name"
                name="bank_name"
                // rules={[{ required: true, message: "Please enter state!" }]}
                initialValue={user && user.profile && user.profile.bank && user.profile.bank.bank_name}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Branch Name"
                name="branch"
                // rules={[{ required: true, message: "Please enter state!" }]}
                initialValue={user && user.profile && user.profile.bank && user.profile.bank.branch_name}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Account Type"
                name="acc_type"
                // rules={[{ required: true, message: "Please enter state!" }]}
                initialValue={user && user.profile && user.profile.bank && user.profile.bank.account_type}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}></Col>

          </Row>
          {diff === "edit" &&
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button ml--10'>
              Submit
            </Button>
          }
        </Form>
      </TabPane>
      <TabPane tab="Commission" key="4">
        <form onSubmit={handleComm}>
          <Row>
            <Col span={7} xs={7} md={7}>
              REGISTER
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[0] && commList.data[0].amount} onChange={(event) => registeramount(event)} name="register"  ></input>
            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={7} xs={7} md={7}>
              SADN
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[3] && commList.data[3].amount} onChange={(event) => sadnamount(event)} name="sadn"></input>

            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={7} xs={7} md={7}>
              FADN
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[7] && commList.data[7].amount} onChange={(event) => fadnamount(event)} name="fadn" ></input>
            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={7} xs={7} md={7}>
              DMP ORDERS
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[2] && commList.data[2].amount} onChange={(event) => dmpamount(event)} name="dmp"  ></input>
            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={7} xs={7} md={7}>
              TREATMENT PLAN
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[10] && commList.data[10].amount} onChange={(event) => treatmentamount(event)} name="treatment"></input>
            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={7} xs={7} md={7}>
              LAB PACKAGES
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[11] && commList.data[11].amount} onChange={(event) => LabPackegeamount(event)} name="lab_package"></input>
            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={7} xs={7} md={7}>
             HEALTH PROFILE
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[1] && commList.data[1].amount} onChange={(event) => HealthProfileamount(event)} name="health_profile"></input>
            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={7} xs={7} md={7}>
             MONTHLY LIMIT
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[12] && commList.data[12].amount} onChange={(event) => MonthlyLimitsamount(event)} name="monthly_limit"></input>
            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>

{/* new fields */}
          <Row>
            <Col span={7} xs={7} md={7}>
             HAIR LOSS
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[4] && commList.data[4].amount} onChange={(event) => Hairloss(event)} name="Hairloss"></input>
            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>




          <Row>
            <Col span={7} xs={7} md={7}>
             PREMATURE EJACULATION
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[5] && commList.data[5].amount} onChange={(event) => PrematureEjaculation(event)} name="Premature Ejaculation"></input>
            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>

          <Row>

            <Col span={7} xs={7} md={7}>
             DIABETES
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[6] && commList.data[6].amount} onChange={(event) => Diabetes(event)} name="Diabetes"></input>
            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={7} xs={7} md={7}>
             HEART AND HYPERTENSION
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[8] && commList.data[8].amount} onChange={(event) => HeartAndHypertension(event)} name="Heart and Hypertension"></input>
            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={7} xs={7} md={7}>
             ERECTILE DYSFUNCTION
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[9] && commList.data[9].amount} onChange={(event) => ErectileDysfunction(event)} name="Erectile dysfunction"></input>
            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={7} xs={7} md={7}>
             THYROID
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[13] && commList.data[13].amount} onChange={(event) => Thyroid(event)} name="Thyroid"></input>
            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>
          
          
          {diff === "edit" &&
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button ml--10'>
              Submit
            </Button>
          }
        </form>
        
      </TabPane>
      {/*  LiDateIssue */}

    </Tabs>
  </Modal>
  </>)
  // );
};

export default ViewPharmacy;
