const userActions = {
  GET_USERS: "GET_USERS",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_PARTNER: "GET_PARTNER",
  GET_PARTNER_SUCCESS: "GET_PARTNER_SUCCESS",
  ADD_USER: "ADD_USER",
  ON_VERIFIED: "ON_VERIFIED",
  ADD_CLINIC: "ADD_CLINIC",
  ADD_LAB: "ADD_LAB",
  ADD_PHARMACY: "ADD_PHARMACY",
  ON_ERROR: "ON_ERROR",
  SET_SKIP: "SET_SKIP",
  SET_LIMIT: "SET_LIMIT",
  ACTIVE_USER: 'ACTIVE_USER',
  ADD_PARTNER: "ADD_PARTNER",
  ADD_PARTNER_SUCCESS: "ADD_PARTNER_SUCCESS",
  GET_INFLUENCER : "GET_INFLUENCERS",
  GET_INFLUENCER_SUCESS : "GET_INFLUENCERS",

  addPartners: (data) => {
    console.log("add partner action", data)
    return {
      type: userActions.ADD_PARTNER,
      data
    };
  },
  getUsers: (searchQuery) => {
    return {
      type: userActions.GET_USERS,
      searchQuery
    };
  },
  getPartner: () => {
    return {
      type: userActions.GET_PARTNER,
    };
  },
  getInfluencer: () => {
    return {
      type: userActions.GET_INFLUENCER,
    };
  },

  addUser: (data) => {
    return {
      type: userActions.ADD_USER,
      data
    };
  },
  
  onVerified: (email) => {
    return {
      type: userActions.ON_VERIFIED,
      email
    }
  },
  addClinic: (data) => {
    return {
      type: userActions.ADD_CLINIC,
      data
    };
  },
  addLab: (data) => {
    return {
      type: userActions.ADD_LAB,
      data
    };
  },
  addPharmacy: (data) => {
    return {
      type: userActions.ADD_PHARMACY,
      data
    };
  },
  setSkip: (skip) => {
    return {
      type: userActions.SET_SKIP,
      skip
    }
  },
  setLimit: (limit) => {
    return {
      type: userActions.SET_LIMIT,
      limit
    }
  },
  activeUser: (data) => {
    return {
      type: userActions.ACTIVE_USER,
      data
    }
  }
};

export default userActions;
