import React, { useState, useEffect } from 'react';
import { Button, Input } from 'antd';
import AddPharmacy from 'components/pharmacies/AddPharmacy';
import AddInfluencers from 'components/influencer_new/AddInfluencer'
// import Pharmacies from 'components/pharmacies/Pharmacies';
import Pharmacies from 'components/influencer_new/InfluencerList';
import { useDispatch, useSelector } from "react-redux";
import actions from "actions";
import pharmacyActions from 'actions/pharmacy';
import { AudioOutlined } from '@ant-design/icons';
const { Search } = Input;
const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: '#1890ff',
    }}
  />
);


const { userActions, commonActions , influencerActions} = actions;
const PharmaciesView = () => {
    const dispatch = useDispatch();
    const [visible, setvisible] = useState(false);
    const citiesnew = useSelector((state) => state.common.newcities);
    const areasnew = useSelector((state) => state.common.newareas);

    useEffect(() => {
        dispatch(commonActions.getMetadata());
        dispatch(commonActions.getallnewcities());
    }, [dispatch]);
    return (
        <div className="pictogram-container">
            <div className="pictogram-header flex-x align-center ptb--15">
                <div className="fs--16 font-semibold flex-1">
                Influencers
                
                </div>
                <Button style={{ height: 42 }} type="primary" onClick={() => setvisible(true)}>Add Influencer</Button>
            </div>
            <div>
                <Pharmacies
                    newcity={citiesnew}

                    onGetarea={(value) => {
                        console.log("area222", value);
                        dispatch(commonActions.getallnewarea(value));
                    }}
                    newareas={areasnew}
                />
            </div>
            <AddInfluencers
                visible={visible}
                handleOk={() => setvisible(false)}
                handleCancel={() => setvisible(false)}
                newcity={citiesnew}
                newareas={areasnew}
                onGetarea={(value) => {
                    console.log("area222", value);
                    dispatch(commonActions.getallnewarea(value));
                }}
                onSubmit={(value, type) => {
                    console.log("easy", value, type);
                    dispatch(influencerActions.register(value));
                    //   switch (type) {
                    //     case "addpartner":
                    //       dispatch(userActions.addPartners(value));
                    //       break;
                    //     case "clinic":
                    //       dispatch(userActions.addClinic(value));
                    //       break;
                    //     case "lab":
                    //       dispatch(userActions.addLab(value));
                    //       break;
                    //     case "pharmacy":
                    //       dispatch(userActions.addPharmacy(value));
                    //       break;
                    //     default:
                    //       console.log("invalid type");
                    //   }
                }}
            />
        </div>
    );
};

export default PharmaciesView;