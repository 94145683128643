import actions from 'actions';
const { pushNotificationAction } = actions;

const initState = {
    loader: false,
    data: [],
    partners: [],
    limit: 10,
    skip: 0,
    count: 0,
    notificationdetails: []
};



export default function rootReducer(state = initState, action) {
    switch (action.type) {
        case pushNotificationAction.ADD_NOTIFICATION:
            return {
                ...state,
                loader: true
            };

        case pushNotificationAction.SEND_FROM_DRAFT:
            return {
                ...state,
                loader: true
            };

        case pushNotificationAction.GET_NOTIFICATIONS:
            return {
                ...state,
                loader: true,
                partnersdetails: action.data
            };
        case pushNotificationAction.GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.data,
                count: action.count,
                partners: action.partners
            };
        default:
            return state;
    }
}
