import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from 'actions';
import { getorderservice, changeOrderstatus } from 'services/order-service';
import { notification } from 'antd';
const { ORDERACTION, authActions } = actions;

export function* getordersaga() {
  yield takeEvery(ORDERACTION.GET_OREDER, function* (query, queryText) {
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield getorderservice({ token }, query, queryText);
      const { data } = response;
      yield put({
        type: ORDERACTION.GET_OREDER_SUCCESS,
        data
      });

    } catch (error) {
      yield notification.open({ message: 'Failed!', description: error.response && error.response.data.message })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

let count = 0;
export function* editOrderStatus() {
  yield takeEvery(ORDERACTION.CHANGE_OREDER_STATUS, function* (orderId, orderStatus, itemId) {
    try {

      if (count === 0) {
        count++
        const token = yield select(state => state.auth.accessToken);
        const response = yield changeOrderstatus({ token }, orderId, orderStatus, itemId);
        const { data } = response;
        if (response.status === 200) {
          yield notification.open({
            message: 'Success!',
            description: 'Order Status Updated Successfully'
          });
          yield put({
            type: ORDERACTION.CHANGE_OREDER_STATUS_SUCCESS
          });
          yield put({
            type: ORDERACTION.GET_OREDER
          });
        } else {
          throw response;
        }
      } else {
        count = 0;
      }

    } catch (error) {
      yield notification.open({ message: 'Failed!', description: error.response && error.response.data })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}



export default function* rootSaga() {
  yield all([fork(getordersaga), fork(editOrderStatus)]);
}

