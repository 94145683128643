import { all, takeEvery, put, fork, select} from 'redux-saga/effects';
import actions from 'actions';
import { getTransaction } from 'services/transaction-service';
import { notification } from 'antd'
const { transactionActions,authActions } = actions;

export function* getTransactionsSaga() {
  yield takeEvery(transactionActions.GET_TRANSACTION, function*(type) {
    console.log(type.Ttype, type.page, "ahsdasguy")
    const typeA = type.Ttype
    const page = type.page
    try{
      const token = yield select(state => state.auth.accessToken);
      const response = yield getTransaction({token}, typeA, page);
        const { data } = response;
        console.log(response, data, "ahsdasguy")
        yield put({
          type: transactionActions.GET_TRANSACTION_SUCCESS,
          data
        });
    }catch(error){
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        // yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getTransactionsSaga)]);
}
