import React from 'react';
import { Tabs, Radio } from "antd";
import actions from "actions";
import SaasDoctorsPage from './SaasDoctorsPage';
import SaasPatientsPage from './SaasPatientsPage';

const { TabPane } = Tabs;


export default function SaasPage() {
    return (
        <>
            <Tabs defaultActiveKey="1" type="card" size={'small'}>
                <TabPane tab="Doctors" key="1">
                    <SaasDoctorsPage />
                </TabPane>
                <TabPane tab="Patients" key="3">
                    <SaasPatientsPage />
                </TabPane>

            </Tabs>
        </>
    )
}
