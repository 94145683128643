const GETSLOTBYNAME = {
  GET_UNIQUE_SLOTS: "GET_UNIQUE_SLOTS",
  GET_UNIQUE_SLOTS_SUCCESS: "GET_UNIQUE_SLOTS_SUCCESS",
  getSlots: () => {
    // console.log(params, "actionparamsSlots");
    return {
      type: GETSLOTBYNAME.GET_UNIQUE_SLOTS,
    };
  },
};

export default GETSLOTBYNAME;
