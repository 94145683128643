
// Used assets
export const yoopha = require('assets/images/yoopha.png');
export const userImage = require('assets/images/user.png');
export const user = require('assets/images/tick-inside-circle.png')
export const icon = require('assets/images/icon.png');
export const green_dot = require('assets/images/green-dot.png');
export const red_dot = require('assets/images/red-dot.png');
export const view_log = require('assets/images/view-log.png');
export const triple_dots = require('assets/images/tripledots.svg');
export const doctall = require('assets/images/Group.png')
// For Future Use
