import { all, takeEvery, put, fork, select } from "redux-saga/effects";
import actions from "actions";
import { getPatientsByConsumer } from "services/bookAppointment";
import { notification } from "antd";
const { GETPATIENTSBYCON, authActions } = actions;

export function* getpatientsallSaga() {
  yield takeEvery(GETPATIENTSBYCON.GET_UNIQUE_PATIENTS, function* (params) {
    try {
      const token = yield select(state => state.auth.accessToken);
      console.log(params, "sagaparamseg");
      const response = yield getPatientsByConsumer({token},params);
      console.log("response", response);
      const { data } = response;
      yield put({
        type: GETPATIENTSBYCON.GET_UNIQUE_PATIENTS_SUCCESS,
        data: data,
      });
    } catch (error) {
      // yield notification.open({
      //   message: "Failed!",
      //   description: "Something went wrongg",
      // });
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getpatientsallSaga)]);
}
