import { all, takeEvery, put, fork ,select} from 'redux-saga/effects';
import actions from 'actions';
import { getFeedbacks } from 'services/feed-services';
import { notification } from 'antd'
const { feebackActions,authActions } = actions;

export function* getFeedbacksSaga() {
  yield takeEvery(feebackActions.GET_FEEDBACKS, function*({query}) {
  // let data;
  try{
      const token = yield select(state => state.auth.accessToken);
      const response = yield getFeedbacks({token},query);
        let {data}  = response;
        yield put({
          type: feebackActions.SET_FEEDBACKS,
          data
        });
    }catch(error){
      let data =[]
      if (error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR  });
      }
      yield put({
        type: feebackActions.SET_FEEDBACKS,
        data
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getFeedbacksSaga)]);
}
