import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Input, Space } from 'antd';
import {
    EditOutlined
} from '@ant-design/icons';
import moment from "moment";
import actions from "actions";
import Influencer from 'components/Influencer/Influencer';
import AddPartner from 'components/users/AddPartner';
import EditInfluencers from "components/Influencer/editInfluencer";
import EditPartner from 'components/users/EditPartner';
import axios from 'axios';
import { DatePicker, Select, notification } from 'antd';
import { exportToExcel } from "services/ExportServices";

const Search = Input.Search;
const { Option } = Select;
const { RangePicker } = DatePicker;

const { influencerActions, userActions, commonActions, influencers } = actions;


export default function InfluencerTable() {
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState('')
    const [pageNumber , setPageNumber] = useState(false)
    const [visiblePartner, setvisiblePartner] = useState(false);
    const [influencervisible, setvisibleInfluencer] = useState(false)
    const [visibleInfluencerEdit , setVisibleInfluencerEdit] = useState(false)
    const [visiblePartnerEdit, setVisiblePartnerEdit] = useState(false);


    const [updateFlag, setUpdateFlag] = useState("ADD");
    const [existingDetails, setExistingDetails] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const [visible, setvisible] = useState(false);
    const influencer = useSelector((state) => state.users.influencers )
    const partner = useSelector((state) => state.users.partners);
    const cities = useSelector((state) => state.common.cities);
    const states = useSelector((state) => state.common.states);
    const citiesnew = useSelector((state) => state.common.newcities);
    const areasnew = useSelector((state) => state.common.newareas);
    const areas = useSelector((state) => state.common.areas);
    const authToken = useSelector((state) => state.auth.accessToken);
    console.log(authToken, "authToken")

    //To get the List of influencers
    const influencers = useSelector((state) => state.influencerReducer);
    console.log(influencers,"influencers")

    // useEffect(() => {
    //     dispatch(influencerActions.getInfluencers(0, setSearchQuery));    
    // }, [searchQuery])

    

    const handleOnSearch = (value) => {
        setSearchQuery(value)
    }



    const editInfluencersDetails = async (payload, uuid) => {
        try {
            let response = await axios.put(`${process.env.REACT_APP_WEB_APP}/user/auth/influencer/update/${uuid}`, payload)
            dispatch(influencerActions.getInfluencers(0, searchQuery));
        } catch (error) {
            if (error && error.response) {
                notification.open({
                    message: "" + error.response.data.message,
                    duration: 1,
                });
            }
        }

    }


    const columns = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            render: (text, row) => (
                <div>{row && row.firstName && row.firstName !== null ? row.firstName : ""} {row && row.lastName && row.lastName !== null ? row.lastName : ""}</div>
            ),
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'last_name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Group',
            dataIndex: 'group',
            key: 'group'
        },
        {
            title: 'Contact No.',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        // {
        //     title: 'Role',
        //     dataIndex: 'role',
        //     key: 'role',
        // },
        {
            title: 'Status',
            dataIndex: 'verified',
            key: 'role',
            render: (text, row) => (
                <div>{row && row.active && row.active === true ? "Active" : "In-Active"}</div>
            ),
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (text, row) => (
                <div style={{ justifyContent: 'center', display: 'flex' }}>

                    <EditOutlined style={{ color: "#1974D2" }}
                        onClick={() => {
                            setVisibleInfluencerEdit(true)
                            setExistingDetails(row)
                        }} />
                </div>
            )
        },
    ];


    const onExportClick = async () => {
        try {
            let APIENDPOINT = ''
            if (toDate === undefined || fromDate === undefined) {
                notification.open({
                    message: "Please Select Dates",
                    duration: 2,
                });
            } else {
                if (moment(toDate).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD") || moment(fromDate).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
                    notification.open({
                        message: "Invalid Date",
                        duration: 1,
                    });
                } else {
                    APIENDPOINT = `/user/partner/export/toexcel/${fromDate}/${toDate}`
                    await exportToExcel(APIENDPOINT, "Partners_", authToken)
                }
            }

        } catch (error) {
            notification.open({
                message: "Failed to export",
                duration: 1,
            });
        }
    }

    function onChange(dates, dateStrings) {
        setFromDate(dateStrings[0])
        setToDate(dateStrings[1])
    }


    return (
        <>
            <div className="pictogram-container">

                <div className="pictogram-header flex-x align-center ptb--15">

                    <div className="fs--16 font-semibold flex-1">
                        <Space direction="vertical">
                            <Search
                                size="large"
                                enterButton="Search"
                                placeholder="Search influencer"
                                onSearch={handleOnSearch}
                            />
                        </Space>
                    </div>


                    <div>

                        <Space direction="vertical" size={12}>

                            <RangePicker
                                ranges={{
                                    Today: [moment(), moment()],
                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                }}
                                format="YYYY-MM-DD"
                                onChange={onChange}
                                disabledDate={d => !d || d.isAfter(moment.format)}
                            />

                        </Space>

                        <Button style={{ height: 42, marginRight: 10, marginLeft: 20 }} type="primary"
                            onClick={onExportClick}
                        >
                            Export
                        </Button>

                        <div className="fs--16 font-semibold flex-1">{/* Users */}</div>


                    </div>

                    {/* <Button
                        style={{ height: 42, marginRight: 10 }}
                        type="primary"
                        onClick={() => setvisiblePartner(true)}
                    >
                        Add Partner
                    </Button> */}
                    <Button
                        style={{ height: 42, marginRight: 10 }}
                        type="primary"
                        onClick={() => setvisibleInfluencer(true)}
                    >
                        Add Influencer
                    </Button>

                </div>


                <div>
                    <Table
                        columns={columns}
                        bordered={true}
                        size='middle'
                        dataSource={influencers && influencers.data && influencers.data.data && influencers.data.data}
                        
                        loading={influencers && influencers.loader}
                        pagination={{
                            total: influencers && influencers.count,
                            showSizeChanger: false
                        }}
                        
                        onChange={(pagination) => {
                            console.log(pagination,"pagination")
                            let pageNumber = ''
                            if (pagination.current === 1) {
                                pageNumber = 0
                            } else {
                                pageNumber = pagination.current - 1
                            }
                            dispatch(influencerActions.getInfluencers(pageNumber, searchQuery));
                        }}
                    />
                  
                </div>

                {/* <AddPartner
                    visible={visiblePartner}
                    partnerUser={partner}
                    updateFlags={updateFlag}
                    exDetails={existingDetails}
                    handleOk={() => setvisiblePartner(false)}
                    cities={cities}
                    states={states}
                    newcity={citiesnew}
                    newareas={areasnew}
                    areas={areas}
                    onGetarea={(value) => {
                        dispatch(commonActions.getallnewarea(value));
                    }}
                    onSubmit={(value, type) => {
                        switch (type) {
                            case "addpartner":
                                dispatch(partnersActions.addingPartner(value));
                            default:
                                console.log("invalid type");
                        }
                    }}
                    handleCancel={() => setvisiblePartner(false)}
                /> */}

<Influencer
                    visible={influencervisible}
                    partnerUser={influencer}
                    updateFlags={updateFlag}
                    exDetails={existingDetails}
                    handleOk={() => setvisibleInfluencer(false)}
                    cities={cities}
                    states={states}
                    newcity={citiesnew}
                    newareas={areasnew}
                    areas={areas}
                    onGetarea={(value) => {
                        dispatch(commonActions.getallnewarea(value));
                    }}
                    onSubmit={(value, type) => {
                        // switch (type) {
                        //     case "addpartner":
                        dispatch(influencerActions.register(value));
                            // default:
                            //     console.log("invalid type");
                        
                    }}
                    handleCancel={() => setvisibleInfluencer(false)}
                />

                <EditInfluencers
                    visible={visiblePartnerEdit}
                    partnerUser={influencer }
                    updateFlags={updateFlag}
                    exDetails={existingDetails}
                    handleOk={() => setvisiblePartner(false)}
                    cities={cities}
                    states={states}
                    newcity={citiesnew}
                    newareas={areasnew}
                    areas={areas}
                    onGetarea={(value) => {
                        dispatch(commonActions.getallnewarea(value));
                    }}
                    onSubmit={(value, type) => {
                        editInfluencersDetails(value, type)
                    }}
                    handleCancel={() => setVisibleInfluencerEdit(false)}
                />


            </div>
        </>
    )
}
