import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
// import AddPharmacy from 'components/pharmacies/AddPharmacy';
import PartnerName from "components/Company_name/AddCompany"
import Pharmacies from 'components/Company_name/CompanyName';
import { useDispatch, useSelector } from "react-redux";
import actions from "actions";
import pharmacyActions from 'actions/pharmacy';
import AddComanyData from "components/Company_name/AddCompanyData"

const { commonActions } = actions;
const { master } = actions
console.log(master,"master")
const PharmaciesView = () => {
    const dispatch = useDispatch();
    const [visible, setvisible] = useState(false);
    const [visible1, setvisible1] = useState(false);
    const citiesnew = useSelector((state) => state.common.newcities);
    const areasnew = useSelector((state) => state.common.newareas);

    console.log(visible,"visible")
    useEffect(() => {
        dispatch(commonActions.getMetadata());
        dispatch(commonActions.getallnewcities());
    }, [dispatch]);
    return (
        <div className="pictogram-container">
            <div className="pictogram-header flex-x align-center ptb--15">
                <div className="fs--16 font-semibold flex-1">
                {/* Pharmacist Partners */}
                Corporate
                </div>
                <Button style={{ height: 42 }} type="primary" onClick={() => setvisible(true)}>Add Company </Button> &nbsp; &nbsp;
                <Button style={{ height: 42 }} type="primary" onClick={() => setvisible1(true)}>Upload Users</Button>
            </div>
            <div>
                <Pharmacies
                    newcity={citiesnew}

                    onGetarea={(value) => {
                        console.log("area222", value);
                        dispatch(commonActions.getallnewarea(value));
                    }}
                    newareas={areasnew}
                />
            </div>
            <PartnerName
                visible={visible}
                handleOk={() => setvisible(false)}
                handleCancel={() => setvisible(false)}
                newcity={citiesnew}
                newareas={areasnew}
                // onGetarea={(value) => {
                //     console.log("area222", value);
                //     dispatch(commonActions.getallnewarea(value));
                // }}
                onSubmit={(value, type) => {
                    console.log("easy", value, type);
                    dispatch(master.addCompany(value));
                }}
            />
            <AddComanyData
                visible={visible1}
                handleOk={() => setvisible1(false)}
                handleCancel={() => setvisible1(false)}
                newcity={citiesnew}
                newareas={areasnew}
                // onGetarea={(value) => {
                //     console.log("area222", value);
                //     dispatch(commonActions.getallnewarea(value));
                // }}
                onSubmit={(value, type) => {
                    console.log("easy", value, type);
                    dispatch(master.addCompany(value));
                }}
            />
        </div>
    );
};

export default PharmaciesView;