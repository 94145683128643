import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Button, Tabs, Radio } from "antd";
import actions from "actions";
import CouponsTable from 'components/coupons/coupon-table/CouponsTable';
import CreateCoupon from 'components/coupons/CreateCoupon';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const { couponsActions } = actions;

export default function CouposListPage() {

    const dispatch = useDispatch();
    const [createCouponVisibility, setCreateCouponVisibility] = useState(false);

    const [infName, setInfName] = useState([])

    const couponsData = useSelector((state) => state.couponsReducer);
    const authToken = useSelector((state) => state.auth)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_WEB_APP}/user/auth/influencer/list?limit=100&pagenum=&search=`,{
            headers: { "x-auth-token": authToken.accessToken }
        })
        .then((res) => {
            console.log(res, "sgfhj")
            setInfName(res.data.data)
        })
        .catch((err) => {
            console.log(err)
        })
    },[])
    return (
        <>
        <Helmet>
            <title>Doctall Super Admin Coupons
</title>
<meta 
            name='description' 
            content='Create and deploy coupons to patients. Set name, amount and validity period for coupons'
         

   />
        </Helmet>
            <div className="pictogram-container">
                <div className="pictogram-header flex-x align-center ptb--15">
                    <div className="fs--16 font-semibold flex-1">{/* Users */}</div>
                    <Button
                        style={{ height: 42 }}
                        type="primary"
                        onClick={() => setCreateCouponVisibility(true)}
                    >
                        Add New
                    </Button>
                </div>
                <div>
                    <CouponsTable
                        couponsData={couponsData}
                        isPromoCode={false}
                    />
                </div>
                <CreateCoupon
                    show={createCouponVisibility}
                    close={() => { setCreateCouponVisibility(false) }}
                    onSubmit={(payLoad) => {
                        dispatch(couponsActions.createCoupons(payLoad));
                    }}
                    infName={infName}
                />
            </div>
        </>
    )
}
