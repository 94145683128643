import actions from 'actions';

const { couponsActions } = actions;

const initState = {
    loader: false,
    data: [],
    partners: [],
    limit: 10,
    skip: 0,
    count: 0,
    notificationdetails: [],
    promoCodeData: [],
    promoCodeCount: 0,
};

export default function rootReducer(state = initState, action) {
    switch (action.type) {
        case couponsActions.CREATE_COUPONS:
            return {
                ...state,
                loader: true
            };

        case couponsActions.EDIT_COUPONS:
            return {
                ...state,
                loader: true
            };
            case couponsActions.CREATE_COUPONS_FAILURE:
                return {
                    ...state,
                    loader: false
                };
            
        case couponsActions.GET_COUPONS:
            return {
                ...state,
                loader: true,
                partnersdetails: action.data
            };
        case couponsActions.GET_PROMOCODE:
            return {
                ...state,
                loader: true,
            };

        case couponsActions.GET_COUPONS_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.data,
                count: action.count,
                partners: action.partners
            };

        case couponsActions.GET_PROMOCODE_SUCCESS:
            return {
                ...state,
                loader: false,
                promoCodeData: action.data,
                promoCodeCount: action.count,
            };

        default:
            return state;
    }
}
