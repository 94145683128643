import React, { useState } from 'react';
import { Table } from 'antd';
import moment from "moment";
import { view_log } from 'helper/constant';
import NotificationView from '../notification--view/NotificationView';
import actions from "actions";
import { useDispatch, useSelector } from "react-redux";


const { pushNotificationAction } = actions;

export default function NotificationTable(props) {
    const dispatch = useDispatch();
    const { data } = props
    const [notificationView, setNotificationView] = useState(false);
    const [notificationDetails, setNotificationDetails] = useState();


    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, row) => (
                <div>{moment(row.createdAt).format("DD/MM/yyyy hh:mm a")}</div>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Description ',
            dataIndex: 'message',
            key: 'message'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, row) => (
                <div>{row.status === 'draft' ? "Draft" : "Sent"}</div>
            ),
        },
        {
            title: 'View',
            dataIndex: 'eye',
            key: 'eye',
            render: (text, row) => (
                <img
                    className='ml--10 cursor-pointer'
                    src={view_log && view_log.default?view_log.default:view_log}
                    alt='view'
                    onClick={() => {
                        setNotificationDetails(row)
                        setNotificationView(true)
                    }}
                />
            )
        },
    ];


    return (
        <>
            <div>
                <Table
                    bordered={true}
                    columns={columns}
                    dataSource={data && data.data}
                    size='middle'
                    loading={data && data.loader}
                    pagination={{
                        total: data && data.count
                    }}
                    onChange={(pagination) => {
                        let pageNumber = ''
                        if (pagination.current === 1) {
                            pageNumber = 0
                        } else {
                            pageNumber = pagination.current - 1
                        }
                        dispatch(pushNotificationAction.getNotification(pageNumber));
                    }}
                />
            </div>

            <NotificationView
                notificationDetails={notificationDetails}
                visible={notificationView}
                close={() => { setNotificationView(false) }}
                onSubmit={(details) => {
                    dispatch(pushNotificationAction.sendDraft(details));
                }}
            />
        </>
    )
}