import React, { useEffect, useState } from 'react';
import { Button, Input, Space } from "antd";
import actions from "actions";
import SaasPatientsTable from "components/saas/SaasPatientsTable";
import { useDispatch, useSelector } from "react-redux";
import { exportToExcel } from 'services/ExportServices';
import moment from 'moment';
import { DatePicker, Select, notification } from 'antd';

const Search = Input.Search;
const { saasActions } = actions;
const { RangePicker } = DatePicker;
const { Option } = Select;


export default function SaasPatientsPage() {
    const dispatch = useDispatch();
    const authToken = useSelector((state) => state.auth.accessToken);
    const doctorsData = useSelector((state) => state.saasReducer);

    const [searchQuery, setSearchQuery] = useState('')
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [doctorId, setDoctorId] = useState();


    useEffect(() => {
        dispatch(saasActions.getSaasPatients(0, searchQuery, doctorId))
    }, [searchQuery, doctorId])


    const handleOnSearch = (value) => {
        setSearchQuery(value)
    }
    function onChange(dates, dateStrings) {
        setFromDate(dateStrings[0])
        setToDate(dateStrings[1])
    }


    const handleExport = async () => {
        if (toDate === undefined || fromDate === undefined) {
            notification.open({
                message: "Please Select Dates",
                duration: 2,
            });
        } else {
            await exportToExcel(`/externalappointment/export/toexcel/${fromDate}/${toDate}`, "SAAS_PATIENT", authToken);
        }
    }


    return (
        <>
            <div className="pictogram-container">
                <div className="pictogram-header flex-x align-center ptb--15">
                    <Space direction="vertical">
                        <Search
                            size="large"
                            enterButton="Search"
                            placeholder="Search Record"
                            onSearch={handleOnSearch}
                        />
                    </Space>
                    <div className="fs--16 font-semibold flex-1">

                        <Select
                            placeholder="Doctor Name"
                            size="large"
                            showSearch
                            style={{ width: 250, marginLeft: 60 }}
                            autoClearSearchValue
                            allowClear
                            onClear={() => {
                                dispatch(saasActions.getSaasPatients(0, searchQuery))
                            }}
                            optionFilterProp="children"
                            labelInValue
                            onChange={(event) => {
                                if (event) {
                                    setDoctorId(event.value)
                                }
                            }}
                        >
                            {doctorsData && doctorsData.data &&
                                doctorsData.data.reverse().map((item) => (
                                    <Option key={item.uuid} value={item.uuid}>
                                        {item.full_name}
                                    </Option>
                                ))}
                        </Select>
                    </div>

                    <Space direction="vertical" size={12}>

                        <RangePicker
                            ranges={{
                                Today: [moment(), moment()],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                            }}
                            format="YYYY-MM-DD"
                            onChange={onChange}
                        />

                    </Space>
                    <Button type="primary" onClick={handleExport}>Export</Button>

                </div>
                <div>
                    <SaasPatientsTable />
                </div>
            </div>
        </>
    )
}
