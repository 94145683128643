import React, { useState, useEffect } from 'react';
import { Button,Input } from 'antd';
// import { Table, Popover,Input } from 'antd';
// import AddPharmacy from 'components/pharmacies/AddPharmacy';
import PartnerName from "components/Partners_name/AddPartners"
import Pharmacies from 'components/Partners_name/partnersName';
import { useDispatch, useSelector } from "react-redux";
import actions from "actions";
import pharmacyActions from 'actions/pharmacy';

const { commonActions } = actions;
const { master } = actions
console.log(master,"master")
const PharmaciesView = () => {
    const dispatch = useDispatch();
    const [visible, setvisible] = useState(false);
    const citiesnew = useSelector((state) => state.common.newcities);
    const areasnew = useSelector((state) => state.common.newareas);

    useEffect(() => {
        dispatch(commonActions.getMetadata());
        dispatch(commonActions.getallnewcities());
    }, [dispatch]);
    return (
        <div className="pictogram-container">
            <div className="pictogram-header flex-x align-center ptb--15">
                <div className="fs--16 font-semibold flex-1">
                {/* Pharmacist Partners */}
                Partner Name
                </div>
                <Button style={{ height: 42 }} type="primary" onClick={() => setvisible(true)}>Add</Button>
            </div>

            {/* <Input 
    // onChange={ e => onChangesearch(e.target.value)}
    type="text"
    placeholder='Search'  
/>

<br/><br/> */}
            <div>
                <Pharmacies
                    newcity={citiesnew}

                    onGetarea={(value) => {
                        console.log("area222", value);
                        dispatch(commonActions.getallnewarea(value));
                    }}
                    newareas={areasnew}
                />
            </div>
            <PartnerName
                visible={visible}
                handleOk={() => setvisible(false)}
                handleCancel={() => setvisible(false)}
                newcity={citiesnew}
                newareas={areasnew}
                // onGetarea={(value) => {
                //     console.log("area222", value);
                //     dispatch(commonActions.getallnewarea(value));
                // }}
                onSubmit={(value, type) => {
                    console.log("easy", value, type);
                    dispatch(master.addPArtner(value));
                }}
            />
        </div>
    );
};

export default PharmaciesView;