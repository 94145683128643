import axios from 'axios';
import { setHeadersWithAccessToken } from './index';
const WEB_API_BASE = process.env.REACT_APP_WEB_APP;



export const getPartnersName = (auth,searchData) => {



  setHeadersWithAccessToken(auth);
  let search;
  if (searchData === undefined) {
    search = " "
  } else {
    search = searchData
  }
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e)1111;
  return axios.get(`${WEB_API_BASE}/metadata/partner/getpartner?limit=10&pagenum=0&search=${search}`).then(e => e);
};

export const getCompanyDataName = (auth) => {

  setHeadersWithAccessToken(auth);
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.get(`${WEB_API_BASE}/user/auth/company/list?limit=10&pagenum=0&search=`).then(e => e);
};


export const getCityName = (auth,searchData) => {

  setHeadersWithAccessToken(auth);
  let search;
  if (searchData === undefined) {
    search = " "
  } else {
    search = searchData
  }
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.get(`${WEB_API_BASE}/upload/newcity?limit=10&pagenum=0&search=${search}`).then(e => e);
};

export const getAreaName = (auth,searchData) => {

  setHeadersWithAccessToken(auth);
  let search;
  if (searchData === undefined) {
    search = " "
  } else {
    search = searchData
  }
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.get(`${WEB_API_BASE}/upload/newarea?limit=10&pagenum=0&search=${search}`).then(e => e);
};

export const editePartner = (auth,payLoad) => {

  setHeadersWithAccessToken(auth);
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.put(`${WEB_API_BASE}/metadata/partner/`,payLoad).then(e => e);
};
export const editeCompany = (auth,payLoad) => {

  setHeadersWithAccessToken(auth);
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.put(`${WEB_API_BASE}/user/auth/company/update/`,payLoad).then(e => e);
};


export const editeCity = (auth,payLoad) => {

  setHeadersWithAccessToken(auth);
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.put(`${WEB_API_BASE}/upload/newcity/`,payLoad).then(e => e);
};

export const editeAreaName = (auth,payLoad) => {

  setHeadersWithAccessToken(auth);
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.put(`${WEB_API_BASE}/upload/newarea`,payLoad).then(e => e);
};



export const addPartner = (auth, data) => {
  setHeadersWithAccessToken(auth);
  return axios.post(`${WEB_API_BASE}/metadata/partner`, data).then(e => e);
};

export const addCompany = (auth, data) => {
  setHeadersWithAccessToken(auth);
  return axios.post(`${WEB_API_BASE}/user/auth/company/register`, data).then(e => e);
};

export const addCityName = (auth, data) => {
  setHeadersWithAccessToken(auth);
  return axios.post(`${WEB_API_BASE}/upload/newcity`, data).then(e => e);
};
export const addAreaName = (auth, data) => {
  setHeadersWithAccessToken(auth);
  return axios.post(`${WEB_API_BASE}/upload/newarea`, data).then(e => e);
};


export const getNationalPathalogy = (auth,pageNumber,pageSiz,searchData) => {

  setHeadersWithAccessToken(auth);
  let page;
  if (pageNumber === undefined) {
      page = 0
  } else {
      page = pageNumber
  }

  let Limit;
  if (pageSiz === undefined ||pageSiz === '' ) {
    Limit = 10
  } else {
    Limit = pageSiz
  }
  let search;
  if (searchData === undefined) {
    search = " "
  } else {
    search = searchData
  }
 
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.get(`${WEB_API_BASE}/upload/nationalPathalogy?limit=${Limit}&page=${page}&search=${search}`).then(e => e);
};

export const getMasterPathalogy = (auth,pageNumber,pageSize,searchData,partnerData,TestNameData,areaData) => {

  setHeadersWithAccessToken(auth);
  let page;
  if (pageNumber === undefined) {
      page = 0
  } else {
      page = pageNumber
  }


  let Limit;
  if (pageSize === undefined ||pageSize === '' ) {
    Limit = 10
  } else {
    Limit = pageSize
  }

  let search;
  if (searchData === undefined) {
    search = " "
  } else {
    search = searchData
  }
  let partner;
  if (partnerData === undefined) {
    partner = " "
  } else {
    partner = partnerData
  }

  let test;
  if (partnerData === undefined) {
    test = " "
  } else {
    test = TestNameData
  }
  let area;
  if (areaData === undefined) {
    area = " "
  } else {
    area = areaData
  }
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.get(`${WEB_API_BASE}/upload/master/pathalogy/list?limit=${Limit}&pagenum=${page}&search=${search}&partner_uuid=${partner}&testname_uuid=${test}&areaRef=${area}`).then(e => e);
};

export const getMasterHomeSample = (auth,pageNumber,pageSize,searchData,partnerData,TestNameData,areaData) => {

  setHeadersWithAccessToken(auth);
  let page;
  if (pageNumber === undefined) {
      page = 0
  } else {
      page = pageNumber
  }


  let Limit;
  if (pageSize === undefined ||pageSize === '' ) {
    Limit = 10
  } else {
    Limit = pageSize
  }

  let search;
  if (searchData === undefined) {
    search = " "
  } else {
    search = searchData
  }
  let partner;
  if (partnerData === undefined) {
    partner = " "
  } else {
    partner = partnerData
  }

  let test;
  if (partnerData === undefined) {
    test = " "
  } else {
    test = TestNameData
  }
  let area;
  if (areaData === undefined) {
    area = " "
  } else {
    area = areaData
  }
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.get(`${WEB_API_BASE}/labtest/diagnostic/home/services/list?limit=${Limit}&pagenum=${page}&search=${search}`).then(e => e);
};

export const getMasterRadiology = (auth,pageNumber,pageSize,searchData,partnerData,TestNameData,areaData) => {

  setHeadersWithAccessToken(auth);
  let page;
  if (pageNumber === undefined) {
      page = 0
  } else {
      page = pageNumber
  }

  let Limit;
  if (pageSize === undefined ||pageSize === '' ) {
    Limit = 10
  } else {
    Limit = pageSize
  }

  let search;
  if (searchData === undefined) {
    search = " "
  } else {
    search = searchData
  }

  let partner;
  if (partnerData === undefined) {
    partner = " "
  } else {
    partner = partnerData
  }

  let test;
  if (partnerData === undefined) {
    test = " "
  } else {
    test = TestNameData
  }
  let area;
  if (areaData === undefined) {
    area = " "
  } else {
    area = areaData
  }
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.get(`${WEB_API_BASE}/upload/master/radiology/list?limit=${Limit}&pagenum=${page}&search=${search}&partner_uuid=${partner}&testname_uuid=${test}&areaRef=${area}`).then(e => e);
};

export const getNationalRadiology = (auth,pageNumber,pageSize,searchData) => {
  setHeadersWithAccessToken(auth);
  let page;
  if (pageNumber === undefined) {
      page = 0
  } else {
      page = pageNumber
  }
  let Limit;
  if (pageSize === undefined ||pageSize === '' ) {
    Limit = 10
  } else {
    Limit = pageSize
  }

  let search;
  if (searchData === undefined) {
    search = " "
  } else {
    search = searchData
  }
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.get(`${WEB_API_BASE}/upload/nationalRadiology?limit=${Limit}&page=${page}&search=${search}`).then(e => e);
};

export const editePathalogy = (auth,payLoad) => {

  setHeadersWithAccessToken(auth);
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.put(`${WEB_API_BASE}/upload/nationalPathalogy`,payLoad).then(e => e);
};

export const  editeMasterHomeSample = (auth,uuid,payLoad) => {

  setHeadersWithAccessToken(auth);
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.put(`${WEB_API_BASE}/labtest/diagnostic/updated/home/service/${uuid}`,payLoad).then(e => e);
};

export const editeMasterPathalogy = (auth,uuid,uuid1,payLoad) => {

  setHeadersWithAccessToken(auth);
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.put(`${WEB_API_BASE}/upload/master/pathalogy/update/${uuid}/${uuid1}`,payLoad).then(e => e);
};

export const editeMasterRadiology = (auth,uuid,uuid1,payLoad) => {

  setHeadersWithAccessToken(auth);
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.put(`${WEB_API_BASE}/upload/master/radiology/update/${uuid}/${uuid1}`,payLoad).then(e => e);
};

export const editeRadiology = (auth,payload) => {
  setHeadersWithAccessToken(auth);
  return axios.put(`${WEB_API_BASE}/upload/nationalRadiology`,payload).then(e => e);
};


export const addPathalogy = (auth, data) => {
  setHeadersWithAccessToken(auth);
  return axios.post(`${WEB_API_BASE}/upload/nationalPathalogy`, data).then(e => e);
};

export const addMasterPathalogy = (auth, data) => {
  setHeadersWithAccessToken(auth);
  return axios.post(`${WEB_API_BASE}/upload/master/pathalogy/create/multi`, data).then(e => e);
};

export const addMasterHomeSample = (auth, data) => {
  setHeadersWithAccessToken(auth);
  return axios.post(`${WEB_API_BASE}/labtest/diagnostic/create/home/service`, data).then(e => e);
};

export const addMasterRadiology = (auth, data) => {
  setHeadersWithAccessToken(auth);
  return axios.post(`${WEB_API_BASE}/upload/master/radiology/create/multi`, data).then(e => e);
};

export const addRadiology = (auth, data) => {
  setHeadersWithAccessToken(auth);
  return axios.post(`${WEB_API_BASE}/upload/nationalRadiology`, data).then(e => e);
};

export const getInfluencerData = (auth,pageNumber,searchData) => {

  setHeadersWithAccessToken(auth);
  let page;
  if (pageNumber === undefined) {
      page = 0
  } else {
      page = pageNumber
  }

  let search;
  if (searchData === undefined) {
    search = " "
  } else {
    search = searchData
  }
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.get(`${WEB_API_BASE}/user/auth/influencer/list?limit=10&pagenum=${page}&search=${search}`).then(e => e);
 
 
};

export const getPharmacyNewData = (auth,pageNumber,searchData) => {

  setHeadersWithAccessToken(auth);
  let page;
  if (pageNumber === undefined) {
      page = 0
  } else {
      page = pageNumber
  }

  let search;
  if (searchData === undefined) {
    search = " "
  } else {
    search = searchData
  }
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);

  return axios.get(`${WEB_API_BASE}/user/auth/pharmacy/list?skip=${page}&limit=10&search=${search}`).then(e => e);
 
 
};


export const getIntroducerData = (auth,pageNumber,searchData) => {

  setHeadersWithAccessToken(auth);
  let page;
  if (pageNumber === undefined) {
      page = 0
  } else {
      page = pageNumber
  }

  let search;
  if (searchData === undefined) {
    search = " "
  } else {
    search = searchData
  }
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  
  return axios.get(`${WEB_API_BASE}/user/auth/introducer/list?limit=10&pagenum=${page}&search=${search}`).then(e => e);
 
 
};
