import axios from 'axios';
import { setHeadersWithAccessToken } from './index';
const API_BASE = process.env.REACT_APP_APIBASE;
const WEB_API_BASE = process.env.REACT_APP_WEB_APP;

export const getUsers = (auth, skip, limit, searchQuery) => {
  let search = ''
  console.log(searchQuery)
  if (searchQuery !== undefined) {
    search = searchQuery
  }
  setHeadersWithAccessToken(auth);
  return axios
    .get(`${WEB_API_BASE}/user/admin/users?skip=${skip}&limit=${limit}&search=${search.toString().trim()}`)
    .then(e => e);
};


export const getPartners = (auth) => {
  setHeadersWithAccessToken(auth);
  return axios
    .get(`${WEB_API_BASE}/metadata/partner/getpartner`)
    .then(e => e);
};


export const addUser = (auth, data) => {
  setHeadersWithAccessToken(auth);
  return axios.post(`${WEB_API_BASE}/user/auth/register`, data).then(e => e);
};

export const addpartnerservice = (auth, data) => {
  setHeadersWithAccessToken(auth);
  return axios.post(`${WEB_API_BASE}/user/partner/createPartner`, data).then(e => e);
};


export const editpartnerservice = (auth, data, uuid) => {
  setHeadersWithAccessToken(auth);
  return axios.put(`${WEB_API_BASE}/user/partner/updatepartnerDetails/${uuid}`, data).then(e => e);
};

export const getInfluencer = (auth, payload) => {
  console.log(payload, "data")
  setHeadersWithAccessToken(auth);
  return axios.post(`${WEB_API_BASE}/user/auth/influencer/register`, payload);
};

export const addIntroducer = (auth, payload) => {
  console.log(payload, "data")
  setHeadersWithAccessToken(auth);
  return axios.post(`${WEB_API_BASE}/user/auth/introducer/register`, payload);
};

// export const editinfluencersservice =(auth, payLoad, uuid) => {
//   setHeadersWithAccessToken(auth);
//   return axios.put(`${WEB_API_BASE}/user/auth/influencer/update/${uuid}`,payLoad).then(e => e);
// }
export const editinfluencersservice = (auth,payLoad) => {

  setHeadersWithAccessToken(auth);
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.put(`${WEB_API_BASE}/user/auth/influencer/update/`,payLoad).then(e => e);
};

export const editintroducerservice = (auth,payLoad) => {

  setHeadersWithAccessToken(auth);
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.put(`${WEB_API_BASE}/user/auth/introducer/update/`,payLoad).then(e => e);
};


export const verifyUser = (auth, data) => {
  setHeadersWithAccessToken(auth);
  return axios
    .put(
      `${WEB_API_BASE}/user/verify-user-email/${data.email}`
    )
    .then(e => e);
};

export const addClinic = (auth, data) => {
  setHeadersWithAccessToken(auth);
  return axios.post(`${API_BASE}/users/clinic`, data).then(e => e);
};

export const addLab = (auth, data) => {
  setHeadersWithAccessToken(auth);
  return axios.post(`${API_BASE}/users/lab`, data).then(e => e);
};

export const addPharmacy = (auth, data) => {
  setHeadersWithAccessToken(auth);
  return axios.post(`${API_BASE}/users/pharmacy`, data).then(e => e);
};

export const activeUser = (auth, data) => {
  setHeadersWithAccessToken(auth);
  return axios.put(`${WEB_API_BASE}/user/suspend-user/${data.uuid}/${data.active}`).then(e => e);
};
