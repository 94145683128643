import { all, takeEvery, put, fork,select } from 'redux-saga/effects';
import actions from 'actions';
import { getPatients,getClonePatients,getpatientHistroservice } from 'services/patients-services';
import { notification } from 'antd';
const { patientActions, authActions } = actions;

export function* getPatintesSaga() {
  yield takeEvery(patientActions.GET_PATIENTS, function*(pagenum,searchQuery) {
    console.log(pagenum,searchQuery, "pagenum")
    try{
      const token = yield select(state => state.auth.accessToken);
      const response = yield getPatients(pagenum.pagenum,pagenum.searchQuery, pagenum.limit,{token});
        const { data } = response;
        yield put({
          type: patientActions.GET_PATIENTS_SUCCESS,
          data
        });
    } catch(error){
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* getClonePatintesSaga() {
  yield takeEvery(patientActions.GET_CLONE_PATIENTS, function*(params) {
    try{
      const token = yield select(state => state.auth.accessToken);
      const response = yield getClonePatients({token},params);
        const { data } = response;
        yield put({
          type: patientActions.GET__CLONE_PATIENTS_SUCCESS,
          data
        });
    } catch(error){
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* gethistropatientSaga() {
  yield takeEvery(patientActions.GET_HISTRO_PATIENTS, function*(params) {
    try{
      const token = yield select(state => state.auth.accessToken);
      const response = yield getpatientHistroservice({token},params);
        const { data } = response;
        yield put({
          type: patientActions.GET_HISTRO_PATIENTS_SUCCESS,
          data
        });
    } catch(error){
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getPatintesSaga),fork(getClonePatintesSaga),fork(gethistropatientSaga)]);
}
