const partnersActions = {

    GET_PARTNERS: "GET_PARTNERS",
    GET_PARTNERS_SUCCESS: "GET_PARTNERS_SUCCESS",

    ADDING_PARTNER: "ADDING_PARTNER",
    ADDING_PARTNER_SUCCESS: "ADDING_PARTNER_SUCCESS",

    EDIT_PARTNER: "EDIT_PARTNER",
    EDIT_PARTNER_SUCCESS: "EDIT_PARTNER_SUCCESS",


    ON_ERROR: "ON_ERROR",


    getPartners: (pageNumber, searchQuery) => {
        console.log(pageNumber,"pageNumber")
        return {
            type: partnersActions.GET_PARTNERS,
            pageNumber,
            searchQuery
        };
    },

    addingPartner: (data) => {
        return {
            type: partnersActions.ADDING_PARTNER,
            data
        };
    },

    editPartner: (data, uuid) => {
        return {
            type: partnersActions.EDIT_PARTNER,
            data,
            uuid
        };
    },
};

export default partnersActions;