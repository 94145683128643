import SaasDoctorsTable from "components/saas/SaasDoctorsTable";
import React from "react";
import { useDispatch, useSelector } from "react-redux";


export default function SaasDoctorsPage() {
    const dispatch = useDispatch();



    return (
        <>

            <div className="pictogram-container">
                <div className="pictogram-header flex-x align-center ptb--15">
                    <div className="fs--16 font-semibold flex-1">{/* Users */}</div>
                </div>
                <div>
                    <SaasDoctorsTable />
                </div>
            </div>
        </>
    )
}

