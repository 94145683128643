import React, { useState, useEffect } from 'react';
import { Table, Popover,Input,Col } from 'antd';
import { view_log, triple_dots } from 'helper/constant';
import EditePathalogy from './EditeRadiology';
import ViewReports from './ViewReports';
import { green_dot, red_dot } from 'helper/constant';
import actions from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { EditTwoTone } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';



const { master } = actions;

const Pathalogy = ({newcity, onGetarea, newareas}) => {
  const dispatch = useDispatch();
  const [visible, setvisible] = useState(false);
  const [reportsvisible, setreportsvisible] = useState(false);
  const [editvisible, setEditvisible] = useState('');
  const [user, setuser] = useState(null);
  const [data, setData] = useState("")
  const [search,setSearch]=useState(" ")

  console.log(search,"search")
  const Pharma = useSelector((state) => state.pharmacies);
  console.log(Pharma && Pharma.pharmacies && Pharma.pharmacies.data, "phar")
  const PharmaData = Pharma && Pharma.pharmacies && Pharma.pharmacies.data;
  const Auth = useSelector((state) => state.auth);
  const masterPartner = useSelector((state) => state.master);

  console.log(masterPartner,"551")


  // const Api = (row) => {
  //   axios.get(`${process.env.REACT_APP_WEB_APP}/user/pharmacyCommission/pharmacy/metrics/data?pharmacy_uuid=${row && row.uuid}`,{
  //       headers:{
  //           "x-auth-token" : Auth.accessToken
  //       }
  //   })
  //   .then((res) => {
  //       console.log(res, "res")
  //       // data.push(res)
  //       setData(res.data)
  //   })
  // }
  // useEffect(() => {
  //   dispatch( MasterAction && MasterAction.getPartnersDta());
  // }, []);

  useEffect(() => {
    dispatch(master.getNationalRadiology(0,''));
  }, []);
  
  const columns = [
   
    {
      title: 'productcode',
      dataIndex: 'productcode',
      key: 'productcode'
    },
    {
      title: 'standardised test name',
      dataIndex: 'standardisedtestname',
      key: 'standardisedtestname'
    },
   
    {
      title: 'test discipline',
      dataIndex: 'testdiscipline',
      key: 'testdiscipline:',
    },


    {
      title: 'View',
      dataIndex: 'eye',
      key: 'eye',
      render: (text, row) => (
        <img
          className='ml--10 cursor-pointer'
          src={view_log && view_log.default?view_log.default:view_log}
          alt='view'
          onClick={() => {
            console.log('view');
            setuser(row);
            setvisible(true);
            setEditvisible('view')
          }}
        />
      )
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, row) => (
        <div className='ml--20'>
          <EditTwoTone
          onClick={() => {
            setuser(row);
            setvisible(true);
            setEditvisible('edit')
          }}
          />
          {/* <Popover
            trigger='hover'
            placement='bottom'
            content={
              <div>
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    setuser(row);
                    setvisible(true);
                    setEditvisible('edit')
                  }}
                >
                  Edit
                </div>
              </div>
            }
          >
            <img src={triple_dots} alt='action' />
          </Popover> */}
        </div>
      )
    }
  ];
  let pageNumber = ''
console.log(masterPartner.NationalPathalogy, "hgjkl")
const onChangesearch = (searchData) => {
  setSearch(searchData)
  dispatch(master.getNationalRadiology(0,'', searchData))
}

  return (
    <div>
     <Col span={4} xs={4} md={4}>
    <Input 
    onChange={ e => onChangesearch(e.target.value)}
    type="text"
    placeholder='Search'  
/>
</Col>

<br/><br/>
      <Table
        columns={columns}
        dataSource={masterPartner.NationalRadiology}
        size='middle'
        pagination={{
                    total: masterPartner.NationalRadiologyCount,
                    defaultPageSize: 10,
                    showSizeChanger: true
                    
                }}

                onChange={(pagination) => {
                    
                    if (pagination.current === 1) {
                        pageNumber = 0
                    } else {
                        pageNumber = pagination.current - 1
                    }
                    dispatch(master.getNationalRadiology(pageNumber,pagination.pageSize))
                }}
      />
      <EditePathalogy
        visible={visible}
        diff={editvisible}
        newcity={newcity}
        onGetarea={onGetarea}
        newareas={newareas}
        loader={false}
        handleOk={() => setvisible(false)}
        handleCancel={() => {setvisible(false); window.location.reload(false)}}
        user={user}
      />
      {/* <ViewReports
      visible={reportsvisible}
      loader={false}
      handleOk={() => setreportsvisible(false)}
      handleCancel={() => {setreportsvisible(false); window.location.reload(false)}}
      data={data}
      >
      </ViewReports> */}
    </div>
  );
};

export default Pathalogy;