import React, { useState, useEffect } from "react";
import moment from "moment";
import { usePubNub } from "pubnub-react";
import { Comment, Avatar, ConfigProvider } from "antd";
import { UserOutlined } from '@ant-design/icons';
import {
    ChannelList,
    Chat,
    MessageInput,
    MessageList,
    TypingIndicator,
} from "@pubnub/react-chat-components";
import { useSelector } from "react-redux";
import * as _ from 'lodash'
import "./Chat.css";
import { getChannels, getChannelMembers } from '../../services/pubnub-services'
import { Helmet } from "react-helmet";

const ChatComponent = () => {
    const pubnub = usePubNub(); //usePubNub is only used here to get current user info

    const currentUser = useSelector((state) => state.auth.admin);

    const [theme] = useState("light");
    const [showChannels] = useState(true);
    const [channels, setChannels] = useState([]);
    const [channelNames, setChannelNames] = useState([]);
    const [users, setUsers] = useState([]);
    const [currentChannel, setCurrentChannel] = useState(null);

    const formatDate = (date) => {
        if (date) {
            return moment(new Date(date)).format("MMMM Do YYYY, h:mm a");
        }
        return null;
    };

    const fetchChannels = async (pubnubObject) => {
        setChannels([]);
        try {
            const applicationChannels = await getChannels(pubnubObject, "", "");
            if (!applicationChannels.data.length) {
                console.log("No application channels found.");
            }
            const channelsList = [];
            const channelNames = [];
            let selectedChannel = {};

            applicationChannels.data.map((channel, index) => {
                selectedChannel = channel;
                selectedChannel.updated = formatDate(channel.updated);
                channelNames.push(channel.id)
                channelsList.push(selectedChannel);
                return false;
            });
            setChannelNames(channelNames)
            setChannels(channelsList);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (!currentChannel && channels.length > 0) {
            const selectedChannel = channels[0]
            setCurrentChannel(selectedChannel)
            getChannelMembers(pubnub, selectedChannel.id, "")
                .then(response => {
                    var ids = _.map(response.data, item => item.uuid.id)
                    ids.push(`user_${currentUser.id}`)
                    var users = _.map(response.data, item => item.uuid)
                    setUsers(users);
                    pubnub.objects.setChannelMembers({
                        channel: selectedChannel.id,
                        uuids: _.uniq(ids)
                    })

                })
                .catch(error => {
                    console.log('getChannelMembers', error);
                })
        }
    }, [channels])

    useEffect(() => {

        console.log();
        if (pubnub) {
            pubnub.subscribe({ channels: channelNames, withPresence: true })
            // We need to return a function that will handle unsubscription on unmount
            return () => {
                pubnub.unsubscribeAll();
            };
        }

    }, [channelNames])

    useEffect(() => {
        // We need to make sure that PubNub is defined
        if (pubnub) {
            // Set the UUID of our user to their chosen emoji
            const myUUID = `user_${currentUser.id}`

            pubnub.setUUID(myUUID);
            pubnub.objects.setUUIDMetadata({
                uuid: myUUID,
                data: {
                    name: currentUser.username,
                    email: currentUser.email,
                }
            })


            fetchChannels(pubnub)

            // We need to return a function that will handle unsubscription on unmount
            return () => {
                pubnub.unsubscribeAll();
            };
        }
    }, [pubnub, currentUser, users]);


    const ChangeAdminName = (name) => {
        if (name && name === "yoopha-admin") {
            return "Doctall Admin"
        } else {
            return name;
        }
    }

    return (
        <div>
            <Helmet>
                <title>Doctall Super Admin Support
</title>
<meta 
            name='description' 
            content='Chat and communicate with team members on the support team'
         

   />
            </Helmet>
            <div className={`app-simple ${theme}`}>
                {/* Be sure to wrap Chat component in PubNubProvider from pubnub-react package.
                    In this case it's done in the index.tsx file */}

                {currentChannel !== null && currentChannel !== undefined ?
                    <Chat theme={theme} currentChannel={currentChannel.id} channels={channels} enablePresence={true} users={users}>
                        {console.log(currentChannel)}
                        <div className={`channels ${showChannels && "shown"}`} style={{ overflowY: 'scroll' }}>
                            <div>
                                <ChannelList
                                    channels={channels}
                                    onChannelSwitched={(channel) => setCurrentChannel(channel)}
                                />
                            </div>
                        </div>

                        <div className="chat">
                            <MessageList
                                fetchMessages={50} messageRenderer={(props) => {
                                    var userDetails = _.first(_.filter(users, user => {
                                        if (props.message.uuid) {
                                            return user.id === props.message.uuid
                                        } else if (props.message.publisher) {
                                            return user.id === props.message.publisher
                                        }
                                    }))
                                    const username = userDetails ? userDetails.name : currentChannel.name
                                    return <Comment avatar={
                                        <Avatar icon={<UserOutlined />} />
                                    } author={username} content={
                                        <p>
                                            {props.message.message.text}
                                        </p>
                                    } datetime={<span>{moment(new Date(props.message.timetoken / 10000000 * 1000)).format('MMM D, YYYY, HH:mmA')}</span>} />
                                }
                                }>
                                <TypingIndicator />
                            </MessageList>
                            <MessageInput typingIndicator />
                        </div>
                    </Chat> : <h4>No Support messages available</h4>}
            </div>
        </div >

    )
}
export default ChatComponent;