import { Modal } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  Tabs,
  notification,
} from 'antd';

import { Spin } from 'antd';
import { green_dot, red_dot } from 'helper/constant';
import { useDispatch } from 'react-redux';
import actions from 'actions';
import axios from "axios"
import { useForm } from "react-hook-form";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import 'antd/dist/antd.css';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { useSelector } from 'react-redux';

import "./partner.css"
// const { pharmacyActions } = actions;
const { master } = actions;



const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }

  return isJpgOrPng && isLt2M;
};


const ViewPharmacy = ({ user, visible, loader, handleOk, handleCancel, diff, newcity, onGetarea, newareas }) => {
  console.log('user222', user);
  const dispatch = useDispatch()


  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  // useEffect(() => {
  //   if (imageUrl == "" || imageUrl == undefined) {
  //     setImageUrl(user && user.logo)
  //   }
  // })

  const uploadImg = useRef(null);
  const auth = useSelector((state) => state.auth.accessToken);

  const [uploadImage, setUploadImage] = useState(null)

  const handleImage = (e) => {
    const [file] = e.target.files;
    setUploadImage(e.target.files[0]);

    // const data = e.target.files[0]

    // if (data) {
    //   // setLoading(true);
    //   const formData = new FormData();
    //   formData.append("media", data);
    //    axios
    //     .post(`${process.env.REACT_APP_WEB_APP}/upload/avatar`, formData, {
    //       headers: { "x-auth-token": auth },
    //     })
    //     .then((res) => {
    //       // setLoading(false);
    //       setImageUrl(res.data.url);
    //       // submitvalues.profile_pic = res.data.url;
    //     })
    //     .catch((err) => {
    //       setLoading(false);
    //     });
    // }
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
        console.log(url, "url")
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  let uuid = user && user.uuid;

  const errorstyle = {
    border: "1px solid #ADC2C2",
    outline: "none !important",
    borderRadius: "5px",
    width: '10px'
  };

  console.log(uuid, "uuid")
  const [hideSubmit, setHideSubmit] = useState(false);
  const [tabIndex, setTabindex] = useState(1);
  const [newcityuuid, setnewcityuuid] = useState('');
  const [partnercity, setpartnercity] = useState('');
  const [partnerarea, setpartnerarea] = useState('');
  const [areaId, setareaId] = useState();
  const [commList, setCommList] = useState();
  const [amounts, setAmount] = useState("");
  const [amounts1, setAmount1] = useState("");
  const [amounts2, setAmount2] = useState("");
  const [amounts3, setAmount3] = useState("");
  const [amounts4, setAmount4] = useState("");
  const [country, setCountry] = useState();

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((res) => {
        setCountry(res.country_code.toLowerCase());
      })
      .catch((data, status) => {
        console.log("Request failed:", data);
      });
  }, []);

  const { handleSubmit } = useForm();

  const items = []



  const fadnamount = (event) => {
    setAmount4(event.target.value)
  }
  const sadnamount = (event) => {
    setAmount3(event.target.value)
  }
  const registeramount = (event) => {
    setAmount1(event.target.value)
  }
  const dmpamount = (event) => {
    setAmount(event.target.value)
  }
  const treatmentamount = (event) => {
    setAmount2(event.target.value)
  }

  console.log("area", commList, items)

  let aId = user && user.profile && user.profile.address && user.profile.address.area;

  console.log(aId, "aid")
  const areasubmit = values => {
    console.log("area submit", values)
    onGetarea(values)
  }
  useEffect(() => {
    getCommission()
  }, [])

  let registerType1 = commList && commList.data && commList.data[0] && commList.data[0];
  let registerType = Object.assign({}, registerType1);
  console.log(registerType, "obj")

  const { Option } = Select;
  const { TabPane } = Tabs;

  const handleTabs = (event) => {
    console.log(event, "event")
    setTabindex(event)
    if (event == "4") {
      getCommission()
    }
    // if (event === "6") {
    //   setHideSubmit(true)
    // } else {
    //   setHideSubmit(false)
    // }
  }
  const handlechange = (event) => {
    console.log(event.target.value, "valuesvalues")
    setAmount(event.target.value)
  }
  const handleComm = values => {
    values.preventDefault();
    // alert(values)
    console.log(values, "valuesvalues")
    var payLoad = {
      //   // "pharmacy_uuid": values.pharm_id,
      //   // "type": values.type,
      //   "amount": amounts

      "data": [
        {
          "pharmacy_uuid": uuid,
          "type": "register",
          "amount": amounts1
        },
        {
          "pharmacy_uuid": uuid,
          "type": "sadn",
          "amount": amounts3
        },
        {
          "pharmacy_uuid": uuid,
          "type": "fadn",
          "amount": amounts4
        },
        {
          "pharmacy_uuid": uuid,
          "type": "dmp_order",
          "amount": amounts
        },
        {
          "pharmacy_uuid": uuid,
          "type": "treatment_plan",
          "amount": amounts2
        }
      ]
    }

    axios.put(`${process.env.REACT_APP_WEB_APP}/user/pharmacyCommission`, payLoad)
      .then((res) => {
        console.log(res, "res")
        if (res.status == 200 || res.status == 201) {
          notification.open({
            message: 'Success',
            description: res.data.message
          })
          handleCancel();
          window.location.reload(false);
          // getCommission()
        }
        else {
          notification.open({
            message: 'Error',
            description: "Something is Wrong"
          })
        }
      })
      .catch((err) => {
        notification.open({
          message: 'Error',
          description: "Something is Wrong"
        })
      })
  }
  const handleSubmit1 = values => {
    var payLoad = {}
    if (uploadImage) {
      // setLoading(true);
      const formData = new FormData();
      formData.append("media", uploadImage);
      axios.post(`${process.env.REACT_APP_WEB_APP}/upload/avatar`, formData, {
        headers: { "x-auth-token": auth },
      })
        .then((res) => {
          console.log(imageUrl, res, "values")
          let type1;
          var payLoad = {
            "uuid": user.uuid,
            "name": values.firstName,
            "code": values.lastName,
            "desc": values.Discription,
            logo: res.data.url || imageUrl
          }
          console.log(payLoad, "payLoad")
          dispatch(master.editPartnerName(payLoad));
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    else {
      let type1;
      var payLoad = {
        "uuid": user.uuid,
        "name": values.firstName,
        "code": values.lastName,
        "desc": values.Discription,
        logo: imageUrl
      }
      console.log(payLoad, "payLoad")
      dispatch(master.editPartnerName(payLoad));
    }

  }

  const getCommission = () => {
    axios.get(`${process.env.REACT_APP_WEB_APP}/user/pharmacyCommission/${uuid && uuid}`)
      .then((res) => {
        console.log(res, "res")
        setCommList(res)

        // res.data.map((item) => {
        //   setAmount(item.amount) 
        // })
        setAmount(res && res.data && res.data[0] && res.data[0].amount)
        setAmount1(res && res.data && res.data[1] && res.data[1].amount)
        setAmount2(res && res.data && res.data[2] && res.data[2].amount)
        setAmount3(res && res.data && res.data[3] && res.data[3].amount)
        setAmount4(res && res.data && res.data[4] && res.data[4].amount)
      })
  }


  return <Modal
    title={`Partner Name`}
    visible={visible}
    onOk={handleOk}
    onCancel={handleCancel}
    width={850}
    footer={[
      <>
        {/* {diff === "edit" &&
        <Button key='submit' type='primary' onClick={handleSubmit}>
          Ok
        </Button>
      } */}
      </>
    ]}
  >
    {/* <Spin spinning={loader}>
        <div className='flex-y align-left'>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Pharmacy Name
            </div>
            <div className='read-form-value'>{user && user.pharmacyName}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>Email</div>
            <div className='read-form-value'>{user && user.email}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Phone Number
            </div>
            <div className='read-form-value'>{user && user.phoneNo}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Inauguration Year
            </div>
            <div className='read-form-value'>
              {user && user.inaugurationYear}
            </div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>PAN</div>
            <div className='read-form-value'>{user && user.pan}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>GST</div>
            <div className='read-form-value'>{user && user.gst}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Licence No
            </div>
            <div className='read-form-value'>{user && user.licenceNo}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>Door No</div>
            <div className='read-form-value'>{user && user.doorNo}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>Street</div>
            <div className='read-form-value'>{user && user.street}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>Area</div>
            <div className='read-form-value'>{user && user.area}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>City</div>
            <div className='read-form-value'>{user && user.city}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>State</div>
            <div className='read-form-value'>{user && user.state}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>PinCode</div>
            <div className='read-form-value'>{user && user.pincode}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Status&nbsp;:&nbsp;
              {user && user.status ? (
                <img src={green_dot}  alt="active" />
              ) : (
                <img src={red_dot} alt="inactive" />
              )}
            </div>
          </div>
        </div>
      </Spin> */}
    <Tabs defaultActiveKey="1" onChange={handleTabs}>
      <TabPane tab="General" key="1">
        <Form onFinish={handleSubmit1} className='gx-form-row0' layout='vertical'>
          <Row className="space-between">
            <Col span={4} xs={7} md={7} >
              <Form.Item
                label="Partner Logo"
                name="partner_logo"
                initialValue={user && user.logo}
                rules={[{ required: true, message: "Please enter logo!" }]}
              >
                {/* <Input size="large" htmlType="file" disabled={diff == 'view'} /> */}
                {/* <Upload
               width="40%"
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >{console.log(imageUrl,user && user,"imageurl")}
                  {((imageUrl == " " || imageUrl === undefined) && user && user.logo != '' ) ? (
                    <img
                      src={(imageUrl == " " || imageUrl === undefined ) ? user && user.logo : imageUrl}
                      alt=""
                      className='saikiran'
                    />
                 ) : (
                  (imageUrl !== " " || imageUrl !== undefined) ?
                  <img
                  src={ imageUrl && imageUrl.default?imageUrl.default:imageUrl}
                  alt=""
                  className='saikiran'
                /> :
                    uploadButton
                  )} 
                </Upload> */}
                <img
                  src={(imageUrl == " " || imageUrl === undefined) && user && user.logo != '' ? user && user.logo : imageUrl && imageUrl.default ? imageUrl?.default : imageUrl}
                  onClick={() => uploadImg.current.click()}
                  alt="user profile pic"
                  width="100%"
                // height="150px"
                />
                <br />

                <input
                  accept="image/*"
                  onChange={(e) => handleImage(e)}
                  id="myInput"
                  type="file"
                  ref={uploadImg}
                  style={{ display: "none" }}
                />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Partner Name"
                name="firstName"
                initialValue={user && user.name}
                rules={[{ required: true, message: "Please enter name!" }]}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Code"
                name="lastName"
                initialValue={user && user.code}
                rules={[{ required: true, message: "Please enter name!" }]}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={12} md={12} >
              <Form.Item
                label="Discription"
                name="Discription"
                initialValue={user && user.desc}
                rules={[{ required: true, message: "Please enter name!" }]}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
          </Row>
          {diff === "edit" &&
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button ml--10'>
              Submit
            </Button>
          }
        </Form>
      </TabPane>

      {/* <TabPane tab="Address" key="2">
        <Form onFinish={handleSubmit1} className='gx-form-row0' layout='vertical'>
          <Row className="space-between">
            <Col span={24} xs={24} md={24}>
              <Form.Item
                label="Address"
                name="address"
                //rules={[{ required: true, message: "Please enter houseNumber!" }]}
                initialValue={user && user.profile && user.profile.address && user.profile.address.address}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="City"
                name="city"
                // rules={[{ required: true, message: "Please enter houseNumber!" }]}
                initialValue={user && user.profile && user.profile.address && user.profile.address.city}
              >
                <Select
                  showSearch
                  autoClearSearchValue
                  allowClear
                  optionFilterProp="children"
                  disabled={diff == 'view'}
                  onChange={(value, secondvalue) => {
                    console.log("cityyy", value
                    )
                    setnewcityuuid(value)
                    areasubmit(value)
                    // setareaId(value)

                    if (value && secondvalue)
                      setpartnercity(secondvalue.key)

                  }}
                  // value={city}
                  value={newcityuuid}
                >
                  {newcity.map(city => (

                    <Option key={city._id} value={city.uuid}>
                      {city.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Area"
                name="area"
              // rules={[{ required: true, message: "Please enter houseNumber!" }]}
              initialValue={user && user.profile && user.profile.address && user.profile.address.area}
              >
                <Select
                  showSearch
                  autoClearSearchValue
                  allowClear
                  optionFilterProp="children"
                  disabled={diff == 'view'}
                  onChange={(value, secondvalue) => {
                    console.log("cityyy", value
                    )
                    setareaId(value)
                    areasubmit(user && user.profile && user.profile.address && user.profile.address.city)

                    if (value && secondvalue)
                    setpartnerarea(secondvalue.key)

                  }}
                  value={areaId}
                >
                   {newareas.map(area => (
                    <Option key={area._id} value={area.name}>
                      {area.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Country"
                name="country"
                //rules={[{ required: true, message: "Please enter houseNumber!" }]}
                initialValue={user && user.profile && user.profile.address && user.profile.address.country}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>

          </Row>
          {diff === "edit" &&
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button ml--10'>
              Submit
            </Button>
          }
        </Form>
      </TabPane> */}
      {/* <TabPane tab="Bank Details" key="3">
        <Form onFinish={handleSubmit1} className='gx-form-row0' layout='vertical'>
          <Row className="space-between">
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Account Name"
                name="acc_name"
                // rules={[{ required: true, message: "Please enter Account Name!" }]}
                initialValue={user && user.profile && user.profile.bank && user.profile.bank.account_name}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Account Number"
                name="acc_number"
                // rules={[{ required: true, message: "Please enter Account Number!" }]}
                initialValue={user && user.profile && user.profile.bank && user.profile.bank.account_number}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Bank Name"
                name="bank_name"
                // rules={[{ required: true, message: "Please enter state!" }]}
                initialValue={user && user.profile && user.profile.bank && user.profile.bank.bank_name}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Branch Name"
                name="branch"
                // rules={[{ required: true, message: "Please enter state!" }]}
                initialValue={user && user.profile && user.profile.bank && user.profile.bank.branch_name}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Account Type"
                name="acc_type"
                // rules={[{ required: true, message: "Please enter state!" }]}
                initialValue={user && user.profile && user.profile.bank && user.profile.bank.account_type}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}></Col>

          </Row>
          {diff === "edit" &&
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button ml--10'>
              Submit
            </Button>
          }
        </Form>
      </TabPane> */}
      {/* <TabPane tab="Commission" key="4">
        <form onSubmit={handleComm}>
          <Row>
            <Col span={7} xs={7} md={7}>
              Rgister
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[1] && commList.data[1].amount} onChange={(event) => registeramount(event)} name="register"  ></input>
            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={7} xs={7} md={7}>
              SADN
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[3] && commList.data[3].amount} onChange={(event) => sadnamount(event)} name="sadn"></input>

            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={7} xs={7} md={7}>
              FADN
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[4] && commList.data[4].amount} onChange={(event) => fadnamount(event)} name="fadn" ></input>
            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={7} xs={7} md={7}>
              DMP ORDER
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[0] && commList.data[0].amount} onChange={(event) => dmpamount(event)} name="dmp"  ></input>
            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={7} xs={7} md={7}>
              TRAETMENT PLAN
            </Col>
            <Col span={7} xs={7} md={7}>
              <input disabled={diff == 'view'} style={{ height: "40px", borderRadius: "5px", border: "1px solid gray" }} defaultValue={commList && commList.data && commList.data[2] && commList.data[2].amount} onChange={(event) => treatmentamount(event)} name="treatment"></input>
            </Col>
            <Col span={7} xs={7} md={7}>
            </Col>
          </Row>
          <br></br>
          {diff === "edit" &&
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button ml--10'>
              Submit
            </Button>
          }
        </form>
        
      </TabPane> */}
      {/*  LiDateIssue */}

    </Tabs>
  </Modal>
  // );
};

export default ViewPharmacy;
