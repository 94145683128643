import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import { user } from 'helper/constant';
import { icon, doctall } from 'helper/constant';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';





const Sidebar = () => {
  console.log(doctall,"doctall")
  const users = useSelector((state) => state.auth);
  console.log(users, "users")
  const [show, setShow] = useState(false);
  const [showMaster, setShowMaster] = useState(false);

  console.log(show, "show")




  return (
    <div id='sidebar' className='sidebar sideBack'>
      <div className='sidebar-header'>
        <img src={ doctall && doctall.default ? doctall.default : doctall} alt='logo' className="sidebar-headerimage" width="100px" height="75px" />
        &nbsp;
      </div>
      <div className='sidebar-wrapper' style={{ width: '104%', overflowY: 'scroll' }}>
        <ul className='nav'>
          <li className='pos-relative'>
            <NavLink to={'/dashboard'} className='nav-link main-list' activeStyle={{
              fontWeight: "normal",
              color: "white",
              backgroundColor: 'rgb(54 162 183)',

              borderRadius: '3%'
            }}>
              <div className='sidebar-icon'>
                <img src={user && user.default ? user.default : user} alt='language' />
              </div>
              <p className='fs--16'>Dashboard</p>
            </NavLink>
          </li>

          {/* All Users Sidebar NavLink start*/}
          <li className='pos-relative'>
            <div className='nav-link main-list' activeStyle={{
              fontWeight: "normal",
              color: "white",
              backgroundColor: 'rgb(54 162 183)',
              borderRadius: '3%'
            }}
              onClick={() => setShow(show == true ? false : true)}
            >
              <div className='sidebar-icon'>
                <img src={user && user.default ? user.default : user} alt='language' />
              </div>
              <p className='fs--16'>All Users</p>&nbsp;
              {
                show == false ?
                  <span>&nbsp;<DownOutlined /></span>
                  :
                  <span>&nbsp;<UpOutlined /></span>
              }
            </div>
          </li>
          {
            show == true ?
              <div style={{ marginLeft: "15%" }}>
                <li className='pos-relative'>
                  <NavLink to={'/users'} className='nav-link main-list' activeStyle={{
                    fontWeight: "normal",
                    color: "white",
                    backgroundColor: 'rgb(54 162 183)',
                    borderRadius: '3%'
                  }}>
                    <div className='sidebar-icon'>
                      <img src={user && user.default ? user.default : user} alt='language' />
                    </div>
                    <p className='fs--16'>Users</p>
                  </NavLink>
                </li>
                {users && users.admin && users.admin.role !== "analytics-admin" &&
            <>
            <li className='pos-relative'>
                <NavLink to={'/partners'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Partners</p>
                </NavLink>
              </li>

              <li className='pos-relative'>
                <NavLink to={'/influencers'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Influencers</p>
                </NavLink>
              </li>
              <li className='pos-relative'>
                <NavLink to={'/introducer'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Introducers</p>
                </NavLink>
              </li>
              <li className='pos-relative'>
                <NavLink to={'/doctors'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Doctors</p>
                </NavLink>
              </li>
              <li className='pos-relative'>
                <NavLink to={'/pharmacy'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Pharmacist Partners</p>
                </NavLink>
              </li>

              <li className='pos-relative'>
                <NavLink to={'/saas'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Third Party Doctors</p>
                </NavLink>
              </li>

              <li className='pos-relative'>
                <NavLink to={'/consumers'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Consumers</p>
                </NavLink>
              </li>
              <li className='pos-relative'>
                <NavLink to={'/Company'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Corporate</p>
                </NavLink>
              </li>
              



            </>
          }







              </div>
              :
              null
          }


          {/* All Users Sidebar NavLink end*/}
















          {users && users.admin && users.admin.role !== "analytics-admin" &&
            <>
              
              {/* 
              <li className='pos-relative'>
                <NavLink to={'/influencer'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user} alt='language' />
                  </div>
                  <p className='fs--16'>Influencers</p>
                </NavLink>
              </li> */}
              

              

            
              {/* <li className='pos-relative'>
            <NavLink to={'/pharmacy'} className='nav-link main-list' activeStyle={{
              fontWeight: "normal",
              color: "white",
              backgroundColor: 'rgb(54 162 183)',
              borderRadius: '3%'
            }}>
              <div className='sidebar-icon'>
                <img src={user} alt='language' />
              </div>
              <p className='fs--16'>Pharmacy Reports</p>
            </NavLink>
          </li> */}
              
          
              <li className='pos-relative'>
                <NavLink to={'/patients'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Patients</p>
                </NavLink>
              </li>
              <li className='pos-relative'>
                <NavLink to={'/transactions'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Transactions</p>
                </NavLink>
              </li>
              <li className='pos-relative'>
                <NavLink to={'/appointments'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Appointments</p>
                </NavLink>
              </li>
              <li className='pos-relative'>
                <NavLink to={'/feedbacks'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Feedbacks</p>
                </NavLink>
              </li>
              <li className='pos-relative'>
                <NavLink to={'/bookAppointment'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Book Appointment</p>
                </NavLink>
              </li>

              <li className='pos-relative'>
                <NavLink to={'/viewpatientDocs'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Patient Documents</p>
                </NavLink>
              </li>

              <li className='pos-relative'>
                <NavLink to={'/order'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Order</p>
                </NavLink>
              </li>
              <li className='pos-relative'>
                <NavLink to={'/treatment_order'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Treatment Order</p>
                </NavLink>
              </li>

              <li className='pos-relative'>
                <NavLink to={'/push-notification'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Push Notification</p>
                </NavLink>
              </li>

              <li className='pos-relative'>
                <NavLink to={'/coupons'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Coupons</p>
                </NavLink>
              </li>



              <li className='pos-relative'>
                <NavLink to={'/support'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Support</p>
                </NavLink>
              </li>

              <li className='pos-relative'>
                <NavLink to={'/Settings'} className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}>
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Settings</p>
                </NavLink>
              </li>
            

                  

              <li className='pos-relative'>
                <div className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}
                  onClick={() => setShowMaster(showMaster == true ? false : true)}
                >
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Master</p>&nbsp;
                  {
                    showMaster == false ?
                      <span><DownOutlined /></span>
                      :
                      <span><UpOutlined /></span>
                  }
                </div>
              </li>
              {
                showMaster == true ?
                  <div style={{marginLeft:"15%"}}>
                  <li className='pos-relative'>

                    <NavLink to={'/PartnersName'} className='nav-link main-list' activeStyle={{
                      fontWeight: "normal",
                      color: "white",
                      backgroundColor: 'rgb(54 162 183)',
                      borderRadius: '3%'
                    }}>
                      <div className='sidebar-icon'>
                        <img src={user && user.default ? user.default : user} alt='language' />
                      </div>
                      <p className='fs--16'>Partners Name</p>
                    </NavLink>
                  </li>
                    <li className='pos-relative'>
                      <NavLink to={'/CityName'} className='nav-link main-list' activeStyle={{
                        fontWeight: "normal",
                        color: "white",
                        backgroundColor: 'rgb(54 162 183)',
                        borderRadius: '3%'
                      }}>
                        <div className='sidebar-icon'>
                          <img src={user && user.default ? user.default : user} alt='language' />
                        </div>
                        <p className='fs--16'>City</p>
                      </NavLink>
                    </li>


                    <li className='pos-relative'>
                      <NavLink to={'/AreaName'} className='nav-link main-list' activeStyle={{
                        fontWeight: "normal",
                        color: "white",
                        backgroundColor: 'rgb(54 162 183)',
                        borderRadius: '3%'
                      }}>
                        <div className='sidebar-icon'>
                          <img src={user && user.default ? user.default : user} alt='language' />
                        </div>
                        <p className='fs--16'>Area</p>
                      </NavLink>
                    </li>

                    <li className='pos-relative'>
                      <NavLink to={'/NationalPathalogy'} className='nav-link main-list' activeStyle={{
                        fontWeight: "normal",
                        color: "white",
                        backgroundColor: 'rgb(54 162 183)',
                        borderRadius: '3%'
                      }}>
                        <div className='sidebar-icon'>
                          <img src={user && user.default ? user.default : user} alt='language' />
                        </div>
                        <p className='fs--16'>National Pathalogy </p>
                      </NavLink>
                    </li>
                    <li className='pos-relative'>
                      <NavLink to={'/NationalRadiology'} className='nav-link main-list' activeStyle={{
                        fontWeight: "normal",
                        color: "white",
                        backgroundColor: 'rgb(54 162 183)',
                        borderRadius: '3%'
                      }}>
                        <div className='sidebar-icon'>
                          <img src={user && user.default ? user.default : user} alt='language' />
                        </div>
                        <p className='fs--16'>National Radiology </p>
                      </NavLink>
                    </li>



                    <li className='pos-relative'>
                      <NavLink to={'/Masterpathelogy'} className='nav-link main-list' activeStyle={{
                        fontWeight: "normal",
                        color: "white",
                        backgroundColor: 'rgb(54 162 183)',
                        borderRadius: '3%'
                      }}>
                        <div className='sidebar-icon'>
                          <img src={user && user.default ? user.default : user} alt='language' />
                        </div>
                        <p className='fs--16'>Master Pathalogy </p>
                      </NavLink>
                    </li> 
                    <li className='pos-relative'> 
                      <NavLink to={'/MasterRadiology'} className='nav-link main-list' activeStyle={{
                        fontWeight: "normal",
                        color: "white",
                        backgroundColor: 'rgb(54 162 183)',
                        borderRadius: '3%'
                      }}>
                        <div className='sidebar-icon'>
                          <img src={user && user.default ? user.default : user} alt='language' />
                        </div>
                        <p className='fs--16'>Master Radiology </p>
                      </NavLink>
                    </li> 
                    <li className='pos-relative'> 
                      <NavLink to={'/homeService'} className='nav-link main-list' activeStyle={{
                        fontWeight: "normal",
                        color: "white",
                        backgroundColor: 'rgb(54 162 183)',
                        borderRadius: '3%'
                      }}>
                        <div className='sidebar-icon'>
                          <img src={user && user.default ? user.default : user} alt='language' />
                        </div>
                        <p className='fs--16'>Home Sample Collections </p>
                      </NavLink>
                    </li> 
                  </div>
                  :
                  null
              }







            </>
          }
          {/* <li className='pos-relative'>
            <NavLink to={'/labs'} className='nav-link main-list'>
              <div className='sidebar-icon'>
                <img src={user} alt='language' />
              </div>
              <p className='fs--16'>Labs</p>
            </NavLink>
          </li> */}


        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
