import React, { useState, useEffect } from 'react';
import { Table, Popover, Input, Col } from 'antd';
import { view_log, triple_dots } from 'helper/constant';
import ViewPharmacy from './EditeAreaName';
import ViewReports from './ViewReports';
import { green_dot, red_dot } from 'helper/constant';
import actions from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { EditTwoTone } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';


const { master } = actions;

const Pharmacies = ({ newcity, onGetarea, newareas, city }) => {
  const dispatch = useDispatch();
  const [visible, setvisible] = useState(false);
  const [reportsvisible, setreportsvisible] = useState(false);
  const [editvisible, setEditvisible] = useState('');
  const [user, setuser] = useState(null);
  const [data, setData] = useState("")
  const [search, setSearch] = useState(" ")
  const Pharma = useSelector((state) => state.pharmacies);
  console.log(Pharma && Pharma?.pharmacies && Pharma?.pharmacies?.data, "phar")
  const PharmaData = Pharma && Pharma?.pharmacies && Pharma?.pharmacies?.data;
  const Auth = useSelector((state) => state.auth);
  const masterPartner = useSelector((state) => state.master);

  console.log(masterPartner, "55554")

  const [dataSource, setDataSource] = useState([]);
  const [value, setValue] = useState('');
  useEffect(() => {
    setDataSource(masterPartner && masterPartner?.araName && masterPartner?.araName?.data)
  }, [masterPartner])

  // const Api = (row) => {
  //   axios.get(`${process.env.REACT_APP_WEB_APP}/user/pharmacyCommission/pharmacy/metrics/data?pharmacy_uuid=${row && row.uuid}`,{
  //       headers:{
  //           "x-auth-token" : Auth.accessToken
  //       }
  //   })
  //   .then((res) => {
  //       console.log(res, "res")
  //       // data.push(res)
  //       setData(res.data)
  //   })
  // }
  // useEffect(() => {
  //   dispatch( MasterAction && MasterAction.getPartnersDta());
  // }, []);

  useEffect(() => {
    dispatch(master?.getAreaDta());
  }, []);

  const onChangesearch = (searchData) => {
    setSearch(searchData)
    dispatch(master?.getAreaDta(searchData))

  }

  const columns = [

    {
      title: 'ID',
      dataIndex: 'uuid',
      key: 'uuid'
    },
    {
      title: 'City Name',
      dataIndex: 'city',
      key: 'city',
      render: (text, row) => (
        <div>{row?.city[0]?.name}</div>
        // <img
        //   className='ml--10 cursor-pointer'
        //   src={view_log && view_log.default?view_log.default:view_log}
        //   alt='view'
        //   onClick={() => {
        //     console.log('view'); 
        //     setuser(row);
        //     setvisible(true);
        //     setEditvisible('view')
        //   }}
        // />
      )
    },

    {
      title: 'Area Name',
      dataIndex: 'name',
      key: 'name'
    },


    {
      title: 'View',
      dataIndex: 'eye',
      key: 'eye',
      render: (text, row) => (
        <img
          className='ml--10 cursor-pointer'
          src={view_log && view_log.default ? view_log.default : view_log}
          alt='view'
          onClick={() => {
            console.log('view');
            setuser(row);
            setvisible(true);
            setEditvisible('view')
          }}
        />
      )
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, row) => (
        <div className='ml--20'>
          <EditTwoTone
            onClick={() => {
              setuser(row);
              setvisible(true);
              setEditvisible('edit')
            }}
          />
          {/* <Popover
            trigger='hover'
            placement='bottom'
            content={
              <div>
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    setuser(row);
                    setvisible(true);
                    setEditvisible('edit')
                  }}
                >
                  Edit
                </div>
              </div>
            }
          >
            <img src={triple_dots} alt='action' />
          </Popover> */}
        </div>
      )
    }
  ];



  return (
    <div>
      <Col span={4} xs={4} md={4}>
        <Input
          // onChange={ e => onChangesearch(e.target.value)}
          type="text"
          placeholder='Search'
          onChange={e => {
            const currValue = e?.target?.value;
            setValue(currValue);
            const filteredData = masterPartner && masterPartner?.araName && masterPartner?.araName.data && masterPartner?.araName?.data?.filter(entry =>
              entry?.name?.includes(currValue)
            );
            setDataSource(filteredData);
          }}
        />
      </Col>
      <br />
      <br />
      <Table
        columns={columns}
        dataSource={dataSource}
        size='middle'
        pagination={{
          defaultPageSize: 10,
          // pageSizeOptions: ['30', '40'],
          //  showSizeChanger: false
        }}
      // onSearch={search}
      />


      <ViewPharmacy
        city={city}
        visible={visible}
        diff={editvisible}
        newcity={newcity}
        onGetarea={onGetarea}
        newareas={newareas}
        loader={false}
        handleOk={() => setvisible(false)}
        handleCancel={() => { setvisible(false); window.location.reload(false) }}
        user={user}
      />
      {/* <ViewReports
      visible={reportsvisible}
      loader={false}
      handleOk={() => setreportsvisible(false)}
      handleCancel={() => {setreportsvisible(false); window.location.reload(false)}}
      data={data}
      >
      </ViewReports> */}
    </div>
  );
};

export default Pharmacies;