import React, { useState, useEffect } from 'react';
import { Table, Popover, Input } from 'antd';
import { view_log, triple_dots } from 'helper/constant';
import ViewPharmacy from './ViewPharmacy';
import ViewReports from './ViewReports';
import ReferedUser from './ReferdUsers'
import { green_dot, red_dot } from 'helper/constant';
import actions from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { EditTwoTone } from '@ant-design/icons';
import { AudioOutlined } from '@ant-design/icons';

const { Search } = Input;
const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: '#1890ff',
    }}
  />
);

const { master } = actions;

const Pharmacies = ({newcity, onGetarea, newareas}) => {
  const dispatch = useDispatch();
  const [visible, setvisible] = useState(false);
  const [referdUsersVisible, setreferdUsersVisible] = useState(false);
  const [editvisible, setEditvisible] = useState('');
  const [user, setuser] = useState(null);
  const [data, setData] = useState("")
  const Pharma = useSelector((state) => state.master);
  console.log(Pharma && Pharma.pharmacies && Pharma.pharmacies.data, "phar")
  const PharmaData = Pharma && Pharma.PharmacyNewData;
  const Auth = useSelector((state) => state.auth);
  const [search,setSearch]=useState(" ")
  console.log(PharmaData,"PharmaData")
  const [reportsvisible, setreportsvisible] = useState(false);
  const [ReferedUsersData, setReferedUsersData] = useState([])
  console.log(ReferedUsersData,"data123")

  const Api = (row) => {
    axios.get(`${process.env.REACT_APP_WEB_APP}/user/pharmacyCommission/pharmacy/metrics/data?pharmacy_uuid=${row && row.uuid}`,{
        headers:{
            "x-auth-token" : Auth.accessToken
        }
    })
    .then((res) => {
        console.log(res, "res")
        // data.push(res)
        setData(res.data)
    })
  }

  const getRefereddUser = (row) => {
    axios.get(`${process.env.REACT_APP_WEB_APP}/user/pharmacyCommission/pharmacy/register/user/list?pharmacy_uuid=${row && row.uuid}&pagenum=0&limit=10`,{
        headers:{
            "x-auth-token" : Auth.accessToken
        }
    })
    .then((res) => {
        console.log(res, "resapi")
        // data.push(res)
        setReferedUsersData(res && res.data && res.data.data)
    })
  }





  
  const columns = [
    {
      title: 'Pharmacy Name',
      dataIndex: 'pharmacy_name',
      key: 'firspharmacy_nametName'
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName'
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: 'Reports',
      dataIndex: 'eye',
      key: 'eye',
      render: (text, row) => (
        <img
          className='ml--10 cursor-pointer'
          src={view_log && view_log.default?view_log.default:view_log}
          alt='view'
          onClick={() => {
            // console.log('view');
            setuser(row);
            Api(row);
            setreportsvisible(true);
            // setEditvisible('view')
          }}
        />
      )
    },
    {
      title: 'Referred Users',
      dataIndex: 'eye',
      key: 'eye',
      render: (text, row) => (
        <img
          className='ml--10 cursor-pointer'
          src={view_log && view_log.default?view_log.default:view_log}
          alt='view'
          onClick={() => {
            // console.log('view');
            setuser(row);
            getRefereddUser(row);
            setreferdUsersVisible(true);
            // setEditvisible('view')
          }}
        />
      )
    },
    // {
    //   title: 'View',
    //   dataIndex: 'eye',
    //   key: 'eye',
    //   render: (text, row) => (
    //     <img
    //       className='ml--10 cursor-pointer'
    //       src={view_log}
    //       alt='view'
    //       onClick={() => {
    //         console.log('view');
    //         setuser(row);
    //         setvisible(true);
    //         setEditvisible('view')
    //       }}
    //     />
    //   )
    // },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, row) => (
        <div className='ml--20'>
          <EditTwoTone
          onClick={() => {
            setuser(row);
            setvisible(true);
            setEditvisible('edit')
          }}
          />
          {/* <Popover
            trigger='hover'
            placement='bottom'
            content={
              <div>
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    setuser(row);
                    setvisible(true);
                    setEditvisible('edit')
                  }}
                >
                  Edit
                </div>
              </div>
            }
          >
            <img src={triple_dots} alt='action' />
          </Popover> */}
        </div>
      )
    }
  ];
  useEffect(() => {
    dispatch(master.getPharmacyNewData());
  }, []);

  let pageNumber = ''
  // console.log(masterPartner.NationalPathalogy, "hgjkl")
  const onChangesearch = (searchData) => {
    setSearch(searchData)
    dispatch(master.getPharmacyNewData(0, searchData))
  }
  return (
    <div>
    <Search 
style={{width:"30%"}}
      placeholder="input search text"
      allowClear
      // enterButton="Search"
      size="large"
      // onSearch={onSearch}
      onChange={ e => onChangesearch(e.target.value)}

  enterButton />
  <br/><br/>

      <Table
        columns={columns}
        dataSource={PharmaData.data}
        size='middle'
        pagination={{
                    total: PharmaData && PharmaData.count,
                    showSizeChanger: false
                }}

                onChange={(pagination) => {
                    
                    if (pagination.current === 1) {
                        pageNumber = 0
                    } else {
                        pageNumber = pagination.current - 1
                    }
                    dispatch(master.getPharmacyNewData(pageNumber))
                }}
      />
      <ViewPharmacy
        visible={visible}
        diff={editvisible}
        newcity={newcity}
        onGetarea={onGetarea}
        newareas={newareas}
        loader={false}
        handleOk={() => setvisible(false)}
        handleCancel={() => {setvisible(false); window.location.reload(false)}}
        user={user}
      />
      <ViewReports
      visible={reportsvisible}
      loader={false}
      handleOk={() => setreportsvisible(false)}
      handleCancel={() => {setreportsvisible(false); window.location.reload(false)}}
      data={data}
      >
      </ViewReports>

      <ReferedUser
      visible={referdUsersVisible}
      loader={false}
      handleOk={() => setreferdUsersVisible(false)}
      handleCancel={() => {setreferdUsersVisible(false)}}
      ReferedUserdata={ReferedUsersData}
      >
      </ReferedUser>
    </div>
  );
};

export default Pharmacies;