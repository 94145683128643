import React, { useState, useEffect } from 'react';
import { Form, Modal, Input, Row, Col, Button, Select } from 'antd';
import axios from "axios"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import 'antd/dist/antd.css';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { useDispatch } from 'react-redux';
import actions from "actions";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }

  return isJpgOrPng && isLt2M;
};

const { Option } = Select;

const AddInfluencer = ({ visible, handleOk, handleCancel, onGetarea, newcity, newareas, onSubmit }) => {

  const [country, setCountry] = useState();

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((res) => {
        setCountry(res.country_code.toLowerCase());
      })
      .catch((data, status) => {
        console.log("Request failed:", data);
      });
  }, []);
  
  const handleSubmit = values => {
    console.log(values, "valuesvalues")
    // let values = values;
    onSubmit(
      {
        "firstName": values.firstName,
        "lastName": values.lastName,
        "email": values.email,
        "mobile": values.Mobile,
        influencer_type: influencertype,
        "influencer_name": values.firstName + values.lastName
      },
      typesubmit

    );
    // reset();
    handleOk();
    // window.location.reload('');
  };

  const [newcityuuid, setnewcityuuid] = useState('');
  const [partnercity, setpartnercity] = useState('');
  const [partnerarea, setpartnerarea] = useState('');
  const [typesubmit, settypesubmit] = useState("addpartner");
  const [influencerlist, setinfluencerlist] = useState([]);
  const [influencertype, setinfluencertype] = useState('influencer');

  const areasubmit = values => {
    console.log("area submit", values)
    onGetarea(values)
  }
  const dispatch = useDispatch()
  const { influencerActions } = actions;

  // rest Api For Countries

  useEffect(() => {
      axios.get(`${process.env.REACT_APP_WEB_APP}/user/auth/introducer/list`)
      .then((res) => {
        console.log(res, "res")
        setinfluencerlist(res.data.data)
      })
      .catch((err) => {
        console.log(err, "res")
      });
    }, []);
  // useEffect(() => {
  //   dispatch(influencerActihandleCancelons.register("payLoad"));
  // })
  return (
    <Modal
      className='no-modal-footer'
      title='Add influencer'
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={900}
      okText='Add'
      footer={[
        <Button className='login-form-button' onClick={handleCancel}>
          Cancel
        </Button>|

        <Button
          type='primary'
          htmlType='submit'
          className='login-form-button ml--10'
        >
          Add
        </Button>
      ]}
    >
      <Form onFinish={handleSubmit} className='gx-form-row0' layout='vertical'>
        {/* <Row>
          <Col span={6} xs={6} md={6}>
            <Form.Item
              label='Partner Logo'
              name='logo'
              rules={[{ required: true, message: 'Please Upload Logo!' }]}
            >

              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="avatar"
                    className='saikiran'
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </Col>
        </Row> */}
        <Row>
        <Col span={6} xs={6} md={6}>
            <Form.Item
              label='Influencer Type'
              name='influencer_type'
            >
              <label>Influencer</label>&nbsp;&nbsp;<Input type="radio" name='influencer_type' checked={influencertype === 'influencer'} onClick={() => setinfluencertype('influencer')} value={'influencer'} size='large' />
              &nbsp;&nbsp;&nbsp;&nbsp;<label>Nurse</label>&nbsp;&nbsp;<Input type="radio" onClick={() => setinfluencertype('nurse')} name='influencer_type' value={'nurse'} size='large' />
            </Form.Item>
          </Col>
          &nbsp;&nbsp;&nbsp;
          <Col span={6} xs={6} md={6}>
            <Form.Item
              label='First Name'
              name='firstName'
              rules={[
                { required: true, message: 'Please input First Name!' }
              ]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          &nbsp;&nbsp;&nbsp;
          <Col span={6} xs={6} md={6}>
            <Form.Item
              label='Last Name'
              name='lastName'
              rules={[{ required: true, message: 'Please input Last Name!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          &nbsp;&nbsp;&nbsp;
          <Col span={6} xs={6} md={6}>
            <Form.Item
              label='Email'
              name='email'
              rules={[{ required: true, message: 'Please input email!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          &nbsp;&nbsp;&nbsp;
          <Col span={6} xs={6} md={6}>
            <Form.Item
              label='Mobile'
              name='Mobile'
              rules={[{ required: true, message: 'Please input Mobile Number!' }]}
            >
              <PhoneInput
                country={country}
                countryCodeEditable={false}
                size="large"
                style={{width:"50px"}}
                // disabled={diff == 'view'}
              />
            </Form.Item>
          </Col>
        </Row>
        <br/>
        <Button className='login-form-button' onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          type='primary'
          htmlType='submit'
          className='login-form-button ml--10'
        >
          Add
        </Button>
      </Form>
    </Modal>
  );
};

export default AddInfluencer;
