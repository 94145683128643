import { all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from 'actions';
import { userLogin } from 'services/auth-services';
import { push } from 'react-router-redux';
import { notification } from 'antd'
const { authActions } = actions;


export function* loginRequest() {
  yield takeEvery(authActions.LOGIN_REQUEST, function* ({ data }) {
    try {
      const response = yield userLogin({
        username: data.username,
        password: data.password
      });
      if (response.status === 200) {
        const { data } = response;
        yield put({
          type: actions.authActions.LOGIN_SUCCESS,
          accessToken: data.accessToken,
          admin: data.user
        });
        yield notification.open({ message: 'Success!', description: 'Logged in successfully!' })
        if (data && data.user) {
          if (data.user.role === 'admin') {
            yield put(push('/dashboard'));
          } else if (data.user.role === 'dmp-admin') {
            yield put(push('/dashboard'));
          } else if (data.user.role === 'support') {
            yield put(push('/support'));
          } else {
            yield put(push('/dashboard'));
          }
        }

      }
    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Username or password is incorrect' })
      yield put({ type: actions.authActions.AUTH_ERROR });
    }
  });
}


export function* authErrorSaga() {
  yield takeEvery(authActions.AUTH_ERROR, function* () {
    yield put({
      type: actions.authActions.LOGOUT
    });
  });
}

export default function* rootSaga() {
  yield all([fork(loginRequest), fork(authErrorSaga)]);
}
