// To fetch all Channels of a PubNub application.
export const getChannels = async (pubnub, nextPage, prevPage) => {
    const channelsResponse = await pubnub.objects.getAllChannelMetadata({
        include: {
            totalCount: true,
        },
        limit: 25,
        page: {
            next: nextPage,
            prev: prevPage,
        },
        sort: { updated: "desc" },
    });
    if (channelsResponse.status === 200) {
        return channelsResponse;
    }
    throw new Error("Failed to get PubNub channels");
}

//To fetch channel Members
export const getChannelMembers = async (pubnub, channelName, nextPage) => {
    const channelMembersResponse = await pubnub.objects.getChannelMembers({
        channel: channelName,
        include: {
            UUIDFields: true,
            customUUIDFields: true,
            totalCount: true,
        },
        limit: 100,
        page: {
            next: nextPage,
        },
    });
    if (channelMembersResponse.status === 200) {
        return channelMembersResponse;
    }
    throw new Error("Failed to get PubNub channel memebers");
}