import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Table, Checkbox, Row, Col, Input, Space } from 'antd';
import moment from "moment";
import { view_log, green_dot, red_dot, triple_dots } from 'helper/constant';
import {
    EditOutlined
} from '@ant-design/icons';

import actions from "actions";
import SaasDoctorsView from './views/SaasDoctorsView';
import AddDoctor from 'components/doctors/AddDoctor';
import TransactionDoctor from './Transactions/index.js';
import { Helmet } from 'react-helmet';
const { saasActions } = actions;

export default function SaasDoctorsTable() {
    const dispatch = useDispatch();
    let history = useHistory();
    const doctorsData = useSelector((state) => state.saasReducer);
    const [doctorsView, setDoctorsView] = useState(false);
    const [doctorsDetails, setDoctorsDetails] = useState({});
    const [doctorsEdit, setDoctorsEdit] = useState(false);
    const [doctorTrans, setDoctorTrans] = useState(false);
    const [searchQuery, setSearchQuery] = useState('')

    const cities = useSelector((state) => state.common.cities);
    const states = useSelector((state) => state.common.states);

    const Search = Input.Search;

    useEffect(() => {
        dispatch(saasActions.getSaasDoctors(0));
    }, [])



    const columns = [
        {
            title: 'Name',
            dataIndex: 'full_name',
            key: 'full_name',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile',
            key: 'mobile',

        },
        {
            title: 'Email ID',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Experience',
            dataIndex: 'total_year_experience',
            key: 'total_year_experience',
        },
        {
            title: 'Status',
            dataIndex: 'total_year_experience',
            key: 'total_year_experience',
            render: (text, row) => (
                <div className="tbl-user-photo">
                    {row && row.active ? (
                        <img src={green_dot && green_dot.default?green_dot.default:green_dot} alt="Active" />
                    ) : (
                        <img src={red_dot && red_dot.default?red_dot.default:red_dot} alt="Inactive" />
                    )}
                </div>
            ),
        },
        {
            title: 'View',
            dataIndex: 'eye',
            key: 'eye',
            render: (text, row) => (
                <img
                    className='ml--10 cursor-pointer'
                    src={view_log && view_log.default?view_log.default:view_log}
                    alt='view'
                    onClick={() => {
                        setDoctorsDetails(row)
                        setDoctorsView(true)
                    }}
                />
            )
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (text, row) => (
                <div style={{ justifyContent: 'center', display: 'flex' }}>

                    <EditOutlined style={{ color: "#1974D2" }}
                        onClick={() => {
                            setDoctorsDetails(row)
                            setDoctorsEdit(true)

                            // history.push({
                            //     pathname: "/saas-doctors-edit",
                            //     state: {
                            //         doctorDetails: row,
                            //     },
                            // });

                        }} />
                </div>
            )
        },
        {
            title: 'Transactions',
            dataIndex: 'eye',
            key: 'eye',
            render: (text, row) => (
                <img
                    className='ml--10 cursor-pointer'
                    src={view_log && view_log.default?view_log.default:view_log}
                    alt='view'
                    onClick={() => {
                        setDoctorsDetails(row)
                        setDoctorTrans(true)
                    }}
                />
            )
        },

    ];

    const handleOnSearch = (value) => {
        console.log(value)
        setSearchQuery(value)
        dispatch(saasActions.getSaasDoctors(0, value));
    }


    return (
        <div>
            <Helmet>
                <title>Doctall Super Admin Third Party Doctors
                </title>
                <meta
                    name='description'
                    content='View third-part doctors added to the platform. See their specialty, experience and transactions at a glance'


                />
            </Helmet>
            <Row>
                <Col md={17}>

                </Col>
                <Col md={6}>
                    <div className="fs--16 font-semibold flex-1">
                        <Space direction="vertical">
                            <Search
                                size="large"
                                // enterButton="Search"
                                placeholder="Search Doctor"
                                enterButton
                                allowClear
                                // onSearch={handleOnSearch}
                                onChange={e => handleOnSearch(e.target.value)}
                            />

                        </Space>
                    </div>
                </Col>
            </Row>
            <Table
                columns={columns}
                bordered={true}
                dataSource={doctorsData && doctorsData.data.reverse()}
                size='middle'
                loading={doctorsData && doctorsData.loader}
                pagination={{
                    total: doctorsData && doctorsData.count,
                    showSizeChanger: false
                }}

                onChange={(pagination) => {
                    let pageNumber = ''
                    if (pagination.current === 1) {
                        pageNumber = 0
                    } else {
                        pageNumber = pagination.current - 1
                    }
                    dispatch(saasActions.getSaasDoctors(pageNumber, searchQuery))
                }}
            />

            <SaasDoctorsView
                doctorDetails={doctorsDetails}
                visible={doctorsView}
                close={() => { setDoctorsView(false); }}
            />

            <AddDoctor
                visible={doctorsEdit}
                cities={cities}
                states={states}
                handleOk={() => {
                    setDoctorsEdit(false);
                    dispatch(saasActions.getSaasDoctors(0))
                }}
                handleCancel={() => {
                    setDoctorsEdit(false);
                    // dispatch(saasActions.getSaasDoctors(0));
                }}
                values={doctorsDetails}
                commissions={true}
            />
            <TransactionDoctor
                visible={doctorTrans}
                cities={cities}
                states={states}
                close={() => { setDoctorTrans(false); }}
                values={doctorsDetails}
                commissions={true}
            />

        </div>
    )
}
