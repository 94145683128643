import React, { useState } from 'react';
import { Modal, Table } from 'antd';
import { view_log, triple_dots } from 'helper/constant';
import RefredIntroducer from "./ReferdIntroducer"
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';


const PharmacyReferedUsers = ({ visible, handleOk, handleCancel, user, data, fun, count, influencerViewData1, ReferedUserdata }) => {
  const Auth = useSelector((state) => state.auth);
  console.log(count, "refered")
  const [userData, setUserData] = useState()
  const [userData1, setUserData1] = useState()
  console.log(userData, "userData")
  const [referdUsersVisible, setreferdUsersVisible] = useState(false);
  
  const [Idss, setIdss] = useState()
  const [counts, setCount] = useState()

  const [ReferedUserdata1, setReferedUsersData] = useState([])

  console.log(ReferedUserdata1, "ReferedUserdata112")

  let pageNumber;

  console.log(referdUsersVisible, "referdUsersVisible")
  var history = useHistory()
  const editEventHandler = (event, pagenum)=> {
    console.log(event.uuid, "event")
    let page ;
    if(pagenum == undefined){
      page = 0
    }
    else{
      page = pagenum
    }
    setIdss(event)
    setUserData1(event)
    setreferdUsersVisible(true)
    axios.get(`${process.env.REACT_APP_WEB_APP}/user/pharmacyCommission/pharmacy/register/user/list?pharmacy_uuid=${event.uuid}&pagenum=${page}&limit=10`, {
      headers: {
        "x-auth-token": Auth.accessToken
      }
    })
      .then((res) => {
        console.log(res.data.data.user, "resapi")
        // data.push(res)
        setReferedUsersData(res && res.data && res.data.data)
        setCount(res && res.data && res.data.count)
      })

  };




  // const getUserRefferData=()=>{


  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name',
      render: (text, item) => (
        <div>{item && item.full_name}</div>
      )
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile',
      key: 'mobile',
      render: (text, item) => (
        <div>{item && item.mobile}</div>
      )
    },
    {
      title: 'Email Id',
      dataIndex: 'email',
      key: 'email',
      render: (text, item) => (
        <div>{item && item.email}</div>
      )
    },
    {
      title: 'User Id',
      dataIndex: 'uuid',
      key: 'uuid',
      render: (text, item) => (
        <div>{item && item.uuid}</div>
      )
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      render: (text, item) => (
        <img
          className='ml--10 cursor-pointer'
          src={view_log && view_log.default?view_log.default:view_log}
          alt='view'
          onClick={() => {editEventHandler(item)
            }   
          }
        />
      )
    }

  ];







  return (
    <>

      <Modal
        title={`Users`}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={850}
        footer={[
        ]}
      >

        {
          ReferedUserdata.length == 0 ?
            <div style={{ textAlign: "center" }}>
              <h4 style={{ fontFamily: "open-sans-regular", fontSize: "20px", fontWeight: "500" }}> No Referred Users </h4>
            </div>
            :
            <div>
              {/* <table style={{ width: "100%" }}>
        <tr style={{ background: "#F0F5F5", height: "38px" }}>
          <th> &nbsp; &nbsp; Full Name</th>
          <th>Mobile No.</th>
          <th>Email Id</th>
          <th>User ID</th>
          <th>View</th>
        </tr>    
      {
        ReferedUserdata.map((item,index)=>{ 
          console.log(item,"item")
         return <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
        <td>{item.full_name}</td>
        <td>{item.mobile}</td>
        <td>{item.email}</td>
        <td>{item.uuid}</td>
        <td><img
          className='ml--10 cursor-pointer'
          src={view_log}

          alt='view'
          onClick={() => {editEventHandler(item)
            // setreferdUsersVisible(true)
            }
            
          }
        />
        </td>
      
      </tr>
        })
      }      
      </table>    */}
              <Table
                columns={columns}
                dataSource={ReferedUserdata}
                size='middle'
                pagination={{
                  total: count,
                  showSizeChanger: false
                }}

                onChange={(pagination) => {

                  if (pagination.current === 1) {
                    pageNumber = 0
                  } else {
                    pageNumber = pagination.current - 1
                  }
                  fun(influencerViewData1, pageNumber)
                  // dispatch(master.getInfluencerData(pageNumber))
                }}
              />
            </div>
        }


        <RefredIntroducer
          visible={referdUsersVisible}
          // loader={false}
          // handleOk={() => setreferdUsersVisible(false)}
          handleCancel={() => { setreferdUsersVisible(false) }}
          refUserData={ReferedUserdata1}
          influencerViewData1={Idss}
          count={counts}
          fun1 = {editEventHandler}
        >
        </RefredIntroducer>


      </Modal>
    </>
  );
};

export default PharmacyReferedUsers;