import React, { useState, useEffect } from 'react';
import { Form, Modal, Input, Row, Col, Button, Select } from 'antd';
import axios from "axios"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const { Option } = Select;

const AddCity = ({ visible, handleOk, handleCancel, onGetarea, newcity, newareas, onSubmit }) => {

  const [country, setCountry] = useState();

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((res) => {
        setCountry(res.country_code.toLowerCase());
      })
      .catch((data, status) => {
        console.log("Request failed:", data);
      });
  }, []);
  const handleSubmit = values => {
    // let values = values;
    onSubmit(
      {
        "name" : values.city_name,
  
      },
      typesubmit

    );
    // reset();
    handleOk();
    // window.location.reload('');
  };
  
  const [newcityuuid, setnewcityuuid] = useState('');
  const [partnercity, setpartnercity] = useState('');
  const [partnerarea, setpartnerarea] = useState('');
  const [typesubmit, settypesubmit] = useState("addpartner");
  const [countrylist, setCountrylist] = useState([]);
  const [areaId, setareaId] = useState();

  const areasubmit = values => {
    console.log("area submit", values)
    onGetarea(values)
  }



// rest Api For Countries

// useEffect(() => {
//     axios.get(`${"https://restcountries.com/v3.1/all"}`)
//     .then((res) => {
//       console.log(res, "res")
//       setCountrylist(res)
//     })
//     .catch((err) => {
//       console.log(err, "res")
//     });
//   }, []);
  return (
    <Modal
      className='no-modal-footer'
      title='Add City'
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={900}
      okText='Add'
      footer={[
        <Button className='login-form-button' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          type='primary'
          htmlType='submit'
          className='login-form-button ml--10'
        >
          Add
        </Button>
      ]}
    >
      <Form onFinish={handleSubmit} className='gx-form-row0' layout='vertical'>
        <Row>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='City_name'
              name='city_name'
              rules={[{ required: true, message: 'Please input pharmacy name!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
         
          
          
        </Row>
        <Button className='login-form-button' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          type='primary'
          htmlType='submit'
          className='login-form-button ml--10'
        >
          Add
        </Button>
      </Form>
    </Modal>
  );
};

export default AddCity;
