const transactionActions = {
    GET_TRANSACTION: "GET_TRANSACTION",
    GET_TRANSACTION_SUCCESS: "GET_TRANSACTION_SUCCESS",
    getTransactions: (Ttype, page) => {
      console.log(Ttype, page, "ahsdasguy")
      return {
        type: transactionActions.GET_TRANSACTION,
        Ttype,
        page
      };
    }
  };
  
  export default transactionActions;
  