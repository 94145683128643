// import React, { useEffect, useState } from "react";
// import { Table, Popover, Button } from "antd";
// import { view_log, triple_dots, green_dot, red_dot } from "helper/constant";
// import ViewTransaction from "./ViewTransaction";
// import { useDispatch, useSelector } from "react-redux";
// import actions from "actions";
// import { CSVLink } from "react-csv";
// import moment from "moment";
// import { Helmet } from "react-helmet";
// const { transactionActions } = actions;

// const Transactions = ({ tableData }) => {
//   const [visible, setvisible] = useState(false);
//   const [user, setuser] = useState(null);
//   const [refs, setRefs] = useState(false);
//   const [exptData, setExptData] = useState([]);

//   const columns = [
//     {
//       title: "Transaction Id",
//       dataIndex: "reference_id",
//       key: "reference_id",
//     },
//     // {
//     //   title: 'Doctor Name',
//     //   dataIndex: 'doctor_name',
//     //   key: 'doctor_name'
//     // },
//     {
//       title: "Transaction Date",
//       dataIndex: "Transaction",
//       key: "Transaction",
//       render: (text, row) => (
//         <div>{moment(row.createdAt).format("DD/MM/yyyy hh:mm a")}</div>
//       ),
//     },
//     {
//       title: "Patient Name",
//       dataIndex: "patient_name",
//       key: "patient_name",
//     },
//     {
//       title: "Patient Email",
//       dataIndex: "patient_email",
//       key: "patient_email",
//     },
//     {
//       title: "Amount",
//       dataIndex: "amount",
//       key: "amount",
//     },
//     {
//       title: "Patient Phone Number",
//       dataIndex: "patient_phone_number",
//       key: "patient_phone_number",
//     },
//     {
//       title: "Type Transaction",
//       dataIndex: "transcation_type",
//       key: "transcation_type",
//     },
//     {
//       title: "View",
//       dataIndex: "eye",
//       key: "eye",
//       render: (text, row) => (
//         <img
//           className="ml--10 cursor-pointer"
//           src={view_log}
//           alt="view"
//           onClick={() => {
//             setuser(row);
//             setvisible(true);
//           }}
//         />
//       ),
//     },
//   ];
//   const dispatch = useDispatch();

//   const payload = useSelector((state) => state.transaction);
//   const src = useSelector((state) => state.transaction.transactions);
//   console.log(payload.transactions)

 
//   useEffect(() => {
//     dispatch(transactionActions.getTransactions());
//   }, []);
//   let exptHead = [
//     "Transaction Id",
//     "Transaction Date",
//     "Doctor Name",
//     "Patient Name",
//     "Patient Email",
//     "Patient Phone Number",
//     "Amount",
//     "Refund Eligible",
//     "Refund Provided",
//     "Transcation Type",
//   ];
  
//   useEffect(() => {
//     let exportDataForming = [];
//     src.map((user) => {
//       let innerActivityRow = [
//         user && user.reference_id,
//         user && moment(user.createdAt).format("DD/MM/yyyy hh:mm a"),
//         user && user.doctor_name,
//         user && user.patient_name,
//         user && user.patient_email,
//         user && user.patient_phone_number,
//         user && user.amount,
//         user && user.refund_eligible !== undefined ? user.refund_eligible : "No Data",
//         user && user.refund_provided,
//         user && user.transcation_type,
//       ];
//       exportDataForming.push(innerActivityRow);
//     });
//   setExptData(exportDataForming);

//   }, [src]);

//   return (
//     <div>
//       <Helmet>
//         <titl>Doctall Super Admin Transactions
// </titl>
// <meta 
//             name='description' 
//             content='View transaction details, from ID to patent associated with the transaction, amount and type of transaction. Search and filter by provided detail.'
         

//    />
//       </Helmet>
//       <div className="pictogram-header flex-x align-center ptb--15">
//         <div className="fs--16 font-semibold flex-1">{/* Users */}</div>

//         <CSVLink
//           data={exptData}
//           headers={exptHead}
//           filename={`Transaction${moment().format("DD/MM/YYYY hh/mm")}.csv`}
//           style={{ color: "#ffff" }}
//         >
//           <Button style={{ height: 42, marginRight: 10 }} type="primary">
//             Export
//           </Button>
//         </CSVLink>
//       </div>
//       <Table
//         columns={columns}
//         loader={payload.loader}
//         dataSource={payload.transactions}
//         size="small"
//         pagination={{
//           defaultPageSize: 12,
//         }}
//       />
//       <ViewTransaction
//         visible={visible}
//         loader={false}
//         closer={setvisible}
//         handleOk={() => setvisible(false)}
//         handleCancel={() => setvisible(false)}
//         user={user}
//         refundStat={refs}
//       />
//     </div>
//   );
// };

// export default Transactions;

// const data = [
//   {
//     key: "1",
//     fullName: "John Brown",
//     phoneNo: "9898145895",
//     email: "test@mailinator.com",
//     dob: "13/05/1993",
//     gender: "Male",
//     status: true,
//     education: "M.B.B.S.",
//     registrationNo: "GDC:1645",
//     registrationCouncil: "GDC",
//     licence: "GSDC",
//     practiceStandardYear: "2018",
//     speciality: "dentist",
//     languages: ["gujrati", "hindi", "english"],
//     shortBio: "i am doctor for 10 years",
//   },
//   {
//     key: "2",
//     email: "test@mailinator.com",
//     fullName: "John Brown",
//     phoneNo: "9898145895",
//     dob: "13/05/1993",
//     gender: "Male",
//     status: false,
//     education: "M.B.B.S.",
//     registrationNo: "GDC:1645",
//     registrationCouncil: "GDC",
//     licence: "GSDC",
//     practiceStandardYear: "2018",
//     speciality: "dentist",
//     languages: ["gujrati", "hindi", "english"],
//     shortBio: "i am doctor for 10 years",
//   },
//   {
//     key: "3",
//     email: "test@mailinator.com",
//     fullName: "John Brown",
//     phoneNo: "9898145895",
//     dob: "13/05/1993",
//     gender: "Male",
//     status: false,
//     education: "M.B.B.S.",
//     registrationNo: "GDC:1645",
//     registrationCouncil: "GDC",
//     licence: "GSDC",
//     practiceStandardYear: "2018",
//     speciality: "dentist",
//     languages: ["gujrati", "hindi", "english"],
//     shortBio: "i am doctor for 10 years",
//   },
//   {
//     key: "4",
//     email: "test@mailinator.com",
//     fullName: "John Brown",
//     phoneNo: "9898145895",
//     dob: "13/05/1993",
//     gender: "Male",
//     status: true,
//     education: "M.B.B.S.",
//     registrationNo: "GDC:1645",
//     registrationCouncil: "GDC",
//     licence: "GSDC",
//     practiceStandardYear: "2018",
//     speciality: "dentist",
//     languages: ["gujrati", "hindi", "english"],
//     shortBio: "i am doctor for 10 years",
//   },
//   {
//     key: "5",
//     email: "test@mailinator.com",
//     fullName: "John Brown",
//     phoneNo: "9898145895",
//     dob: "13/05/1993",
//     gender: "Male",
//     status: true,
//     education: "M.B.B.S.",
//     registrationNo: "GDC:1645",
//     registrationCouncil: "GDC",
//     licence: "GSDC",
//     practiceStandardYear: "2018",
//     speciality: "dentist",
//     languages: ["gujrati", "hindi", "english"],
//     shortBio: "i am doctor for 10 years",
//   },
//   {
//     key: "6",
//     email: "test@mailinator.com",
//     fullName: "John Brown",
//     phoneNo: "9898145895",
//     dob: "13/05/1993",
//     gender: "Male",
//     status: true,
//     education: "M.B.B.S.",
//     registrationNo: "GDC:1645",
//     registrationCouncil: "GDC",
//     licence: "GSDC",
//     practiceStandardYear: "2018",
//     speciality: "dentist",
//     languages: ["gujrati", "hindi", "english"],
//     shortBio: "i am doctor for 10 years",
//   },
//   {
//     key: "7",
//     email: "test@mailinator.com",
//     fullName: "John Brown",
//     phoneNo: "9898145895",
//     dob: "13/05/1993",
//     gender: "Male",
//     status: true,
//     education: "M.B.B.S.",
//     registrationNo: "GDC:1645",
//     registrationCouncil: "GDC",
//     licence: "GSDC",
//     practiceStandardYear: "2018",
//     speciality: "dentist",
//     languages: ["gujrati", "hindi", "english"],
//     shortBio: "i am doctor for 10 years",
//   },
//   {
//     key: "8",
//     email: "test@mailinator.com",
//     fullName: "John Brown",
//     phoneNo: "9898145895",
//     dob: "13/05/1993",
//     gender: "Male",
//     status: true,
//     education: "M.B.B.S.",
//     registrationNo: "GDC:1645",
//     registrationCouncil: "GDC",
//     licence: "GSDC",
//     practiceStandardYear: "2018",
//     speciality: "dentist",
//     languages: ["gujrati", "hindi", "english"],
//     shortBio: "i am doctor for 10 years",
//   },
// ];

import { Modal } from 'antd';
import React, { useState } from 'react';
import { Button, Tabs } from 'antd';
import { Spin } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Appointments from "./TransactionTabs/Appointment"
import Dmp from "./TransactionTabs/Dmp"
import TreatmentPlans from "./TransactionTabs/TreatmentPlans"
import actions from "actions";

const { transactionActions } = actions;

const ViewPatients = ({ user, visible, loader, handleOk, handleCancel }) => {

  const { TabPane } = Tabs;

const callback = (k) => {
if(k === "1"){
  {dispatch(transactionActions.getTransactions("appointment", 0))}
}
else if(k === "2"){
  {dispatch(transactionActions.getTransactions("cart", 0))}
}
else if(k==="3" ) {
  {dispatch(transactionActions.getTransactions("treatmentplan", 0))}
}
}

const dispatch = useDispatch();

  return (


      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab={<h3>Appointments </h3>} key="1">
          
          <Appointments />
        </TabPane>
        <TabPane tab={<h3>DMP</h3>} key="2">
          <Dmp />
        </TabPane>
        <TabPane tab={<h3>Treatment Plans</h3>} key="3">
          <TreatmentPlans />
        </TabPane>
      </Tabs>
  );
};

export default ViewPatients;
