import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import PartnersTable from 'components/partners/PartnersTable'
import actions from "actions";

const { userActions, commonActions } = actions;

export default function PartnerPage() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(commonActions.getMetadata());
        dispatch(commonActions.getallnewcities());
        dispatch(userActions.getPartner());
    }, [dispatch]);

    return (
        <div className="pictogram-container">

            <PartnersTable />

        </div >

    )
}
