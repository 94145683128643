import { all, takeEvery, put, fork,select } from 'redux-saga/effects';
import actions from 'actions';
import { notification } from 'antd';
import { editinfluencersservice ,editintroducerservice, getInfluencer,addIntroducer } from 'services/user-services'

import { fetchinfluencers } from 'services/influencerServices';


const { influencerActions ,authActions } = actions;

export function* fetchInfluencer(){
console.log(fetchInfluencer,"fetchInfluencer")
    yield takeEvery(influencerActions.GET_INFLUENCERS, function* ({ pageNumber1, searchQuery1 }) {
        try{
            console.log(pageNumber1)
            const token = yield select(state => state.auth.accessToken);
            const response = yield fetchinfluencers({ token }, pageNumber1, searchQuery1 );
            console.log("response", response);
            if(response.status === 200){
                yield put({
                    type: influencerActions.GET_INFLUENCERS_SUCCESS,
                    data: response.data,
                    count: response.data.count,
                });
            }else {
                throw response;
            }
        }catch (error){
            console.log(error)
        }
    });
}

export function* influencersaga(){
    
    yield takeEvery(influencerActions.INFLUENCER_REQUEST, function*({ payload }){
        console.log(payload, "reg_reducers")
        try{
        const token = yield select(state =>state.auth.accessToken);
        const response = yield getInfluencer({token}, payload );

        const { data } = response;
        yield put({
          type:  influencerActions.INFLUENCER_REQUEST_SUCCESS,
          data: data  
        });
        yield notification.open({ message: 'Success' , description: `${"influencer Added Successfully"}`})
        setTimeout(function(){
            window.location.reload();
          },1000)

        } catch(error){
            yield notification.open({ message: 'Failed' , description: `${error.response.data}`})
            // if (error.response && error.response.status === 401 ){
            //     yield put({type : influencerActions.error})
            // }
        }
    });
}

export function* AddIntroducersaga(){
    
  yield takeEvery(influencerActions.ADD_INTRODUCER, function*({ payload }){
      console.log(payload, "reg_reducers")
      try{
      const token = yield select(state =>state.auth.accessToken);
      const response = yield addIntroducer({token}, payload );

      const { data } = response;
      yield put({
        type:  influencerActions.ADD_INTRODUCER_SUCESS,
        data: data  
      });
      setTimeout(function(){
          window.location.reload();
        },1000)
        yield notification.open({ message: 'Success!', description: 'Introducer Add Succesfully' })
      } catch(error){
          yield notification.open({ message: 'Failed' , description: `${error.response.data}`})
          // if (error.response && error.response.status === 401 ){
          //     yield put({type : influencerActions.error})
          // }
      }
  });
}





// export function* editInfluencerSaga(){
//     console.log()
//     yield takeEvery(influencerActions.EDIT_INFLUENCER, function* ({ data , uuid }) {
//         try {
//             const token = yield select(state => state.auth.accessToken);

//             const response = yield editinfluencersservice({ token }, data , uuid);
//             if (response.status === 200) {
//                 // getToken()
//                 yield notification.open({
//                     message: 'Success!',
//                     description: 'Influencer Details Updated Successfully!'
//                 });
//                 yield put({
//                     type: influencerActions.EDIT_INFLUENCER_SUCESS,
//                 });
//                 yield put({
//                     type: influencerActions.GET_INFLUENCERS
//                 });
//             } else {
//                 throw response;
//             }
//         }catch (error) {
//             if (error && error.response) {
//                 yield notification.open({
//                     message: 'Failed!',
//                     description: error.response.data.message
//                 });
//                 yield put({ type: influencerActions.ON_ERROR });
//             }
//             if (error.response.status === 401) {
//                 yield put({ type: authActions.AUTH_ERROR });
//             }
//         }
//     } );
// }

export function*  editInfluencerSaga() {
    yield takeEvery(influencerActions.EDIT_INFLUENCER, function*({payLoad}) {
      console.log(payLoad,"pay")
      try{
        const token = yield select(state => state.auth.accessToken);
        const response = yield editinfluencersservice({token},payLoad);
          const { data } = response;
          yield put({
            type: influencerActions.EDIT_INFLUENCER_SUCESS,
            data
          });
          setTimeout(function(){
            window.location.reload();
          }, 1000)
          
          yield notification.open({ message: 'Success!', description: 'updated succesfully' })
      }catch(error){
        yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
        if (error.response && error.response.status === 401) {
          yield put({ type: authActions.AUTH_ERROR });
        }
      }
    });
  }

  export function*  editIntroducerSaga() {
    yield takeEvery(influencerActions.EDIT_INTRODUCER, function*({payLoad}) {
      console.log(payLoad,"pay")
      try{
        const token = yield select(state => state.auth.accessToken);
        const response = yield editintroducerservice({token},payLoad);
          const { data } = response;
          yield put({
            type: influencerActions.EDIT_INTRODUCER_SUCESS,
            data
          });
          setTimeout(function(){
            window.location.reload();
          }, 1000)
          
          yield notification.open({ message: 'Success!', description: 'updated succesfully' })
      }catch(error){
        yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
        if (error.response && error.response.status === 401) {
          yield put({ type: authActions.AUTH_ERROR });
        }
      }
    });
  }





export default function* rootSaga() {
    yield all([fork(fetchInfluencer), fork(influencersaga), fork(editInfluencerSaga), fork(editIntroducerSaga), fork(AddIntroducersaga)]);
  }