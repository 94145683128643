import axios from 'axios';
import { setHeadersWithAccessToken } from './index';
const WEB_API_BASE = process.env.REACT_APP_WEB_APP;


export const getSaasDoctors = (pageNumber,search, auth) => {
    setHeadersWithAccessToken(auth);
    let page;
    if (pageNumber === undefined) {
        page = 0
    } else {
        page = pageNumber
    }
    let searchItem;
    if (search === undefined) {
        searchItem = ''
    } else {
        searchItem = search
    }
    return axios
        .get(`${WEB_API_BASE}/user/admin/search/external/doctors?pagenum=${page}&limit=10&search=${searchItem}`)
        .then(e => e);
};


export const getSaasAllDoctors = (pageNumber, auth) => {
    setHeadersWithAccessToken(auth);
    let page;
    if (pageNumber === undefined) {
        page = 0
    } else {
        page = pageNumber
    }
    return axios
        .get(`${WEB_API_BASE}/user/admin/search/external/doctors?pagenum=${page}&limit=10&search=`)
        .then(e => e);
};


export const getSaasPatient = (auth, pageNumber, searchQuery, doctorId) => {
    setHeadersWithAccessToken(auth);
    let page;
    if (pageNumber === undefined) {
        page = 0
    } else {
        page = pageNumber
    }
    if (doctorId) {
        return axios.get(`${WEB_API_BASE}/patient/list/from/doctall/saas?search=${searchQuery}&limit=10&page=${pageNumber}&doctor_uuid=${doctorId}`)
            .then(e => e);
    } else {
        return axios.get(`${WEB_API_BASE}/patient/list/from/doctall/saas?search=${searchQuery}&limit=10&page=${pageNumber}`)
            .then(e => e);
    }
};


