import React, { useEffect, useState } from 'react'
import { Form, Modal, Input, Spin, Row, Table, Button, Select, Card, Col, DatePicker } from 'antd';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
const { Option } = Select;
const { TextArea } = Input;

export default function SaasDoctorsView(props) {

    const dispatch = useDispatch();

    const { doctorDetails, visible, close, values } = props;
    console.log(props)
    const auth = useSelector((state) => state.auth.accessToken);
    const [form] = Form.useForm();
    const [viewForm, setViewForm] = useState(false)


    const [transId, setTransId] = useState()
    const [amount, setAmount] = useState()
    const [datee, setDate] = useState()

    const [tableColums, setTableColums] = useState([])
    const [tableColums1, setTableColums1] = useState()

    const onChange = (date, dateString) => {
        setDate(date)

    }
    const handleTrans = (event) => {
        setTransId(event.target.value)
    }
    const handleAmount = (event) => {
        setAmount(event.target.value)
    }
    useEffect(() => {
        GetListTransactions()
    }, [props]);

    function GetListTransactions() {
        let Transaction_API = `${process.env.REACT_APP_WEB_APP}/ep/dtransaction/list?limit=10&pagenum=0&doctor_uuid=${values && values.uuid}&search=`
        axios.get(Transaction_API, { headers: { 'x-auth-token': auth } })
            .then((response) => {
                setTableColums(response.data.data)
                setTableColums1(response.data)
                console.log(response, "res")
            })
            .catch((err) => {
                console.log(err)
                console.log("err")
            })
    }

    function AddTransaction() {
        let Transaction_API = `${process.env.REACT_APP_WEB_APP}/ep/dtransaction`
        let payLoad = {
            "transaction_id": transId,
            "amount": amount,
            "doctor_uuid": values && values.uuid
        }
        axios.post(Transaction_API, payLoad, { headers: { 'x-auth-token': auth } })
            .then((response) => {
                // setTableColums(response.data.data)
                console.log(response, "sucess")
                GetListTransactions()
                setViewForm(false)
            })
            .catch((err) => {
                console.log(err)
                GetListTransactions()
                console.log("err")
            })
    }
    const columns = [
        {
            title: 'Transaction Id',
            dataIndex: `transaction_id`,
            key: 'transaction_id',

        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',

        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, row) => (
                <div className="tbl-user-photo">
                    {row && row.createdAt && moment(row.createdAt).format('LL')}
                </div>
            ),
        },
    ]
    return (
        <>
            <Modal
                title={"Doctor Transaction Details"}
                visible={visible}
                onOk={close}
                onCancel={close}
                width={900}
                footer={[
                    ""
                ]}
            >
                <Row>
                    <Col span={24} xs={24} md={7}>
                        <Card>
                            <h3>Total Earnings</h3>
                            <label style={{ color: "#B2BEB5" }}>₦{tableColums1 && tableColums1.earned.toFixed(2)} </label>
                        </Card>
                    </Col>
                    <Col span={24} xs={24} md={1}></Col>
                    <Col span={24} xs={24} md={7}>
                        <Card>
                            <h3>Total OutStandings</h3>
                            <label style={{ color: "#B2BEB5" }}>₦{tableColums1 && tableColums1.outstanding && tableColums1.outstanding.toFixed(2)} </label>
                        </Card>
                    </Col>
                </Row>
                <br></br>
                <Table
                    columns={columns}
                    bordered={true}
                    dataSource={tableColums && tableColums}
                    size='middle'
                    loading={tableColums && tableColums.loader}
                    pagination={{
                        total: tableColums && tableColums.count,
                        showSizeChanger: false
                    }}

                    onChange={(pagination) => {
                        let pageNumber = ''
                        if (pagination.current === 1) {
                            pageNumber = 0
                        } else {
                            pageNumber = pagination.current - 1
                        }
                        dispatch(GetListTransactions(pageNumber))
                    }}
                />
                <br></br>
                {!viewForm && <Button key='submit' type='primary' style={{ textAlign: "right" }} onClick={() => setViewForm(true)} >
                    Add
                </Button>
                }

                {viewForm &&
                    <>
                        <br></br>
                        <Card>
                            <Form>
                                <Row>
                                    <Col span={24} xs={24} md={7}>
                                        <label>Transaction Id</label>
                                        <br></br>
                                        <Form.Item
                                            name='transaction_id'
                                            rules={[{ required: true, message: 'Please enter Transaction Id' }]}
                                        >
                                            <Input size='large' value={transId} onChange={handleTrans} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} xs={24} md={1}></Col>
                                    <Col span={24} xs={24} md={7}>
                                        <label>Amount</label>
                                        <Form.Item
                                            name='amount'
                                            rules={[{ required: true, message: 'Please enter Amount!' }]}
                                        >
                                            <Input size='large' value={amount} onChange={handleAmount} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} xs={24} md={1}></Col>
                                    <Col span={24} xs={24} md={7}>
                                        <label>Date</label>
                                        <br></br>
                                        <DatePicker onChange={onChange} style={{ width: "100%", borderRadius: "5px" }} value={datee} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} xs={24} md={12}></Col>
                                    <Col span={24} xs={24} md={6}>
                                    </Col>
                                    <Col span={24} xs={24} md={6}>
                                        <Button key='submit' type='primary' onClick={AddTransaction}>
                                            submit
                                        </Button>
                                        <Button style={{ marginLeft: "10%" }} onClick={() => setViewForm(false)}>
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </>
                }
            </Modal>
        </>
    )
}

