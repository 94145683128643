const consumerActions = {
    GET_CONSUMERS: "GET_CONSUMERS",
    GET_CONSUMERS_SUCCESS: "GET_CONSUMERS_SUCCESS",
    getConsumers: (pageNumber, searchQuery, params) => {
      return {
        type: consumerActions.GET_CONSUMERS,
        pageNumber,
        searchQuery,
        params
      };
    }
};
  
export default consumerActions;
  