import React, { useState, useEffect } from 'react';
import { Form, Modal, Input, Row, Col, Button, Select } from 'antd';
import "react-phone-input-2/lib/style.css";
import 'antd/dist/antd.css';
import { message, Upload } from 'antd';



const { Option } = Select;

const AddRadiology = ({ visible, handleOk, handleCancel, onGetarea, newcity, newareas, onSubmit }) => {

  const [active, setActive] = useState();
  const [popular , setPopular] = useState();
  console.log(active,"Active")
  
  const handleChange = (event) => {
    console.log(event,"adasd")
    setActive(event);
  };

  const handleChange1 = (event) =>{
    console.log(event,"tttt")
    setPopular(event)
  }

  const handleSubmit = values => {
    // let values = values;
    console.log(active,"active123")

 
    onSubmit(
      {
        // "uuid" : user.uuid,
      "active" : active ,
      "city" : values.city,
      "area" : values.area,
      "diagnosticspartner" : values.diagnosticspartner,
      "productcode" : values.productcode,
      "testdiscipline" : values.testdiscipline,
      "standardisedtestname" : values.standardisedtestname,
      "populartest_y_n" : popular,
      },
      typesubmit

    );
    // reset();
    handleOk();
  

    // window.location.reload('');
  };


  const [typesubmit, settypesubmit] = useState("addpartner");


 



  return (
    <Modal
      className='no-modal-footer'
      title='Add Radiology'
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={900}
      okText='Add'
      footer={[
        <Button className='login-form-button' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          type='primary'
          htmlType='submit'
          className='login-form-button ml--10'
        >
          Add
        </Button>
      ]}
    >
      <Form onFinish={handleSubmit} className='gx-form-row0' layout='vertical'>
        <Row className="space-between">
            <Col span={7} xs={7} md={7}>

            {/* <span style={{ color: "red", fontSize: "20px", marginTop: "10px" }}>*</span><span style={{ marginBottom: "20px" }}> City Name</span> */}
            <Form.Item
              label='Active'
              // name='area_name'
              rules={[{ required: true, message: 'Please input pharmacy name!' }]}
            >
              <Select
                label='Area Name'
                rules={[{ required: true, message: 'Please input pharmacy name!' }]}
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                placeholder="Select City"
                onChange={handleChange}
                // defaultValue={user && user.active}
                // initialValue={user && user.city_uuid}
                
              >
           
          <Option value={true}>True</Option>
          <Option value={false}>False</Option>



              </Select>
            </Form.Item>

          </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="City Name"
                name="city"
                // initialValue={user && user.city}
                rules={[{ required: true, message: "Please enter Area name!" }]}
              >
                <Input size="large"  />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Area Name"
                name="area"
                // initialValue={user && user.area}
                rules={[{ required: true, message: "Please enter Area name!" }]}
              >
                <Input size="large"  />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Diagnostics Partner"
                name="diagnosticspartner"
                // initialValue={user && user.diagnosticspartner}
                rules={[{ required: true, message: "Please enter Area name!" }]}
              >
                <Input size="large"  />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Product Code "
                name="productcode"
                // initialValue={user && user.productcode}
                rules={[{ required: true, message: "Please enter Area name!" }]}
              >
                <Input size="large"  />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Test Discipline "
                name="testdiscipline"
                // initialValue={user && user.testdiscipline}
                rules={[{ required: true, message: "Please enter Area name!" }]}
              >
                <Input size="large"  />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Standardised Test Name"
                name="standardisedtestname"
                // initialValue={user && user.standardisedtestname}
                rules={[{ required: true, message: "Please enter Area name!" }]}
              >
                <Input size="large"  />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Popular Test_y_n"
                // name="populartest_y_n"
                // initialValue={user && user.populartest_y_n}
                rules={[{ required: true, message: "Please enter popular test!" }]}
              >
                 <Select
                label='Area Name'
                rules={[{ required: true, message: 'Please input popular test!' }]}
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                placeholder="Select popular test"
                onChange={handleChange1}

              >
                <Option value={"yes"}>Yes</Option>
                <Option value={"no"}>No</Option>
                </Select>
              </Form.Item>
            </Col>
        
          </Row>




        <Button className='login-form-button' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          type='primary'
          htmlType='submit'
          className='login-form-button ml--10'
        >
          Add
        </Button>
      </Form>
    </Modal>
  );
};

export default AddRadiology;
