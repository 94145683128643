import React, { useEffect, useState } from "react";
import { Card, Form, Select, Input, DatePicker, Row, Col, Button, notification } from "antd";
import axios from "axios";
import { Helmet } from "react-helmet";

export default function Sadn() {
     
    const [amountfree, setAmountFree] = useState()

    const [amountSadn, setAmountSadn] = useState()

    const [uuidfree, setuuidFree] = useState()

    const [uuidSadn, setuuidSadn] = useState()

    const [typeFree, settypeFree] = useState()

    const [typeSadn, settypeSadn] = useState()


    const [timeSadn, setTimeSadn] = useState()
    
    const [timeFree, setTimeFree] = useState()

    const [sadn, setSadn] = useState()
    const [fadn, setFadn] = useState()
    const [reg, setReg] = useState()
    const [hprof, setHprof] = useState()

    const [sadnt, setSadnt] = useState()
    const [fadnt, setFadnt] = useState()
    const [regt, setRegt] = useState()
    const [hproft, setHproft] = useState()


    useEffect(() => {
        getReceipt()
        getReferal()
      }, []);
    const getReceipt = () => {
        try {
          let url = `${process.env.REACT_APP_WEB_APP}/booking/setting/list`
    
          axios.get(url)
          .then(res => {
            //   console.log(res.data, "sdsd")
              setAmountFree(res && res.data[0] && res.data[0].amount)
              setuuidFree(res && res.data[0] && res.data[0].uuid)
              settypeFree(res && res.data[0] && res.data[0].type)
              setTimeFree(res && res.data[0] && res.data[0].time_in_hour)

              setAmountSadn(res && res.data[1] && res.data[1].amount)
              setuuidSadn(res && res.data[1] && res.data[1].uuid)
              settypeSadn(res && res.data[1] && res.data[1].type)
              setTimeSadn(res && res.data[1] && res.data[1].time_in_hour)
          })
        } catch (error) {
            notification.open({ message: 'Failed!', description: error })
        }
    }
    const getReferal = () => {
        try {
          let url = `${process.env.REACT_APP_WEB_APP}/user/referal/setting`
    
          axios.get(url)
          .then(res => {
              console.log(res.data, "sdsd")
              res.data.map((item) => {
                console.log(item, "sdsd")
                if(item.type == "register"){
                    setReg(item.amount)
                    setRegt(item.type)
                }
                if(item.type == "sadn"){
                    setSadn(item.amount)
                    setSadnt(item.type)
                }
                if(item.type == "fadn"){
                    setFadn(item.amount)
                    setFadnt(item.type)
                }
                if(item.type == "healthprofile"){
                    setHprof(item.amount)
                    setHproft(item.type)
                }
              })

          })
        } catch (error) {
            notification.open({ message: 'Failed!', description: error })
        }
    }
    const handleSubmitSadn = (e) => {
        var payLoad = {
            "type": typeSadn,
            "amount" : amountSadn,
            "time_in_hour":timeSadn
        }
        axios.put( `${process.env.REACT_APP_WEB_APP}/booking/setting/update/${uuidSadn}`, payLoad)
        .then((res) => {
           notification.open({ message: 'Success', description: 'Details Updated Successfully' })
        })
        .catch((err) => {
            notification.open({ message: 'Failed!', description: err })
        })
    }

    const handleSubmitFree = (e) => {
        var payLoad = {
            "type": typeFree,
            "amount" : amountfree,
            "time_in_hour":timeFree
        }
        axios.put( `${process.env.REACT_APP_WEB_APP}/booking/setting/update/${uuidfree}`, payLoad)
        .then((res) => {
           notification.open({ message: 'Success', description: 'Details Updated Successfully' })
        })
        .catch((err) => {
            notification.open({ message: 'Failed!', description: err })
        })
    }

    const handleSadnReferal = (e) => {
        var payLoad = {
            "type": sadnt,
            "amount" : sadn,
        }
        axios.post( `${process.env.REACT_APP_WEB_APP}/user/referal/setting`, payLoad)
        .then((res) => {
           notification.open({ message: 'Success', description: 'Details Updated Successfully' })
        })
        .catch((err) => {
            notification.open({ message: 'Failed!', description: err })
        })
    }
    const handleFadnReferal = (e) => {
        var payLoad = {
            "type": fadnt,
            "amount" : fadn,
        }
        axios.post( `${process.env.REACT_APP_WEB_APP}/user/referal/setting`, payLoad)
        .then((res) => {
           notification.open({ message: 'Success', description: 'Details Updated Successfully' })
        })
        .catch((err) => {
            notification.open({ message: 'Failed!', description: err })
        })
    }
    const handleRegReferal = (e) => {
        var payLoad = {
            "type": regt,
            "amount" : reg,
        }
        axios.post( `${process.env.REACT_APP_WEB_APP}/user/referal/setting`, payLoad)
        .then((res) => {
           notification.open({ message: 'Success', description: 'Details Updated Successfully' })
        })
        .catch((err) => {
            notification.open({ message: 'Failed!', description: err })
        })
    }
    const handleHprofileReferal = (e) => {
        var payLoad = {
            "type": hproft,
            "amount" : hprof,
        }
        axios.post( `${process.env.REACT_APP_WEB_APP}/user/referal/setting`, payLoad)
        .then((res) => {
           notification.open({ message: 'Success', description: 'Details Updated Successfully' })
        })
        .catch((err) => {
            notification.open({ message: 'Failed!', description: err })
        })
    }
    
    const TimeChangeSadn = (event) => {
        setTimeSadn(event.target.value)
    }
    const AmountChangeSadn = (event) => {
        setAmountSadn(event.target.value)
    }
    const TimeChangeFree = (event) => {
        setTimeFree(event.target.value)
    }
    const sadnchange = (event) => {
        setSadn(event.target.value)
    }
    const fadnchange = (event) => {
        setFadn(event.target.value)
    }
    const regchange = (event) => {
        setReg(event.target.value)
    }
    const hprofchange = (event) => {
        setHprof(event.target.value)
    }
    return(
        <>
        <Helmet>
            <title>Doctall Super Admin Settings
</title>
<meta 
            name='description' 
            content='Update settings for appointments, price and referrals'
         

   />
        </Helmet>
        <Card>
            <h2>SADN</h2>
            <br></br>
            <Form>
                <Row>
                    <Col span={24} xs={24} md={9}>
                        <label>Price</label>
                        <br></br>
                        <Input size='large' value={amountSadn} onChange={(event) => AmountChangeSadn(event)}/>
                    </Col>
                    <Col span={24} xs={24} md={1}></Col>
                    <Col span={24} xs={24} md={9}>
                        <label>Time(hr's)</label>
                        <Input size='large' value={timeSadn} onChange={(event) => TimeChangeSadn(event)}/>
                    </Col>
                    <Col span={24} xs={24} md={5}>
                        <br></br>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='login-form-button ml--20'
                        onClick={handleSubmitSadn}
                    >
                    submit
                    </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
        <br></br>
        <Card>
            <h2>Free Appointment</h2>
            <br></br>
            <Form>
                <Row>
                    <Col span={24} xs={24} md={9}>
                        <label>Time(hr's)</label>
                        <Input size='large' value={timeFree} onChange={(event) => TimeChangeFree(event)}/>
                    </Col>
                    <Col span={24} xs={24} md={5}>
                        <br></br>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='login-form-button ml--20'
                        onClick={handleSubmitFree}
                    >
                    submit
                    </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
        <br></br>
        <Card>
            <h2>Referral Settings</h2>
            <br></br>
            <Form>
                <Row>
                    <Col span={24} xs={24} md={9}>
                        <label>SADN</label>
                        <Input size='large' value={sadn} onChange={(event) => sadnchange(event)}/>
                    </Col>
                    <Col span={24} xs={24} md={5}>
                        <br></br>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='login-form-button ml--20'
                        onClick={handleSadnReferal}
                    >
                    submit
                    </Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} xs={24} md={9}>
                        <label>FADN</label>
                        <Input size='large' value={fadn} onChange={(event) => fadnchange(event)}/>
                    </Col>
                    <Col span={24} xs={24} md={5}>
                        <br></br>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='login-form-button ml--20'
                        onClick={handleFadnReferal}
                    >
                    submit
                    </Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} xs={24} md={9}>
                        <label>REGISTER</label>
                        <Input size='large' value={reg} onChange={(event) => regchange(event)}/>
                    </Col>
                    <Col span={24} xs={24} md={5}>
                        <br></br>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='login-form-button ml--20'
                        onClick={handleRegReferal}
                    >
                    submit
                    </Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} xs={24} md={9}>
                        <label>HEALTH PROFILE</label>
                        <Input size='large' value={hprof} onChange={(event) => hprofchange(event)}/>
                    </Col>
                    <Col span={24} xs={24} md={5}>
                        <br></br>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='login-form-button ml--20'
                        onClick={handleHprofileReferal}
                    >
                    submit
                    </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
        </>
    )
}