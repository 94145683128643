import React from 'react';
import { Modal } from 'antd';


const PharmacyReferedUsers = ({ visible, handleOk, handleCancel, user, data, ReferedUserdata }) => {

  console.log(ReferedUserdata,"refered")
 

 
  return (<Modal
    title={`Referred Users`}
    visible={visible}
    onOk={handleOk}
    onCancel={handleCancel}
    width={850}
    footer={[
    ]}
  >

  {
    ReferedUserdata.length==0?
    <div style={{textAlign:"center"}}>
<h4 style={{fontFamily: "open-sans-regular", fontSize:"20px",fontWeight:"500"}}> No Referred Users </h4>
  </div>
  :
  <div>   
      <table style={{ width: "100%" }}>
        <tr style={{ background: "#F0F5F5", height: "38px" }}>
          <th> &nbsp; &nbsp; Full Name</th>
          <th>Mobile No.</th>
          <th>Email Id</th>
          <th>User ID</th>
        </tr>    
      {
        ReferedUserdata.map((item,index)=>{ 
          console.log(item,"item")
         return <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
        <td>{item.user[0].full_name}</td>
        <td>{item.user[0].mobile}</td>
        <td>{item.user[0].email}</td>
        <td>{item.user[0].uuid}</td>
      </tr>
        })
      }      
      </table>   
  </div>
  }

 

    
 
  </Modal>
  );
};

export default PharmacyReferedUsers;