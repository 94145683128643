import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import { user } from 'helper/constant';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { icon, doctall } from 'helper/constant';

const DmpAdminSidebar = () => {
  console.log(doctall,"doctall")
    const [show, setShow] = useState(false);
    return (
        <div id='sidebar' className='sidebar sideBack'>
            <div className='sidebar-header'>
                <img src={doctall && doctall.default ? doctall.default : doctall} alt='logo' className="sidebar-headerimage" width="100px" height="75px" />
                &nbsp;
            </div>
            <div className='sidebar-wrapper' style={{ width: '104%', overflowY: 'scroll' }}>
                <ul className='nav'>

                    <li className='pos-relative'>
                        <NavLink to={'/dashboard'} className='nav-link main-list' activeStyle={{
                            fontWeight: "normal",
                            color: "white",
                            backgroundColor: 'rgb(54 162 183)',

                            borderRadius: '3%'
                        }}>
                            <div className='sidebar-icon'>
                                <img src={user && user.default ? user.default : user} alt='language' crossOrigin = 'anonymous'/>
                            </div>
                            <p className='fs--16'>Dashboard</p>
                        </NavLink>
                    </li>
                    <li className='pos-relative'>
                        <NavLink to={'/partners'} className='nav-link main-list' activeStyle={{
                            fontWeight: "normal",
                            color: "white",
                            backgroundColor: 'rgb(54 162 183)',
                            borderRadius: '3%'
                        }}>
                            <div className='sidebar-icon'>
                                <img src={user && user.default ? user.default : user} alt='language' />
                            </div>
                            <p className='fs--16'>Partners</p>
                        </NavLink>
                    </li>


                    <li className='pos-relative'>
                        <NavLink to={'/order'} className='nav-link main-list' activeStyle={{
                            fontWeight: "normal",
                            color: "white",
                            backgroundColor: 'rgb(54 162 183)',
                            borderRadius: '3%'
                        }}>
                            <div className='sidebar-icon'>
                                <img src={user && user.default ? user.default : user} alt='language' />
                            </div>
                            <p className='fs--16'>Order</p>
                        </NavLink>
                    </li>

                    {/* <li className='pos-relative'>
                      <NavLink to={'/Masterpathelogy'} className='nav-link main-list' activeStyle={{
                        fontWeight: "normal",
                        color: "white",
                        backgroundColor: 'rgb(54 162 183)',
                        borderRadius: '3%'
                      }}>
                        <div className='sidebar-icon'>
                          <img src={user && user.default ? user.default : user} alt='language' />
                        </div>
                        <p className='fs--16'>Master Pathalogy </p>
                      </NavLink>
                    </li> 
                    <li className='pos-relative'>
                      <NavLink to={'/MasterRadiology'} className='nav-link main-list' activeStyle={{
                        fontWeight: "normal",
                        color: "white",
                        backgroundColor: 'rgb(54 162 183)',
                        borderRadius: '3%'
                      }}>
                        <div className='sidebar-icon'>
                          <img src={user && user.default ? user.default : user} alt='language' />
                        </div>
                        <p className='fs--16'>Master Radiology </p>
                      </NavLink>
                    </li>  */}
                    
                    <li className='pos-relative'>
                <div className='nav-link main-list' activeStyle={{
                  fontWeight: "normal",
                  color: "white",
                  backgroundColor: 'rgb(54 162 183)',
                  borderRadius: '3%'
                }}
                  onClick={() => setShow(show == true ? false : true)}
                >
                  <div className='sidebar-icon'>
                    <img src={user && user.default ? user.default : user} alt='language' />
                  </div>
                  <p className='fs--16'>Master</p>&nbsp;
                  {
                    show == false ?
                      <span><DownOutlined /></span>
                      :
                      <span><UpOutlined /></span>
                  }
                </div>
              </li>
              {
                show == true ?
                  <div style={{marginLeft:"15%"}}>
                  <li className='pos-relative'>

                    <NavLink to={'/PartnersName'} className='nav-link main-list' activeStyle={{
                      fontWeight: "normal",
                      color: "white",
                      backgroundColor: 'rgb(54 162 183)',
                      borderRadius: '3%'
                    }}>
                      <div className='sidebar-icon'>
                        <img src={user && user.default ? user.default : user} alt='language' />
                      </div>
                      <p className='fs--16'>Partners Name</p>
                    </NavLink>
                  </li>
                    <li className='pos-relative'>
                      <NavLink to={'/CityName'} className='nav-link main-list' activeStyle={{
                        fontWeight: "normal",
                        color: "white",
                        backgroundColor: 'rgb(54 162 183)',
                        borderRadius: '3%'
                      }}>
                        <div className='sidebar-icon'>
                          <img src={user && user.default ? user.default : user} alt='language' />
                        </div>
                        <p className='fs--16'>City</p>
                      </NavLink>
                    </li>


                    <li className='pos-relative'>
                      <NavLink to={'/AreaName'} className='nav-link main-list' activeStyle={{
                        fontWeight: "normal",
                        color: "white",
                        backgroundColor: 'rgb(54 162 183)',
                        borderRadius: '3%'
                      }}>
                        <div className='sidebar-icon'>
                          <img src={user && user.default ? user.default : user} alt='language' />
                        </div>
                        <p className='fs--16'>Area</p>
                      </NavLink>
                    </li>

                    <li className='pos-relative'>
                      <NavLink to={'/NationalPathalogy'} className='nav-link main-list' activeStyle={{
                        fontWeight: "normal",
                        color: "white",
                        backgroundColor: 'rgb(54 162 183)',
                        borderRadius: '3%'
                      }}>
                        <div className='sidebar-icon'>
                          <img src={user && user.default ? user.default : user} alt='language' />
                        </div>
                        <p className='fs--16'>National Pathology </p>
                      </NavLink>
                    </li>
                    <li className='pos-relative'>
                      <NavLink to={'/NationalRadiology'} className='nav-link main-list' activeStyle={{
                        fontWeight: "normal",
                        color: "white",
                        backgroundColor: 'rgb(54 162 183)',
                        borderRadius: '3%'
                      }}>
                        <div className='sidebar-icon'>
                          <img src={user && user.default ? user.default : user} alt='language' />
                        </div>
                        <p className='fs--16'>National Radiology </p>
                      </NavLink>
                    </li>


                    <li className='pos-relative'>
                      <NavLink to={'/Masterpathelogy'} className='nav-link main-list' activeStyle={{
                        fontWeight: "normal",
                        color: "white",
                        backgroundColor: 'rgb(54 162 183)',
                        borderRadius: '3%'
                      }}>
                        <div className='sidebar-icon'>
                          <img src={user && user.default ? user.default : user} alt='language' />
                        </div>
                        <p className='fs--16'>Master Pathology </p>
                      </NavLink>
                    </li> 
                    <li className='pos-relative'>
                      <NavLink to={'/MasterRadiology'} className='nav-link main-list' activeStyle={{
                        fontWeight: "normal",
                        color: "white",
                        backgroundColor: 'rgb(54 162 183)',
                        borderRadius: '3%'
                      }}>
                        <div className='sidebar-icon'>
                          <img src={user && user.default ? user.default : user} alt='language' />
                        </div>
                        <p className='fs--16'>Master Radiology </p>
                      </NavLink>
                    </li> 

                  </div>
                  :
                  null
              }





                </ul>
            </div>
        </div >
    );
};

export default DmpAdminSidebar;
