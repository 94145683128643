import React, { useEffect } from 'react'
import { Form, Modal, Input, Spin, Row, Col, Button, Select, Typography } from 'antd';

const { Option } = Select;
const { TextArea } = Input;
const { Text, Link } = Typography;


export default function NotificationView(props) {
    const [form] = Form.useForm();

    const { notificationDetails, visible, loader, close, onSubmit } = props;

    const handleSend = () => {
        onSubmit(notificationDetails)
        close()
    }

    const resetFormFields = () => {
        form.resetFields();
    }

    // useEffect(() => {
    //     findUsers()
    // }, [])

    const findUsers = () => {
        try {
            if (notificationDetails.user && notificationDetails.user[0] !== "ALL") {
                return notificationDetails.user.length
            } else {
                return "all"
            }

        } catch (error) {
            console.log()
        }
    }


    return (
        <Modal
            className='no-modal-footer'
            title={`Notification Details`}
            visible={visible}
            width={500}
            onCancel={close}
        >

            <Form onFinish={handleSend} form={form} className='gx-form-row0' layout='vertical'>
                <Row>

                    <Col span={24} xs={24} md={24}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%' }}>
                            Title
                        </div>
                        <Input size='large'
                            value={notificationDetails && notificationDetails.title}
                        />
                    </Col>


                    <Col span={24} xs={24} md={24}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Description
                        </div>
                        <TextArea size='large' value={notificationDetails && notificationDetails.message} />
                    </Col>

                    <Col span={24} xs={24} md={24}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Status
                        </div>
                        <Input size='large'
                            value={notificationDetails && notificationDetails.status}
                        />
                    </Col>

                    <Col span={24} xs={24} md={24}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>

                        </div>
                        <Text type="secondary">This notification is for {findUsers()} user </Text>
                    </Col>


                    <Col className='ml-auto'>
                        <br />
                        <br />
                        <Button className='login-form-button' onClick={close}>
                            Cancel
                        </Button>
                        {notificationDetails && notificationDetails.status === "draft" &&
                            < Button
                                type='primary'
                                htmlType='submit'
                                className='login-form-button ml--10'
                            >
                                Send
                            </Button>
                        }

                    </Col>

                </Row>
            </Form>


        </Modal >
    )
}
