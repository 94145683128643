import UsersView from 'views/users/UsersView';
import DoctorsView from 'views/doctors/DoctorsView';
// import pharmacyView from 'views/P';
import pharmacyView from "views/pharmacies/PharmaciesView";
import Partners_name from "views/Partners_name/PartnersName";
import City_name from "views/City_name/CityName";
import Area_name from "views/Area_name/AreaName";
import National_pathalogy from "views/National_Pathalogy/NationalPathalogy"
import National_Radiology from "views/National_Radiology/NationalRadiology"
import TransactionView from 'views/transactions/TransactionView';
import AppointmentView from 'views/appointments/Appointments';
import FeedbackView from 'views/feedbacks/FeedbackView';
import PatientView from 'views/patients/Patients';
import ConsumersView from 'views/consumer/Consumer';
import BookAppointmentsView from 'views/bookAppointment/bookAppointment';
import Viewpatientdocs from 'views/Viewpatientdocs/Viewpatientdocs'
import Orderview from 'views/order/Order'
import Treatmentrderview from 'views/teatement_order/Order'
import Dashboard from 'views/dashboard/dashboard';
import PushNotificationPage from 'views/push-notifications/PushNotificationPage';
import ChatView from 'views/support/chat';
import CouponsPage from 'views/coupons/CouponsPage';
import PartnerPage from 'views/partners/PartnerPage';
import SaasPage from 'views/saas/SaasPage';
import InfluencerPage from 'views/partners/InfluencerPage'
import Sadn from "./../components/Sadn/index.js"
import Register from "./../views/Register/Register"
import influencerNewView from "views/influencer_new/influencerNewView";
import CompanyName from './../views/Company_name/PartnersName';
import Introduccer from "views/introducer_name/IntroducerView"
import Masterpathelogy from "views/Master_Pathalogy/MasterPathalogy.jsx"
import Masterradiology from "views/Master_Radiology/MasterRadiology.jsx"
import HomeServiceCollection from 'views/Home_Sample_Collection/homesamplecollection.jsx'



const dashboardRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/register', component: Register },
  { path: '/users', component: UsersView },
  { path: '/doctors', component: DoctorsView },
  { path: '/pharmacy', component: pharmacyView },
  { path: '/saas', component: SaasPage },
  { path: '/transactions', component: TransactionView },
  { path: '/appointments', component: AppointmentView },
  { path: '/partners', component: PartnerPage },
  { path: '/influencer', component: InfluencerPage },
  { path: '/viewpatientDocs', component: Viewpatientdocs },
  { path: '/push-notification', component: PushNotificationPage },
  { path: '/coupons', component: CouponsPage },
  { path: '/feedbacks', component: FeedbackView },
  { path: '/patients', component: PatientView },
  { path: '/consumers', component: ConsumersView },
  { path: '/bookAppointment', component: BookAppointmentsView },
  { path: '/viewpatientDocs', component: Viewpatientdocs },
  { path: '/order', component: Orderview },
  {path:'/treatment_order', component: Treatmentrderview},
  { path: '/support', component: ChatView },
  { path: '/Settings', component: Sadn },
  { path: '/PartnersName', component: Partners_name },
  { path: '/CityName', component: City_name },
  { path: '/AreaName', component: Area_name },
  { path: '/NationalPathalogy', component: National_pathalogy },
  { path: '/NationalRadiology', component: National_Radiology },
  { path: '/influencers', component: influencerNewView },
  { path: '/introducer', component: Introduccer },
  { path: '/Company', component: CompanyName },
  { path: '/Masterpathelogy', component: Masterpathelogy},
  { path: '/MasterRadiology', component: Masterradiology},
  { path: '/homeService', component: HomeServiceCollection}


 
  
];

export default dashboardRoutes;
