import actions from 'actions';

const initState = {
  partnerName: [],
  cityName: [],
  araName: [],
  NationalPathalogy: [],
  MasterPathalogy: [],
  MasterHomeSample: [],
  MasterRadiology: [],
  NationalRadiology: [],
  NationalPathalogyCount:0,
  NationalRadiologyCount:0,
  influencerData: [],
  influencerDataCount:0,
  PharmacyNewData: [],
  PharmacyNewDataCount:0,
  CompanyData:[],
  introducerData: [],
  introducerDataCount:0,


  // doctorsclone: [],
  loader: false

};

console.log(initState, "actions")

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case actions.master.GET_PARTNERS_NAME:
      return {
        ...state,
        loader: true
      };
    case actions.master.GET_PARTNERS_SUCCESS:
      return {
        ...state,
        loader: false,
        partnerName: action.data
      };

      case actions.master.GET_COMPANY_DATA:
        return {
          ...state,
          loader: true
        };
      case actions.master.GET_COMPANY_DATA_SUCCESS:
        return {
          ...state,
          loader: false,
          CompanyData: action.data
        };



    case actions.master.GET_CITY_NAME:
      return {
        ...state,
        loader: true
      };

      

    case actions.master.GET_CITY_SUCCESS:
      return {
        ...state,
        loader: false,
        cityName: action.data
      };

    case actions.master.GET_AREA_NAME:
      return {
        ...state,
        loader: true

      };

    case actions.master.GET_AREA_SUCCESS:
      return {
        ...state,
        loader: false,
        araName: action.data
      };


    case actions.master.EDITE_PARTNER:
      return {
        ...state,
        loader: true
      };
    case actions.master.EDITE_PARTNER_SUCCESS:
      return {
        ...state,
        loader: true
      };

      case actions.master.EDITE_COMPANY:
        return {
          ...state,
          loader: true
        };
      case actions.master.EDITE_COMPANY_SUCCESS:
        return {
          ...state,
          loader: true
        };
  

    case actions.master.EDITE_CITY:
      return {
        ...state,
        loader: true
      };
    case actions.master.EDITE_CITY_SUCCESS:
      return {
        ...state,
        loader: true
      };

      case actions.master.EDITE_AREA_NAME:
        return {
          ...state,
          loader: true
        };
      case actions.master.EDITE_AREA_SUCCESS:
        return {
          ...state,
          loader: true
        };

    case actions.master.ADD_PARTNER1:
      return {
        ...state,
        loader: true
      };

      case actions.master.ADD_COMPANY:
        return {
          ...state,
          loader: true
        };

    case actions.master.ADD_CITY_NAME:
      return {
        ...state,
        loader: true
      };

    case actions.master.ADD_AREA_NAME:
      return {
        ...state,
        loader: true
      };


    // case actions.master.ADD_PARTNER_SUCCES:
    //     return {
    //       ...state,
    //       loader: true
    //     };
   
    case actions.master.GET_NATIONAL_PATHALOGY:
      return {
        ...state,
        loader: true
      };
      case actions.master.GET_MASTER_PATHALOGY:
        return {
          ...state,
          loader: true
        };
        case actions.master.GET_MASTER_PATHALOGY_SUCCESS:
          return {
            ...state,
            loader: false,
            MasterPathalogy: action.data,
            // NationalPathalogyCount:action.count
          };
          case actions.master.GET_MASTER_HOMESAMPLE:
            return {
              ...state,
              loader: true
            };
            case actions.master.GET_MASTER_HOMESAMPLE_SUCCESS:
              return {
                ...state,
                loader: false,
                MasterHomeSample: action.data,
                // NationalPathalogyCount:action.count
              };
          
          case actions.master.GET_MASTER_RADIOLOGY:
            return {
              ...state,
              loader: true
            };
            case actions.master.GET_MASTER_RADIOLOGY_SUCCESS:
              return {
                ...state,
                loader: false,
                MasterRadiology: action.data,
                // NationalPathalogyCount:action.count
              };

    case actions.master.GET_NATIONAL_PATHALOGY_SUCCESS:
      return {
        ...state,
        loader: false,
        NationalPathalogy: action.data,
        NationalPathalogyCount:action.count
      };

      case actions.master.EDITE_NATIONAL_PATHALOGY:
        return {
          ...state,
          loader: true
        };
      case actions.master.EDITE_NATIONAL_PATHALOGY_SUCCESS:
        return {
          ...state,
          loader: true
        };

        case actions.master.EDITE_MASTER_PATHALOGY:
          return {
            ...state,
            loader: true
          };
        case actions.master.EDITE_MASTER_PATHALOGY_SUCCESS:
          return {
            ...state,
            loader: true
          };

          case actions.master.EDITE_MASTER_HOMESAMPLE:
            return {
              ...state,
              loader: true
            };
          case actions.master.EDITE_MASTER_HOMESAMPLE_SUCCESS:
            return {
              ...state,
              loader: true
            };

          case actions.master.EDITE_MASTER_RADIOLOGY:
            return {
              ...state,
              loader: true
            };
          case actions.master.EDITE_MASTER_RADIOLOGY_SUCCESS:
            return {
              ...state,
              loader: true
            };


        case actions.master.ADD_NATIONAL_PATHALOGY:
          return {
            ...state,
            loader: true
          };
          case actions.master.ADD_MASTER_PATHALOGY:
            // console.log('Reducer  In...........', state)
            return {
              ...state,
              loader: true
            };
            
            case actions.master.ADD_MASTER_RADIOLOGY:
              // console.log('Reducer  In...........', state)
              return {
                ...state,
                loader: true
              };

              case actions.master.ADD_MASTER_HOME_SAMPLE_COLLECTION:
                // console.log('Reducer  In...........', state)
                return {
                  ...state,
                  loader: true
                };

          case actions.master.GET_NATIONAL_RADIOLOGY:
            return {
              ...state,
              loader: true
            };
            
          case actions.master.GET_NATIONAL_RADIOLOGY_SUCESS:
            console.log(action)
            return {
              ...state,
              loader: false,
              NationalRadiology: action.NationalRadiology,
              NationalRadiologyCount: action.NationalRadiologyCount
            };

            case actions.master.EDITE_NATIONAL_RADIOLOGY:
              return {
                ...state,
                loader: true
              };
            case actions.master.EDITE_NATIONAL_RADIOLOGY_SUCCESS:
              return {
                ...state,
                loader: true
              };

              case actions.master.ADD_NATIONAL_RADIOLOGY:
          return {
            ...state,
            loader: true
          };



          case actions.master.GET_INFLUENCER_DATA:
      return {
        ...state,
        loader: true
      };

    case actions.master.GET_INFLUENCER_SUCCESS:
      return {
        ...state,
        loader: false,
        influencerData: action.data,
        influencerDataCount: action.count
      };

      case actions.master.GET_PHARMACY_NEW_DATA:
        return {
          ...state,
          loader: true
        };
  
      case actions.master.GET_PHARMACY_NEW_SUCCESS:
        return {
          ...state,
          loader: false,
          PharmacyNewData: action.data,
          PharmacyNewDataCount: action.count
        };
  



      case actions.master.GET_INTRODUCER_DATA:
        return {
          ...state,
          loader: true
        };
  
      case actions.master.GET_INTRODUCER_SUCCESS:
        return {
          ...state,
          loader: false,
          introducerData: action.data,
          introducerDataCount: action.count
        };
          
          default:
            return state;

  }

}
