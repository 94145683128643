import { all, takeEvery, put, fork, select } from "redux-saga/effects";
import actions from "actions";
import { getSlotsByDoctor } from "services/bookAppointment";
import { notification } from "antd";
const { GETSLOTBYDOCTOR, authActions } = actions;

export function* getslotDoctorsallSaga() {
  yield takeEvery(GETSLOTBYDOCTOR.GET_UNIQUE_SLOTS_DOCTOR, function* ({params,param2}) {
    try {
      const token = yield select(state => state.auth.accessToken);
      console.log(params,param2, "sagaparamsDo");
      const response = yield getSlotsByDoctor({token},params,param2);
      console.log("response", response);
      const { data } = response;
      yield put({
        type: GETSLOTBYDOCTOR.GET_UNIQUE_SLOTS_DOCTOR_SUCCESS,
        data: data,
      });
    } catch (error) {
      // yield notification.open({
      //   message: "Failed!",
      //   description: "Something went wronggsd",
      // });
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getslotDoctorsallSaga)]);
}
