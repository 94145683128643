// import { Modal } from 'antd';
import React, { useState, useEffect } from "react";
import actions from "actions";
// import { Button } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { Spin, notification } from "antd";
import { green_dot, red_dot } from "helper/constant";
import moment from "moment";
import axios from "axios";
import { Form, Modal, Input, Row, Col, Button, Select } from "antd";
const { appointmentActions } = actions;

const { Option } = Select;

const { confirm } = Modal;

const ViewOrder = ({ user, visible, loader, handleOk, handleCancel, val }) => {

  {console.log(user,"shvshvdhsdvha")}
  
  const [form] = Form.useForm();
  const [group, setgroup] = useState("");
  const [test, setTest] = useState(val);
  const [disable, setDisable] = useState(false)

  const dispatch = useDispatch();

  const doctallAction = [
    "Doctor Will Confirm  Refund Request",
    "Confirm Result Received",
    "Confirm Result Accepted",
    "Result Rejected",
    "Confirm Refund Completed",
    "Confirm Receipt of Result",
  ];
  const handleChanger = (val, e) => {

    axios.put(`${process.env.REACT_APP_WEB_APP}/order/updateOrderStatusDoctall_action?doctall_action=${e.value}&uuid=${e.dataid}`, '', { headers: {} }).then((i =>
      setDisable(true),
      notification.open({ message: 'Success!', description: 'Status Changed' }))).catch(err => { console.log(err) })

  };
  console.log(disable)


  return (
    <Modal
      title={`Order Details`}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="submit" type="primary" onClick={handleOk}>
          Ok
        </Button>,
      ]}
    >
    <>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  Test Name
                </div>
                <div className="read-form-value">
                  {user && user.details && user.details.diseases_name}
                  {/* {console.log(user && user.user[0] && user.user[0].full_name)} */}
                </div>
              </div>
                           

            </>
    </Modal>
  );
};

export default ViewOrder;
