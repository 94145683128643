import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from 'actions';
import { fetchPartners } from 'services/PartnersServices';
import {
    addpartnerservice,
    editpartnerservice
} from 'services/user-services';

import { notification } from 'antd';
import { getToken } from '../firebase';


const { partnersActions, authActions } = actions;

export function* fetchPartner() {
  console.log(fetchPartner,"fetchPartner")
    yield takeEvery(partnersActions.GET_PARTNERS, function* ({ pageNumber, searchQuery }) {
        try {
            console.log(pageNumber)
            const token = yield select(state => state.auth.accessToken);
            const response = yield fetchPartners({ token }, pageNumber, searchQuery);
            if (response.status === 200) {
                yield put({
                    type: partnersActions.GET_PARTNERS_SUCCESS,
                    data: response.data,
                    count: response.data.count,
                });
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
        }
    });
}

export function* addPartnerSaga() {

    console.log('saga');
    yield takeEvery(partnersActions.ADDING_PARTNER, function* ({ data }) {
        try {
            const token = yield select(state => state.auth.accessToken);

            // console.log(token);
            const response = yield addpartnerservice({ token }, data);
            if (response.status === 200) {
                localStorage.setItem("AddPartner", JSON.stringify(response.data))
                // getToken()
                yield notification.open({
                    message: 'Success!',
                    description: 'Partner added Successfully!'
                });
                yield put({
                    type: partnersActions.ADDING_PARTNER_SUCCESS,
                });
                yield put({
                    type: partnersActions.GET_PARTNERS
                });
            } else {
                throw response;
            }
        } catch (error) {
            if (error && error.response) {
                yield notification.open({
                    message: 'Failed!',
                    description: error.response.data.message
                });
                yield put({ type: partnersActions.ON_ERROR });
            }
            if (error.response.status === 401) {
                yield put({ type: authActions.AUTH_ERROR });
            }
        }
    });
}

export function* editPartnerSaga() {

    console.log('saga');
    yield takeEvery(partnersActions.EDIT_PARTNER, function* ({ data, uuid }) {
        try {
            const token = yield select(state => state.auth.accessToken);

            // console.log(token);
            const response = yield editpartnerservice({ token }, data, uuid);
            if (response.status === 200) {
                // getToken()
                yield notification.open({
                    message: 'Success!',
                    description: 'Partner Details Updated Successfully!'
                });
                yield put({
                    type: partnersActions.EDIT_PARTNER_SUCCESS,
                });
                yield put({
                    type: partnersActions.GET_PARTNERS
                });
            } else {
                throw response;
            }
        } catch (error) {
            if (error && error.response) {
                yield notification.open({
                    message: 'Failed!',
                    description: error.response.data.message
                });
                yield put({ type: partnersActions.ON_ERROR });
            }
            if (error.response.status === 401) {
                yield put({ type: authActions.AUTH_ERROR });
            }
        }
    });
}

export default function* rootSaga() {
    yield all([fork(fetchPartner), fork(addPartnerSaga), fork(editPartnerSaga)]);
}