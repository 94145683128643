import { Modal } from 'antd';
import React,{ useState,useEffect} from 'react';
import { Button } from 'antd';
import { Spin ,Select,notification} from 'antd';
import axios from 'axios';
import { green_dot, red_dot } from 'helper/constant';
const { Option } = Select;

const ViewTransaction = ({ user, visible, loader, handleOk, handleCancel ,refundStat}) => {


  const WEB_API_BASE = process.env.REACT_APP_WEB_APP;
  const [refund,setRefund]=useState(false)
  // console.log(refund,refundStat)
  const handleChange =(value)=>{
    if(value.label === 'Complete'){
      axios.put(`${WEB_API_BASE}/payment/updateTheRefundProvideStatus/${user && user.reference_id}`,'',{headers:{}}).then((i)=>{
        if(i.status===200 ){
          setRefund(true)
          notification.open({
            message: 'Refund Status',
            description: "Refund Completed",
            duration:2
          })
        }
         
      }).catch(err=>{})
    }
  }

  // React.useEffect(()=>{
    
  //     if(user && user.refund_provided === 'Complete'){
  //       setRefund(true)
  //     }
    
    
  // })
  return (
    <Modal
      title={`Transaction Details`}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key='submit' type='primary' onClick={handleOk}>
          Ok
        </Button>
      ]}
    >
      <Spin spinning={loader}>
        <div className='flex-y align-left'>
        <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Transaction Id
            </div>
            <div className='read-form-value'>{user && user.reference_id}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Doctor Name
            </div>
            <div className='read-form-value'>{user && user.doctor_name}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Patient Name
            </div>
            <div className='read-form-value'>{user && user.patient_name}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Amount
            </div>
            <div className='read-form-value'>{user && user.amount}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>Patient Email</div>
            <div className='read-form-value'>{user && user.patient_email}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
             Patient Phone Number
            </div>
            <div className='read-form-value'>{user && user.patient_phone_number}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
             Refund Eligible
            </div>
            <div className='read-form-value'>{user && user.refund_eligible !== undefined?
            user.refund_eligible:"No Data" }</div>
          </div>

          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
             Refund Provided
            </div>
            {user && user.refund_provided !== undefined ?
            <div className='read-form-value'>
           { user.refund_provided === 'Incomplete'?
            <Select 
            disabled={refund}
            labelInValue
            defaultValue={{ value: user.refund_provided }}
            style={{ width: '100%' }}
            onChange={handleChange}
            >
            <Option  value={'Complete'}>
              Complete
            </Option>
            <Option  value={'Incomplete'}>
              Incomplete
            </Option>
          </Select>
          :
            user.refund_provided }
            </div>
            :<div className='read-form-value'>No Data</div> }
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Transcation Type
            </div>
            <div className='read-form-value'>{user && user.transcation_type}</div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default ViewTransaction;
