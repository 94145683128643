import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import { user } from 'helper/constant';
import { icon, doctall } from 'helper/constant';

const SupportSidebar = () => {

    return (
        <div id='sidebar' className='sidebar sideBack'>
            <div className='sidebar-header'>
                <img src={doctall &&  doctall.default?doctall.default:doctall} alt='logo' className="sidebar-headerimage" width="100px" height="75px" />
                &nbsp;
            </div>
            <div className='sidebar-wrapper' style={{ width: '104%', overflowY: 'scroll' }}>
                <ul className='nav'>

                    <li className='pos-relative'>
                        <NavLink to={'/support'} className='nav-link main-list' activeStyle={{
                            fontWeight: "normal",
                            color: "white",
                            backgroundColor: 'rgb(54 162 183)',
                            borderRadius: '3%'
                        }}>
                            <div className='sidebar-icon'>
                                <img src={user && user.default ? user.default : user} alt='language' />
                            </div>
                            <p className='fs--16'>Support</p>
                        </NavLink>
                    </li>

                </ul>
            </div>
        </div >
    );
};

export default SupportSidebar;
