import DmpAdminSidebar from "components/sidebar/DmpAdminSidebar";
import Sidebar from "components/sidebar/Sidebar";
import SupportSidebar from "components/sidebar/SupportSidebar";
import React from "react";
import { useSelector } from "react-redux";


export default function RoleSideBar() {

    const userState = useSelector((state) => state.auth);
    let adminRole = userState && userState.admin ? userState.admin.role : 'admin'


    const getRoleWiseSideBar = () => {
        switch (adminRole) {
            case 'admin':
                return <Sidebar />
            case 'dmp-admin':
                return <DmpAdminSidebar />
            case 'support':
                return <SupportSidebar />
            default:
                return <Sidebar />
        }
    }

    return (
        <>
            {getRoleWiseSideBar()}
        </>
    )
}



