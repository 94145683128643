import React, { useState, useEffect } from 'react';
import { Table, Popover } from 'antd';
import { view_log, triple_dots } from 'helper/constant';
import { green_dot, red_dot } from 'helper/constant';
import actions from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import axios from 'axios';
const { Column, ColumnGroup } = Table;

const { pharmacyActions } = actions;

const Pharmacies = ({ visible, handleOk, handleCancel, user, data }) => {
  const dispatch = useDispatch();

  const Auth = useSelector((state) => state.auth);
  //   const [data, setData] = useState("")
  console.log(user, "reportsvisible")

  //   useEffect(()=> {
  //     axios.get(`${process.env.REACT_APP_WEB_APP}/user/pharmacyCommission/pharmacy/metrics/data?pharmacy_uuid=${user && user.uuid}`,{
  //         headers:{
  //             "x-auth-token" : Auth.accessToken
  //         }
  //     })
  //     .then((res) => {
  //         console.log(res, "res")
  //         // data.push(res)
  //         setData(res.data)
  //     })
  //   },[])

  console.log(data, "data")
  return (<Modal
    title={`Pharmacy Details`}
    visible={visible}
    onOk={handleOk}
    onCancel={handleCancel}
    width={850}
    footer={[
      <>
        {/* {diff === "edit" &&
        <Button key='submit' type='primary' onClick={handleSubmit}>
          Ok
        </Button>
      } */}
      </>
    ]}
  >
    <div>
      <table style={{ width: "100%" }}>
        <tr style={{ background: "#F0F5F5", height: "38px" }}>
          <th>&nbsp;&nbsp;&nbsp;&nbsp;Name</th>
          <th>Total Register</th>
          <th>Total Amount</th>
          <th>Commission</th>
        </tr>
        <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;Register</td>
          <td>{data && data.count[0] && data.count[0].total_register_user}</td>
          <td>{data && data.total_amount[0] && data && data.total_amount[0].total_register_user} </td>
          <td>{data && data.commission_amount[0] && data && data.commission_amount[0].total_register_user}</td>
        </tr>
        <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;SADN</td>
          <td>{data && data.count[0] && data.count[0].total_sadn}</td>
          <td>{data && data.total_amount[0] && data && data.total_amount[0].total_sadn} </td>
          <td>{data && data.commission_amount[0] && data && data.commission_amount[0].total_sadn}</td>
        </tr>
        <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;FADN</td>
          <td>{data && data.count[0] && data.count[0].total_fadn}</td>
          <td>{data && data.total_amount[0] && data && data.total_amount[0].total_fadn} </td>
          <td>{data && data.commission_amount[0] && data && data.commission_amount[0].total_fadn}</td>
        </tr>
        <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;DMP</td>
          <td>{data && data.count[0] && data.count[0].total_dmp_order}</td>
          <td>{data && data.total_amount[0] && data && data.total_amount[0].total_dmp_order} </td>
          <td>{data && data.commission_amount[0] && data && data.commission_amount[0].total_dmp_order}</td>
        </tr>
        <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;TREATMENT PLAN</td>
          <td>{data && data.count[0] && data.count[0].total_treatment_plan}</td>
          <td>{data && data.total_amount[0] && data && data.total_amount[0].total_treatment_plan} </td>
          <td>{data && data.commission_amount[0] && data && data.commission_amount[0].total_treatment_plan}</td>
        </tr>
        <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;LAB PACKAGE</td>
          <td>{data && data.count[0] && data.count[0].total_lab_package}</td>
          <td>{data && data.total_amount[0] && data && data.total_amount[0].total_lab_package} </td>
          <td>{data && data.commission_amount[0] && data && data.commission_amount[0].total_lab_package}</td>
        </tr>
        <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;LAB TEST</td>
          <td>{data && data.count[0] && data.count[0].total_lab_test}</td>
          <td>{data && data.total_amount[0] && data && data.total_amount[0].total_lab_test} </td>
          <td>{data && data.commission_amount[0] && data && data.commission_amount[0].total_lab_test}</td>
        </tr>
      </table>
      {/* <Table
        columns={columns}
        // dataSource={PharmaData}
        size='middle'
        pagination={{
          defaultPageSize: 5
        }}
      /> */}
      {/* <ViewPharmacy
        visible={visible}
        diff={editvisible}
        newcity={newcity}
        onGetarea={onGetarea}
        newareas={newareas}
        loader={false}
        handleOk={() => setvisible(false)}
        handleCancel={() => {setvisible(false); window.location.reload(false)}}
        user={user}
      /> */}
    </div>
  </Modal>
  );
};

export default Pharmacies;