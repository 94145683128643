const pushNotificationAction = {

    GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
    GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
    ADD_NOTIFICATION: "ADD_NOTIFICATION",
    SEND_NOTIFICATION_ALL: "SEND_NOTIFICATION_ALL",
    ADD_NOTIFICATION_SUCCESS: "ADD_NOTIFICATION_SUCCESS",
    SEND_FROM_DRAFT: "SEND_FROM_DRAFT",
    SEND_FROM_DRAFT_SUCCESS: "SEND_FROM_DRAFT",

    getNotification: (pageNumber) => {
        return {
            type: pushNotificationAction.GET_NOTIFICATIONS,
            pageNumber
        };
    },

    sendNotifications: (data, consumers, selectOption, key) => {
        return {
            type: pushNotificationAction.ADD_NOTIFICATION,
            data,
            consumers,
            selectOption,
            key
        };
    },

    sendDraft: (data) => {
        return {
            type: pushNotificationAction.SEND_FROM_DRAFT,
            data
        };
    },
};

export default pushNotificationAction;

