import React, { useEffect, useState } from "react";
import { Table, Select, Input, Space, Row, Col } from "antd";
import { Tabs } from "antd";
import { Spin } from "antd";
import { view_log } from "helper/constant";
import { useDispatch, useSelector } from "react-redux";
import ViewOrder from "./ViewOrder";
import DetailOrderView from "./detailOrderView";
import actions from "actions";
import axios from 'axios';
import { base64toBlob } from "./Utils";
import moment from 'moment';
import { Helmet } from "react-helmet";
const { ORDERACTION, userActions } = actions;
const { Option } = Select;
const { TabPane } = Tabs;
const Search = Input.Search;


const Order = () => {
  const auth = useSelector((state) => state.auth.accessToken);
  const payloadloader = useSelector((state) => state.order);
  const partnerslist = useSelector((state) => state.users.partners);

  const payload = useSelector((state) => state.order.orders);

  const tablevalue = payload ? payload.order_info : [];



  const [visible, setvisible] = useState(false);
  const [user, setuser] = useState(null);
  const [query, setQuery] = useState("SYNLAB");
  const [tabkey, setTabkey] = useState("1")
  const [uploaded, setuploaded] = useState()
  const [orderid, setorderid] = useState()
  const [orderSearch, setOrderSearch] = useState('')

  const dispatch = useDispatch();
  const handleChangeTab = () => {
    setTabkey("2")
  }


  useEffect(() => {
    dispatch(ORDERACTION.getorderbysuperadmin(query, orderSearch));
  }, [query, orderSearch]);


  useEffect(() => {
    dispatch(userActions.getPartner());
  }, []);



  const onOrderSearch = (event) => {
    setOrderSearch(event.target.value)
  }

  const handleOnSearch = (value) => {
    setOrderSearch(value)
  }


  let getReceipt = (orderId) => {
    try {
      let url = `${process.env.REACT_APP_WEB_APP}/ep/orderPaymentPreview/${orderId}`

      axios.get(url).then(res => {
        if (res.data.data) {
          const pdfData = res.data.data;
          const url = URL.createObjectURL(
            base64toBlob(`data:application/pdf;base64,${pdfData}`)
          );
          window.open(url, "_blank");
        }
      })
    } catch (error) {
      console.log(error)
    }


  }



  const columns = [
    {
      title: "View",
      dataIndex: "eye",
      key: "eye",
      render: (text, row) => (
        <img
          className="ml--10 cursor-pointer"
          src={view_log && view_log.default?view_log.default:view_log}
          alt="view"
          onClick={() => {
            handleChangeTab()
            setuser(row);
            setorderid(row.order_info &&
              row.order_info.order_id)
          }}
        />
      ),
    },
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "username",
      render: (text, row) => (
        <div>
          {row.user_info &&
            row.user_info.user_name}
        </div>
      ),

    },

    {
      title: "User Phone No",
      dataIndex: "user_phone_num",
      key: "user_phone_num",
      render: (text, row) => (
        <div>
          {row.user_info &&
            row.user_info.user_phone_num}
        </div>
      ),
    },

    {
      title: "Patient Name",
      dataIndex: "patient_name",
      key: "patient_name",
      render: (text, row) => (
        <div>
          {row.patient_info &&
            row.patient_info.patient_name}
        </div>
      ),
    },
    {
      title: "Patient Phone No",
      dataIndex: "patient_mbl_num",
      key: "patient_mbl_num",
      render: (text, row) => (
        <div>
          {row.patient_info &&
            row.patient_info.mobile}
        </div>
      ),
    },
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      render: (text, row) => (
        <div>
          {row.order_info &&
            row.order_info.order_id}
        </div>
      ),
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "order_id",
      render: (text, row) => (
        <div>
          {row.order_info &&
            row.order_info.transaction_id}
        </div>
      ),
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
      render: (text, row) => (
        <div>
          {moment(row.order_info && row.order_info.order_date).format('ll')}
        </div>
      ),
    },

    {
      title: "City/Area",
      dataIndex: "order_date",
      key: "order_date",
      render: (text, row) => (
        <div>
          {row.patient_info &&
            row.patient_info.address &&
            row.patient_info.address.state}
          /
          {row.patient_info &&
            row.patient_info.address &&
            row.patient_info.address.area}
        </div>
      ),
    },
    {
      title: "Home Service",
      dataIndex: "home_service",
      key: "home_service",
      render: (text, row) => (
        <div>
          {row.home_service &&
            row.home_service.home_service_available}
        </div>
      ),
    },
    {
      title: "Total Cost",
      dataIndex: "total_cost",
      key: "total_cost",
      render: (text, row) => (
        <div>
          {row.order_info &&
            row.order_info.total_cost}
        </div>
      ),
    },

    {
      title: "Receipt",
      dataIndex: "eye",
      key: "eye",
      render: (text, row) => (
        <img
          className="ml--10 cursor-pointer"
          src={view_log && view_log.default?view_log.default:view_log}
          alt="view"
          onClick={() => {
            getReceipt(row.order_info &&
              row.order_info.order_id)
          }}
        />
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, row) => (
        <div>
          {row && row.order_info &&
            row.order_info.order_status}
        </div>
      ),
    },



  ];


const reload = () => {
  window.location.reload(false)
}


  return (
    <div>
      <Helmet>
        <title>Doctall Super Admin Orders
</title>
<meta 
            name='description' 
            content='View orders created by patients. Also see date the order was created as well as its status'
         

   />
      </Helmet>
      <Tabs activeKey={tabkey} onChange={() => { setTabkey("1") }}>
        <TabPane
          tab={<h3 onClick={reload}>Orders</h3>}
          key={'1'}
          style={{
            overflowX: "auto",
          }}
        >
          <div >
            <Row>
              <Col span={6}>

                <h3>Select Partner</h3>
                <Select
                  size="large"
                  style={{ width: 252, }}
                  showSearch
                  autoClearSearchValue
                  allowClear
                  optionFilterProp="children"
                  onChange={(value) => {
                    setQuery(value);
                  }}
                  value={query}
                >

                  {partnerslist &&
                    partnerslist.map((partner) => (
                      <option key={partner.name} value={partner.name}>
                        {`${partner.name}`}
                      </option>
                    ))}
                </Select>

              </Col>

              <Col span={5}></Col>
              <Col span={5}></Col>

              <Col span={8} >
                <h3>Search Records</h3>
                <Space direction="vertical">
                  <Search
                    size="large"
                    // allowClear
                    // onChange={onOrderSearch}
                    enterButton="Search"
                    placeholder="Search Record"
                    // onPressEnter={handleOnSearch}
                    onSearch={handleOnSearch}
                  />
                </Space>

              </Col>
            </Row>



          </div>

          <Spin spinning={payloadloader.loader}>


            <Table
              style={{ marginTop: 10 }}
              bordered={true}
              columns={columns}
              dataSource={tablevalue}
              loader={true}
              size="middle"
              pagination={{
                defaultPageSize: 12,
              }}
            />
          </Spin>
          <ViewOrder
            visible={visible}
            loader={false}
            handleOk={() => setvisible(false)}
            handleCancel={() => setvisible(false)}
            user={user}
            val={0}
          />
        </TabPane>
        <TabPane tab={<h3>Orders view</h3>} key={'2'}>
          <DetailOrderView 
          data={user}
           aws={orderid}
            onChangeStatus={(orderId, status, itemId) => {
              dispatch(dispatch(ORDERACTION.updateOrderStatus(orderId, status, itemId)));
            }}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Order;
