import { all, takeEvery, put, fork,select } from 'redux-saga/effects';
import actions from 'actions';
import { getAppointment } from 'services/bookAppointment';
import { notification } from 'antd';
const {BookAppointments , authActions } = actions;

export function* getConsumerallSaga() {
  yield takeEvery(BookAppointments.GET_UNIQUE_CONSUMER, function*(pagenum, search) {
    console.log("search", pagenum)
    try{
      const token = yield select(state => state.auth.accessToken);
      const response = yield getAppointment(pagenum,search, {token});
      console.log("responseconsumer",response)
        const { data } = response;
        yield put({
          type: BookAppointments.GET_UNIQUE_CONSUMER_SUCCESS,
          data:data
        });
    } catch(error){
      yield notification.open({ message: 'Failed!', description: 'Something went wrongC' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getConsumerallSaga)]);
}
