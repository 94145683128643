import { Modal } from 'antd';
import React,{useState} from 'react';
import { Button, Tabs } from 'antd';
import { Spin } from 'antd';
import moment from 'moment';
import BloodPresure from "./trackerFiles/bloodPresure"
import BloodGlucose from "./trackerFiles/bloodGlucose"
import Heartrate from "./trackerFiles/HeartRate"
import Spo2 from "./trackerFiles/spo2"
import Temperature from "./trackerFiles/temperature"
import BMI from "./trackerFiles/bmi"

const ViewPatients = ({ user, visible, loader, handleOk, handleCancel }) => {

  const { TabPane } = Tabs;
const userId = user && user.uuid
  console.log(user, "agvdhagdv")
  const getPercentage = (user) => {
    let percentage = 15;
    if (!user) return 0;
    const { personalQuestions = [], medicalQuestions = [], lifestyleQuestions = [] } = user;
    if (personalQuestions && personalQuestions.length > 1) {
      percentage = 60;
    } if (medicalQuestions && medicalQuestions.length > 1) {
      percentage = 80;
    }
    if (lifestyleQuestions && lifestyleQuestions.length > 1) {
      percentage = 100;
    }
    return percentage;
  }
  console.log(user)
  console.log(user && user.patients)


  const callback = (k) => {
    // if(k === "1"){
    //   {dispatch(transactionActions.getTransactions("appointment", 0))}
    // }
    // else if(k === "2"){
    //   {dispatch(transactionActions.getTransactions("cart", 0))}
    // }
    // else if(k==="3" ) {
    //   {dispatch(transactionActions.getTransactions("treatmentplan", 0))}
    // }
    }
  return (
    <Modal
      title={`Health Tracker Details`}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      footer={[
        <Button key='submit' type='primary' onClick={handleCancel}>
          Ok
        </Button>
      ]}
    >

      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab={<h3>Blood Pressure </h3>} key="1">
          <BloodPresure userId={userId} />
        </TabPane>
        <TabPane tab={<h3>Blood Glucose</h3>} key="2">
          <BloodGlucose userId={userId}/>
        </TabPane>
        <TabPane tab={<h3>Heart Rate</h3>} key="3">
          <Heartrate userId={userId}/>
        </TabPane>
        <TabPane tab={<h3>SpO2</h3>} key="4">
          <Spo2 userId={userId}/>
        </TabPane>
        <TabPane tab={<h3>Temperature</h3>} key="5">
          <Temperature userId={userId}/>
        </TabPane>
        <TabPane tab={<h3>BMI</h3>} key="6">
          <BMI userId={userId}/>
        </TabPane>
      </Tabs>

    </Modal>
  );
};

export default ViewPatients;
