import React from 'react'
import { Form, Modal, Input, Spin, Row, Col, Button, Select } from 'antd';
import moment from "moment";
const { Option } = Select;
const { TextArea } = Input;

export default function CouponsView(props) {

    const { couponsDetails, visible, close, isPromoCode } = props;
    const [form] = Form.useForm();


    const getCouponType = () => {
        if (isPromoCode) {
            return couponsDetails && couponsDetails.coupon && 0 in couponsDetails.coupon && couponsDetails.coupon[0].coupon_type
        } else {
            return couponsDetails && couponsDetails.coupon_type
        }
    }

    const getCouponCategory = () => {
        if (isPromoCode) {
            let category = 'Lab Test'
            return category;
        } else {
            return couponsDetails && couponsDetails.category
        }
    }


    return (
        <Modal
            title={`Coupon Details`}
            visible={visible}
            onOk={close}
            onCancel={close}
            width={800}
            footer={[
                <Button key='submit' type='primary' onClick={close} >
                    Ok
                </Button>
            ]}
        >

            <Form form={form} className='gx-form-row0' layout='vertical'>
                <Row className='space-between'>

                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%' }}>
                            Coupon Code
                        </div>
                        <Input size='large'
                            value={couponsDetails && couponsDetails.coupon_code}
                        />
                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Coupon Type
                        </div>
                        <Input size='large'
                            value={getCouponType()}
                        />
                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Coupon Category
                        </div>
                        <Input size='large'
                            value={getCouponCategory()}
                        />
                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Valid From
                        </div>
                        <Input size='large'
                            value={moment(couponsDetails && couponsDetails.valid_from).format("DD/MM/yyyy hh:mm a")}
                        />
                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Valid Till
                        </div>
                        <Input size='large'
                            value={moment(couponsDetails && couponsDetails.valid_to).format("DD/MM/yyyy hh:mm a")}
                        />
                    </Col>

                    {!isPromoCode && <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Points
                        </div>
                        <Input size='large'
                            value={couponsDetails && couponsDetails.point ? couponsDetails.point : 'NA'}
                        />
                    </Col>}


                    {!isPromoCode && <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Appointment Type
                        </div>
                        <Input size='large'
                            value={couponsDetails && couponsDetails.sub_category && couponsDetails.sub_category === "sadn" ? "SADN" : "FADN"}
                        />
                    </Col>}

                </Row>
                <Row>
                    <Col span={24} xs={24} md={24}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Description
                        </div>
                        <TextArea size='large' value={couponsDetails && couponsDetails.description} />
                    </Col>
                </Row>
            </Form>

        </Modal>
    )
}
