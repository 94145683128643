const feedbackActions = {
    GET_FEEDBACKS: "GET_FEEDBACKS",
    SET_FEEDBACKS: "SET_FEEDBACKS",
    getFeedbacks: (query) => {
      return {
        type: feedbackActions.GET_FEEDBACKS,
        query
      };
    },
    setFeedbacks: (data) => {
      return {
        type: feedbackActions.SET_FEEDBACKS,
        data
      };
    }
  };
  
  export default feedbackActions;
  