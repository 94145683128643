import axios from 'axios';
import { setHeadersWithAccessToken } from './index';

const WEB_API_BASE = process.env.REACT_APP_WEB_APP;


export const getorderservice = (auth, { query, queryText }) => {
  console.log(query)
  setHeadersWithAccessToken(auth);
  if (queryText === undefined) {

    return axios.get(`${WEB_API_BASE}/order/admin/fullorderdetails?partnerName=${query}&search=`, {
    }).then(e => e);
  } else {
    return axios.get(`${WEB_API_BASE}/order/admin/fullorderdetails?partnerName=${query}&search=${queryText}`, {
    }).then(e => e);
  }

};


export const changeOrderstatus = (auth, orderId, status, itemId) => {
  setHeadersWithAccessToken(auth);

  let payLoad = {
    "order_id": orderId.orderId,
    "doctall_action": orderId.status,
    "test_id": orderId.itemId
  }
  return axios.put(`${WEB_API_BASE}/order/admin/update/doctalAction`, payLoad).then(e => e);
};


