import React from 'react';
import Transactions from 'components/transactions/Transactions';

const TransactionView = () => {
    return (
        <div className="pictogram-container">
            <div className="pictogram-header flex-x align-center ptb--15">
                <div className="fs--16 font-semibold flex-1">
                    {/* Transactions */}
                </div>
                {/* < style={{ height: 42 }} type="primary" onClick={() => setvisible(true)}>Add</Button> */}
            </div>
            <div>
                <Transactions />
            </div>
        </div>
    );
};

export default TransactionView;