import actions from "actions";

const initState = {
  allSlots: [],
  loader: false,
};

export default function rootReducer(state = initState, action) {

  switch (action.type) {
    case actions.GETSLOTBYNAME.GET_UNIQUE_SLOTS:
        console.log("reducerpaSlots",action.data)

      return {
        ...state,
        loader: true,
      };
    case actions.GETSLOTBYNAME.GET_UNIQUE_SLOTS_SUCCESS:
      return {
        ...state,
        loader: false,
        allSlots: action.data,
      };
    default:
      return state;
  }
}
