import { all, takeEvery, put, fork, select } from "redux-saga/effects";
import actions from "actions";
import { getSlotsByType } from "services/bookAppointment";
import { notification } from "antd";
const { GETSLOTBYNAME, authActions } = actions;

export function* getslotsallSaga() {
  yield takeEvery(GETSLOTBYNAME.GET_UNIQUE_SLOTS, function* () {
    try {
      const token = yield select(state => state.auth.accessToken);
      // console.log(params, "sagaparams");
      const response = yield getSlotsByType({token});
      console.log("response", response);
      const { data } = response;
      yield put({
        type: GETSLOTBYNAME.GET_UNIQUE_SLOTS_SUCCESS,
        data: data,
      });
    } catch (error) {
      yield notification.open({
        message: "Failed!",
        description: "Something went wronggs",
      });
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getslotsallSaga)]);
}
