import React, { useState, useRef, useEffect } from "react";
import { Table, Spin, Select, Modal, Tabs } from "antd";

function DetailOrderView({ data, aws, onChangeStatus }) {

  const tableData = data === undefined ? [] : data.details.treatment;

  console.log(data.user, "tableData")

  // const guestDetailCol = [
  //   {
  //     title: "disease Name",
  //     dataIndex: "diseases_name",
  //     key: "diseases_name",
  //     render: (text, row) => <div>{row.diseases_name}</div>,
  //   },
  //   {

  //   }
  // ]
  const detailCol1 = [
    {
      title: "disease Name",
      dataIndex: "diseases_name",
      key: "diseases_name",
      render: (text, row) => <div>{row.diseases_name}</div>,
    },
    {
      title: "Plan Name",
      dataIndex: "plan_name",
      key: "plan_name",
      render: (text, row) => <div>{row.plan_name}</div>
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text, row) => <div>{row.price}</div>,
    },
  ]
  // const guestDetailCol
  const detailCol = [
    {
      title: "Brand Name",
      dataIndex: "brand_name",
      key: "brand_name",
      render: (text, row) => <div>{row.brand_name}</div>,
    },
    {
      title: "Drug Name",
      dataIndex: "drug_name",
      key: "drug_name",
      render: (text, row) => <div>{row.drug_name}</div>
    },
    {
      title: "Manufacturing",
      dataIndex: "manufacturing",
      key: "manufacturing",
      render: (text, row) => <div>{row.manufacturing}</div>
    },
    {
      title: "Manufacturing Country",
      dataIndex: "manufacturing_country",
      key: "manufacturing_country",
      render: (text, row) => <div>{row.manufacturing_country}</div>,
    },
    {
      title: "Mg",
      dataIndex: "mg",
      key: "mg",
      render: (text, row) => <div>{row.mg}</div>,
    },
    {
      title: "Tabs",
      dataIndex: "tabs",
      key: "tabs",
      render: (text, row) => <div>{row.tabs}</div>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text, row) => <div>{row.price}</div>,
    },
  ];
  console.log(tableData)
  return (
    <div>
      <Table
        columns={data.details.diseases_name === "Diabetes" ? detailCol1 : data.user.length > 0 ? detailCol : detailCol1 }
        dataSource={tableData}
        size="small"
        pagination={{
          defaultPageSize: 12,
        }}
      />
     
    </div>
  );
}

export default DetailOrderView;
