import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import AddPharmacy from 'components/pharmacies/AddPharmacy';
import AddArea from 'components/Area_Name/AddArea';
// import CityName from 'components/City_name/CityName';
import Area from "components/Area_Name/AreaName";
import { useDispatch, useSelector } from "react-redux";
import actions from "actions";
import pharmacyActions from 'actions/pharmacy';

const { userActions, commonActions } = actions;
const { master } = actions;
const AreaName = () => {
    const dispatch = useDispatch();
    const [visible, setvisible] = useState(false);
    const citiesnew = useSelector((state) => state.common.newcities);
    const areasnew = useSelector((state) => state.common.newareas);
    const masterPartner = useSelector((state) => state.master);

    console.log(masterPartner,"city123")

    useEffect(() => {
        dispatch(master.getCityDta());
      }, []);

    useEffect(() => {
        dispatch(commonActions.getMetadata());
        dispatch(commonActions.getallnewcities());
    }, [dispatch]);
    return (
        <div className="pictogram-container">
            <div className="pictogram-header flex-x align-center ptb--15">
                <div className="fs--16 font-semibold flex-1">
                Area Name
                </div>
                <Button style={{ height: 42 }} type="primary" onClick={() => setvisible(true)}>Add</Button>
            </div>
            <div>
                <Area
                city={masterPartner}
                    newcity={citiesnew}
                    onGetarea={(value) => {
                        console.log("area2223", value);
                        dispatch(commonActions.getallnewarea(value));
                    }}
                    newareas={areasnew}
                />
            </div>
            <AddArea
            city={masterPartner}

                visible={visible}
                handleOk={() => setvisible(false)}
                handleCancel={() => setvisible(false)}
                newcity={citiesnew}
                newareas={areasnew}
                onGetarea={(value) => {
                    console.log("area222", value);
                    dispatch(commonActions.getallnewarea(value));
                }}
                onSubmit={(value, type) => {
                    console.log("easy", value, type);
                    dispatch(master.addAreaName(value));
                    //   switch (type) {
                    //     case "addpartner":
                    //       dispatch(userActions.addPartners(value));
                    //       break;
                    //     case "clinic":
                    //       dispatch(userActions.addClinic(value));
                    //       break;
                    //     case "lab":
                    //       dispatch(userActions.addLab(value));
                    //       break;
                    //     case "pharmacy":
                    //       dispatch(userActions.addPharmacy(value));
                    //       break;
                    //     default:
                    //       console.log("invalid type");
                    //   }
                }}
            />
        </div>
    );
};

export default AreaName;