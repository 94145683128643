import React from 'react';
import Order from 'components/order/Order';

const Orderview = () => {
    return (
        <div>
            <div>
                <Order />
            </div>
        </div>
    );
};

export default Orderview;