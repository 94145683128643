import axios from 'axios';
import { setHeadersWithAccessToken } from './index';
const WEB_API_BASE = process.env.REACT_APP_WEB_APP;

export const getPatients = (pagenum,searchQuery,limit,auth) => {
let page ;
let lim;
  if(pagenum === undefined){
    page = 0;
    lim = 10;
  }
  else if(pagenum == ''){
    page = '';
    lim = '';
  }
  else{
    page = pagenum
    lim = 10;
  }
  setHeadersWithAccessToken(auth)
  // return axios.get(`${WEB_API_BASE}/patient/data-patient`).then(e => e);
  return axios.get(`${WEB_API_BASE}/patient/admin/data-patient?pagenum=${page}&limit=${lim}&search=${searchQuery}`).then(e => e);
};


export const getClonePatients = (auth,{params}) => {
  // console.log("service clonePatients",params)

  setHeadersWithAccessToken(auth)
  // return axios.get(`${WEB_API_BASE}/patient/data-patient`).then(e => e);
  return axios.get(`${WEB_API_BASE}/patient/admin/data-patient`,{
    params
}).then(e => e);
};

export const getpatientHistroservice = (auth,{params}) => {
  // console.log("service getpatientHistroservice",params)

  setHeadersWithAccessToken(auth)
  // return axios.get(`${WEB_API_BASE}/patient/data-patient`).then(e => e);
  return axios.get(`${WEB_API_BASE}/ep/healthDetails/${params}`,{
}).then(e => e);
};
