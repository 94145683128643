const commonActions = {
  GET_METADATA:"GET_METADATA",
  GET_STATES: 'GET_STATES',
  GET_CITIES: 'GET_CITIES',
  GET_STATES_SUCCESS: 'GET_STATES_SUCCESS',
  GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS',
  GET_CITIES_NEW: 'GET_CITIES_NEW',
  GET_CITIES_NEW_SUCCESS: 'GET_CITIES_NEW_SUCCESS',
  GET_AREAS_NEW: 'GET_AREAS_NEW',
  GET_AREAS_NEW_SUCCESS: 'GET_AREAS_NEW_SUCCESS',



  GET_CITIES_NEW_FOR_PARTNER: 'GET_CITIES_NEW_FOR_PARTNER',
  GET_CITIES_NEW_SUCCESS_FOR_PARTNER: 'GET_CITIES_NEW_SUCCESS_FOR_PARTNER',
  GET_AREAS_NEW_FOR_PARTNER: 'GET_AREAS_NEW_FOR_PARTNER',
  GET_AREAS_NEW_SUCCESS_FOR_PARTNER: 'GET_AREAS_NEW_SUCCESS_FOR_PARTNER',
  getMetadata :()=>({
    type:commonActions.GET_METADATA
  }),

  getallnewcities: () => {
    console.log("getallnewcities action")

    return {
      type: commonActions.GET_CITIES_NEW
    };
  },

  getallnewarea: (cityuuid) => {
    console.log("getallnewarea action",cityuuid)

    return {
      type: commonActions.GET_AREAS_NEW,
      cityuuid
    };
  },


  getallnewcitiesForPartners: () => {
    console.log("getallnewcities1 action")

    return {
      type: commonActions.GET_CITIES_NEW_FOR_PARTNER
    };
  },

  getallnewareaForPartners: (cityuuid) => {
    console.log("getallnewarea1 action",cityuuid)

    return {
      type: commonActions.GET_AREAS_NEW_FOR_PARTNER,
      cityuuid
    };
  },





};

export default commonActions;
