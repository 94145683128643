import React, { useEffect, useState } from "react";
import { Card, Modal, Spin, DatePicker, notification } from "antd";
import { AreaChartOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import moment from "moment";
import axios from "axios";
import "./styles.css";
import { Helmet } from "react-helmet";

function HomeLayout() {
  const { RangePicker } = DatePicker;
  const auth = useSelector((state) => state.auth.accessToken);
  const api = process.env.REACT_APP_WEB_APP;
  const [state, setState] = useState([]);
  const [chartData, setChartData] = useState({});
  const [openChart, setOpenChart] = useState(false);
  const [modelHead, setModelHead] = useState("");
  const [fetching, setFetching] = useState(false);
  const [date, setDate] = useState([
    "2020-01-01",
    moment().format("YYYY-MM-DD"),
  ]);


  useEffect(() => {
    (async () => {
      setFetching(true);
      await axios
        .get(
          api +
          `/booking/adminDashBoards?type=all&start_date=${moment(
            date[0]
          ).format("YYYY-MM-DD")}&end_date=${moment(date[1]).format(
            "YYYY-MM-DD"
          )}`,
          {
            headers: { "x-auth-token": auth },
          }
        )
        .then((res) => {
          setState(res.data);
          setFetching(false);
        });
    })()
  }, [date]);

  const colors = [
    "rgba(255, 99, 132)",
    "rgba(54, 162, 235)",
    "rgba(255, 206, 86)",
    "rgba(75, 192, 192)",
    "rgba(153, 102, 255)",
    "rgba(255, 159, 64)",
    "rgba(255, 120, 64)",
    "rgba(155, 113, 64)",
    "rgba(0, 0, 64)",
  ];
  //
  const chartView = async (type) => {
    setOpenChart(true);
    setModelHead(type);
    let keyss = [];
    let valuess = [];
    if (type === "Slots Overview") {
      await axios
        .get(api + `/clinic/slotCountForAdminIndivi?type=all&start_date=${moment(
          date[0]
        ).format("YYYY-MM-DD")}&end_date=${moment(date[1]).format(
          "YYYY-MM-DD"
        )}`, {
          headers: { "x-auth-token": auth },
        })
        .then((res) => {
          for (const Obj of Object.keys(res.data)) {
            keyss.push(Obj);
          }
          for (const Obj of Object.values(res.data)) {
            valuess.push(Obj);
          }
          setChartData({
            labels: keyss,
            datasets: [
              {
                label: "Total",
                data: valuess,
                backgroundColor: colors,
                borderWidth: 1,
              },
            ],
          });
        });
    } else if (type === "Prescription") {
      await axios
        .get(api + `/ep/getEpAndEplCountsDoctor/all?type=all&start_date=${moment(
          date[0]
        ).format("YYYY-MM-DD")}&end_date=${moment(date[1]).format(
          "YYYY-MM-DD"
        )}`, {
          headers: { "x-auth-token": auth },
        })
        .then((res) => {
          for (const Obj of Object.keys(res.data)) {
            keyss.push(Obj);
          }
          for (const Obj of Object.values(res.data)) {
            valuess.push(Obj);
          }
          setChartData({
            labels: keyss,
            datasets: [
              {
                label: "Total",
                data: valuess,
                backgroundColor: colors,
                borderWidth: 1,
              },
            ],
          });
        });
    } else if (type === "Consumer Overview") {
      await axios
        .get(api + `/notification/admin/getUserDeviceDetails?type=all&start_date=${moment(
          date[0]
        ).format("YYYY-MM-DD")}&end_date=${moment(date[1]).format(
          "YYYY-MM-DD"
        )}`, {
          headers: { "x-auth-token": auth },
        })
        .then((res) => {
          for (const Obj of Object.keys(res.data)) {
            keyss.push(Obj);
          }
          for (const Obj of Object.values(res.data)) {
            valuess.push(Obj);
          }
          setChartData({
            labels: keyss,
            datasets: [
              {
                label: "Total",
                data: valuess,
                backgroundColor: colors,
                borderWidth: 1,
              },
            ],
          });
        });
    } else if (type === "Appointment Type Overview") {
      await axios
        .get(api + `/booking/getCountDetails?type=all&start_date=${moment(
          date[0]
        ).format("YYYY-MM-DD")}&end_date=${moment(date[1]).format(
          "YYYY-MM-DD"
        )}`, {
          headers: { "x-auth-token": auth },
        })
        .then((res) => {
          for (const Obj of Object.keys(res.data)) {
            keyss.push(Obj);
          }
          for (const Obj of Object.values(res.data)) {
            valuess.push(Obj);
          }
          setChartData({
            labels: keyss,
            datasets: [
              {
                label: "Total",
                data: valuess,
                backgroundColor: colors,
                borderWidth: 1,
              },
            ],
          });
        });
    } else if (type === "Appointment Status Overview") {
      await axios
        .get(api + `/booking/getAptDetailsBasStatus?type=all&start_date=${moment(
          date[0]
        ).format("YYYY-MM-DD")}&end_date=${moment(date[1]).format(
          "YYYY-MM-DD"
        )}`, {
          headers: { "x-auth-token": auth },
        })
        .then((res) => {
          for (const Obj of Object.keys(res.data)) {
            keyss.push(Obj);
          }
          for (const Obj of Object.values(res.data)) {
            valuess.push(Obj);
          }
          setChartData({
            labels: keyss,
            datasets: [
              {
                label: "Total",
                data: valuess,
                backgroundColor: colors,
                borderWidth: 1,
              },
            ],
          });
        });
    } else if (type === "User Overview") {
      await axios
        .get(api + `/user/admin/getTotalUsersIndi?type=all&start_date=${moment(
          date[0]
        ).format("YYYY-MM-DD")}&end_date=${moment(date[1]).format(
          "YYYY-MM-DD"
        )}`, {
          headers: { "x-auth-token": auth },
        })
        .then((res) => {
          for (const Obj of Object.keys(res.data)) {
            keyss.push(Obj);
          }
          for (const Obj of Object.values(res.data)) {
            valuess.push(Obj);
          }
          setChartData({
            labels: keyss,
            datasets: [
              {
                label: "Total",
                data: valuess,
                backgroundColor: colors,
                borderWidth: 1,
              },
            ],
          });
        });
    }
  };

  return (
    <div>
      <Helmet>
        <title>Doctall Super Admin Dashboard

</title>
<meta 
            name='description' 
            content='Overview of all appactivities. View information on the number of users, patients, doctors, diagnostic partners and pharmacy users. See number of booked appointment and their type from the dashboard. 
            '
         

   />
      </Helmet>
      {state === [] ||
        state === "" ||
        state === undefined ||
        state.length === 0 ? (
        <Spin size="middle" spinning={fetching}>
          <div className="homeContainer">
            <Card className="cardContainer">
              <h2 className="HeadContent">No Records </h2>
              <p className="bodyContent">No Data Found</p>
            </Card>
          </div>
        </Spin>
      ) : (
        <Spin size="middle" spinning={fetching}>
          <div className="homeContainer">
            <h2>Select Date Range</h2>
            <RangePicker
              onChange={(val) => {
                if (val === null) {
                  setDate(["2020-01-01", moment().format("YYYY-MM-DD")]);
                } else if (
                  moment(val[0]).format("YYYY-MM-DD") >
                  moment().format("YYYY-MM-DD")
                ) {
                  notification.open({
                    message:
                      "you selected future Date, Please Select vaild date",
                    duration: 2,
                  });
                } else {
                  setDate(val);
                }
              }}
            />
            <div className="headConatiner">
              <Card className="cardContainer">
                <h2 className="HeadContent">
                  Appointment Type
                  <button
                    className="chartIcon"
                    onClick={() => {
                      chartView("Appointment Type Overview");
                    }}
                  >
                    <AreaChartOutlined />
                  </button>
                </h2>

                <p className="bodyContent">
                  Total Booking{" "}
                  <span className="numbers">{state.total_booking}</span>
                </p>
                <p className="bodyContent">
                  No of Free Bookings{" "}
                  <span className="numbers">{state.total_free_booking}</span>
                </p>
                <p className="bodyContent">
                  No of SADN{" "}
                  <span className="numbers">{state.total_sadn_booking}</span>
                </p>
                <p className="bodyContent">
                  No of Find A Doc{" "}
                  <span className="numbers">
                    {state.total_find_doc_booking}
                  </span>
                </p>
                <p className="bodyContent">
                  No of International{" "}
                  <span className="numbers">
                    {state.total_international_booking}
                  </span>
                </p>
              </Card>
              <Card className="cardContainer">
                <h2 className="HeadContent">
                  Appointment Status{" "}
                  <button
                    className="chartIcon"
                    onClick={() => {
                      chartView("Appointment Status Overview");
                    }}
                  >
                    <AreaChartOutlined />
                  </button>
                </h2>
                <p className="bodyContent">
                  Booked
                  <span className="numbers">
                    {state.total_booked_appointments}
                  </span>
                </p>
                <p className="bodyContent">
                  Closed{" "}
                  <span className="numbers">
                    {state.total_closed_appointments}
                  </span>
                </p>
                <p className="bodyContent">
                  Missed{" "}
                  <span className="numbers">
                    {state.total_missed_appointments}
                  </span>
                </p>
                <p className="bodyContent">
                  Extended{" "}
                  <span className="numbers">
                    {state.total_extended_appointments}
                  </span>
                </p>
                <p className="bodyContent">
                  Follow{" "}
                  <span className="numbers">
                    {state.total_followup_appointments}
                  </span>
                </p>
                <p className="bodyContent">
                  Rescheduled
                  <span className="numbers">
                    {state.total_rescheduled_appointments}
                  </span>
                </p>
                <p className="bodyContent">
                  Followup Completed{" "}
                  <span className="numbers">
                    {state.total_followup_C_appointments}
                  </span>
                </p>
                <p className="bodyContent">
                  Extension Completed{" "}
                  <span className="numbers">
                    {state.total_extended_C_appointments}
                  </span>
                </p>
                <p className="bodyContent">
                  Cancelled{" "}
                  <span className="numbers">
                    {state.total_cancelled_appointments}
                  </span>
                </p>
              </Card>
              <Card className="cardContainer">
                <h2 className="HeadContent">
                  User Overview
                  <button
                    className="chartIcon"
                    onClick={() => {
                      chartView("User Overview");
                    }}
                  >
                    <AreaChartOutlined />
                  </button>
                </h2>
                <p className="bodyContent">
                  Total Users
                  <span className="numbers">{state.total_users}</span>
                </p>
                <p className="bodyContent">
                  Consumers
                  <span className="numbers">{state.total_consumer}</span>
                </p>
                <p className="bodyContent">
                  Patients
                  <span className="numbers">{state.total_patient}</span>
                </p>
                <p className="bodyContent">
                  Doctors
                  <span className="numbers">{state.total_doctor}</span>
                </p>
                <p className="bodyContent">
                  Diagnostic Partners
                  <span className="numbers">
                    {state.total_diagnostic_admin}
                  </span>
                </p>
                <p className="bodyContent">
                  Diagnostic Users
                  <span className="numbers">{state.total_diagnostic_user}</span>
                </p>
                <p className="bodyContent">
                  Pharmacy Admins
                  <span className="numbers">{state.total_pharmacy_admin}</span>
                </p>
                <p className="bodyContent">
                  Pharmacy users
                  <span className="numbers">{state.total_pharmacy_user}</span>
                </p>
              </Card>
              <Card className="cardContainer">
                <h2 className="HeadContent">
                  Consumer Overview
                  <button
                    className="chartIcon"
                    onClick={() => {
                      chartView("Consumer Overview");
                    }}
                  >
                    <AreaChartOutlined />
                  </button>
                </h2>
                <p className="bodyContent">
                  Total Consumer{" "}
                  <span className="numbers">{state.total_consumer}</span>
                </p>
                <p className="bodyContent">
                  Android Users{" "}
                  <span className="numbers">{state.total_android_users}</span>
                </p>
                <p className="bodyContent">
                  Web App Users{" "}
                  <span className="numbers">
                    {state.total_webappconsumer_users}
                  </span>
                </p>
                <p className="bodyContent">
                  Ios Users{" "}
                  <span className="numbers">{state.total_ios_users}</span>
                </p>
              </Card>

              <Card className="cardContainer">
                <h2 className="HeadContent">
                  Slots Overview
                  <button
                    className="chartIcon"
                    onClick={() => {
                      chartView("Slots Overview");
                    }}
                  >
                    <AreaChartOutlined />
                  </button>
                </h2>
                <p className="bodyContent">
                  Total Slots
                  <span className="numbers">{state.total_slots}</span>
                </p>
                <p className="bodyContent">
                  Booked{" "}
                  <span className="numbers">{state.total_booked_slot}</span>
                </p>
                {/* <p className="bodyContent">
                  Available{" "}
                  <span className="numbers">
                    {state.total_avl_slot_from_today}
                  </span>
                </p> */}
                <p className="bodyContent">
                  Not Booked{" "}
                  <span className="numbers">{state.total_available_slot}</span>
                </p>
              </Card>
              <Card className="cardContainer">
                <h2 className="HeadContent">
                  E Prescription
                  <button
                    className="chartIcon"
                    onClick={() => {
                      chartView("Prescription");
                    }}
                  >
                    <AreaChartOutlined />
                  </button>
                </h2>
                <p className="bodyContent">
                  Total Prescription{" "}
                  <span className="numbers">{state.eprescription_count}</span>
                </p>
                <p className="bodyContent">
                  Lab Count{" "}
                  <span className="numbers">
                    {state.eprescription_lab_count}
                  </span>
                </p>
              </Card>
            </div>
          </div>
        </Spin>
      )}
      <Modal
        title={modelHead}
        width={600}
        visible={openChart}
        onOk={() => {
          setOpenChart(false);
        }}
        onCancel={() => {
          setOpenChart(false);
        }}
      >
        <Pie
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: true,
            indexAxis: "X",
            plugins: {
              legend: {
                position: "right",
              },
            },
          }}
        />
      </Modal>
    </div>
  );
}

export default HomeLayout;
