const pharmacyActions = {
    ADD_PHARMACY1: "ADD_PHARMACY1",
    GET_PHARMACY: "GET_PHARMACY",
    GET_PHARMACY_SUCCESS: "GET_PHARMACY_SUCCESS",
    GET_CLONE_PHARMACY: "GET_CLONE_PHARMACY",
    GET_CLONE_PHARMACY_SUCCESS: "GET_CLONE_PHARMACY_SUCCESS",
    EDIT_PHARMACY:'EDIT_PHARMACY',
    
    addPharmacies: (data) => {
        console.log("add pharmacy action", data)
        return {
          type: pharmacyActions.ADD_PHARMACY1,
          data
        };
      },
      
      getPharmacy1: (page, searchData) => {
      console.log("GET_doc1")
  
      return {
        type: pharmacyActions.GET_PHARMACY,
        page,
        searchData
      };
    },
    getDoctorclone: (params) => {
      return {
        type: pharmacyActions.GET_CLONE_PHARMACY,
        params
      };
    },
  
    editPharma: (uuid,payLoad, type1, handleOk) => {
      return {
        type: pharmacyActions.EDIT_PHARMACY,
        uuid,
        payLoad,
        type1,
        handleOk
      };
    }
  };
  
  export default pharmacyActions;
  