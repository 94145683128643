import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Table, Checkbox } from 'antd';
import { view_log, green_dot, red_dot, triple_dots } from 'helper/constant';
import {
    EditOutlined
} from '@ant-design/icons';
import actions from "actions";
import SaasPatientView from './views/SaasPatientView';
import moment from 'moment'
const { saasActions } = actions;

export default function SaasPatientsTable() {
    const dispatch = useDispatch();
    let history = useHistory();
    const saasState = useSelector((state) => state.saasReducer);
    const [patientView, setPatientView] = useState(false);
    const [patientsDetails, setPatientsDetails] = useState({});

    useEffect(() => {
        dispatch(saasActions.getSaasPatients(0, ""));
    }, [])


    const getDoctorNames = (row) => {
        let data = [];
        row.doctor.map((item) => {
            data.push(
                <>
                    <p>{item.full_name}</p>  <br></br>
                </>
            )
        })

        return data;
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'firstName',
            render: (text, row) => (
                <div className="tbl-user-photo">
                    {row.full_name}
                </div>
            ),
        },
        {
            title: 'Phone Number',
            dataIndex: 'mobile',
            key: 'mobile',

        },

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Date Of Birth',
            dataIndex: 'dob',
            key: 'age',
            render: (text, row) => (
                <div className="tbl-user-photo">
                    {row && row.dob && moment(row.dob).format('LL')}
                </div>
            ),
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
        },
        {
            title: 'Doctor (s)',
            dataIndex: 'doctor',
            key: 'doctor',
            render: (text, row) => (
                <div className="tbl-user-photo">
                    {row && row.doctor &&
                        getDoctorNames(row)
                    }
                </div>
            ),

        },
        {
            title: 'Lab Tests',
            dataIndex: 'labTests',
            key: 'gender',
        },
        {
            title: 'Prescriptions',
            dataIndex: 'prescriptions',
            key: 'gender',
        },
        {
            title: 'View',
            dataIndex: 'eye',
            key: 'eye',
            render: (text, row) => (
                <img
                    className='ml--10 cursor-pointer'
                    src={view_log && view_log.default?view_log.default:view_log}
                    alt='view'
                    onClick={() => {
                        setPatientsDetails(row)
                        setPatientView(true)
                    }}
                />
            )
        },
    ];



    return (
        <div>
            <Table
                columns={columns}
                bordered={true}
                dataSource={saasState && saasState.patientsData.reverse()}
                size='middle'
                loading={saasState && saasState.loader}
                pagination={{
                    total: saasState && saasState.patientCount,
                    showSizeChanger: false
                }}

                onChange={(pagination) => {
                    let pageNumber = ''
                    if (pagination.current === 1) {
                        pageNumber = 0
                    } else {
                        pageNumber = pagination.current - 1
                    }
                    dispatch(saasActions.getSaasPatients(pageNumber, ""))
                }}
            />
            <SaasPatientView
                patientDetails={patientsDetails}
                visible={patientView}
                close={() => { setPatientView(false) }}
            />

        </div>
    )
}
