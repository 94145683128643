import axios from 'axios';
import { setHeadersWithAccessToken } from './index';

const WEB_API_BASE = process.env.REACT_APP_WEB_APP;


export const getAppointments = (auth,params,params1) => {
  setHeadersWithAccessToken(auth);
  // return axios.get(`${WEB_API_BASE}/booking/all`,{
    return axios.get(`${WEB_API_BASE}/booking/adminBooking/adminall`,{

    params
  }).then(e => e);
};


export const cancelAppointmentsService = (auth,params) => {
  console.log("servive para",params)
  setHeadersWithAccessToken(auth);
  // return axios.get(`${WEB_API_BASE}/booking/adminBooking/admincancel/${params}`,{
    // return axios.get(`http://localhost/api/v1/booking/adminBooking/admincancel/${params}`,{
      return axios.get(`${WEB_API_BASE}/booking/adminBooking/admincancel/${params}`,{

  }).then(e => e);
};

export const reScheduledAppointmentsService = (auth,data) => {
  console.log("servive data",data)
  setHeadersWithAccessToken(auth);
  // return axios.get(`${WEB_API_BASE}/booking/adminBooking/admincancel/${params}`,{
    // return axios.post(`http://localhost/api/v1/booking/adminBooking/adminrescheduled`,data,{
      return axios.post(`${WEB_API_BASE}/booking/adminBooking/adminrescheduled`,data,{

    
  }).then(e => e);
};



