import actions from 'actions';

const initState = {
    // orders1: [],
    
    orders1: [],
    limit: 10,
    pagenum: 0,
    loader: false
};

export default function rootReducer(state = initState, action) {
    switch (action.type) {
        case actions.TREATMENTORDERACTION.GET_OREDER1:
            return {
                ...state,
                loader: true
            };
        case actions.TREATMENTORDERACTION.GET_OREDER_SUCCESS1:
            return {
                ...state,
                loader: false,
                orders: action.data
            };
        case actions.TREATMENTORDERACTION.CHANGE_OREDER_STATUS1:
            return {
                ...state,
                loader: true,
            };

        case actions.TREATMENTORDERACTION.CHANGE_OREDER_STATUS_SUCCESS1:
            return {
                ...state,
                loader: false,
            };


        default:
            return state;
    }
}
