import axios from 'axios';
import { setHeadersWithAccessToken } from './index';
const WEB_API_BASE = process.env.REACT_APP_WEB_APP;


export const getPharmacycloneSaga = (auth,{params}) => {
  setHeadersWithAccessToken(auth);
  return axios.get(`${WEB_API_BASE}/user/admin/search/doctors`,{
    params
  }).then(e => e);
};


// export const getPharmacySaga = (auth) => {
//   setHeadersWithAccessToken(auth);
//   return axios.get(`${WEB_API_BASE}/user/admin/search/doctors`).then(e => e);
// };
export const getPharmacyData = (auth) => {
  setHeadersWithAccessToken(auth);
  return axios.get(`${WEB_API_BASE}/user/auth/pharmacy/list?skip=0&limit=10&search=`).then(e => e);
};

export const editPharmacyapi = (auth,uuid,payLoad,type1) => {
  console.log(uuid,payLoad,type1, "dfgjh")
  setHeadersWithAccessToken(auth)
  if(type1 == "address"){
    return axios.put(`${WEB_API_BASE}/user/auth/pharmacy/update/address?user_id=${uuid}`, payLoad).then(e => e);
  }
  else if(type1 == "bank"){
    return axios.put(`${WEB_API_BASE}/user/auth/pharmacy/update/bank?user_id=${uuid}`, payLoad).then(e => e);
  }
  else if(type1 == "General"){
    return axios.put(`${WEB_API_BASE}/user/auth/pharmacy/update?user_id=${uuid}`, payLoad).then(e => e);
  }
};

export const addPharmacies = (auth, data) => {
    setHeadersWithAccessToken(auth);
    return axios.post(`${WEB_API_BASE}/user/auth/pharmacy/register`, data).then(e => e);
  };