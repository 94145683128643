import axios from "axios"
import { setHeadersWithAccessToken } from './index';
const WEB_API_BASE = process.env.REACT_APP_WEB_APP;


export const fetchinfluencers =( auth, pageNumber, searchQuery) => {
  setHeadersWithAccessToken(auth);

  let Search = ' '

  if (searchQuery) {
    try {
      Search = searchQuery.toString().trim()
    }catch (error) {
      Search = ' '
    }
  }

  let Limit = "10"
  let Pages;
  if (pageNumber === undefined ){
    Pages = 0
  }else {
    Pages = pageNumber
  }

  return axios
  .get(`${WEB_API_BASE}/user/auth/influencer/list?skip=${Pages}&limit=${Limit}&search=${Search}`)
  .then(e => e);

};