import React from 'react';
import Chat from 'components/support/Chat';

const ChatView = () => {
    return (
        <div className="pictogram-container">
            <div>
                <Chat />
            </div>
        </div>
    );
};

export default ChatView;