import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from 'actions';
import { getorderservice, changeOrderstatus } from 'services/treatment_order-service';
import { notification } from 'antd';
const { TREATMENTORDERACTION, authActions } = actions;

export function* getordersaga() {
  yield takeEvery(TREATMENTORDERACTION.GET_OREDER1, function* ({ pageNumber }) {
    try {
      const token = yield select(state => state.auth.accessToken);
      const user_id =  yield select((state) => state.auth.admin.uuid);
      const response = yield getorderservice({ token },pageNumber, user_id);
      const { data } = response;
      yield put({
        type: TREATMENTORDERACTION.GET_OREDER_SUCCESS1,
        data
      });

    } catch (error) {
      yield notification.open({ message: 'Failed!', description: error.response && error.response.data.message })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

let count = 0;
export function* editOrderStatus() {
  yield takeEvery(TREATMENTORDERACTION.CHANGE_OREDER_STATUS1, function* (orderId, orderStatus, itemId) {
    try {

      if (count === 0) {
        count++
        const token = yield select(state => state.auth.accessToken);
        const response = yield changeOrderstatus({ token }, orderId, orderStatus, itemId);
        const { data } = response;
        if (response.status === 200) {
          yield notification.open({
            message: 'Success!',
            description: 'Order Status Updated Successfully'
          });
          yield put({
            type: TREATMENTORDERACTION.CHANGE_OREDER_STATUS_SUCCESS1
          });
          yield put({
            type: TREATMENTORDERACTION.GET_OREDER1
          });
        } else {
          throw response;
        }
      } else {
        count = 0;
      }

    } catch (error) {
      yield notification.open({ message: 'Failed!', description: error.response && error.response.data })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}



export default function* rootSaga() {
  yield all([fork(getordersaga), fork(editOrderStatus)]);
}

