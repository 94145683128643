import actions from 'actions';

const initState = {
    feedbacks: [],
    loader: false
};

export default function rootReducer(state = initState, action) {
    switch (action.type) {
        case actions.feebackActions.GET_FEEDBACKS:
            return {
                ...state,
                loader: true
            };
        case actions.feebackActions.SET_FEEDBACKS:
            return {
                ...state,
                loader: false,
                feedbacks: action.data
            };
        default:
            return state;
    }
}
