import React, { useEffect, useState } from "react";
import axios from "axios";
import "./styles.css";
import { Table, Popover, Select, Spin,DatePicker,notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import actions from "actions";
import { Card,Modal } from "antd";
import { Bar,Doughnut,Pie } from "react-chartjs-2";
import {AreaChartOutlined } from '@ant-design/icons';
import moment from "moment";

const { partnersActions } = actions;

const { Option } = Select;
const { RangePicker } = DatePicker;

function PartnerLayout() {
  const dispatch = useDispatch();
  
  const [searchQuery, setSearchQuery] = useState('')

  const auth = useSelector((state) => state.auth.accessToken);
  // const state = useSelector((state) => console.log(state, "state"));
  const partners = useSelector((state) => state.partnersReducers);

  console.log(partners.count, "state")
  useEffect(() => {
      dispatch(partnersActions.getPartners(0,searchQuery));
  },[searchQuery] )

  const [apiData, setApiData] = useState([]);
  const [apiData1, setApiData2] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [chartData, setChartData] = useState({});
  const [openChart,setOpenChart]=useState(false)
  const api = process.env.REACT_APP_WEB_APP;
  const [date, setDate] = useState([
    "2020-01-01",
    moment().format("YYYY-MM-DD"),
  ]);
  const apiFetch = async () => {
    setFetching(true);
    // .get(
    //   api +
    //     `/order/admin/getOrderDetailsForAdmin?type=all&start_date=${moment(
    //       date[0]
    //     ).format("YYYY-MM-DD")}&end_date=${moment(date[1]).format(
    //       "YYYY-MM-DD"
    //     )}`,
    //   {
    //     headers: { "x-auth-token": auth },
    //   }
    // )
    await axios
    .get(api + `/doctallnew/order/dashboard/data`)
      .then((res) => {
        console.log(res.data,"res")
        setApiData(res.data.totalData);
        setApiData2(res.data.totalDataDisease)
        setFetching(false);
      });
  };
console.log(apiData)
  useEffect(() => {
    apiFetch();
  }, [date]);
//   const handleChangePatient = (val) => {
//     if (val === undefined) {
//       setTypes("all");
//     } else {
//       let data = val.key;
//       setSearchPatient(data);
//       setTypes("patient");
//     }
//   };
  const colors =[
    "rgba(255, 99, 132)",
    "rgba(54, 162, 235)",
    "rgba(255, 206, 86)",
    "rgba(75, 192, 192)",
    "rgba(153, 102, 255)",
    "rgba(255, 159, 64)",
    "rgba(255, 120, 64)",
    "rgba(155, 113, 64)",
    "rgba(0, 0, 64)"
  ]
  const chartView = async (type)=>{
    setOpenChart(true)
      let keyss = [];
      let valuess = [];
      await axios.get(api + `/doctallnew/order/dashboard/data`)
        .then((res) => {
            console.log(res.data,"res")
            if(type == "treatement"){
              for (const Obj of Object.keys(res.data.totalData[0])) {
                keyss.push(Obj);
              }
              for (const Obj of Object.values(res.data.totalData[0])) {
                valuess.push(Obj);
              }
            }
            else if(type == "disease"){
              res.data.totalDataDisease.map((item) => {
                keyss.push(item._id);
              })
              res.data.totalDataDisease.map((item) => {
                valuess.push(item.total_treatment_plan_order);
              })
            }
            console.log(valuess,"values")
            console.log(keyss,"values")

          console.log(valuess)
          setChartData({
            labels: keyss,
            datasets: [
              {
                label: "Total",
                data: valuess,
                backgroundColor:colors ,
                borderWidth: 1
              }
            ]
          });
          
        });
   
    
  }

  return (
    <div>
      <div className="homeContainer">
      {/* <RangePicker
            onChange={(val) => {
              if (val === null) {
                setDate(["2020-01-01", moment().format("YYYY-MM-DD")]);
              } else if (
                moment(val[0]).format("YYYY-MM-DD") >
                moment().format("YYYY-MM-DD")
              ) {
                notification.open({
                  message: "you selected future Date, Please Select vaild date",
                  duration: 2,
                });
              } else {
                setDate(val);
              }
            }}
          /> */}
        {(apiData !== [] || apiData !== '' || apiData !== undefined || apiData.length !== 0) ? (
          <>
            <Spin size="middle" spinning={fetching}>
              <div className="headConatiner">
                <Card className="cardContainer">
                  <h2 className="HeadContent">Total Orders
                  <button className="chartIcon" onClick={()=>{chartView('treatement')}}><AreaChartOutlined /> </button>
                  </h2>
                  <p className="bodyContent">
                  Total Orders{" "}
                    <span className="numbers">
                      {apiData && apiData[0] && apiData[0].total_treatment_plan_order}
                    </span>
                  </p>
                  <p className="bodyContent">
                  Orders Paid{" "}
                    <span className="numbers">{apiData && apiData[0] && apiData[0].total_treatment_plan_order_paid}</span>
                  </p>
                  <p className="bodyContent">
                  Orders Unpaid{" "}
                    <span className="numbers">
                      {apiData && apiData[0] && apiData[0].total_treatment_plan_order_unpaid}
                    </span>
                  </p>
                  <p className="bodyContent">
                  Orders Completed{" "}
                    <span className="numbers">{apiData && apiData[0] && apiData[0].total_treatment_plan_order_completed}</span>
                  </p>

                  <p className="bodyContent">
                  Orders Pending{" "}
                    <span className="numbers">
                      {apiData && apiData[0] && apiData[0].total_treatment_plan_order_pending}
                    </span>
                  </p>
                </Card>
                <Card className="cardContainer">
                  <h2 className="HeadContent">Disease Wise
                  <button className="chartIcon" onClick={()=>{chartView('disease')}}><AreaChartOutlined /> </button>
                  </h2>
                  {apiData1 && apiData1.map((item) => {
                                  return    <p className="bodyContent">
                                      {item && item._id}{" "}
                                        <span className="numbers">
                                          {item && item.total_treatment_plan_order}
                                        </span>
                                      </p>
                  })}

                </Card>
              </div>
            </Spin>
          </>
        ) : (

            <><Spin size="middle" spinning={fetching}>
            <Card className="cardContainer">
              <h2 className="HeadContent">No Records </h2>
              <p className="bodyContent">No Data Found</p>
            </Card>
            </Spin>
          </>

        )}
      </div>
      <Modal title={'Earnings Overview'}
      width={600} 
      visible={openChart} 
      onOk={()=>{setOpenChart(false)}} 
      onCancel={()=>{setOpenChart(false)}}>
        <Pie data={chartData} 
        options={{
          responsive: true,
          maintainAspectRatio: true,
          indexAxis: "X",
          plugins: {
                legend: {
                  position: "right",
                }
              }
        }}
        />
      </Modal>
    </div>
  );
}

export default PartnerLayout;
