import React, { useState, useEffect } from 'react'
import { Form, Modal, Input, Row, Col, Button, Select, Radio, Checkbox } from 'antd';
import axios from 'axios';
import { getConsumer } from 'services/pushNotificationServices';
import useDebounce from 'components/custom-hooks/useDebounce';
import { SettingsCellOutlined } from '@material-ui/icons';


const { Option } = Select;
const { TextArea } = Input;


export default function ComposeNotification(props) {

    const { show, close, onSubmit } = props;
    const [form] = Form.useForm();
    const [customers, setCustomers] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedCustomer, setSelectedCustomer] = useState([])
    const [radioSelection, setRadioSelection] = useState(1)
    const [searchQuery, setSearchQuery] = useState('')
    const debouncedSearchTerm = useDebounce(searchQuery, 500);
    const [saveDraft, setSaveDraft] = useState(false)


    const resetFormFields = () => {
        form.resetFields();
        setRadioSelection(1)
    }

    const handleSubmit = values => {
        console.log(values, "dsvg")
        if (saveDraft === true) {
            onSubmit(values, selectedCustomer, radioSelection, "DRAFT")
        } else {
            onSubmit(values, selectedCustomer, radioSelection, "SEND")
        }
        onCancelClick()
    };


    function onCheckChanged(event) {
        setSaveDraft(event.target.checked)
    }



    const onCancelClick = () => {
        close()
        resetFormFields()
        setSaveDraft(false)
    };


    // useEffect(() => {
    //     handleChange()
    // }, [debouncedSearchTerm])
    const [page, setPage] = useState(0)
    const [search, setSearch] = useState('')
    const [count, setCount] = useState()


    const getConsumer = async (pages, searchQuery) => {
        // let response = await axios.get(`${process.env.REACT_APP_WEB_APP}/user/all/consumer/getconsumers?search=${searchQuery}`)
        let response = await axios.get(`${process.env.REACT_APP_WEB_APP}/user/getallconsumer?pagenum=${pages}&limit=10&search=${searchQuery}`)
        if (response) {
            console.log(response, "sbbdjsj")
            setCustomers(response.data.data)
            setCount(response.data.count)
        } else {
            throw new Error("Consumer Not Found");
        }
    }
    useEffect(() => {
        getConsumer(page, search)
    }, [])

    const scrollFun = () => {
        if(Math.ceil(count/10) > page && customers.length >= 10){
            setPage(page + 1)
            getConsumer(page + 1, search)
          }
        // setPage(page + 1)
        // getConsumer(page + 1, search)
    }

    console.log(customers, "customers")
    const handleSearchQuery = (event) => {
        // setCustomers([])
        setSearch(event)
        getConsumer(page, event)
    }

    const handleChange = async (event) => {
        try {
            setLoading(true)
            let response = await getConsumer(event)
            if (response) {
                setCustomers(response.data)
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }


    var selectedValues = [];
    const handleSelection = (event) => {
        //Add Data To Selected List
        selectedValues.push(event)
        setSelectedCustomer((prev) => [...prev, ...selectedValues])
    }

    const handleDeselection = (event) => {
        //Delete Data From Selected List
        var newArray = [...selectedCustomer]; // make a separate copy of the array
        var index = newArray.indexOf(event)
        if (index !== -1) {
            newArray.splice(index, 1);
            setSelectedCustomer(newArray)
        }
    }

    const onRadioValueChange = e => {
        setRadioSelection(e.target.value);
        if (e.target.value === 2) {
            setSelectedCustomer([])
        }
    };




    return (
        <Modal
            className='no-modal-footer'
            title='Compose Notification'
            visible={show}
            width={500}
            onCancel={onCancelClick}
            okText='Add'
        >
            <Form onFinish={handleSubmit} form={form} className='gx-form-row0' layout='vertical'>
                <Row>

                    <Col span={24} xs={24} md={24}>
                        <Form.Item
                            label='Title'
                            name='title'
                            rules={[{ required: true, message: 'Title Is Mandatory' }]}
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>


                    <Col span={24} xs={24} md={24}>
                        <Form.Item
                            label='Description'
                            name='description'
                            rules={[{ required: true, message: 'Description Is Mandatory' }]}
                        >
                            <TextArea size='large' />
                        </Form.Item>
                    </Col>

                    <Col span={24} xs={24} md={24}>

                        <Radio.Group onChange={onRadioValueChange} value={radioSelection} style={{ marginBottom: '3%' }}>
                            <Radio value={1}>Select Consumers</Radio>
                            <Radio value={2}>All Consumers</Radio>
                        </Radio.Group>

                    </Col>


                    {
                        radioSelection === 1 &&
                        <Col span={24} xs={24} md={24}>
                            <Form.Item
                                label=''
                                name='users'
                                rules={[{ required: true, message: 'Consumer Selection Is Mandatory' }]}
                            >
                                <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                size="large"
                                showSearch
                                optionFilterProp="children"
                                placeholder="Please select"
                                onSearch={(event) => { handleSearchQuery(event); handleChange(event) }}
                                loading={loading}
                                onSelect={(event) => { handleSelection(event) }}
                                onDeselect={(event) => { handleDeselection(event) }}
                                onClear={() => { setSelectedCustomer([]) }}
                                onPopupScroll={scrollFun}
                                >
                                    {customers &&
                                        customers.map((details) => (
                                            <option key={details.uuid} value={details.uuid}>
                                                {`${details.full_name}`}
                                            </option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    }

                    <Col span={24} xs={24} md={24}>
                        <Form.Item
                            label=''
                            name='draft'
                            rules={[{ required: false, message: 'NULL' }]}
                        >
                            <Checkbox onChange={onCheckChanged}>SAVE AS DRAFT</Checkbox>
                        </Form.Item>
                    </Col>


                    <Col className='ml-auto'>
                        <br />
                        <br />
                        <Button className='login-form-button' onClick={() => { onCancelClick() }}>
                            Cancel
                        </Button>

                        <Button
                            type='primary'
                            htmlType='submit'
                            className='login-form-button ml--10'
                            value='send'

                        >
                            {saveDraft === true ? "Save" : "Send"}
                        </Button>

                    </Col>
                </Row>
            </Form>
        </Modal >
    )
}
