const GETSLOTBYDOCTOR = {
  GET_UNIQUE_SLOTS_DOCTOR: "GET_UNIQUE_SLOTS_DOCTOR",
  GET_UNIQUE_SLOTS_DOCTOR_SUCCESS: "GET_UNIQUE_SLOTS_DOCTOR_SUCCESS",
  getSlotsDoctor: (params,param2) => {
    console.log(params, "actionparamDoctorID");
    return {
      type: GETSLOTBYDOCTOR.GET_UNIQUE_SLOTS_DOCTOR,
      params,
      param2
    };
  },
};

export default GETSLOTBYDOCTOR;
