import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from 'actions';
import { notification } from 'antd';
import { getUsedCoupons, getCouponStat } from 'services/couponsServices';

const { usedCouponsActions, authActions } = actions;



export function* fetchUsedCoupons() {
    console.log('saga');
    yield takeEvery(usedCouponsActions.GET_USED_COUPONS, function* ({ pageNumber, searchQuery }) {
        try {
            const token = yield select(state => state.auth.accessToken);
            const response = yield getUsedCoupons({ token }, pageNumber, searchQuery);
           
            if (response.status === 200) {
                yield put({
                    type: usedCouponsActions.GET_USED_COUPONS_SUCCESS,
                    data: response.data,
                    count: response.data.count,
                });
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
        }
    });
}


export function* fetchCouponSaga() {
    console.log('saga');
    yield takeEvery(usedCouponsActions.GET_COUPONS_STATS, function* ({ searchQuery1 }) {
        try {
            const token = yield select(state => state.auth.accessToken);
            const response = yield getCouponStat({ token }, searchQuery1);
            if (response.status == 200) {
                yield put({
                    type: usedCouponsActions.GET_COUPONS_STATS_SUCCESS,
                    stats: response.data,
                });
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
        }
    });
}

export default function* rootSaga() {
    yield all([fork(fetchUsedCoupons),fork(fetchCouponSaga)]);
}