import actions from 'actions';

const initState = {
    orders: [],
    loader: false

};

export default function rootReducer(state = initState, action) {
    switch (action.type) {
        case actions.ORDERACTION.GET_OREDER:
            return {
                ...state,
                loader: true
            };
        case actions.ORDERACTION.GET_OREDER_SUCCESS:
            return {
                ...state,
                loader: false,
                orders: action.data
            };
        case actions.ORDERACTION.CHANGE_OREDER_STATUS:
            return {
                ...state,
                loader: true,
            };

        case actions.ORDERACTION.CHANGE_OREDER_STATUS_SUCCESS:
            return {
                ...state,
                loader: false,
            };


        default:
            return state;
    }
}
