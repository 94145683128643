const authActions = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGOUT: "LOGOUT",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  AUTH_ERROR: "AUTH_ERROR",

  login: payload => {
    return {
      type: authActions.LOGIN_REQUEST,
      data: payload
    };
  },
  
  logout: () => {
    return {
      type: authActions.LOGOUT,
      isLogin: false,
      accessToken: null
    };
  }
};

export default authActions;
