import axios from 'axios';
import { setHeadersWithAccessToken } from './index';
const WEB_API_BASE = process.env.REACT_APP_WEB_APP;


export const getCoupons = (auth, pageNumber) => {
    setHeadersWithAccessToken(auth);
    let page;
    if (pageNumber === undefined) {
        page = 0
    } else {
        page = pageNumber
    }
    return axios
        .get(`${WEB_API_BASE}/coupon/list/${page}`)
        .then(e => e);
};

export const getPromoCode = (auth, pageNumber, searchQuery) => {
    setHeadersWithAccessToken(auth);
    let page;
    if (pageNumber === undefined) {
        page = 0
    } else {
        page = pageNumber
    }
    return axios
        .get(`${WEB_API_BASE}/coupon/applied/dmp/coupon/list?pagenum=${page}&limit=10&search=${searchQuery.toString().trim()}`)
        .then(e => e);
};



export const getUsedCoupons = (auth, pageNumber, searchQuery) => {
    setHeadersWithAccessToken(auth);
    let limit = 10
    let page;
    if (pageNumber === undefined) {
        page = 0
    } else {
        page = pageNumber
    }

    return axios
        .get(`${WEB_API_BASE}/coupon/applied/coupon/list?limit=${limit}&pagenum=${page}&search=${searchQuery}`)
        .then(e => e);
};


export const getCouponStat = (auth, searchQuery) => {
    setHeadersWithAccessToken(auth);
    // let limit = 10
    // let page;
    let searchData;
    // if (pageNumber === undefined) {
    //     page = 0
    // } else {
    //     page = pageNumber
    // }

    if (searchQuery === undefined) {
        searchData = ''
    } else {
        searchData = searchQuery
    }

    return axios
        .get(`${WEB_API_BASE}/coupon/used/coupon/metrics?user_id=${searchData}`)
        .then(e => e);
};

export const createCoupans = (auth, payLoad) => {
    setHeadersWithAccessToken(auth);
    return axios.post(`${WEB_API_BASE}/coupon/create`, payLoad).then(e => e);
};


export const updateCoupans = (auth, payLoad, id) => {
    setHeadersWithAccessToken(auth);
    return axios.put(`${WEB_API_BASE}/coupon/update/${id}`, payLoad).then(e => e);
};

