import { Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  Tabs,
  notification,
} from 'antd';
import { useDispatch } from 'react-redux';
import actions from 'actions';
import "react-phone-input-2/lib/style.css";

const { Option } = Select;

const { master } = actions;
const EditeCompany = ({ user, visible, handleOk, handleCancel, diff, city }) => {
  console.log('user4',user);
  const dispatch = useDispatch()
  const [newcityuuid, setnewcityuuid] = useState('');
console.log(newcityuuid,"newcity")

  const CityChange = (item) => {
    console.log(item, "item")
    setnewcityuuid(item)
  }


  const { TabPane } = Tabs;


 
  const handleSubmit1 = values => {
    console.log(values)

    var payLoad = {
      "uuid": user.uuid,
      "company_name": values.company_name,
      "company_reg_id": values.company_reg_id,
      "firstName": values.firstName,
      "lastName": values.lastName,
      "email": values.email,
      "mobile": values.mobile,
      "company_address": values.company_address,
  
     
  }
   console.log(payLoad,"payLoad")
    dispatch(master.editCompany(payLoad));
  }

  


  return <Modal
    title={`Company Details`}
    visible={visible}
    onOk={handleOk}
    onCancel={handleCancel}
    width={850}
    footer={[]}
   
    
  >
   
    {/* <Tabs defaultActiveKey="1" > */}
      {/* <TabPane
       tab="General" key="1"
       > */}
        <Form onFinish={handleSubmit1} 
        className='gx-form-row0' layout='vertical'
        >
        <Row
         className="space-between"
         >
          
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Company Name"
              name="company_name"
              initialValue={user && user.company_name}
              rules={[{ required: true, message: "Please enter Company name!" }]}
            >
              <Input size="large" disabled={diff == 'view'} />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Company ID"
              name="company_reg_id"
              initialValue={user && user.company_reg_id}
              rules={[{ required: true, message: "Please enter Company Reg id!" }]}
            >
              <Input size="large" disabled={diff == 'view'} />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="First Name"
              name="firstName"
              initialValue={user && user.firstName}
              rules={[{ required: true, message: "Please enter Company name!" }]}
            >
              <Input size="large" disabled={diff == 'view'} />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Last Name"
              name="lastName"
              initialValue={user && user.lastName}
              rules={[{ required: true, message: "Please enter Company name!" }]}
            >
              <Input size="large" disabled={diff == 'view'} />
            </Form.Item>
          </Col>
         
            
          
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Email ID"
              name="email"
              initialValue={user && user.email}
              rules={[{ required: true, message: "Please enter Company name!" }]}
            >
              <Input size="large" disabled={diff == 'view'} />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Mobile Number"
              name="mobile"
              initialValue={user && user.mobile}
              rules={[{ required: true, message: "Please enter name!" }]}
            >
              <Input size="large" disabled={diff == 'view'} />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7} >
            <Form.Item
              label="Company Address"
              name="company_address"
              initialValue={user && user.company_address}
              rules={[{ required: true, message: "Please enter name!" }]}
            >
              <Input size="large" disabled={diff == 'view'} />
            </Form.Item>
          </Col>
          {/* <Col span={7} xs={7} md={7} >
            <Form.Item
              label="Refferal Code"
              name="refferealcode"
              initialValue={user && user.wallet && user.wallet[0] && user.wallet[0].referal_code}
              rules={[{ required: true, message: "Please enter name!" }]}
            >
              <Input size="large" disabled={diff == 'view'} />
            </Form.Item>
          </Col> */}
          <Col span={7} xs={7} md={7}>
              
              <p style={{paddingBottom:"10px"}}>Refferal code</p>
               <Input
              value={user && user.wallet && user.wallet[0] && user.wallet[0].referal_code}
              // disabled
              size="large"
             />             
            </Col>  
            <Col span={7} xs={7} md={7}>
            </Col>

        </Row>
          {diff === "edit" &&
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button ml--10'>
              Submit
            </Button>
          }
        </Form>
      {/* </TabPane> */}

    

    {/* </Tabs> */}
  </Modal>
  // );
};

export default EditeCompany;
