import React, { useEffect, useState } from "react";
import { Table, Popover, Select, Row, Col, Input, Space } from "antd";
import { view_log, triple_dots, green_dot, red_dot } from "helper/constant";
import ViewDoctor from "./ViewDoctor";
import AddDoctor from "./AddDoctor";
import { useDispatch, useSelector } from "react-redux";
import actions from "actions";
import { Spin } from "antd";
import { Helmet } from "react-helmet";

const { doctorActions } = actions;
const { Option } = Select;
const Search = Input.Search

const Doctors = ({ tableData }) => {
  const [visible, setvisible] = useState(false);
  const [user, setuser] = useState(null);
  const [query, setQuery] = useState({});
  const [editVisible, setEditVisible] = useState(null);
  const [searchQuery, setSearchQuery] = useState('')

  const dispatch = useDispatch();

  const cities = useSelector((state) => state.common.cities);
  const states = useSelector((state) => state.common.states);

  const handleOnSearch = (value) => {
    console.log(value)
    setSearchQuery(value)
    // dispatch(consumerActions.getConsumers(0, value, query));
    dispatch(doctorActions.getDoctorclone(0, value, query));

  }

  const columns = [
    {
      title: "Doctor Id",
      dataIndex: "uuid",
      key: "uuid",
    },
    {
      title: "Doctor Name",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Nationality",
      dataIndex: "Nationality",
      key: "Nationality",
      render: (text, row) => (
        <div>
          {row.profile && row.profile.address && row.profile.address.country}
        </div>
      ),
    },

    {
      title: "Speciality",
      dataIndex: "profile.speciality",
      key: "profile.speciality",
      render: (text, row) => (
        <div>{row.profile && row.profile.speciality.join(",")}</div>
      ),
    },

    {
      title: "Languages",
      dataIndex: "Languages",
      key: "Languages",
      render: (text, row) => (
        <div>{row && row.profile && row.profile.languages && row.profile.languages.join(",")}</div>
      ),
    },
    {
      title: "Doctor Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, row) => (
        <div className="tbl-user-photo">
          {row.verified ? (
            <img src={green_dot && green_dot.default?green_dot.default:green_dot} alt="active" />
          ) : (
            <img src={red_dot && red_dot.default?red_dot.default:red_dot} alt="inactive" />
          )}
        </div>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "View",
      dataIndex: "eye",
      key: "eye",
      render: (text, row) => (
        <img
          className="ml--10 cursor-pointer"
          src={view_log && view_log.default?view_log.default:view_log}
          alt="view"
          onClick={() => {
            console.log("view");
            setuser(row);
            setvisible(true);
          }}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="ml--20">
          <Popover
            trigger="hover"
            placement="bottom"
            content={
              <div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    console.log("edit");
                    console.log("view");
                    setuser(row);
                    setEditVisible(true);
                  }}
                >
                  Edit
                </div>
                {/* <div
                  className='pt--7 cursor-pointer'
                  onClick={() => {
                    console.log('delete');
                  }}
                >
                  Delete
                </div> */}
              </div>
            }
          >
            <img src={triple_dots && triple_dots.default?triple_dots.default:triple_dots} alt="action" />
          </Popover>
        </div>
      ),
    },
  ];

  // useEffect(() => {
  //   dispatch(doctorActions.getDoctor(0));
  // }, []);

  useEffect(() => {
    dispatch(doctorActions.getDoctorclone(0, searchQuery, query));
  }, [query]);

  const payload = useSelector((state) => state.doctors);
  const payloadClone = useSelector((state) => state.doctors);
  console.log("payloadClonedctor", payload);

  const ddddd =
    payload &&
    payload.doctors.data &&
    payload.doctors.data.map((r) => (r.profile ? r.profile.speciality : []));
  // console.log(" payload && payload.doctors",ddddd);
  var myNewArray = [].concat.apply([], ddddd);
  // console.log(" myNewArray && myNewArray.doctors",myNewArray);

  const langua =
    payload &&
    payload.doctors.data &&
    payload.doctors.data.map((r) => (r.profile ? r.profile.languages : []));

  //console.log(" langua && langua.langua",langua);
  var myNewArraylan = [].concat.apply([], langua);
  // console.log(" myNewArraylan && myNewArraylan.myNewArraylan",myNewArraylan);

  let uniqueChars = [...new Set(myNewArray)];
  console.log("uniqueChars", uniqueChars);

  let uniqueCharslan = [...new Set(myNewArraylan)];

  var dropdownnational =
    payload &&
    payload.doctors.data &&
    payload.doctors.data.map((item) => {
      return [
        item.profile && item.profile.address && item.profile.address.country,
        item,
      ];
    }); // creates array of array
  var maparr = new Map(dropdownnational); // create key value pair from array of array

  var doctnationlaresult = [...maparr.values()]; //converting back to array from mapobject

  // console.log("result",result); //[{"name":"abc","age":27},{"name":"pqr","age":27}]

  return (
    <div>
      <Helmet>
        <title>Doctall Super Admin Doctors
        </title>
        <meta
          name='description'
          content='View list of doctors on the platform and their details- nationality, spevialty, contact details and other informations. Also add new doctors.'


        />
      </Helmet>
      {/* <Select
        placeholder="Doctor Name"
        size=",medium"
        style={{ width: 250, marginRight: 10 }}
        showSearch
        autoClearSearchValue
        allowClear
        optionFilterProp="children"
        labelInValue
        onChange={(value) => {
          if (value && value.value) {
            setQuery({ doctorname: value.value });
            console.log("value.doctorname", value.value);
          }
        }}
      >
        {payload &&
          payload.doctors.data &&
          payload.doctors.data.map((option, i) => (
            <Option key={option._id} value={option.full_name}>
              {option.full_name}
            </Option>
          ))}
      </Select>

      <Select
        placeholder="Doctor Email"
        size=",medium"
        style={{ width: 250, marginRight: 10 }}
        showSearch
        autoClearSearchValue
        allowClear
        optionFilterProp="children"
        labelInValue
        onChange={(value) => {
          if (value && value.value) {
            setQuery({ doctoremail: value.value });
            console.log("value.doctoremail", value.value);
          }
        }}
      >
        {payload &&
          payload.doctors.data &&
          payload.doctors.data.map((option, i) => (
            <Option key={option._id} value={option.email}>
              {option.email}
            </Option>
          ))}
      </Select>

      <Select
        placeholder="Specialtity"
        size=",medium"
        style={{ width: 250, marginRight: 10 }}
        showSearch
        autoClearSearchValue
        allowClear
        optionFilterProp="children"
        labelInValue
        onChange={(value) => {
          if (value && value.value) {
            setQuery({ speciality: value.value });
            console.log("value.Specialtity", value.value);
          }
        }}
      >
        {uniqueChars &&
          uniqueChars != undefined &&
          uniqueChars.map((option, i) => (
            <Option key={option._id} value={option}>
              {option}
            </Option>
          ))}
      </Select> */}

      {/* 
<Select
                       placeholder="languages"
                        size=",medium"
                        style={{ width: 250, marginRight: 10}}
                        showSearch
                        autoClearSearchValue
                        allowClear
                        optionFilterProp="children"
                        labelInValue
                        onChange={value =>
                          {
                           
                              if(value && value.value)
                              {
                                setQuery({  languages: value.value })
                                console.log("value.languages",value.value)
  
                              }
  
         
                          }}
                        
                    >
{
  
  uniqueCharslan && uniqueCharslan!=undefined && uniqueCharslan.map((option, i) => <Option key={option} value={option}>{option}</Option>)
  // docspeciresult
} */}
      {/* </Select> */}

      {/* <Select
        placeholder="Nationality"
        size=",medium"
        style={{ width: 250, marginRight: 10 }}
        showSearch
        autoClearSearchValue
        allowClear
        optionFilterProp="children"
        labelInValue
        onChange={(value) => {
          if (value && value.value) {
            setQuery({ country: value.value });
            console.log("value.country", value.value);
          }
        }}
      >
        {doctnationlaresult != undefined &&
          doctnationlaresult.map((option, i) => (
            <Option
              key={option._id}
              value={
                option.profile &&
                  option.profile.address &&
                  option.profile.address.country
                  ? option.profile.address.country
                  : ""
              }
            >
              {option.profile &&
                option.profile.address &&
                option.profile.address.country
                ? option.profile.address.country
                : ""}
            </Option>
          ))}
      </Select> */}
      <Row>
        <Col md={17}>

        </Col>
        <Col md={6}>
          <div className="fs--16 font-semibold flex-1">
            <Space direction="vertical">
              <Search
                size="large"
                // enterButton="Search"
                placeholder="Search"
                enterButton
                allowClear
                // onSearch={handleOnSearch}
                onChange={e => handleOnSearch(e.target.value || '')}
              />

            </Space>
          </div>
        </Col>
        <Col md={1}>

        </Col>

      </Row>
      <Spin spinning={payload.loader}>
        <Table
          columns={columns}
          // loading={payloadClone.doctorsclone.loader}
          //payload.doctors.data
          dataSource={payload.doctorsclone.data}
          size="middle"
          rowKey={"_id"}
          // pagination={{
          //   defaultPageSize: 12,
          // }}
          pagination={{
            // defaultPageSize: 15,
            total: payload.doctorsclone.count,
            defaultPageSize: 10,
            showSizeChanger: false
          }}
          onChange={(pagination) => {
            let pageNumber = ''
            if (pagination.current === 1) {
              pageNumber = 0
            } else {
              pageNumber = pagination.current - 1
            }
            dispatch(doctorActions.getDoctorclone(pageNumber, searchQuery, query))
          }}
        />
      </Spin>
      <ViewDoctor
        visible={visible}
        loader={false}
        handleOk={() => setvisible(false)}
        handleCancel={() => setvisible(false)}
        user={user}
      />
      <AddDoctor
        visible={editVisible}
        // onSubmit={(values) => {
        //   console.log("changed values", values);
        //   // dispatch(doctorActions.editDoctors(user.uuid, values));
        // }}
        cities={cities}
        states={states}
        handleOk={() => setEditVisible(false)}
        handleCancel={() => setEditVisible(false)}
        values={user}
      />
    </div>
  );
};

export default Doctors;
