import firebase from 'firebase/app';
import 'firebase/messaging';
import axios from 'axios'

const config = {
  apiKey: "AIzaSyBWhzKmSKgVKyy0S_57l4X5EgMuVKFrF7k",
  authDomain: "doctall.firebaseapp.com",
  databaseURL: "https://doctall-default-rtdb.firebaseio.com",
  projectId: "doctall",
  storageBucket: "doctall.appspot.com",
  messagingSenderId: "815723410350",
  appId: "1:815723410350:web:6fef8316a685075e8b0e3f",
  measurementId: "G-SR14ZN8Q27"
};

firebase.initializeApp(config);
let messaging = ''
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}
// const messaging = firebase.messaging();

export const getToken = (setTokenFound) => {
  return messaging?.getToken({ vapidKey: 'BBYRSZQYDO97VuIcVO1CgVSTzWaFMJXhItu4klK9BjXL3BBLw5dFUA33iLYTzw0Yr29K2AlwTGozsI1wpa2t9XE' }).then((currentToken) => {
    // console.log('current token for client: ', currentToken);
    if (currentToken) {
      // console.log('current token for client: ', currentToken);
      let userdata = localStorage.getItem("persist:root");
      userdata = JSON.parse(userdata)
      userdata = JSON.parse(userdata.auth)

      if(userdata !== undefined){
      var config = {
        method: 'post',
        url: process.env.REACT_APP_WEB_APP + `/notification/`+userdata.admin.uuid,
        data: {
          firebase_token:currentToken,
          device_type:"adminWebApp"
        },
        headers: {
          'x-auth-token': userdata.accessToken
        }
      };
       axios(config).then((resp) => {
        // console.log(resp)
        })
        .catch(err => {
          // console.log(err)

        })
      }
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      // console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    // console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging?.onMessage((payload) => {
      resolve(payload);
    });
  });