// import { Modal } from 'antd';
import React, { useState } from "react";
import actions from "actions";
// import { Button } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Spin ,notification} from "antd";
import { green_dot, red_dot } from "helper/constant";
import moment from "moment";
import { Form, Modal, Input, Row, Col, Button, Select } from "antd";
const { appointmentActions } = actions;

const { Option } = Select;

const { confirm } = Modal;

const ViewAppointments = ({
  user,
  visible,
  loader,
  handleOk,
  closer,
  handleCancel,
}) => {
  const [form] = Form.useForm();
  const [group, setgroup] = useState("");
  const [bid, setBookingid] = useState("");
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.accessToken);
  const api = process.env.REACT_APP_WEB_APP;

  const showConfirm = (value, id, full) => {
    confirm({
      title: "Are you sure?",
      content: `Do you want to Change the status of this booking to ${value}.`,
      okText: "Yes",
      onOk() {
        if (value == "Cancelled") {
          dispatch(appointmentActions.CancelAppointment(id));
        }
        if (value == "Rescheduled") {
          const data = {
            patient_uuid: full.patient_uuid,
            doctor_uuid: full.doctor_uuid,
            slot: full.slot,
            booking_date: full.booking_date,
            booking_uuid: full.uuid,
            status: "rescheduled",
            video: true,
            category: "GC",
            amount: full.amount,
          };
          dispatch(appointmentActions.reScheduleAppointment(data));
        }else if(value === "Reject"){
         axios.put(`${api}/booking/adminBooking/updateThePendingStatus/${user.uuid}`,'',{headers:{'x-auth-token':auth}}).then((i)=>(
          notification.open({
            message: 'Rejected!',
            description: 'booking Rejected Successfully!'
          })
          // closer(true)
         )).catch(err=>{})
        }

      },
    });
  };
  // console.log(user);
  return (
    <Modal
      title={`Appointment Details`}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="submit" type="primary" onClick={handleOk}>
          Ok
        </Button>,
      ]}
    >
      <Spin spinning={loader}>
        <div className="flex-y align-left">
          <div className="read-form-input flex-1 mr--10 mt--15">
            <div className="read-form-label fs--14 font-semibold">
              Patient Full Name
            </div>
            <div className="read-form-value">
              {user && user.patient && user.patient.full_name}
            </div>
          </div>
          <div className="read-form-input flex-1 mr--10 mt--15">
            <div className="read-form-label fs--14 font-semibold">
              Patient Email
            </div>
            <div className="read-form-value">
              {user && user.patient && user.patient.email}
            </div>
          </div>
          <div className="read-form-input flex-1 mr--10 mt--15">
            <div className="read-form-label fs--14 font-semibold">
              Patient Phone Number
            </div>
            <div className="read-form-value">
              {user && user.patient && user.patient.mobile}
            </div>
          </div>
          <div className="read-form-input flex-1 mr--10 mt--15">
            <div className="read-form-label fs--14 font-semibold">
              Doctor Name
            </div>
            <div className="read-form-value">
              {user && user.doctors[0] && user.doctors[0].full_name}
            </div>
          </div>
          <div className="read-form-input flex-1 mr--10 mt--15">
            <div className="read-form-label fs--14 font-semibold">Amount</div>
            <div className="read-form-value">{user && user.amount}</div>
          </div>

          <div className="read-form-input flex-1 mr--10 mt--15">
            <div className="read-form-label fs--14 font-semibold">
              Number of Prescription
            </div>
            <div className="read-form-value">
              {user &&
              user.eprescriptions_count &&
              user.eprescriptions_count &&
              user.eprescriptions_count.total_eprescription !== undefined
                ? user.eprescriptions_count.total_eprescription
                : "No Data"}
            </div>
          </div>
          <div className="read-form-input flex-1 mr--10 mt--15">
            <div className="read-form-label fs--14 font-semibold">
              Number of Lab Test
            </div>
            <div className="read-form-value">
              {user &&
              user.eprescription_labs_count &&
              user.eprescription_labs_count &&
              user.eprescription_labs_count.total_eprescription_lab !==
                undefined
                ? user.eprescription_labs_count.total_eprescription_lab
                : "No Data"}
            </div>
          </div>
          <div className="read-form-input flex-1 mr--10 mt--15">
            <div className="read-form-label fs--14 font-semibold">
              Reference Id
            </div>
            <div className="read-form-value">
              {user && user.ref_id !== undefined ? user.ref_id : "No Data"}
            </div>
          </div>
          <div className="read-form-input flex-1 mr--10 mt--15">
            <div className="read-form-label fs--14 font-semibold">
              Transaction Id
            </div>
            <div className="read-form-value">
              {user && user.transaction !== undefined
                ? user.transaction.reference_id
                : "No Data"}
            </div>
          </div>
          <div className="read-form-label fs--14 font-semibold">
            Booking status
          </div>
          {user && user.status === "pending" ? (
            <Col span={24} xs={24} md={24}>
              <Form.Item>
                  <Select
                    onChange={(value, other) => {
                      setBookingid(other.props.name);
                      setgroup(value);
                      showConfirm(value, other.props.name, other.props.id);
                    }}
                    value={user && user.status}
                  >
                    <Option
                      name={user && user.uuid}
                      id={user}
                      value="Reject"
                    >
                      Reject
                    </Option>
                  </Select>
              </Form.Item>
            </Col>
          ) : (
            <Col span={24} xs={24} md={24}>
              <Form.Item>
                {user && user.status == "booked" ? (
                  <Select
                    onChange={(value, other) => {
                      setBookingid(other.props.name);
                      setgroup(value);
                      showConfirm(value, other.props.name, other.props.id);
                    }}
                    value={user && user.status}
                  >
                    <Option
                      name={user && user.uuid}
                      id={user}
                      value="Cancelled"
                    >
                      Cancelled
                    </Option>
                    <Option
                      id={user}
                      name={user && user.uuid}
                      value="Rescheduled"
                    >
                      Rescheduled
                    </Option>
                  </Select>
                ) : (
                  <Select
                    disabled={true}
                    onChange={(value, other) => {
                      setBookingid(other.props.name);
                      setgroup(value);
                      showConfirm(value, other.props.name, other.props.id);
                    }}
                    value={user && user.status}
                  ></Select>
                )}
              </Form.Item>
            </Col>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default ViewAppointments;
