import React, { useEffect, useState } from "react";
import axios from "axios";
import "./styles.css";
import { useSelector, useDispatch } from "react-redux";
import actions from "actions";
import { Card, Modal, Select, Spin, DatePicker, notification } from "antd";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import { AreaChartOutlined } from "@ant-design/icons";
import moment from "moment";

const { doctorActions } = actions;
const { Option } = Select;
const { RangePicker } = DatePicker;

function DoctorLayout() {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth.accessToken);
  const doctor = useSelector((state) => state.doctors.doctors);
  const [searchDoctor, setSearchDoctor] = useState("");
  const [types, setTypes] = useState("all");
  const [apiData, setApiData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [chartData, setChartData] = useState({});
  const [openChart, setOpenChart] = useState(false);
  const [modelHead, setModelHead] = useState("");
  const [pagenum, setPagenum] = useState(0)
  const api = process.env.REACT_APP_WEB_APP;
  const [date, setDate] = useState([
    "2020-01-01",
    moment().format("YYYY-MM-DD"),
  ]);

  useEffect(() => {
    dispatch (doctorActions.getDoctor(pagenum));
   }, []);

  const apiFetch = async () => {
    setFetching(true);
    await axios
      .get(
        api +
          `/booking/getCountDetailsDocBasAppointments?doctor_uuid=${searchDoctor}&type=${types}&start_date=${moment(
            date[0]
          ).format("YYYY-MM-DD")}&end_date=${moment(date[1]).format(
            "YYYY-MM-DD"
          )}`,
        {
          headers: { "x-auth-token": auth },
        }
      )
      .then((res) => {
        setApiData([res.data]);
        console.log(res.data);
        setFetching(false);
      });
  };

  useEffect(() => {
    apiFetch();
  }, [searchDoctor, types, date]);
  const handleChangeDoc = (val) => {
    if (val === undefined) {
      setTypes("all");
    } else {
      let data = val.key;
      setSearchDoctor(data);
      setTypes("doctor");
    }
  };
  const colors = [
    "rgba(255, 99, 132)",
    "rgba(54, 162, 235)",
    "rgba(255, 206, 86)",
    "rgba(75, 192, 192)",
    "rgba(153, 102, 255)",
    "rgba(255, 159, 64)",
    "rgba(255, 120, 64)",
    "rgba(155, 113, 64)",
    "rgba(0, 0, 64)",
  ];
  const chartView = async (type) => {
    setOpenChart(true);
    setModelHead(type);
    let keyss = [];
    let valuess = [];
    if (type === "Slots Overview") {
      await axios
        .get(
          api +
            `/clinic/slotCountForAdminIndivi?type=${types}&doctor_uuid=${searchDoctor}&start_date=${moment(
              date[0]
            ).format("YYYY-MM-DD")}&end_date=${moment(date[1]).format(
              "YYYY-MM-DD"
            )}`,
          {
            headers: { "x-auth-token": auth },
          }
        )
        .then((res) => {
          for (const Obj of Object.keys(res.data)) {
            keyss.push(Obj);
          }
          for (const Obj of Object.values(res.data)) {
            valuess.push(Obj);
          }
          setChartData({
            labels: keyss,
            datasets: [
              {
                label: "Total",
                data: valuess,
                backgroundColor: colors,
                borderWidth: 1,
              },
            ],
          });
        });
    } else if (type === "Prescription") {
      await axios
        .get(
          api +
            `/ep/getEpAndEplCountsDoctor/${types}?doctor_uuid=${searchDoctor}&start_date=${moment(
              date[0]
            ).format("YYYY-MM-DD")}&end_date=${moment(date[1]).format(
              "YYYY-MM-DD"
            )}`,
          {
            headers: { "x-auth-token": auth },
          }
        )
        .then((res) => {
          for (const Obj of Object.keys(res.data)) {
            keyss.push(Obj);
          }
          for (const Obj of Object.values(res.data)) {
            valuess.push(Obj);
          }
          setChartData({
            labels: keyss,
            datasets: [
              {
                label: "Total",
                data: valuess,
                backgroundColor: colors,
                borderWidth: 1,
              },
            ],
          });
        });
    } else if (type === "Earned Overview") {
      await axios
        .get(
          api +
            `/booking/getTheIncomeDetails?type=${types}&doctor_uuid=${searchDoctor}&start_date=${moment(
              date[0]
            ).format("YYYY-MM-DD")}&end_date=${moment(date[1]).format(
              "YYYY-MM-DD"
            )}`,
          {
            headers: { "x-auth-token": auth },
          }
        )
        .then((res) => {
          for (const Obj of Object.keys(res.data)) {
            keyss.push(Obj);
          }
          for (const Obj of Object.values(res.data)) {
            valuess.push(Obj);
          }
          setChartData({
            labels: keyss,
            datasets: [
              {
                label: "Total",
                data: valuess,
                backgroundColor: colors,
                borderWidth: 1,
              },
            ],
          });
        });
    } else if (type === "Appointment Type Overview") {
      await axios
        .get(
          api +
            `/booking/getCountDetails?type=${types}&doctor_uuid=${searchDoctor}&start_date=${moment(
              date[0]
            ).format("YYYY-MM-DD")}&end_date=${moment(date[1]).format(
              "YYYY-MM-DD"
            )}`,
          {
            headers: { "x-auth-token": auth },
          }
        )
        .then((res) => {
          for (const Obj of Object.keys(res.data)) {
            keyss.push(Obj);
          }
          for (const Obj of Object.values(res.data)) {
            valuess.push(Obj);
          }
          setChartData({
            labels: keyss,
            datasets: [
              {
                label: "Total",
                data: valuess,
                backgroundColor: colors,
                borderWidth: 1,
              },
            ],
          });
        });
    } else if (type === "Appoinment Status Overview") {
      await axios
        .get(
          api +
            `/booking/getAptDetailsBasStatus?type=${types}&doctor_uuid=${searchDoctor}&start_date=${moment(
              date[0]
            ).format("YYYY-MM-DD")}&end_date=${moment(date[1]).format(
              "YYYY-MM-DD"
            )}`,
          {
            headers: { "x-auth-token": auth },
          }
        )
        .then((res) => {
          for (const Obj of Object.keys(res.data)) {
            keyss.push(Obj);
          }
          for (const Obj of Object.values(res.data)) {
            valuess.push(Obj);
          }
          setChartData({
            labels: keyss,
            datasets: [
              {
                label: "Total",
                data: valuess,
                backgroundColor: colors,
                borderWidth: 1,
              },
            ],
          });
        });
    }
  };

  const scrollFun = () => {
    setPagenum(pagenum+1)
    dispatch(doctorActions.getDoctor(pagenum+1));
    // dispatch(patientActions.getPatients(pagenum+1, '', 10))
  }
  const consumerChange = (event) => {
    // setPagenum(pagenum+1)
    console.log("search", event)
    dispatch(doctorActions.getDoctor('', event));
    // dispatch(patientActions.getPatients(pagenum+1, '', 10))
  }
  return (
    <div>
      <div className="homeContainer">
        <div style={{ display: "flex" }}>
          <Select
            placeholder="Doctor Name"
            size="large"
            style={{ width: 400, marginRight: 10 }}
            showSearch
            onSearch={(event) => { consumerChange(event) }}
            autoClearSearchValue
            allowClear
            optionFilterProp="children"
            labelInValue
            onPopupScroll={scrollFun}
            onChange={handleChangeDoc}
          >
            {doctor && doctor.data && doctor.length !== 0
              ? doctor && doctor.data && doctor.data.map((option, i) => (
                  <Option key={option._id} value={option.uuid}>
                    {option.full_name}
                  </Option>
                ))
              : ""}
          </Select>
          <RangePicker
            onChange={(val) => {
              if (val === null) {
                setDate(["2020-01-01", moment().format("YYYY-MM-DD")]);
              } else if (
                moment(val[0]).format("YYYY-MM-DD") >
                moment().format("YYYY-MM-DD")
              ) {
                notification.open({
                  message: "you selected future Date, Please Select vaild date",
                  duration: 2,
                });
              } else {
                setDate(val);
              }
            }}
          />
        </div>
        {apiData.length !== 0 && apiData[0].status === undefined ? (
          <>
            <Spin size="middle" spinning={fetching}>
              <div className="headConatiner">
                <Card className="cardContainer">
                  <h2 className="HeadContent">
                    Appointment Type{" "}
                    <button
                      className="chartIcon"
                      onClick={() => {
                        chartView("Appointment Type Overview");
                      }}
                    >
                      <AreaChartOutlined />{" "}
                    </button>
                  </h2>
                  <p className="bodyContent">
                    Total Booking{" "}
                    <span className="numbers">
                      {apiData[0].total_appointments}
                    </span>
                  </p>
                  <p className="bodyContent">
                    No of Free Bookings{" "}
                    <span className="numbers">{apiData[0].total_free}</span>
                  </p>
                  <p className="bodyContent">
                    No of SADN{" "}
                    <span className="numbers">{apiData[0].total_sadn}</span>
                  </p>
                  <p className="bodyContent">
                    No of Find A Doc{" "}
                    <span className="numbers">
                      {apiData[0].total_find_a_doctor}
                    </span>
                  </p>
                  <p className="bodyContent">
                    No of International{" "}
                    <span className="numbers">
                      {apiData[0].total_international}
                    </span>
                  </p>
                </Card>
                <Card className="cardContainer">
                  <h2 className="HeadContent">
                    Appoinment Status
                    <button
                      className="chartIcon"
                      onClick={() => {
                        chartView("Appoinment Status Overview");
                      }}
                    >
                      <AreaChartOutlined />{" "}
                    </button>
                  </h2>
                  <p className="bodyContent">
                    Booked{" "}
                    <span className="numbers">
                      {apiData[0].total_booked_appointments}
                    </span>
                  </p>
                  <p className="bodyContent">
                    Closed{" "}
                    <span className="numbers">
                      {apiData[0].total_closed_appointments}
                    </span>
                  </p>
                  <p className="bodyContent">
                    Missed{" "}
                    <span className="numbers">
                      {apiData[0].total_missed_appointments}
                    </span>
                  </p>
                  <p className="bodyContent">
                    Reschedule{" "}
                    <span className="numbers">
                      {apiData[0].total_rescheduled_appointments}
                    </span>
                  </p>
                  <p className="bodyContent">
                    Followup{" "}
                    <span className="numbers">
                      {apiData[0].total_followup_appointments}
                    </span>
                  </p>
                  <p className="bodyContent">
                    Followup Completed{" "}
                    <span className="numbers">
                      {apiData[0].total_followup_C_appointments}
                    </span>
                  </p>
                  <p className="bodyContent">
                    Extended{" "}
                    <span className="numbers">
                      {apiData[0].total_extended_appointments}
                    </span>
                  </p>
                  <p className="bodyContent">
                    Extension Completed{" "}
                    <span className="numbers">
                      {apiData[0].total_extended_C_appointments}
                    </span>
                  </p>
                  <p className="bodyContent">
                    Cancelled{" "}
                    <span className="numbers">
                      {apiData[0].total_cancelled_appointments}
                    </span>
                  </p>
                </Card>
                <Card className="cardContainer">
                  <h2 className="HeadContent">
                    Slots Overview
                    <button
                      className="chartIcon"
                      onClick={() => {
                        chartView("Slots Overview");
                      }}
                    >
                      <AreaChartOutlined />{" "}
                    </button>
                  </h2>
                  <p className="bodyContent">
                    Total Slots{" "}
                    <span className="numbers">{apiData[0].total_slots}</span>
                  </p>
                  <p className="bodyContent">
                    Booked Slots{" "}
                    <span className="numbers">
                      {apiData[0].total_booked_slot}
                    </span>
                  </p>
                  <p className="bodyContent">
                    Slots Available{" "}
                    <span className="numbers">
                      {apiData[0].total_available_slot}
                    </span>
                  </p>

                  {/* <p className="bodyContent">
                    Today Slots{" "}
                    <span className="numbers">
                      {apiData[0].total_avl_slot_from_today}
                    </span>
                  </p> */}
                </Card>
                <Card className="cardContainer">
                  <h2 className="HeadContent">
                    E Prescription
                    <button
                      className="chartIcon"
                      onClick={() => {
                        chartView("Prescription");
                      }}
                    >
                      <AreaChartOutlined />{" "}
                    </button>
                  </h2>
                  <p className="bodyContent">
                    E Prescription{" "}
                    <span className="numbers">
                      {apiData[0].total_eprescription}
                    </span>
                  </p>
                  <p className="bodyContent">
                    Lab Test generated{" "}
                    <span className="numbers">
                      {apiData[0].total_epresc_lab}
                    </span>
                  </p>
                </Card>
                <Card className="cardContainer">
                  <h2 className="HeadContent">
                    Earning Overview
                    <button
                      className="chartIcon"
                      onClick={() => {
                        chartView("Earned Overview");
                      }}
                    >
                      <AreaChartOutlined />{" "}
                    </button>
                  </h2>

                  <p className="bodyContent">
                    Earned By Appointment{" "}
                    <span className="numbers">{apiData[0].total_amount}</span>
                  </p>
                </Card>
              </div>
            </Spin>
          </>
        ) : (
          <>
            <Spin size="middle" spinning={fetching}>
              <Card className="cardContainer">
                <h2 className="HeadContent">No Records </h2>
                <p className="bodyContent">No Data Found</p>
              </Card>
            </Spin>
          </>
        )}
      </div>
      <Modal
        title={modelHead}
        width={600}
        visible={openChart}
        onOk={() => {
          setOpenChart(false);
        }}
        onCancel={() => {
          setOpenChart(false);
        }}
      >
        <Pie
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: true,
            indexAxis: "X",
            plugins: {
              legend: {
                position: "right",
              },
            },
          }}
        />
      </Modal>
    </div>
  );
}

export default DoctorLayout;
