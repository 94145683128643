const patientActions = {
  GET_PATIENTS: "GET_PATIENTS",
  GET_PATIENTS_SUCCESS: "GET_PATIENTS_SUCCESS",
  GET_CLONE_PATIENTS: "GET_CLONE_PATIENTS",
  GET__CLONE_PATIENTS_SUCCESS: "GET__CLONE_PATIENTS_SUCCESS",
  GET_HISTRO_PATIENTS: "GET_HISTRO_PATIENTS",
  GET_HISTRO_PATIENTS_SUCCESS: "GET_HISTRO_PATIENTS_SUCCESS",
  
  getPatients: (pagenum, searchQuery, limit) => {
    return {
      type: patientActions.GET_PATIENTS,
      pagenum,
      searchQuery,
      limit
    };
  },
  getclonePatients: (params) => {
    console.log("GET_ClonePATS",params)
    return {
      type: patientActions.GET_CLONE_PATIENTS,
      params
    };
  },
  getHistroricaldocumentdetail:(params) =>{
    // console.log("GET_HISTRO_PATIENTS action",params)
    return {
      type: patientActions.GET_HISTRO_PATIENTS,
      params
    };


  }
};

export default patientActions;
