import actions from 'actions';

const initState = {
    transactions: [],
    loader: false
};

export default function rootReducer(state = initState, action) {
    switch (action.type) {
        case actions.transactionActions.GET_TRANSACTION:
            return {
                ...state,
                loader: true
            };
        case actions.transactionActions.GET_TRANSACTION_SUCCESS:
            return {
                ...state,
                loader: false,
                transactions: action.data
            };
        default:
            return state;
    }
}
