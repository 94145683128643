import actions from 'actions';

const initState = {
    consumers: [],
    loader: false
};

export default function rootReducer(state = initState, action) {
    switch (action.type) {
        case actions.consumerActions.GET_CONSUMERS:
            return {
                ...state,
                loader: true
            };
        case actions.consumerActions.GET_CONSUMERS_SUCCESS:
            return {
                ...state,
                loader: false,
                consumers: action.data
            };
        default:
            return state;
    }
}
