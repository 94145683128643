import Orderview from 'views/order/Order'
import PartnerPage from 'views/partners/PartnerPage';
import InfluencerPage from 'views/partners/InfluencerPage'
import Partners_name from "views/Partners_name/PartnersName";
import City_name from "views/City_name/CityName";
import Area_name from "views/Area_name/AreaName";
import National_pathalogy from "views/National_Pathalogy/NationalPathalogy"
import National_Radiology from "views/National_Radiology/NationalRadiology"
// import Master_Pathalogy from 'views/Master_Pathalogy/MasterPathalogy'
import Master_Pathalogy from './../views/Master_Pathalogy/MasterPathalogy'

const dmpRoutes = [
    { path: '/partners', component: PartnerPage },
    { path: '/order', component: Orderview },
    { path: '/influencer' , component: InfluencerPage},
    { path: '/PartnersName', component: Partners_name },
    { path: '/CityName', component: City_name },
    { path: '/AreaName', component: Area_name },
    { path: '/NationalPathalogy', component: National_pathalogy },
    { path: '/NationalRadiology', component: National_Radiology },
    { path: '/Masterpathelogy', component: Master_Pathalogy },

];

export default dmpRoutes;
