import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from 'actions';
import { getPartnersName } from 'services/masters-services';
import {
  getCityName,
  editePartner,
  getAreaName,
  editeCity,
  editeAreaName,
  addPartner,
  addCityName,
  addAreaName,
  addMasterRadiology,
  getMasterRadiology,
  editeMasterRadiology,
  editeMasterPathalogy,
  getMasterPathalogy,
  getNationalRadiology,
  getNationalPathalogy,
  editePathalogy,
  editeRadiology,
  addRadiology,
  addPathalogy,
  addMasterPathalogy,
  getInfluencerData,
  getPharmacyNewData,
  getIntroducerData,
  getCompanyDataName,
  addCompany,
  editeCompany,
  addMasterHomeSample,
  getMasterHomeSample,
  editeMasterHomeSample
}
  from 'services/masters-services';

import { notification } from 'antd'
const { master, authActions } = actions;



export function*  getPartnerNameSaga() {
  yield takeEvery(master.GET_PARTNERS_NAME, function*({searchData}) {
    try{
      const token = yield select(state => state.auth.accessToken);
      const response = yield getPartnersName({token},searchData);
        const { data } = response;
        yield put({
          type: master.GET_PARTNERS_SUCCESS,
          data
        });
    }catch(error){
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* getCompanyDataSaga() {
  yield takeEvery(master.GET_COMPANY_DATA, function* () {
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield getCompanyDataName({ token });
      const { data } = response;
      yield put({
        type: master.GET_COMPANY_DATA_SUCCESS,
        data
      });
    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function*  getCityNameSaga() {
  
  yield takeEvery(master.GET_CITY_NAME, function*({searchData}) {
    console.log(master.GET_CITY_NAME,"master.GET_CITY_NAME")
    try{
      const token = yield select(state => state.auth.accessToken);
      const response = yield getCityName({token},searchData);
        const { data } = response;
        yield put({
          type: master.GET_CITY_SUCCESS,
          data
        });
    }catch(error){
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* getAreaNameSaga() {
  console.log("kdsj")
  yield takeEvery(master.GET_AREA_NAME, function*({searchData}) {

    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield getAreaName({token},searchData);
      
        const { data } = response;
        yield put({
          type: master.GET_AREA_SUCCESS,
          data
        });
    }catch(error){
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}




export function* editePartnerSaga() {
  yield takeEvery(master.EDITE_PARTNER, function* ({ payLoad }) {
    console.log(payLoad, "pay")
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield editePartner({ token }, payLoad);
      const { data } = response;
      yield put({
        type: master.EDITE_PARTNER_SUCCESS,
        data
      });
      yield notification.open({ message: 'Success!', description: 'updated succesfully' })
    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* editeCompanySaga() {
  yield takeEvery(master.EDITE_COMPANY, function* ({ payLoad }) {
    console.log(payLoad, "pay")
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield editeCompany({ token }, payLoad);
      const { data } = response;
      yield put({
        type: master.EDITE_COMPANY_SUCCESS,
        data
      });
      yield notification.open({ message: 'Success!', description: 'updated succesfully' })
      setTimeout(function () {
        window.location.reload();
      }, 300);
    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}



export function* editeCitySaga() {
  yield takeEvery(master.EDITE_CITY, function* ({ payLoad }) {
    console.log(payLoad, "pay")
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield editeCity({ token }, payLoad);
      const { data } = response;
      yield put({
        type: master.EDITE_CITY_SUCCESS,
        data
      });
      yield notification.open({ message: 'Success!', description: 'updated succesfully' })
    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* editeAreaNameSaga() {
  console.log("1234")
  yield takeEvery(master.EDITE_AREA_NAME, function* ({ payLoad }) {
    console.log(payLoad, "pay")
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield editeAreaName({ token }, payLoad);
      const { data } = response;
      yield put({
        type: master.EDITE_AREA_SUCCESS,
        data
      });
      yield notification.open({ message: 'Success!', description: 'updated succesfully' })
    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}


export function* addPartnerSaga() {
  console.log('saga');
  yield takeEvery(master.ADD_PARTNER1, function* ({ data }) {
    try {
      console.log(data, "data")
      const token = yield select(state => state.auth.accessToken);
      console.log(token);
      const response = yield addPartner({ token }, data);
      if (response.status === 200) {
        yield notification.open({
          message: 'Success!',
          description: 'Partner Added Successfully!'
        });
        yield put({
          type: master.GET_PARTNERS_NAME
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
      else {
        throw response;
      }
    } catch (error) {
      console.log(error.response, "error");
      yield notification.open({
        message: 'Failed!',
        description: error.response.data
      });
      setTimeout(function () {
        window.location.reload();
      }, 1500);
      // yield put({ type: userActions.ON_ERROR });
      // if (error.response.status === 401) {
      //   yield put({ type: authActions.AUTH_ERROR });
      // }
    }
  });
}

export function* addCompanySaga() {
  console.log('saga123');
  yield takeEvery(master.ADD_COMPANY, function* ({ data }) {
    try {
      console.log(data, "data")
      const token = yield select(state => state.auth.accessToken);
      console.log(token);
      const response = yield addCompany({ token }, data);
      if (response.status === 200) {
        yield notification.open({
          message: 'Success!',
          description: 'Company Added Successfully!'
        });
        yield put({
          type: master.GET_PARTNERS_NAME
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
      else {
        throw response;
      }
    } catch (error) {
      console.log(error.response, "error");
      yield notification.open({
        message: 'Failed!',
        description: error.response.data
      });
      setTimeout(function () {
        window.location.reload();
      }, 1500);
      // yield put({ type: userActions.ON_ERROR });
      // if (error.response.status === 401) {
      //   yield put({ type: authActions.AUTH_ERROR });
      // }
    }
  });
}


export function* addCityNameSaga() {
  console.log('saga');
  yield takeEvery(master.ADD_CITY_NAME, function* ({ data }) {
    try {
      console.log(data, "data")
      const token = yield select(state => state.auth.accessToken);
      console.log(token);
      const response = yield addCityName({ token }, data);
      if (response.status === 200) {
        yield notification.open({
          message: 'Success!',
          description: 'City Added Successfully!'
        });
        yield put({
          type: master.GET_CITY_NAME
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
      else {
        throw response;
      }
    } catch (error) {
      console.log(error.response, "error");
      yield notification.open({
        message: 'Failed!',
        description: error.response.data
      });
      setTimeout(function () {
        window.location.reload();
      }, 1500);
      // yield put({ type: userActions.ON_ERROR });
      // if (error.response.status === 401) {
      //   yield put({ type: authActions.AUTH_ERROR });
      // }
    }
  });
}

export function* addAreaNameSaga() {
  console.log('saga1234');
  yield takeEvery(master.ADD_AREA_NAME, function* ({ data }) {
    try {
      console.log(data, "data")
      const token = yield select(state => state.auth.accessToken);
      console.log(token);
      const response = yield addAreaName({ token }, data);
      if (response.status === 200) {
        yield notification.open({
          message: 'Success!',
          description: 'Area Added Successfully!'
        });
        yield put({
          type: master.GET_AREA_NAME
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
      else {
        throw response;
      }
    } catch (error) {
      console.log(error.response, "error");
      yield notification.open({
        message: 'Failed!',
        description: error.response.data
      });
      setTimeout(function () {
        window.location.reload();
      }, 1500);
      // yield put({ type: userActions.ON_ERROR });
      // if (error.response.status === 401) {
      //   yield put({ type: authActions.AUTH_ERROR });
      // }
    }
  });
}


export function* getNationalPathalogySaga() {
  yield takeEvery(master.GET_NATIONAL_PATHALOGY, function* ({ page,pageSiz, searchData }) {
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield getNationalPathalogy({ token }, page, pageSiz,searchData);
      const { data, } = response;
      console.log(data.count, "data123")
      yield put({
        type: master.GET_NATIONAL_PATHALOGY_SUCCESS,
        data,
        count: data.count,
      });
    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* getMasterPathalogySaga() {
  yield takeEvery(master.GET_MASTER_PATHALOGY, function* ({ page,pageSize, searchData ,partnerData,TestNameData ,areaData}) {
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield getMasterPathalogy({ token }, page,pageSize,searchData,partnerData,TestNameData,areaData);
      const { data, } = response;
      console.log(data.count, "data123")
      yield put({
        type: master.GET_MASTER_PATHALOGY_SUCCESS,
        data,
        count: data.count,
      });
    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* getMasterhHomeSampleSaga() {
  yield takeEvery(master.GET_MASTER_HOMESAMPLE, function* ({ page,pageSize, searchData ,partnerData,TestNameData ,areaData}) {
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield getMasterHomeSample({ token }, page,pageSize,searchData,partnerData,TestNameData,areaData);
      const { data, } = response;
      console.log(data.count, "data123")
      yield put({
        type: master.GET_MASTER_HOMESAMPLE_SUCCESS,
        data,
        count: data.count,
      });
    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* getNationalRadiologySaga() {
  yield takeEvery(master.GET_NATIONAL_RADIOLOGY, function* ({ pagenum,pageSize, searchDataradio }) {
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield getNationalRadiology({ token }, pagenum,pageSize, searchDataradio);
      const { data } = response;
      console.log(data, "data123")
      if (response.status == 200) {
        yield put({
          type: master.GET_NATIONAL_RADIOLOGY_SUCESS,
          NationalRadiology: data.data,
          NationalRadiologyCount: data.count,
        });
      }

    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}


export function* editePathalogySaga() {
  yield takeEvery(master.EDITE_NATIONAL_PATHALOGY, function* ({ payLoad }) {
    console.log(payLoad, "pay")
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield editePathalogy({ token }, payLoad);
      const { data } = response;
      yield put({
        type: master.EDITE_NATIONAL_PATHALOGY_SUCCESS,
        data
      });
      yield notification.open({ message: 'Success!', description: 'updated succesfully' })
    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* editeMasterPathalogySaga() {
  yield takeEvery(master.EDITE_MASTER_PATHALOGY, function* ({ uuid, uuid1, payLoad }) {
    console.log(payLoad, "pay")
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield editeMasterPathalogy({ token }, uuid, uuid1, payLoad);
      const { data } = response;
      yield put({
        type: master.EDITE_MASTER_PATHALOGY_SUCCESS,
        data
      });
      yield notification.open({ message: 'Success!', description: 'updated succesfully' })
    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* editeMasterHomeSampleSaga() {
  yield takeEvery(master.EDITE_MASTER_HOMESAMPLE, function* ({ uuid, payLoad }) {
    console.log(payLoad, "pay")
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield editeMasterHomeSample({ token }, uuid, payLoad);
      const { data } = response;
      yield put({
        type: master.EDITE_MASTER_HOMESAMPLE_SUCCESS,
        data
      });
      yield notification.open({ message: 'Success!', description: 'updated succesfully' })
    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

console.log("gggy")
export function* editeRadiologySaga() {

  yield takeEvery(master.EDITE_NATIONAL_RADIOLOGY, function* ({ payload }) {
    console.log(payload, "pay")
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield editeRadiology({ token }, payload);
      const { data } = response;
      yield put({
        type: master.EDITE_NATIONAL_RADIOLOGY_SUCCESS,
        data
      });
      yield notification.open({ message: 'Success!', description: 'updated succesfully' })
    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}




export function* addPathalogySaga() {
  console.log('saga');
  yield takeEvery(master.ADD_NATIONAL_PATHALOGY, function* ({ data }) {
    try {
      console.log(data, "data")
      const token = yield select(state => state.auth.accessToken);
      console.log(token);
      const response = yield addPathalogy({ token }, data);
      if (response.status === 200) {
        yield notification.open({
          message: 'Success!',
          description: 'Pathology Added Successfully!'
        });
        yield put({
          type: master.GET_NATIONAL_PATHALOGY
        });
      }
      else {
        throw response;
      }
    } catch (error) {
      console.log(error.response, "error");
      yield notification.open({
        message: 'Failed!',
        description: error.response.data
      });
    }
  });
}

export function* addMasterPathalogySaga() {
  console.log('saga');
  yield takeEvery(master.ADD_MASTER_PATHALOGY, function* ({ data }) {
    try {
      console.log(data, "data")
      const token = yield select(state => state.auth.accessToken);
      console.log(token);
      const response = yield addMasterPathalogy({ token }, data);
      if (response.status === 200) {
        yield notification.open({
          message: 'Success!',
          description: 'Master Pathology Added Successfully!'
        });
        yield put({
          type: master.GET_MASTER_PATHALOGY
        });
      }
      else {
        throw response;
      }
    } catch (error) {
      console.log(error.response, "error");
      yield notification.open({
        message: 'Failed!',
        description: error.response.data
      });
    }
  });
}

export function* addHomeSampleCollectionSaga() {
  console.log('saga');
  yield takeEvery(master.ADD_MASTER_HOME_SAMPLE_COLLECTION, function* ({ data }) {
    try {
      console.log(data, "data")
      const token = yield select(state => state.auth.accessToken);
      console.log(token);
      const response = yield addMasterHomeSample({ token }, data);
      if (response.status === 200) {
        yield notification.open({
          message: 'Success!',
          description: 'Home Sample Collection Added Successfully!'
        });
        yield put({
          type: master.GET_MASTER_HOMESAMPLE
        });
      }
      else {
        throw response;
      }
    } catch (error) {
      console.log(error.response, "error");
      yield notification.open({
        message: 'Failed!',
        description: error.response.data
      });
    }
  });
}


//Radiology 
export function* addMasterRadiologySaga() {
  console.log('saga');
  yield takeEvery(master.ADD_MASTER_RADIOLOGY, function* ({ data }) {
    try {
      console.log(data, "data")
      const token = yield select(state => state.auth.accessToken);
      console.log(token);
      const response = yield addMasterRadiology({ token }, data);
      if (response.status === 200) {
        yield notification.open({
          message: 'Success!',
          description: 'Master Radiology Added Successfully!'
        });
        yield put({
          type: master.GET_MASTER_RADIOLOGY
        });
      }
      else {
        throw response;
      }
    } catch (error) {
      console.log(error.response, "error");
      yield notification.open({
        message: 'Failed!',
        description: error.response.data
      });
    }
  });
}

export function* getMasterRadiologySaga() {
  yield takeEvery(master.GET_MASTER_RADIOLOGY, function* ({ page, searchData,partnerData,TestNameData,areaData }) {
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield getMasterRadiology({ token }, page, searchData,partnerData,TestNameData,areaData);
      const { data, } = response;
      console.log(data.count, "data123")
      yield put({
        type: master.GET_MASTER_RADIOLOGY_SUCCESS,
        data,
        count: data.count,
      });
    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* editeMasterRadiologySaga() {
  yield takeEvery(master.EDITE_MASTER_RADIOLOGY, function* ({ uuid, uuid1, payLoad }) {
    console.log(payLoad, "pay")
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield editeMasterRadiology({ token }, uuid, uuid1, payLoad);
      const { data } = response;
      yield put({
        type: master.EDITE_MASTER_RADIOLOGY_SUCCESS,
        data
      });
      yield notification.open({ message: 'Success!', description: 'updated succesfully' })
    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

//Masterr Radiology End

export function* addRadiologySaga() {
  console.log('saga');
  yield takeEvery(master.ADD_NATIONAL_RADIOLOGY, function* ({ data }) {
    try {
      console.log(data, "data")
      const token = yield select(state => state.auth.accessToken);
      console.log(token);
      const response = yield addRadiology({ token }, data);
      if (response.status === 200) {
        yield notification.open({
          message: 'Success!',
          description: 'Radiology Added Successfully!'
        });
        yield put({
          type: master.GET_NATIONAL_RADIOLOGY
        });
        // setTimeout(function(){
        //   window.location.reload();
        // }, 3000);
      }
      else {
        throw response;
      }
    } catch (error) {
      console.log(error.response, "error");
      yield notification.open({
        message: 'Failed!',
        description: error.response.data
      });
      // setTimeout(function(){
      //   window.location.reload();
      // }, 1500);
      // yield put({ type: userActions.ON_ERROR });
      // if (error.response.status === 401) {
      //   yield put({ type: authActions.AUTH_ERROR });
      // }
    }
  });
}


export function* getInfluencerSaga() {
  yield takeEvery(master.GET_INFLUENCER_DATA, function* ({ page, searchData }) {
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield getInfluencerData({ token }, page, searchData);
      const { data } = response;
      yield put({
        type: master.GET_INFLUENCER_SUCCESS,
        data,
        count: data.count,
      });
    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* getPharmacyNewSaga() {
  yield takeEvery(master.GET_PHARMACY_NEW_DATA, function* ({ page, searchData }) {
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield getPharmacyNewData({ token }, page, searchData);
      const { data } = response;
      yield put({
        type: master.GET_PHARMACY_NEW_SUCCESS,
        data,
        count: data.count,
      });
    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* getIntroducerSaga() {
  yield takeEvery(master.GET_INTRODUCER_DATA, function* ({ page, searchData }) {
    try {
      const token = yield select(state => state.auth.accessToken);
      const response = yield getIntroducerData({ token }, page, searchData);
      const { data } = response;
      yield put({
        type: master.GET_INTRODUCER_SUCCESS,
        data,
        count: data.count,
      });
    } catch (error) {
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}



export default function* rootSaga() {
  yield all([fork(getPartnerNameSaga),
  fork(getCityNameSaga),
  fork(getAreaNameSaga),
  fork(editePartnerSaga),
  fork(editeCitySaga),
  fork(editeAreaNameSaga),
  fork(addPartnerSaga),
  fork(addCityNameSaga),
  fork(getMasterPathalogySaga),
  fork(addAreaNameSaga),
  fork(getNationalPathalogySaga),
  fork(editePathalogySaga),
  fork(addPathalogySaga),
  fork(getNationalRadiologySaga),
  fork(editeRadiologySaga),
  fork(addRadiologySaga),
  fork(addMasterPathalogySaga),
  fork(addHomeSampleCollectionSaga),
  fork(getInfluencerSaga),
  fork(getPharmacyNewSaga),
  fork(getIntroducerSaga),
  fork(getCompanyDataSaga),
  fork(addCompanySaga),
  fork(editeCompanySaga),
  fork(editeMasterPathalogySaga),
  fork(addMasterRadiologySaga),
  fork(getMasterRadiologySaga),
  fork(editeMasterRadiologySaga),
  fork(getMasterhHomeSampleSaga),
  fork(editeMasterHomeSampleSaga)

  ]);
}
