import React, { useEffect, useState } from 'react';
import { Table,Select, Button, Input } from 'antd';
import { view_log, green_dot, red_dot } from 'helper/constant';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'actions';
import moment from 'moment';
import { DatePicker } from 'antd';
import Form from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { Helmet } from 'react-helmet';

const {BookAppointments,GETPATIENTSBYCON,GETSLOTBYNAME,GETSLOTBYDOCTOR,POST_APPOINTMENT} = actions;

const BookAppointment = () => {
  const [medication, setMedication] = useState('');

  const [visible, setvisible] = useState(false);
  const [user, setuser] = useState(null);
  const [date,setDate] = useState(new Date());
  const [consumerId,setConsumerId]=useState('');
  const [PatinetId,setPatinetId]=useState('');
  const [Slottype,setSlotType]=useState('');
  const [sympt,setSympt]=useState('');
  const [DoctorID,setDoctorID]=useState('');
  const [SlotID,setSlotID]=useState('');
  const [amount,setAmount]=useState();
  const [Desc,setDesc]=useState();
  const [Addinfo,setAddInfo]=useState();
  const [reference,setReference]=useState();
  const [BookDate,setBookingDate]= useState();
  const [clinic,setClinic]=useState();
  const [pagenum, setPagenum] = useState(0);
  const [search, setSearch] = useState('');
  
  const [pid,setPostId]=useState('');

    const [type,setTypes]=useState(["free","sadn","nigerian","international"])
    const { Option } = Select;

  //   const consumerChange = (event) => {
  //     console.log(event)
  //     setConsumerName(event)
  //     axios.get(`${process.env.REACT_APP_WEB_APP}/user/all/?search=${event}`)
  //         .then((res) => {
  //             setCustomers(res.data)
  //         })
  //         .catch((err) => {
  //             console.log(err)
  //         })
  //     // setCustomers([])
  // }

    const symptomsData = [
      "Abdominal Pain",
      "Abscess",
      "Absence of Menstruation / Amenorrhea",
      "Acid Reflux / Heartburn",
      "Acne",
      "Allergic Cough",
      "Allergy Consultation",
      "Anaphylaxis / Allergy",
      "Anemia",
      "Angina",
      "Annual Check Up",
      "Anorexia",
      "Arm Pain",
      "Arm Problem(s)",
      "Arthritis",
      "Asthma",
      "Atrial Flutter",
      "Atrioventricular Septal Defect (AVSD)",
      "Back Pain",
      "Back Problems",
      "Barrett's Esophagus",
      "Basal Cell Carcinoma (BCC)",
      "Bedsores",
      "Bedwetting / Enuresis",
      "Bladder and Bowel Management",
      "Bleeding Disorder",
      "Blisters",
      "Blood Disorder",
      "Blood Pressure Testing",
      "Blood Work",
      "Blood in Stool",
      "Blood in Urine / Hematuria",
      "Body Ache / Pain",
      "Bowel and Bladder Management",
      "Bradycardia",
      "Breast Pain",
      "Breathing Problems",
      "Bronchitis",
      "Bronchoscopy",
      "Bruise / Contusion",
      "Celiac Disease",
      "Cellulitis",
      "Chest Pain",
      "Chicken Pox",
      "Chickenpox (Varicella) Vaccine",
      "Cholesterol / Lipids Checkup",
      "Cholesterol Management",
      "Chronic Cough",
      "Chronic Illness",
      "Chronic Obstructive Pulmonary Disease (COPD)",
      "Chronic Pain",
      "Cold",
      "Cold Sores / Herpes Labialis",
      "Colon Polyps",
      "Concussion",
      "Congenital Heart Disease",
      "Constipation",
      "Coronary Artery Disease",
      "Cough",
      "Crohn's Disease",
      "Daytime Sleepiness",
      "Dehydration",
      "Delirium",
      "Dengue",
      "Depression",
      "Diarrhea",
      "Diverticulitis / Diverticulosis",
      "Dizziness",
      "ECG / EKG",
      "Ear Infection",
      "Eczema",
      "Edema",
      "Electrocardiogram",
      "Esophageal Stricture",
      "Fainting / Syncope",
      "Fall",
      "Familial Adenomatous Polyposis (FAP)",
      "Fecal Occult Blood Test (FOBT)",
      "Fever",
      "Flu",
      "Flu Shot",
      "Food Allergy",
      "Food Intolerance",
      "Frailty",
      "Frequent Urination",
      "Gall Bladder Problem",
      "Gallstones",
      "Gastritis",
      "Gout",
      "HIV / AIDS",
      "HIV / AIDS Consultation",
      "HIV Pre-Exposure Prophylaxis (PrEP)",
      "Headache",
      "Hearing Problems / Ringing in Ears",
      "Hepatitis",
      "Hepatitis A Vaccine",
      "Hepatitis B",
      "Hepatitis B Vaccine",
      "Hepatitis C",
      "High Blood Pressure / Hypertension",
      "High Cholesterol / Lipid Problem",
      "Hyperlipidemia",
      "Hyperparathyroidism",
      "Hyperthyroidism / Overactive Thyroid",
      "Hypopituitarism",
      "Impaired Gait",
      "Incontinence",
      "Increased Salivation",
      "Infection Follow Up",
      "Inflammatory Bowel Disease",
      "Irritable Bowel Syndrome",
      "Itching",
      "Joint Injection",
      "Joint Pain",
      "Joint Problem ",
      "Joint Stiffness",
      "Knee Pain",
      "Lactose Intolerance",
      "Leg Pain",
      "Low Blood Sugar / Hypoglycemia",
      "Lower Back Pain / Lumbago",
      "Lower Extremity Pain",
      "Lower Extremity Swelling",
      "Measles / Rubeola",
      "Menopause",
      "Menopause Follow Up",
      "Menstrual Problems",
      "Metabolic Syndrome",
      "Migraine",
      "Muscle Pain",
      "Muscle Strain",
      "Nausea and Vomiting",
      "Neck Pain",
      "New Patient Visit",
      "Nose Bleed / Epistaxis",
      "Nutrition Issues",
      "Pain in Extremities",
      "Panic Attack",
      "Patient History",
      "Pelvic Pain",
      "Plantar Fasciitis",
      "Pneumococcal Vaccine",
      "Pneumonia",
      "Polycystic Ovary Syndrome / Metabolic Syndrome",
      "Polymyalgia Rheumatica",
      "Post-Menopausal Bleeding",
      "Pre-Surgery Checkup / Pre-Surgical Clearance",
      "Pre-Travel Checkup",
      "Pre-Travel Consultation",
      "Pre-Travel Follow Up",
      "Preventive Medicine Consultation",
      "Primary Care Consultation",
      "Primary Pulmonary Hypertension",
      "Quitting Smoking / Smoking Cessation",
      "Rash",
      "Screening for Disease / Preventive Medicine",
      "Severe Infection",
      "Sexual Dysfunction / Painful Sex / Dyspareunia",
      "Sexually Transmitted Disease (STD)",
      "Sexually Transmitted Infection",
      "Shingles",
      "Shortness of Breath / Difficulty in Breathing",
      "Shoulder Pain",
      "Sinus Problems / Sinusitis",
      "Sleep Problems",
      "Sore Throat",
      "Stiffness",
      "Stomach Pain",
      "Stress",
      "Swelling",
      "Swelling in Legs",
      "Swelling in Neck",
      "Syphilis",
      "Tetanus Shot",
      "Throbbing",
      "Tingling / Numbness / Weakness",
      "Tiredness / Fatigue",
      "Travel Medicine Consultation",
      "Type 1 Diabetes",
      "Type 2 Diabetes",
      "Ulcerative Colitis",
      "Urinary Incontinence",
      "Urinary Tract Infection (UTI)",
      "Vaccine(s)",
      "Vaginal Discharge / Infection",
      "Vitamin B12 Deficiency",
      "Vitamin D Deficiency",
      "Wellness Care",
      "Wound Care",
      "Other"
      ]
      

  const dispatch = useDispatch();

  // const testState = useSelector(state=>console.log(state,'statebooking'))
  // const payload = useSelector(state=>state.consumer);
  const payload = useSelector(state=>state.bookingAppointment)
  const payloadPatient = useSelector(state=>state.getPatients)
  const payloadDoctor = useSelector(state=>state.allSlots)
  const payloadSlotByDoctor = useSelector(state=>state.allSlotsByDoctor)

  console.log("compa",Math.ceil(payload.allconsumers.count/10));
  // console.log("jhgygg",payloadPatient.allpatients)
  // console.log("dddaa",payloadDoctor.allSlots.data)
  // console.log("ddd2",payloadSlotByDoctor.allSlotsByDoctor)
  
  // useEffect(()=>{
  //   dispatch(consumerActions.getConsumers({
  //     search:''
  //   }))  
  // },[date])

  //get all consumers

  useEffect(()=>{
    // if(Math.ceil(payload.allconsumers.count/10) > pagenum){
    //   dispatch(BookAppointments.getAppointment(pagenum, search));
    // }
    dispatch(BookAppointments.getAppointment(pagenum, search))
  },[])


  //get all patients by consumers
  useEffect(()=>{
    console.log(consumerId,'check')
    dispatch(GETPATIENTSBYCON.getPatientsByConsumer(consumerId))
  },[consumerId])


//get slot by type 


useEffect(()=>{
  // console.log(Slottype,'checkslot')
  dispatch(GETSLOTBYNAME.getSlots())
},[])

//get slot by doctor

useEffect(()=>{
  // console.log(DoctorID,'checkDoctorId',Slottype )
  dispatch(GETSLOTBYDOCTOR.getSlotsDoctor(DoctorID,Slottype))
},[DoctorID, Slottype])



// const Appointments = (patientId,SlotID,DoctorID, Slottype,
//   amount,
//   Desc,
//   sympt) =>
  
//   (
//     console.log(patientId,SlotID,DoctorID, Slottype,
//     amount,
//     Desc,
//     sympt,'111111111')

   
// )


const Appointment = (patientId,DoctorID,SlotID,sympt,Desc,Slottype, amount, Addinfo,reference) => {
  var d = new Date(BookDate);
  var booking= d.toISOString();
  
  dispatch(
  POST_APPOINTMENT.postAppoint({
    "clinic_uuid":clinic,
    "patient_uuid": patientId,
        "doctor_uuid":DoctorID,
        "slot": SlotID,
        "booking_date":booking,
        "reasons":[sympt],
        "desc":Desc,
        "video":true,
        "category":"CG",
        "type":Slottype,
        "amount":0,
        "amount_value":amount,
        "addInfo":Addinfo,
        "admin":true,
        "rfid":reference
        // reference
              // booking_uuid: props.booking_uuid,
              // tests: data,
              // notes

            
          })

  )
  // console.log("rrytdfsy  fzfd yfdzg7")

    }
  

// const Appointment = (patientId,SlotID,DoctorID, Slottype,
//     amount,
//     Desc,
//     sympt) => {
//  var  data = {
//     patient_uuid: patientId,
//     doctor_uuid:DoctorID,
//     slot: SlotID,

//   };
//     }
const scrollFun = () => {
  if(Math.ceil(payload.allconsumers.count/10) > pagenum && payload.allconsumers.data.length >= 10){
    setPagenum(pagenum+1)
    dispatch(BookAppointments.getAppointment(pagenum+1, search));
  }
}
const consumerChange = (event) => {
  // setPagenum(pagenum+1)
  // console.log("search", event)
  setSearch(event)
  dispatch(BookAppointments.getAppointment(pagenum, event));
  // dispatch(patientActions.getPatients(pagenum+1, '', 10))
}

  return (
    <div>
      <Helmet>
        <title>Doctall Super Admin Book Appointment
</title>
<meta 
            name='description' 
            content='Book appoinments for patients when necessary. Select type of appointment, slot and doctor for the appointment
            '
         

   />
      </Helmet>
      {/* <Table
        columns={columns}
        dataSource={payload.consumers.consumers}
        loader={payload.loader}
        size='middle'
        pagination={{
          defaultPageSize: 10
        }}
      /> */}
      {/* <ViewConsumers
        visible={visible}
        loader={false}
        handleOk={() => setvisible(false)}
        handleCancel={() => setvisible(false)}
        user={user && user.patients}
      /> */}

<Form>
 <label> Consumer Mobile 
 </label><br/>

 <Select
                       placeholder="Consumer Mobile"
                        size="large"
                        style={{ width: 450, marginRight: 10 }}
                        showSearch
                        
                        onPopupScroll={scrollFun}
                        onSearch={(event) => { consumerChange(event) }}
                        autoClearSearchValue = {false}
                        optionFilterProp="children"
                        // labelInValue
                        onChange={e =>
                        {                   
                         const {value} = e;
                         console.log(e, 'check')
                            setConsumerId(e)
                        }}  
                    >
                        {
                           payload.allconsumers && payload.allconsumers.data && payload.allconsumers.data.map((option, i) => <Option key={option._id} value={option.uuid}>{option.full_name} &nbsp;&nbsp; {option.mobile}</Option>)
                        }
                    </Select><br/>

                    <br/>


<label> Patient Name</label><br/>
                    <Select
                       placeholder="patient name"
                        size="large"
                        style={{ width: 450, marginRight: 10 }}
                        showSearch
                        autoClearSearchValue
                        
                        optionFilterProp="children"
                        labelInValue
                                   
                        onChange={(e)=>{
                          const {value} = e;

                            setPatinetId(value)


                            // console.log("consumeruuid",value)


                      }} 
                    >

{
                            payloadPatient.allpatients  && payloadPatient.allpatients.map((option, i) => <Option key={option._id} value={option.uuid}>{option.full_name}</Option>)
                        }

                    </Select><br/>


                    <br/>


         <label> Type of appointment</label><br/>
                    <Select
                       placeholder="Type of Appointment"
                        size="large"
                        style={{ width: 450, marginRight: 10 }}
                        showSearch
                        autoClearSearchValue
                        optionFilterProp="children"
                        labelInValue
                        onChange={(e)=>{
                          // console.log(e)
                          const {value} = e;
                          setSlotType(value);
                      }} 
                      
                    >
                        {
                            type !=null && type.map((option, i) => <Option  value={option}>{option}</Option>)
                        }
                        

                    </Select><br/>

                    <br/>




<label> Choose a  Doctor</label><br/>
                    <Select
                       placeholder="Doctor name"
                        size="large"
                        style={{ width: 450, marginRight: 10 }}
                        showSearch
                        autoClearSearchValue
                        
                        optionFilterProp="children"
                        labelInValue
                                   
                        onChange={(e)=>{
                          const {value} = e;

                          setDoctorID(value)


                            // console.log("consumeruuid",value)


                      }} 
                      
                    >

  
                       {/* console.log("fijffffffffffiu",payload.consumers.consumers) */}

{

payloadDoctor.allSlots.data && payloadDoctor.allSlots.data.map((option, i) => <Option key={option._id} value={option.uuid}>{option.full_name}</Option>

)
                        }


                    </Select><br/>

           
                    <br/>





<label> Pick  a Slot</label><br/>
                    <Select
                       placeholder="pick a slot"
                        size="large"
                        style={{ width: 450, marginRight: 10 }}
                        showSearch
                        autoClearSearchValue
                        optionFilterProp="children"
                        labelInValue
                                   
                                       
                        onChange={(e,name)=>{
                          const {value,label} = e;

                          // console.log("s",name.name)
                          //  2021-12-05T14:25:00+05:30
                                                                                    //2021-05-12 08:55:20.237Z

            // var d = new Date(label);
            // var n = d.toISOString();                 

                          // console.log("ssssssssss",value,n)
                          // console.log("sssssssssuhs",id)
                          // console.log(e,"kygyu")

setClinic(name.name)
                          setSlotID(value)
                          setBookingDate(label)


                            // console.log("consumeruuid",value)


                      }} 

                    >
{

                            payloadSlotByDoctor.allSlotsByDoctor&& payloadSlotByDoctor.allSlotsByDoctor.map((option, i) => 
                            
                            <Option name={option.clinic} onChange={e => console.log(e,"khuygygftf")} key={option._id} id={option.uuid} value={option.uuid}>
                              
                              {/* {option.start_time} */}
                              {moment(option.start_time).format('MM/DD/yyyy hh:mm a')}

                              
                              </Option>)
                        } 

                    </Select><br/>
                    <br/>         
<br/>
<label> Choose the symptoms</label><br/>
<Select
   placeholder="Symptoms"
    size="large"
    style={{ width: 450, marginRight: 10 }}
    showSearch
    autoClearSearchValue
    // allowClear
    optionFilterProp="children"
    labelInValue
    onChange={(e)=>{
      const {value} = e;
      setSympt(value)
        // console.log("consumeruuid",value)
  }} 
>

{
  symptomsData != null && symptomsData.map((option, i) => <Option key={option} value={option}>{option}</Option>)
}

</Select><br/><br/>

<label> Additional Info</label><br/>

<TextArea  onChange={ e => setAddInfo(e.target.value)}   style={{ width: 450, marginRight: 10 }}
/><br/><br/>

<label> Reference id</label><br/>

<Input  onChange={ e => setReference(e.target.value)}    type="text"  style={{ width: 450, marginRight: 10 }}
/><br/><br/>


<label> Amount</label><br/>

<Input  onChange={ e => setAmount(e.target.value)}    type="number"  style={{ width: 450, marginRight: 10 }}
/><br/><br/>

<label> Description</label><br/>

<TextArea  onChange={ e => setDesc(e.target.value)}   style={{ width: 450, marginRight: 10 }}
/><br/><br/>


<Button className="varient" type="primary" onClick={()=>Appointment(PatinetId,DoctorID,SlotID,sympt,Desc,
Slottype,
  amount,
  Addinfo,
  reference
  )}>Book an Appointment</Button>

</Form>

    </div>
  );
};

export default BookAppointment;












// import React, { useState,useEffect } from 'react';
// import { Table, Popover,Select } from 'antd';
// import { view_log, triple_dots, green_dot, red_dot } from 'helper/constant';
// import ViewbookAppointments from './ViewbookAppointment';
// import { useDispatch, useSelector } from 'react-redux';
// import actions from 'actions';
// import moment from 'moment';
// import { PAUSE } from 'redux-persist';
// const { patientActions  } = actions;
// const { Option } = Select

// const BookAppointment = ({ tableData }) => {
//   const [visible, setvisible] = useState(false);
//   const [user, setuser] = useState(null);
//   const [consumerId,setConsumerId]=useState('')
//   const dispatch = useDispatch();
//   const columns = [
//     {
//       title: 'Patient Id',
//       dataIndex: 'uuid',
//       key: 'uuid'
//     },
//     {
//       title: 'Full Name',
//       dataIndex: 'full_name',
//       key: 'full_name'
//     },
//     {
//       title: 'Email',
//       dataIndex: 'email',
//       key: 'email'
//     },
//     // {
//     //   title: 'Status',
//     //   dataIndex: 'active',
//     //   key: 'active',
//     //   render: (text, row) => (
//     //     <div className='tbl-user-photo'>
//     //       {row.active ? (
//     //         <img src={green_dot} alt='active' />
//     //       ) : (
//     //         <img src={red_dot} alt='inactive' />
//     //       )}
//     //     </div>
//     //   )
//     // },
//     // {
//     //   title: 'View',
//     //   dataIndex: 'eye',
//     //   key: 'eye',
//     //   render: (text, row) => (
//     //     <img
//     //       className='ml--10 cursor-pointer'
//     //       src={view_log}
//     //       alt='view'
//     //       onClick={() => {
//     //         console.log('view');
//     //         setuser(row);
//     //         setvisible(true);
//     //       }}
//     //     />
//     //   )
//     // },
   
//   ];

//   const payload = useSelector(state=>state.patients);

//   console.log(payload);

//   useEffect(()=>{
//     dispatch(patientActions.getPatients());  
//   },[])

//   return (
//     <div>
//       {/* <Table
//         columns={columns}
//         dataSource={payload.patients}
//         loader={payload.loader}
//         size='middle'
//         pagination={{
//           defaultPageSize: 10
//         }}
//       /> */}

// <label > Consumer Name {consumerId}
//  </label><br/>

// <Select
//                        placeholder="consumer name"
//                         size="large"
//                         style={{ width: 450, marginRight: 10 }}
//                         showSearch
//                         autoClearSearchValue
//                         allowClear
//                         optionFilterProp="children"
//                         labelInValue
//                         // onChange={e =>
//                         // {
//                         //     setMedication(null);
//                         //     if(e) setQuery({ ...query, city: e.label })
//                         // }}
//                         onChange={(e)=>{
//                           const {value} = e;
//                             setConsumerId(value)
//                       }} 
                        
//                     >
//                         {
//                             payload.patients && payload.patients.map((option, i) => <Option key={option._id} value={option.uuid}>{option.full_name}</Option>)
//                         }
//                     </Select><br/>



// <label> Patient Name</label><br/>
//                     <Select
//                        placeholder="patient name"
//                         size="large"
//                         style={{ width: 450, marginRight: 10 }}
//                         showSearch
//                         autoClearSearchValue
//                         allowClear
//                         optionFilterProp="children"
//                         labelInValue
//                         // onChange={e =>
//                         // {
//                         //     setMedication(null);
//                         //     if(e) setQuery({ ...query, city: e.label })
//                         // }}

//                       //   onChange ={ (e) =>  {
//                       //     setConsumerId("hoi")
//                       //     console.log("working")
//                       //   }
//                       // }

                   
                      
//                     >
//                         {
//                             payload.patients && payload.patients.map((option, i) =>
                            
//                             <Option    
           


//                             key={option._id}  value={option.uuid}>{option.full_name }</Option>
                            
                            
//                             )
//                         }
//                     </Select>

//       {/* <ViewbookAppointments
//         visible={visible}
//         loader={false}
//         handleOk={() => setvisible(false)}
//         handleCancel={() => setvisible(false)}
//         user={user}
//       /> */}
//     </div>
//   );
// };

// export default BookAppointment;
