import React, { useEffect, useState } from "react";
import { Table, Popover, Select, Button, notification } from "antd";
import { CSVLink } from "react-csv";
import moment from "moment";
import { view_log, triple_dots, green_dot, red_dot } from "helper/constant";
import ViewFeedbacks from "./ViewFeedbacks";
import { useDispatch, useSelector } from "react-redux";
import actions from "actions";
import { DatePicker, Space } from 'antd';
import axios from 'axios';
import { Helmet } from "react-helmet";
const { feebackActions, doctorActions, patientActions } = actions;
const { Option } = Select;
const { RangePicker } = DatePicker;


const Feedbacks = ({ tableData }) => {
  const [visible, setvisible] = useState(false);
  const [feedbackData, setFeedbackData] = useState([]);
  const [feedbackItem, setFeedbackItem] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [exptData, setExptData] = useState([]);

  const [query, setQuery] = useState({
    type: "all",
  });

  const dispatch = useDispatch();

  const payload = useSelector((state) => state.feedback);
  const payloaddoctor = useSelector((state) => state.doctors);

  const payloadpatient = useSelector((state) => state.patients);
  console.log(payloadpatient, "payloaddoctor")
  useEffect(() => {
    dispatch(feebackActions.getFeedbacks(query));
  }, [query]);

  useEffect(() => {
    dispatch(doctorActions.getDoctor());
  }, []);

  useEffect(() => {
    dispatch(patientActions.getPatients(0, '',10));
  }, []);

  useEffect(() => {
    const data =
      payload &&
      payload.feedbacks.map((feedback) => {
        const details = feedback[feedback.length - 1];
        let item = { ...details };
        item.feedbacks = feedback.slice(0, feedback.length - 1);
        return item;
      });
    setFeedbackData(data);
  }, [payload]);

  const columns = [
    {
      title: "Booking Id",
      dataIndex: "BookingId",
      key: "BookingId",
    },
    {
      title: "Doctor Name",
      dataIndex: "DoctorName",
      key: "DoctorName",
    },
    {
      title: "Patient Name",
      dataIndex: "PatientName",
      key: "PatientName",
    },
    {
      title: "Appointment Type",
      dataIndex: "appointment_type",
      key: "appointment_type",
    },
    {
      title: "Booking Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, row) => (
        <div>{moment(row.createdAt).format("DD/MM/YYYY")}</div>
      ),
    },
    {
      title: "Rating",
      dataIndex: "Rating",
      key: "Rating",
      render: (text, row) => (
        <div>
          {row.feedbacks.map((fee) => {
            return (
              <div>
                {fee.question == "Please rate this consultation"
                  ? fee.answer
                  : ""}
              </div>
            );
          })}
        </div>
      ),
    },
    {
      title: "View",
      dataIndex: "eye",
      key: "eye",
      render: (text, row) => (
        <img
          className="ml--10 cursor-pointer"
          src={view_log && view_log.default?view_log.default:view_log}
          alt="view"
          onClick={() => {
            console.log("view");
            setFeedbackItem(row);
            setvisible(true);
          }}
        />
      ),
    },
  ];
  let exptHead = [
    "Booking Id",
    "Doctor Name",
    "Patient Name",
    'feedback'
  ];
  if (feedbackData.length > 0) {
    console.log(feedbackData[0].feedbacks[0].answer[0])

  }
  useEffect(() => {
    let exportDataForming = [];
    feedbackData.map((feedback) => {
      let innerActivityRow = [
        feedback && feedback.BookingId,
        feedback && feedback.DoctorName,
        feedback && feedback.PatientName,
        feedback && feedback.feedbacks.map(i => ('Ques:' + i.question + ' ' + 'Ans:' + i.answer + "   ")),
      ];
      exportDataForming.push(innerActivityRow);
    });
    setExptData(exportDataForming);

  }, [feedbackData]);


  function onChange(dates, dateStrings) {
    setFromDate(dateStrings[0])
    setToDate(dateStrings[1])
  }


  const onExportClick = () => {
    try {


      if (toDate === undefined || fromDate === undefined) {
        notification.open({
          message: "Please Select Dates",
          duration: 2,
        });
      } else {
        if (moment(toDate).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD") || moment(fromDate).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
          notification.open({
            message: "Future date selected",
            duration: 1,
          });
        } else {
          window.open(`${process.env.REACT_APP_WEB_APP}/booking/feedback/export/admin/${fromDate}/${toDate}`)
        }
      }

    } catch (error) {
      notification.open({
        message: "Failed to export",
        duration: 1,
      });
    }
  }

  return (
    <div>
      <Helmet>
        <title>Doctall Super Admin Feedbacks
        </title>
        <meta
          name='description'
          content='View feedback from patient after each appointment. See patients name as well as the doctors name and rating given.'


        />
      </Helmet>
      <Select
        placeholder="Doctor Name"
        size="medium"
        style={{ width: 250, marginRight: 10 }}
        showSearch
        autoClearSearchValue
        allowClear
        optionFilterProp="children"
        labelInValue
        onChange={(value) => {
          if (value && value.value) {
            setQuery({ type: "doctor", doctor_uuid: value.value });
            console.log("value.doctorname", value.value);
          }
        }}
      >
        {payloaddoctor &&
          payloaddoctor.doctors.data &&
          payloaddoctor.doctors.data.map((option, i) => (
            <Option key={option._id} value={option.uuid}>
              {option.full_name}
            </Option>
          ))}
      </Select>

      <Select
        placeholder="Patient Name"
        size="medium"
        style={{ width: 250, marginRight: 10 }}
        showSearch
        autoClearSearchValue
        allowClear
        optionFilterProp="children"
        labelInValue
        onChange={(value) => {
          if (value && value.value) {
            setQuery({ type: "patient", patient_uuid: value.value });
            console.log("value.patientname", value.value);
          }
        }}
      >
        {payloadpatient &&
        payloadpatient.patients.data &&
          payloadpatient.patients.data.map((option, i) => (
            <Option key={option._id} value={option.uuid}>
              {option.full_name}
            </Option>
          ))}
      </Select>
      <div className="pictogram-header flex-x align-center ptb--15">
        <div className="fs--16 font-semibold flex-1">{/* Users */}</div>



        <Space direction="vertical" size={12}>

          <RangePicker
            ranges={{
              Today: [moment(), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
            }}
            format="YYYY-MM-DD"
            onChange={onChange}
          />

        </Space>

        <Button style={{ height: 32, marginRight: 10, marginLeft: 20 }} type="primary" onClick={onExportClick}>
          Export
        </Button>

        {/* <CSVLink
          data={exptData}
          headers={exptHead}
          filename={`Transaction${moment().format("DD/MM/YYYY hh/mm")}.csv`}
          style={{ color: "#ffff" }}
        >
          <Button style={{ height: 42, marginRight: 10 }} type="primary">
            Export
          </Button>
        </CSVLink> */}
      </div>
      <Table
        columns={columns}
        loader={payload.loader}
        dataSource={feedbackData}
        size="middle"
        pagination={{
          defaultPageSize: 12,
        }}
      />
      <ViewFeedbacks
        visible={visible}
        loader={false}
        handleOk={() => setvisible(false)}
        handleCancel={() => setvisible(false)}
        feedback={feedbackItem}
      />
    </div>
  );
};

export default Feedbacks;
