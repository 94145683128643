import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { Table, Popover, Input } from 'antd';
import axios from 'axios';


const InfluencerView= ({ visible, handleOk, handleCancel, user, data,auth,count,influencerViewData2, influencerViewData1,fun }) => {

  console.log(influencerViewData2,"refered123")
  const [influencerViewData, setInfluencerViewData] = useState([])

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name',
      render: (text, item) => (
<div>{item && item.user && item.user[0] && item.user[0].full_name}</div>
      )
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile',
      key: 'mobile',
      render: (text, item) => (
        <div>{item && item.user && item.user[0] && item.user[0].mobile}</div>
              )
    },
    {
      title: 'Email Id',
      dataIndex: 'email',
      key: 'email',
      render: (text, item) => (
        <div>{item && item.user && item.user[0] && item.user[0].email}</div>
              )
    },
    {
      title: 'User Id',
      dataIndex: 'uuid',
      key: 'uuid',
      render: (text, item) => (
        <div>{item && item.user && item.user[0] && item.user[0].uuid}</div>
              )
    },
   
  ];
  let pageNumber = 0 ;

  console.log()
  return (<Modal
    title={`Followers List`}
    visible={visible}
    onOk={handleOk}
    onCancel={handleCancel}
    width={850}
    footer={[
    ]}
  >

  {
    count==0?
    <div style={{textAlign:"center"}}>
<h4 style={{fontFamily: "open-sans-regular", fontSize:"20px",fontWeight:"500"}}> No Referred Users </h4>
  </div>
  :
  <div>   
      {/* <table style={{ width: "100%" }}>
        <tr style={{ background: "#F0F5F5", height: "38px" }}>
          <th> &nbsp; &nbsp; Full Name</th>
          <th>Mobile Number</th>
          <th>Email ID</th>
          <th>User ID</th>
        </tr>    
      {
        influencerViewData.map((item,index)=>{ 
          console.log(item,"item")
         return <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
        <td>{item && item.user && item.user[0] && item.user[0].full_name}</td>
        <td>{item && item.user && item.user[0] && item.user[0].mobile}</td>
        <td>{item && item.user && item.user[0] && item.user[0].email}</td>
        <td>{item && item.user && item.user[0] && item.user[0].uuid}</td>
      </tr>
        })
      }      
      </table>    */}
      <Table
        columns={columns}
        dataSource={influencerViewData2}
        size='middle'
        pagination={{
          total: count,
          showSizeChanger: false
        }}

        onChange={(pagination) => {

          if (pagination.current === 1) {
            pageNumber = 0
          } else {
            pageNumber = pagination.current - 1
          }
          fun(influencerViewData1,pageNumber)
          // dispatch(master.getInfluencerData(pageNumber))
        }}
      />
  </div>
  }

 

    
 
  </Modal>
  );
};

export default InfluencerView;