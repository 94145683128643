import React from 'react';
import ViewpatientdocsComponent from 'components/viewpatientdocsComponent/viewpatientdocsComponent';

const Viewpatientdocs = () => {
    return (
        <div className="pictogram-container">
            <div className="pictogram-header flex-x align-center ptb--15">
                <div className="fs--16 font-semibold flex-1">
                {/* Patient Documents */}
                </div>
                {/* < style={{ height: 42 }} type="primary" onClick={() => setvisible(true)}>Add</Button> */}
            </div>
            <div>
                <ViewpatientdocsComponent />
            </div>
        </div>
    );
};

export default Viewpatientdocs;