import { Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  Tabs,
  notification,
} from 'antd';
import { useDispatch } from 'react-redux';
import actions from 'actions';
import "react-phone-input-2/lib/style.css";

const { Option } = Select;

const { master } = actions;
const EditeCity = ({ user, visible, handleOk, handleCancel, diff, city }) => {
  console.log('user4',user);
  const dispatch = useDispatch()
  const [newcityuuid, setnewcityuuid] = useState('');
console.log(newcityuuid,"newcity")

  const CityChange = (item) => {
    console.log(item, "item")
    setnewcityuuid(item)
  }

  useEffect(()=>{
    if(user){
      setnewcityuuid(user.city_uuid)
    }
    
  },[user])

  const { TabPane } = Tabs;


 
  const handleSubmit1 = values => {
    console.log(values)

    var payLoad = {
      "city_uuid" : newcityuuid,
      "uuid" : user.uuid,
      "name" : values.firstName,

  
     
  }
   console.log(payLoad,"payLoad")
    dispatch(master.editAreaName(payLoad));
  }

  
  const handleCityChange = (value) => {
    // setSelectedCity(value);
    setnewcityuuid(value)
  };

  return <Modal
    title={`Area Name`}
    visible={visible}
    onOk={handleOk}
    onCancel={handleCancel}
    width={850}
    footer={[]}
   
    
  >
   
    <Tabs defaultActiveKey="1" >
      <TabPane tab="General" key="1">
        <Form onFinish={handleSubmit1} className='gx-form-row0' layout='vertical'>
          <Row className="space-between">
          <Col span={7} xs={7} md={7}>

            {/* <span style={{ color: "red", fontSize: "20px", marginTop: "10px" }}>*</span><span style={{ marginBottom: "20px" }}> City Name</span> */}
            <Form.Item
              label='City Name'
              // name='area_name'
              rules={[{ required: true, message: 'Please input pharmacy name!' }]}
            >
              {/* <Select
                label='Area Name'
                showSearch
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                placeholder="Select City"
                onChange={CityChange}
                defaultValue={user && user.city_uuid}
                // initialValue={user && user.city_uuid}
              >

                {city && city.cityName && city.cityName.data && city.cityName.data.map((item) => {
                  return <Option value={item.uuid}>{item.name}</Option>
                })}


              </Select> */}

              <Select
        label='Area Name'
        showSearch
        style={{ width: '100%', borderRadius: '6px' }}
        placeholder="Select City"
        onChange={handleCityChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={newcityuuid}
      >
        {city && city.cityName && city.cityName.data && city.cityName.data.map((item) => (
          <Option key={item.uuid} value={item.uuid}>
            {item.name}
          </Option>
        ))}
      </Select>

            </Form.Item>

          </Col>



          &nbsp;  &nbsp;  &nbsp;  &nbsp;
        
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Area Name"
                name="firstName"
                initialValue={user && user.name}
                rules={[{ required: true, message: "Please enter Area name!" }]}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            
            
          </Row>
          {diff === "edit" &&
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button ml--10'>
              Submit
            </Button>
          }
        </Form>
      </TabPane>

    

    </Tabs>
  </Modal>
  // );
};

export default EditeCity;
