import { Modal } from 'antd';
import React from 'react';
import { Button } from 'antd';
import { Spin } from 'antd';
import { green_dot, red_dot } from 'helper/constant';

const ViewTransaction = ({ feedback, visible, loader, handleOk, handleCancel }) => {
  return (
    <Modal
      title={`Feedback Details`}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key='submit' type='primary' onClick={handleOk}>
          Ok
        </Button>
      ]}
    >
      <Spin spinning={loader}>
        <div className='flex-y align-left'>
        <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Booking Id
            </div>
            <div className='read-form-value'>{feedback && feedback.BookingId}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Doctor Name
            </div>
            <div className='read-form-value'>{feedback && feedback.DoctorName}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Patient Name
            </div>
            <div className='read-form-value'>{feedback && feedback.PatientName}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Feedbacks
            </div>
            {
              feedback.feedbacks && feedback.feedbacks.map(item =>{
                return <div>
                  <div className='read-form-input flex-1 mr--10 mt--15'>
                  <div className='read-form-label fs--14 font-semibold'>
                    Question
                  </div>
                  <div className='read-form-value'>{item && item.question}</div>
                </div>
                <div className='read-form-input flex-1 mr--10 mt--5'>
                  <div className='read-form-label fs--14 font-semibold'>
                    Answer
                  </div>
                  <div className='read-form-value'>{item && item.answer}</div>
                </div>
                </div>
              })
            }
          </div>
          <div/>
        </div>
      </Spin>
    </Modal>
  );
};

export default ViewTransaction;
