import { all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from 'actions';
import { getCities, getStates ,getCitiesnew,getAreasnew,getCitiesnewForPartners,getAreasnewForPartners} from 'services/common-service';
import { notification } from 'antd';
const { authActions, commonActions } = actions;

export function* loginRequest() {
  yield takeEvery(commonActions.GET_METADATA, function*() {
    yield put({
      type: commonActions.GET_STATES
    });
    yield put({
      type: commonActions.GET_CITIES
    });
  });
}


export function* getCitiesnewsaga() {
  yield takeEvery(commonActions.GET_CITIES_NEW, function*() {
    try {
      const response = yield getCitiesnew();
      if (response.status === 200) {
        const { data } = response;
        yield put({
          type: actions.commonActions.GET_CITIES_NEW_SUCCESS,
          data: data
        });
      }
    } catch (error) {
      yield notification.open({
        message: 'Failed!',
        description: error.message
      });
      if (error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* getCitiesnewForPartnerssaga() {
  yield takeEvery(commonActions.GET_CITIES_NEW, function*() {
    try {
      const response = yield getCitiesnewForPartners();
      if (response.status === 200) {
        const { data } = response;
        yield put({
          type: actions.commonActions.GET_CITIES_NEW_SUCCESS_FOR_PARTNER,
          data: data
        });
      }
    } catch (error) {
      yield notification.open({
        message: 'Failed!',
        description: error.message
      });
      if (error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}


export function* getAreasnewsaga() {
  yield takeEvery(commonActions.GET_AREAS_NEW, function*(params) {
    console.log("saga area uuid",params.cityuuid)
    try {
      const response = yield getAreasnew(params.cityuuid);
      if (response.status === 200) {
        const { data } = response;
        yield put({
          type: actions.commonActions.GET_AREAS_NEW_SUCCESS,
          data: data
        });
      }
    } catch (error) {
      yield notification.open({
        message: 'Failed!',
        description: error.message
      });
      if (error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* getAreasnewForPartnerssaga() {
  yield takeEvery(commonActions.GET_AREAS_NEW, function*(params) {
    console.log("saga area uuid",params.cityuuid)
    try {
      const response = yield getAreasnewForPartners(params.cityuuid);
      if (response.status === 200) {
        const { data } = response;
        yield put({
          type: actions.commonActions.GET_AREAS_NEW_SUCCESS_FOR_PARTNER,
          data: data
        });
      }
    } catch (error) {
      yield notification.open({
        message: 'Failed!',
        description: error.message
      });
      if (error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}


export function* getCitiesSaga() {
  yield takeEvery(commonActions.GET_CITIES, function*() {
    try {
      const response = yield getCities();
      if (response.status === 200) {
        const { data } = response;
        yield put({
          type: actions.commonActions.GET_CITIES_SUCCESS,
          data: data
        });
      }
    } catch (error) {
      yield notification.open({
        message: 'Failed!',
        description: error.message
      });
      if (error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* getStatesSaga() {
  yield takeEvery(commonActions.GET_STATES, function*() {
    try {
      const response = yield getStates();
      if (response.status === 200) {
        const { data } = response;
        yield put({
          type: commonActions.GET_STATES_SUCCESS,
          data: data
        });
      }
    } catch (error) {
      yield notification.open({
        message: 'Failed!',
        description: error?.message
      });
      if (error?.response?.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loginRequest), fork(getCitiesSaga), fork(getStatesSaga),fork(getCitiesnewsaga),fork(getAreasnewsaga),fork(getCitiesnewForPartnerssaga),fork(getAreasnewForPartnerssaga)]);
}
