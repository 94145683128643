import actions from 'actions';

const { saasActions } = actions;

const initState = {
    loader: false,
    data: [],
    patientsData: [],
    limit: 10,
    skip: 0,
    count: 0,
    patientCount: 0,
    allDoctors: []
};

export default function rootReducer(state = initState, action) {
    switch (action.type) {

        case saasActions.GET_SAAS_DOCTORS:
            return {
                ...state,
                loader: true,
                data: []
            };


        case saasActions.GET_SAAS_DOCTORS_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.data,
                count: action.count,
            };

        case saasActions.GET_SAAS_ALL_DOCTOR_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.data,
                count: action.count,
            };

        //Patients Actions

        case saasActions.GET_SAAS_PATIENT:
            return {
                ...state,
                loader: true,
            };


        case saasActions.GET_SAAS_PATIENT_SUCCESS:
            return {
                ...state,
                loader: false,
                patientsData: action.patientsData,
                patientCount: action.patientCount,
            }
        default:
            return state;
    }
}
