const doctorActions = {
  GET_DOCTORS: "GET_DOCTORS",
  GET_DOCTORS_SUCCESS: "GET_DOCTORS_SUCCESS",
  GET_CLONE_DOCTORS: "GET_CLONE_DOCTORS",
  GET_CLONE_DOCTORS_SUCCESS: "GET_CLONE_DOCTORS_SUCCESS",
  EDIT_DOCTORS:'EDIT_DOCTORS',
  getDoctor: (pagenum, search) => {
    console.log(pagenum, "GET_doc1")

    return {
      type: doctorActions.GET_DOCTORS,
      pagenum,
      search
    };
  },
  getDoctorclone: (pagenum,searchQuery, params) => {
    console.log(params, "GET_doc1")
    return {
      type: doctorActions.GET_CLONE_DOCTORS,
      pagenum,
      searchQuery,
      params
    };
  },

  editDoctors: (uuid,doctor) => {
    return {
      type: doctorActions.EDIT_DOCTORS,
      uuid,
      doctor
    };
  }
};

export default doctorActions;
