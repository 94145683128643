import React from 'react'
import { Form, Modal, Input, Spin, Row, Col, Button, Select } from 'antd';
import moment from "moment";
const { Option } = Select;
const { TextArea } = Input;

export default function UsedCouponView(props) {
    const { couponsDetails, visible, close } = props;
    const [form] = Form.useForm();
    console.log(couponsDetails)


    return (
        <Modal
            title={`Coupon Details`}
            visible={visible}
            onOk={close}
            onCancel={close}
            width={800}
            footer={[
                <Button key='submit' type='primary' onClick={close} >
                    Ok
                </Button>
            ]}
        >


            <Form form={form} className='gx-form-row0' layout='vertical'>
                <Row className='space-between'>

                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%' }}>
                            User Name
                        </div>
                        <Input size='large'
                            value={couponsDetails && 0 in couponsDetails.user && couponsDetails.user[0].full_name}
                        />
                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%' }}>
                            Coupon Code
                        </div>
                        <Input size='large'
                            value={couponsDetails && couponsDetails.coupon_code}
                        />
                    </Col>



                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Coupon Type
                        </div>
                        <Input size='large'
                            value={couponsDetails && 0 in couponsDetails.coupon && couponsDetails.coupon[0].category}
                        />
                    </Col>



                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Valid From
                        </div>
                        <Input size='large'
                            value={moment(couponsDetails && couponsDetails.coupon && couponsDetails.coupon[0] && couponsDetails.coupon[0].valid_from).format("lll")}
                        />
                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Valid Till
                        </div>
                        <Input size='large'
                            value={moment(couponsDetails && couponsDetails.coupon && couponsDetails.coupon[0] && couponsDetails.coupon[0].valid_to).format("lll")}
                        />
                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Coupon Applied on
                        </div>
                        <Input size='large'
                            value={moment(couponsDetails && couponsDetails.coupon && couponsDetails.updatedAt).format("lll")}
                        />
                    </Col>


                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Total Amount
                        </div>
                        <Input size='large'
                            value={couponsDetails && couponsDetails.total_amount}
                        />
                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Discount
                        </div>
                        <Input size='large'
                            value={couponsDetails && couponsDetails.discount}
                        />
                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Amount Paid
                        </div>
                        <Input size='large'
                            value={couponsDetails && couponsDetails.total_amount_paid}
                        />
                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Appointment Type
                        </div>
                        <Input size='large'
                            value={couponsDetails && 0 in couponsDetails.coupon && couponsDetails.coupon[0].sub_category && couponsDetails.coupon[0].sub_category === "sadn" ? "SADN" : "FADN"}
                        />
                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Transaction Id
                        </div>
                        <Input size='large'
                            value={couponsDetails && couponsDetails.reference_id}
                        />
                    </Col>





                </Row>
                <Row>
                    <Col span={24} xs={24} md={24}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Description
                        </div>
                        <TextArea size='large' value={couponsDetails && couponsDetails.description} />
                    </Col>
                </Row>
            </Form>


        </Modal>
    )
}

