import { all } from "redux-saga/effects";
import authSagas from "./auth";
import userSagas from "./users";
import pharmacysagas from "./pharmacy"
import notificationSagas from './NotificationSaga'
import couponsSaga from './couponsSaga'
import commonSagas from "./common";
import feedbackSagas from "./feedback";
import doctorSagas from "./doctor";
import appointementSaga from "./appointment";
import patientSaga from "./patients";
import transactionSaga from "./transaction";
import consumerSaga from "./consumer";
import getConsumerallSaga from './bookAppointment';
import getpatientsallSaga from './getPatients';
import getslotsallSaga from './getSlots';
import getslotDoctorsallSaga from './getSlotsDoctor'
import postAppointmentSaga from './postAppointment'
import orderSaga from './order'
import treatmentorderSaga from './treatment_order'
import usedCouponsSaga from './UsedCouponsSaga'
import partnerSaga from './PartnerSaga'
import saasSaga from './saasSaga'
import master from "./masterData"
import influencerSaga from "./influencerSaga";

export default function* rootSaga() {
  yield all([
    authSagas(),
    commonSagas(),
    userSagas(),
    feedbackSagas(),
    doctorSagas(),
    appointementSaga(),
    patientSaga(),
    transactionSaga(),
    consumerSaga(),
    getConsumerallSaga(),
    getpatientsallSaga(),
    getslotsallSaga(),
    getslotDoctorsallSaga(),
    influencerSaga(),
    postAppointmentSaga(),
    orderSaga(),
    treatmentorderSaga(),
    notificationSagas(),
    couponsSaga(),
    pharmacysagas(),
    usedCouponsSaga(),
    partnerSaga(),
    saasSaga(),
    master()
  ]);
}
