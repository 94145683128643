import React, { useState, useRef, useEffect } from "react";
import { Table, Spin, Select, Modal, Tabs } from "antd";
import moment from "moment";
import { view_log } from "helper/constant";
import { Form, Input, Row, Col, Button } from "antd";
import { FcUpload } from 'react-icons/fc';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { notification } from 'antd'


function DetailOrderView({ data, aws, onChangeStatus }) {


  const [form] = Form.useForm();

  const uploadImg = useRef(null);

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const auth = useSelector((state) => state.auth.accessToken);

  const [labresult, setlabresult] = useState(null)
  const [modalopen, setmodalopen] = useState(false)
  const [submitvalues, setsubmitvalues] = useState(false)

  console.log(submitvalues.uuid)
  const [changedStatus, setChangedStatus] = useState()
  const [tableData, setTableData] = useState([])

  console.log(tableData, "tableData")

  // const tableData = data === undefined ? [] : data.full_order_details;

  // order/getOrderDetialsByOrderId/DOC-55FCEFB89C90

  console.log(tableData)
  const [open, setOpen] = useState(false);
  const [modelIndex, setModelIndex] = useState(0);
  const [uploaded, setuploaded] = useState()

  const [result, setresult] = useState()




  // useEffect(async () => {
  //   setLoading2(true)
  //   await axios.get(`${process.env.REACT_APP_WEB_APP}/upload/admin/adminlistuploaddetails/${aws}`, {
  //     headers: { "x-auth-token": auth },
  //   })
  //     .then((res) => {

  //       setuploaded(res)
  //       setLoading2(false)
  //       console.log("success respnse", res)


  //     })
  //     .catch((err) => {

  //     })

  // }, [])

  useEffect(() => {
    // setLoading2(true)
    // alert("enter")
    // axios.get(`${process.env.REACT_APP_WEB_APP}/upload/admin/adminlistuploaddetails/${aws}`, {
      OrderDatabyOrderid()
    
  }, [])

  const OrderDatabyOrderid = () => {
    axios.get(`${process.env.REACT_APP_WEB_APP}/order/getOrderDetialsByOrderId/${aws}`, {
      headers: { "x-auth-token": auth },
    })
      .then((res) => {
        setuploaded(res)
        setLoading2(false)
        setTableData(res && res.data && res.data.order_info && res.data.order_info[0] && res.data.order_info[0].full_order_details)
        console.log("success respnse", res.data.order_info[0].full_order_details)
      })
      .catch((err) => {

      })
  }
  const handleImage = (e) => {
    const [file] = e.target.files;
    setlabresult(e.target.files[0]);
    if (file) {
      notification.open({ message: 'Success!', description: 'File Selected' })
      setlabresult(file);

    }
  };

  let count = 0;
  const handleStatusChange = (details) => {

    if (changedStatus === undefined) {
      notification.open({
        message: 'Error',
        description: 'Select status to edit'
      })
    } else {
      if (changedStatus === 'Result Accepted' || changedStatus === 'Result Rejected') {
        if (details.final_action.result.length > 0) {
          onChangeStatus(data.uuid, changedStatus, details.test_info.uuid)
        } else {
          notification.open({
            message: 'Error',
            description: 'Result not yet uploaded for the test'
          })
        }
      } else {
        onChangeStatus(data.uuid, changedStatus, details.test_info.uuid)
      }
    }

  }


  const handleActionChange = (event) => {
    setChangedStatus(event.value)
  }

  const handleSubmit = async (values) => {

    console.log("rowdata", submitvalues)
    console.log("all", data)
    console.log("user_uuid", data.user_info.user_uuid)
    console.log("order_uuid", data.uuid)
    console.log("det_order", submitvalues.uuid)

    console.log("item_uuid", submitvalues.test_info.uuid)
    console.log("lab_partner_name", submitvalues.test_info.diagnosticspartner)
    console.log("amount", submitvalues.test_info.doctallsexclusivediscountedprice)

    console.log("patient_uuid", data.patient_info.patient_uuid)


    if (labresult == null) {
      notification.open({ message: 'Failed!', description: 'Please Select a file' })
    }
    else {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", labresult);



      formData.append("user_uuid", data.user_info.user_uuid)
      formData.append("order_uuid", data.uuid)
      formData.append("det_order", submitvalues.uuid)

      formData.append("item_uuid", submitvalues.test_info.uuid)
      formData.append("lab_partner_name", submitvalues.test_info.diagnosticspartner)
      formData.append("amount", submitvalues.test_info.doctallsexclusivediscountedprice)

      formData.append("patient_uuid", data.patient_info.patient_uuid)

      // console.log("formDataformData",formData)


      // await axios
      // .post(`${process.env.REACT_APP_WEB_APP}/upload/admin/adminTestResultUpload`, formData, {
      //   headers: { "x-auth-token": auth },
      // })
      await axios
        .post(`${process.env.REACT_APP_WEB_APP}/upload/labtest-result`, formData, {
          headers: { "x-auth-token": auth },
        })
        .then((res) => {

          setmodalopen(false)
          var Payload = {
            "data": [
              {
                "order_uuid": data.uuid,
                "uuid": submitvalues.uuid,
                "acceptance_status": "Yes",
                // "sample_collection_progress": "Sample collected for in-branch analysis",
                // "final_action_status": "Result released and updated",
                "final_action_result": [
                  {
                    "_id": res.data.url._id,
                    "filename": res.data.url.filename,
                    "location": res.data.url.location,
                    "url": res.data.url.url
                  }
                ]
              }
            ]
          }
          setresult(res)
          axios.put(`${process.env.REACT_APP_WEB_APP}/order/updateStatus`, Payload, {
            headers: { "x-auth-token": auth },
          })
          .then((res) => {
            console.log("success respnse")
            setLoading(false);
            notification.open({ message: 'Success!', description: 'Upload File Successfully' })
            OrderDatabyOrderid()
            // window.location.reload(false);
          })
          .catch((err) => {
            setLoading(false);
            notification.open({ message: 'Failed!', description: 'File not Uploaded' })
          })
        })
        .catch((err) => {
          setLoading(false);
          notification.open({ message: 'Failed!', description: 'File not Uploaded' })
        });


    }


  };


  const { Option } = Select;
  const { TabPane } = Tabs;
  const doctallAction = [
    "Result Accepted",
    "Result Rejected",
    "Refund Completed",
    "Receipt of Result",
  ];
  const sty = {
    borderBottom: "1px solid blueviolet",
    paddingBottom: "5px",
  };
  const detailCol = [
    {
      title: "Test Name",
      dataIndex: "Name",
      key: "Name",
      render: (text, row) => <div>{row.test_info.standardisedtestname}</div>,
    },
    {
      title: "Product Code",
      dataIndex: "productcode",
      key: "productcode",
      render: (text, row) => <div>{row.test_info.productcode}</div>
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "Price",
      render: (text, row) => <div>{row.test_info.doctallsexclusivediscountedprice}</div>
    },
    {
      title: "Clinical Advice",
      dataIndex: "clinical_advice",
      key: "clinical_advice",
      render: (text, row) => <div>{row.clinical_advice}</div>,
    },
    {
      title: "No Of Days",
      dataIndex: "partnerlabtat",
      key: "partnerlabtat",
      render: (text, row) => <div>{row.test_info.partnerlabtat}</div>,
    },
    {
      title: "MAX TAT",
      dataIndex: "partnerlabtatindays",
      key: "partnerlabtatindays",
      render: (text, row) => <div>{row.test_info.partnerlabtatindays}</div>,
    },
    {
      title: "Acceptance Status",
      dataIndex: "acceptance_status",
      key: "acceptance_status",
      render: (text, row) => (
        <div>
          {row && row.acceptance_status && row.acceptance_status.reason ? row.acceptance_status.reason : ""},
          <br />
          <br />
          {row.acceptance_status.status}
        </div>
      ),
    },
    // {
    //   title: "Interim Progress",
    //   dataIndex: "interim_progress",
    //   key: "interim_progress",
    //   render: (text, row) => <div>{row && row.interim_progress && row.interim_progress.status ? row.interim_progress.status : "NA"}</div>,
    // },
    {
      title: "Sample Collection",
      dataIndex: "sample_collection_progress",
      key: "sample_collection_progress",
      render: (text, row) => <div>{row.sample_collection_progress}</div>,
    },
    {
      title: "Test Result",
      dataIndex: ".final_action",
      key: ".final_action",
      render: (text, row) => (
        <div>
          {/* {row.final_action.status}
          <br />
          <br /> */}
          {row.final_action.result.map((i) => (
            <div>
              <a href={i.url} target="_blank">
                {i.filename}
              </a>
              <br />
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Doctall Action",
      dataIndex: "Doctall Action",
      key: "Doctall Action",
      render: (text, row) => (
        <div>

          <div>
            <Select
              style={{ width: 200 }}
              showSearch
              autoClearSearchValue
              optionFilterProp="children"
              onChange={handleActionChange}
              labelInValue
              defaultValue={{ value: row && row.doctall_action }}
            >
              {doctallAction.map((i, ind) => (
                <Option key={ind} value={i}>
                  {i}
                </Option>
              ))}
            </Select>

          </div>


          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <button style={{
              background: "#1890ff",
              border: "none",
              color: "white",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "16px",
              margin: "4px 2px",
              cursor: "pointer"
            }} onClick={() => handleStatusChange(row && row)}>Save</button>
          </div>
        </div>


      ),
    },
    {
      title: "Action",
      dataIndex: "Notes",
      key: "Notes",
      render: (text, row, index) => (
        <div>
          <img
            className="ml--10 cursor-pointer"
            src={view_log && view_log.default?view_log.default:view_log}
            alt="view"
            onClick={() => {
              setModelIndex(index)
              setOpen(true);
            }}
          />
          {/* <img
            className="ml--10 cursor-pointer"
            src={view_log}
            alt="view"
            onClick={() => { setmodalopen(true); setsubmitvalues(row) }}
          /> */}
          <FcUpload onClick={() => { setmodalopen(true); setsubmitvalues(row) }} style={{ fontSize: '18px' }} />
        </div>
      ),
    },

    // {
    //   title: "Upload Lab Test",
    //   dataIndex: "eye",
    //   key: "eye",
    //   render: (text, row) => (
    //     <>
    //       <button style={{
    //         background: "#1890ff",
    //         /* Green */
    //         border: "none",
    //         color: "white",
    //         textAlign: "center",
    //         textDecoration: "none",
    //         display: " inline-block",
    //         fontSize: "16px",
    //         margin: "4px 2px",
    //         cursor: "pointer"

    //       }} onClick={() => { setmodalopen(true); setsubmitvalues(row) }} >Upload</button>


    //       <input
    //         accept="image/*"
    //         onChange={(e) => handleImage(e)}
    //         id="myInput"
    //         type="file"
    //         ref={uploadImg}
    //         style={{ display: "none" }} />

    //     </>

    //   ),
    // },


    // {
    //   title: "Uploaded results",
    //   dataIndex: "",
    //   key: "",
    //   render: (text, row) => (
    //     <div>

    //       <Spin spinning={loading2}>
    //         {
    //           uploaded && uploaded.data !== "" && uploaded.data.uploadedData.map(e => {

    //             return (

    //               <div>{e.item_uuid == row.test_info.uuid ? <div>

    //                 <a href={e.url} target="_blank">{e.filename}</a>
    //                 <br />
    //               </div> : ""}

    //               </div>
    //             )

    //           })

    //         }

    //       </Spin>
    //     </div>
    //   ),
    // },
  ];
  console.log(tableData)
  return (

    <div style={{ overflow: "visible" }}>

      <Table
        columns={detailCol}
        dataSource={tableData}
        size="small"
        style={{ overflow: "visible" }}
        pagination={{
          defaultPageSize: 12,
        }}
      />
      <Modal
        visible={open}
        onOk={() => {
          setOpen(false);
        }}
        onCancel={() => {
          setOpen(false);
        }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<h3>Notes</h3>} key="1">
            <div style={{ height: "350px", overflowY: "auto" }}>

              {tableData.length !== 0 && tableData[modelIndex].notes.map((i, index) => (
                <div style={sty} key={index}>
                  <p>Date: {moment(i.date).format("DD/MM/YYYY")}</p>
                  <p>
                    Acceptance Status:{" "}
                    {i.status === "" ? "No Data" : i.status}
                  </p>
                  <p>Notes: {i.note}</p>
                </div>
              ))}
            </div>
          </TabPane>


          <TabPane tab={<h3>Issues</h3>} key="2">
            <div style={{ height: "350px", overflowY: "auto" }}>

              {tableData.length !== 0 && tableData[modelIndex].issues.map((i, index) => (
                <div style={sty} key={index}>
                  <p>Date: {moment(i.date).format("DD/MM/YYYY")}</p>
                  <p>Issues: {i.issue}</p>
                </div>
              ))}


            </div>
          </TabPane>

        </Tabs>
      </Modal>

      <Modal
        className='no-modal-footer'
        title='Upload Lab Test'
        visible={modalopen}
        // onOk={onOk}
        // width={750}
        onCancel={() => setmodalopen(false)}
        okText='Add'
      >

        <Spin spinning={loading}>
          <>

            <Form form={form} className='gx-form-row0' layout='vertical'>
              <Row>


                <Col span={24} xs={24} md={24}>


                  <button style={{
                    background: "#1890ff",

                    border: "none",
                    color: "white",
                    textAlign: "center",
                    textDecoration: "none",
                    display: " inline-block",
                    fontSize: "16px",
                    margin: "4px 2px",
                    cursor: "pointer"

                  }} onClick={() => uploadImg.current.click()} >Select Lab Test</button>

                  <input
                    accept=".pdf, .doc, .docx"
                    onChange={(e) => handleImage(e)}
                    id="myInput"
                    type="file"
                    ref={uploadImg}
                    style={{ display: "none" }} />
                </Col>

                <Col className="ml-auto">
                  <Button className='login-form-button' onClick={() => setmodalopen(false)}>
                    Cancel
                  </Button>
                  <Button onClick={handleSubmit}
                    type='primary'
                    htmlType='submit'
                    className='login-form-button ml--10'
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>

          </>
        </Spin>

      </Modal>

    </div>
  );
}

export default DetailOrderView;
