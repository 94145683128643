import React, { useEffect, useState } from "react";
import { Form, Modal, Input, Row, Col, Button, Select, Table, Space } from "antd";
import { CSVLink } from "react-csv";
import { view_log, green_dot, red_dot } from "helper/constant";
import ViewConsumers from "./ViewConsumers";
import ViewConsumerTrack from "./viewTracker"
import { useDispatch, useSelector } from "react-redux";
import actions from "actions";
import { Spin } from "antd";

import moment from "moment";
import { DatePicker } from "antd";
import { Helmet } from "react-helmet";
const { consumerActions } = actions;
const { Option } = Select;
const {
  BookAppointments,
  GETPATIENTSBYCON,
  GETSLOTBYNAME,
  GETSLOTBYDOCTOR,
  POST_APPOINTMENT,
} = actions;
const { patientActions } = actions;
const Search = Input.Search;

const Consumers = () => {
  const [visible, setvisible] = useState(false);
  const [visibleTrack, setvisibleTrack] = useState(false);

  const [user, setuser] = useState(null);
  const [exptData, setExptData] = useState([]);
  const [booking, setbooking] = useState(null);
  const [dataSrc, setDataSrc] = useState([])
  const [date, setDate] = useState(new Date());
  const [query, setQuery] = useState({});
  const [searchQuery, setSearchQuery] = useState('')

  let va;


  const handleOnSearch = (value) => {
    console.log(value)
    setSearchQuery(value)
    dispatch(consumerActions.getConsumers(0, value, query));

  }
  const dispatch = useDispatch();

  const columns = [
    {
      title: "Consumer Id",
      dataIndex: "uuid",
      key: "uuid",
    },
    {
      title: "Consumer Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Consumer Email",
      dataIndex: "email",
      key: "email",
    },
    // {
    //   title: 'Patient Id',
    //   dataIndex: 'patients.full_name',
    //   key: 'patients.uuid',
    //   render: (text, row) => (
    //     <div className='tbl-user-photo'>
    //       {row.patients && row.patients.uuid}
    //     </div>
    //   )
    // },
    // {
    //   title: 'Patient Mobile',
    //   dataIndex: 'patients.Mobile',
    //   key: 'patients.Mobile',
    //   render: (text, row) => (
    //     <div className='tbl-user-photo'>
    //       {row.patients && row.patients.mobile}
    //     </div>
    //   )
    // },
    {
      title: "Patient Name",
      dataIndex: "patients.full_name",
      key: "patients.full_name",
      render: (text, row) => (
        <div className="tbl-user-photo">
          {row.patients && row.patients.full_name}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (text, row) => (
        <div className="tbl-user-photo">
          {row.verified ? (
            <img src={green_dot && green_dot.default?green_dot.default:green_dot} alt="active" />
          ) : (
            <img src={red_dot && red_dot.default?red_dot.default:red_dot} alt="inactive" />
          )}
        </div>
      ),
    },
    {
      title: "View",
      dataIndex: "eye",
      key: "eye",
      render: (text, row) => (
        <img
          className="ml--10 cursor-pointer"
          src={view_log && view_log.default?view_log.default:view_log}
          alt="view"
          onClick={() => {
            console.log("view");
            setuser(row);
            setvisible(true);
          }}
        />
      ),
    },
    {
      title: "View",
      dataIndex: "eye1",
      key: "eye1",
      render: (text, row) => (
        <img
          className="ml--10 cursor-pointer"
          src={view_log && view_log.default?view_log.default:view_log}
          alt="view"
          onClick={() => {
            console.log("view");
            setuser(row);
            setvisibleTrack(true);
          }}
        />
      ),
    },
  ];

  const payload = useSelector((state) => state.consumer);
  const conPayload = useSelector((state) => state.bookingAppointment);
  console.log(useSelector((state) => state.bookingAppointment), "gfhjkjlk;")
  const soruce = payload.consumers.consumers;



  const patientpayload = useSelector((state) => state.patients);

  useEffect(() => {
    dispatch(patientActions.getPatients(0,'',10));
  }, []);

  function onChange(date, dateString) {
    setDate(date);
  }

  useEffect(() => {
    dispatch(BookAppointments.getAppointment(0));
  }, []);

  useEffect(() => {
    dispatch(consumerActions.getConsumers(0, searchQuery, query));
  }, [date, query]);


  let exptHead = [
    "Consumer Id",
    "Consumer Username",
    "Consumer Email",
    "Patient Name",
    "Secondary Email",
    "Mobile",
    "Other Mobile",
    "Dob",
    "gender",
    "blood_group",
    "marital_status",
    "totalBookings",
    "lastAppointmentDate",
    "totalAmountConsumer",
    "signed up Date",
    "totalOrders",
  ];


  useEffect(() => {
    let exportDataForming = [];
    console.log(soruce)
    if (soruce) {
      soruce.map((user) => {
        let innerActivityRow = [
          user && user.uuid,
          user && user.username,
          user && user.email,
          user && user.full_name,
          user && user.secondary_email,
          user && user.mobile,
          user && user.other_mobile,
          moment(user && user.dob).format("DD/MM/YYYY"),
          user && user.patients && user.patients.gender,
          user && user.patients && user.patients.blood_group,
          user && user.patients && user.patients.marital_status,
          user && user.bookings && user.bookings.totalBookings,
          user &&
          user.bookings &&
          moment(user.bookings.lastAppointmentDate).format(
            "DD/MM/YYYY hh:mm a"
          ),
          user &&
            user.bookings_details &&
            user.bookings_details[0] &&
            user.bookings_details[0].totalAmountConsumer !== undefined
            ? user.bookings_details[0].totalAmountConsumer
            : "No Data",
          user &&
            user.orders_details &&
            user.orders_details[0] &&
            user.orders_details[0].totalAmountConsumersOrder !== undefined
            ? user.orders_details[0].totalAmountConsumersOrder
            : "No Data",
          user &&
            user.user_details &&
            user.user_details[0] &&
            user.user_details[0].user_signed_up !== undefined
            ? moment(user.user_details[0].user_signed_up).format("DD/MM/YYYY")
            : "No Data",
          user && user.orders && user.orders.totalOrders,
        ];
        exportDataForming.push(innerActivityRow);
      });
    }

    setExptData(exportDataForming);
  }, [soruce]);

  return (
    <div>
      <Helmet>
        <title>Doctall Super Admin Consumers
        </title>
        <meta
          name='description'
          content='View consumer ID, username and email at a glance. Also search and export list'


        />
      </Helmet>
      <Row>
        <Col md={15}>

        </Col>
        <Col md={6}>
          <div className="fs--16 font-semibold flex-1">
            <Space direction="vertical">
              <Search
                size="large"
                // enterButton="Search"
                placeholder="Search Consumer"
                enterButton
                allowClear
                // onSearch={handleOnSearch}
                onChange={e => handleOnSearch(e.target.value)}
              />

            </Space>
          </div>
        </Col>
        <Col md={1}>

        </Col>
        <Col md={2}>
          <CSVLink
            data={exptData}
            headers={exptHead}
            filename={`Consumer${moment().format("DD/MM/YYYY hh/mm")}.csv`}
            style={{ color: "#ffff" }}
          ><Button style={{ height: 42, marginRight: 10 }} type="primary">
              Export
            </Button>
          </CSVLink>
        </Col>
      </Row>

      {/* <Select
        placeholder="Consumer Username"
        size=",medium"
        style={{ width: 250, marginRight: 10 }}
        showSearch
        autoClearSearchValue
        allowClear
        optionFilterProp="children"
        labelInValue
        onChange={(value) => {
          if (value && value.value) {
            setQuery({ conusername: value.value });
          }
        }}
      >
        {conPayload.allconsumers &&
          conPayload.allconsumers.data &&
          conPayload.allconsumers.data.map((option, i) => (
            <Option key={option._id} value={option.username}>
              {option.username}
            </Option>
          ))}
      </Select> */}

      {/* <Select
        placeholder="Consumer Email"
        size=",medium"
        style={{ width: 250, marginRight: 10 }}
        showSearch
        autoClearSearchValue
        allowClear
        optionFilterProp="children"
        labelInValue
        onChange={(value) => {
          if (value && value.value) {
            setQuery({ consumeremail: value.value });
            console.log("value.consumeremail", value.value);
          }
        }}
      >
        {conPayload.allconsumers &&
          conPayload.allconsumers.data &&
          conPayload.allconsumers.data.map((option, i) => (
            <Option key={option._id} value={option.email}>
              {option.email}
            </Option>
          ))}
      </Select> */}

      <Spin spinning={payload.loader}>
        <Table
          columns={columns}
          dataSource={payload.consumers.consumers}
          loader={payload.loader}
          size="middle"
          // pagination={{
          //   defaultPageSize: 12,
          // }}
          pagination={{
            total: payload.consumers.count,
            defaultPageSize: 10,
            showSizeChanger: false
          }}
          onChange={(pagination) => {
            let pageNumber = ''
            if (pagination.current === 1) {
              pageNumber = 0
            } else {
              pageNumber = pagination.current - 1
            }
            dispatch(consumerActions.getConsumers(pageNumber, '', query));
          }}
        />
      </Spin>

      <ViewConsumers
        visible={visible}
        loader={false}
        handleOk={() => setvisible(false)}
        handleCancel={() => setvisible(false)}
        user={user}
      // user={patientpayload && patientpayload.patients}
      />
      {console.log(visibleTrack,"dbhjvsdha")}
      <ViewConsumerTrack
      visible={visibleTrack}
      loader={false}
      handleOk={() => setvisibleTrack(false)}
      handleCancel={() => {setvisibleTrack(false); window.location.reload(false)}}
      user={user}
      />
    </div>
  );
};

export default Consumers;
