const BookAppointments = {
  GET_UNIQUE_CONSUMER: "GET_UNIQUE_CONSUMER",
  GET_UNIQUE_CONSUMER_SUCCESS: "GET_UNIQUE_CONSUMER_SUCCESS",
  getAppointment: (pagenum, search) => {
    return {
      type: BookAppointments.GET_UNIQUE_CONSUMER,
      pagenum,
      search
    };
  },
};

export default BookAppointments;



