import actions from 'actions';

const initState = {
    pharmaciesData: [],
    pharmaciesDataCount:0,
    pharmaciesclone: [],
    loader: false
};

export default function rootReducer(state = initState, action) {
    switch (action.type) {
        case actions.pharmacyActions.GET_PHARMACY:
            return {
                ...state,
                loader: true
            };
        case actions.pharmacyActions.GET_PHARMACY_SUCCESS:
            return {
                ...state,
                loader: false,
                pharmacies: action.data
            };
            case actions.pharmacyActions.GET_CLONE_PHARMACY:
            return {
                ...state,
                loader: true
            };
        case actions.pharmacyActions.GET_CLONE_PHARMACY_SUCCESS:
            return {
                ...state,
                loader: false,
                pharmaciesclone: action.data
            };
            case actions.pharmacyActions.ADD_PHARMACY1:
                return {
                  ...state,
                  loader: true
                };
                case actions.pharmacyActions.EDIT_PHARMACY:
                    return {
                      ...state,
                      loader: true
                    };
        default:
            return state;
    }
}
