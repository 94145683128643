import { Modal } from 'antd';
import React from 'react';
import { Button } from 'antd';
import { Spin } from 'antd';
import { green_dot, red_dot } from 'helper/constant';
import moment from 'moment';

const ViewUser = ({ user, visible, loader, handleOk, handleCancel }) => {
  // console.log('user', user);
  return (
    <Modal
      title={`User Details`}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key='submit' type='primary' onClick={handleOk}>
          Ok
        </Button>
      ]}
    >
      <Spin spinning={loader}>
        <div className='flex-y align-left'>
        <div className='flex-x'>
            <div className='read-form-input flex-1 mr--10 mt--15'>
              <div className='read-form-label fs--14 font-semibold'>
                First Name
              </div> 
              <div className='read-form-value'>{user && user.firstName}</div>
            </div>
            <div className='read-form-input flex-1 mr--10 mt--15'>
              <div className='read-form-label fs--14 font-semibold'>
                Middle Name
              </div>
              <div className='read-form-value'>{user && user.middleName}</div>
            </div>
            <div className='read-form-input flex-1 mr--10 mt--15'>
              <div className='read-form-label fs--14 font-semibold'>
                Last Name
              </div>
              <div className='read-form-value'>{user && user.lastName}</div>
            </div>
          </div>
          <div className='flex-x'>
            <div className='read-form-input flex-1 mr--10 mt--15'>
              <div className='read-form-label fs--14 font-semibold'>
                Full Name
              </div>
              <div className='read-form-value'>{user && user.full_name}</div>
            </div>
            <div className='read-form-input flex-1 mr--10 mt--15'>
              <div className='read-form-label fs--14 font-semibold'>
                User Name
              </div>
              <div className='read-form-value'>{user && user.username}</div>
            </div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>Email</div>
            <div className='read-form-value'>{user && user.email}</div>
          </div>
          <div className='flex-x'>
            <div className='read-form-input flex-1 mr--10 mt--15'>
              <div className='read-form-label fs--14 font-semibold'>
                Mobile Number
              </div>
              <div className='read-form-value'>{user && user.mobile}</div>
            </div>
            <div className='read-form-input flex-1 mr--10 mt--15'>
              <div className='read-form-label fs--14 font-semibold'>
                User Type
              </div>
              <div className='read-form-value'>{user && user.group}</div>
            </div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Status&nbsp;:&nbsp;&nbsp;
              {user && user.verified ? (
                <div className='read-form-value'>
                  <img src={green_dot && green_dot.default?green_dot.default:green_dot} alt='active' />
                  &nbsp;&nbsp;<label>Verified</label>
                </div>
              ) : (
                <div className='read-form-value'>
                  <img src={red_dot && red_dot.default?red_dot.default:red_dot} alt='inactive' />
                  &nbsp;&nbsp;<label>Not Verified</label>
                </div>
              )}
            </div>
          </div>
          {user && user.profile && (
            <div>
              <div className='flex-x'>
                <div className='read-form-input flex-1 mr--10 mt--15'>
                  <div className='read-form-label fs--14 font-semibold'>
                    Date of Birth
                  </div>
                  <div className='read-form-value'>
                    {moment(user.profile.dob).format('DD/MM/YYYY') || 'NA'}
                  </div>
                </div>
                <div className='read-form-input flex-1 mr--10 mt--15'>
                  <div className='read-form-label fs--14 font-semibold'>
                    Gender
                  </div>
                  <div className='read-form-value'>
                    { user.profile.gender || 'NA' }
                  </div>
                </div>
              </div>
              <div className='flex-x'>
                <div className='read-form-input flex-1 mr--10 mt--15'>
                  <div className='read-form-label fs--14 font-semibold'>
                    Blood Group
                  </div>
                  <div className='read-form-value'>
                    { user.profile.blood_group || 'NA'}
                  </div>
                </div>
                <div className='read-form-input flex-1 mr--10 mt--15'>
                  <div className='read-form-label fs--14 font-semibold'>
                    Education
                  </div>
                  <div className='read-form-value'>
                    { user.profile.education || 'NA'}
                  </div>
                </div>
              </div>
              <div className='flex-x'>
                <div className='read-form-input flex-1 mr--10 mt--15'>
                  <div className='read-form-label fs--14 font-semibold'>
                    Registration No
                  </div>
                  <div className='read-form-value'>
                    { user.profile.registration_no || 'NA'}
                  </div>
                </div>
                <div className='read-form-input flex-1 mr--10 mt--15'>
                  <div className='read-form-label fs--14 font-semibold'>
                    Registration Council
                  </div>
                  <div className='read-form-value'>
                    { user.profile.registration_council || 'NA'}
                  </div>
                </div>
              </div>
              <div className='flex-x'>
                <div className='read-form-input flex-1 mr--10 mt--15'>
                  <div className='read-form-label fs--14 font-semibold'>
                    License
                  </div>
                  <div className='read-form-value'>
                    {user.profile.license || 'NA'}
                  </div>
                </div>
                <div className='read-form-input flex-1 mr--10 mt--15'>
                  <div className='read-form-label fs--14 font-semibold'>
                    Practice Started Year
                  </div>
                  <div className='read-form-value'>
                    {user.profile.practice_started_year || 'NA'}
                  </div>
                </div>
              </div>
              <div className='flex-x'>
                <div className='read-form-input flex-1 mr--10 mt--15'>
                  <div className='read-form-label fs--14 font-semibold'>
                    Speciality
                  </div>
                  <div className='read-form-value'>
                    {user.profile.speciality || 'NA'}
                  </div>
                </div>
                <div className='read-form-input flex-1 mr--10 mt--15'>
                  <div className='read-form-label fs--14 font-semibold'>
                    Languages
                  </div>
                  <div className='read-form-value'>
                    {user.profile.languages || 'NA'}
                  </div>
                </div>
              </div>
              <div className='flex-x'>
                <div className='read-form-input flex-1 mr--10 mt--15'>
                  <div className='read-form-label fs--14 font-semibold'>
                    Bio
                  </div>
                  <div className='read-form-value'>
                    {user.profile.bio || 'NA'}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default ViewUser;
