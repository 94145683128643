import React from 'react'
import { Form, Modal, Input, Spin, Row, Col, Button, Select } from 'antd';
import moment from "moment";
const { Option } = Select;
const { TextArea } = Input;

export default function SaasDoctorsView(props) {

    const { doctorDetails, visible, close } = props;
    const [form] = Form.useForm();


    return (
        <>
            <Modal
                title={"Doctor Details"}
                visible={visible}
                onOk={close}
                onCancel={close}
                width={900}
                footer={[
                    <Button key='submit' type='primary' onClick={close} >
                        Ok
                    </Button>
                ]}
            >

                <Form form={form} className='gx-form-row0' layout='vertical'>
                    <Row className='space-between' style={{ marginTop: '3%' }}>

                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%' }}>
                                Name
                            </div>
                            <Input size='large'
                                value={doctorDetails && doctorDetails.full_name && doctorDetails.full_name ? doctorDetails.full_name : "NA"}
                            />
                        </Col>

                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                                Email ID
                            </div>
                            <Input size='large'
                                value={doctorDetails && doctorDetails.email && doctorDetails.email ? doctorDetails.email : "NA"}
                            />
                        </Col>
                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                                Mobile Number
                            </div>
                            <Input size='large'
                                value={doctorDetails && doctorDetails.mobile && doctorDetails.mobile ? doctorDetails.mobile : "NA"}
                            />
                        </Col>
                    </Row>

                    {/* Second Block */}


                    <Row className='space-between' style={{ marginTop: '3%' }}>

                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%' }}>
                                Total Appointments
                            </div>
                            <Input size='large'
                                value={doctorDetails && doctorDetails.total_appointments && doctorDetails.total_appointments}
                            />
                        </Col>

                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                                Total Slots Booked
                            </div>
                            <Input size='large'
                                value={doctorDetails && doctorDetails.total_slots_booked && doctorDetails.total_slots_booked}
                            />
                        </Col>
                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                                Total Slots Open
                            </div>
                            <Input size='large'
                                value={doctorDetails && doctorDetails.total_slots_opened && doctorDetails.total_slots_opened}
                            />
                        </Col>
                    </Row>
                    {/* Third Block */}

                    <Row className='space-between' style={{ marginTop: '3%' }}>

                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%' }}>
                                Experience
                            </div>
                            <Input size='large'
                                value={doctorDetails && doctorDetails.total_year_experience && doctorDetails.total_year_experience}
                            />
                        </Col>

                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                                Total Revenue
                            </div>
                            <Input size='large'
                                value={doctorDetails && doctorDetails.total_revenue && doctorDetails.total_revenue}
                            />
                        </Col>
                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                                Recommended Prescriptions
                            </div>
                            <Input size='large'
                                value={doctorDetails && doctorDetails.total_recommendation_eprescription && doctorDetails.total_recommendation_eprescription}
                            />
                        </Col>
                    </Row>

                    {/* Fourth Block */}


                    <Row className='space-between' style={{ marginTop: '3%' }}>

                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%' }}>
                                Recommended Lab Test Advices
                            </div>
                            <Input size='large'
                                value={doctorDetails && doctorDetails.total_recommendation_lab && doctorDetails.total_recommendation_lab}
                            />
                        </Col>

                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                                Purchased Prescriptions
                            </div>
                            <Input size='large'
                                value={doctorDetails && doctorDetails.total_recommendation_purchased_eprescription && doctorDetails.total_recommendation_purchased_eprescription}
                            />
                        </Col>
                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                                Purchased Lab Test Advices
                            </div>
                            <Input size='large'
                                value={doctorDetails && doctorDetails.total_recommendation_purchased_lab && doctorDetails.total_recommendation_purchased_lab}
                            />
                        </Col>
                    </Row>


                    <Row className='space-between' style={{ marginTop: '3%' }}>

                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%' }}>
                                Lab Test Advice Commission
                            </div>
                            <Input size='large'
                                value={doctorDetails && doctorDetails.profile && doctorDetails.profile.advice_commission ? doctorDetails.profile.advice_commission : "NA"}
                            />
                        </Col>

                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                                Prescription Commission
                            </div>
                            <Input size='large'
                                value={doctorDetails && doctorDetails.profile && doctorDetails.profile.medicine_prescription_commision ? doctorDetails.profile.medicine_prescription_commision : "NA"}
                            />
                        </Col>

                    </Row>


                </Form>

            </Modal>
        </>
    )
}

