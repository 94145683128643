import React from 'react'
import { Form, Modal, Input, Spin, Row, Col, Button, Select } from 'antd';
import moment from "moment";
const { Option } = Select;
const { TextArea } = Input;

export default function SaasPatientView(props) {

    const { patientDetails, visible, close } = props;
    const [form] = Form.useForm();

    console.log(props)

    return (
        <>
            <Modal
                title={"Patient Details"}
                visible={visible}
                onOk={close}
                onCancel={close}
                width={900}
                footer={[
                    <Button key='submit' type='primary' onClick={close} >
                        OK
                    </Button>
                ]}
            >

                <Form form={form} className='gx-form-row0' layout='vertical'>
                    <Row className='space-between' style={{ marginTop: '3%' }}>

                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%' }}>
                                Name
                            </div>
                            <Input size='large'
                                value={patientDetails && patientDetails.full_name && patientDetails.full_name ? patientDetails.full_name : "NA"}
                            />
                        </Col>

                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                                Email
                            </div>
                            <Input size='large'
                                value={patientDetails && patientDetails.email && patientDetails.email ? patientDetails.email : "NA"}
                            />
                        </Col>
                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                                Phone Number
                            </div>
                            <Input size='large'
                                value={patientDetails && patientDetails.mobile && patientDetails.mobile ? patientDetails.mobile : "NA"}
                            />
                        </Col>
                    </Row>

                    {/* Second Block */}


                    <Row className='space-between' style={{ marginTop: '3%' }}>

                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%' }}>
                                Date Of Birth
                            </div>
                            <Input size='large'
                                value={patientDetails && patientDetails.dob && patientDetails.dob ? moment(patientDetails.dob).format('LL') : "NA"}
                            />
                        </Col>

                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                                Blood Group
                            </div>
                            <Input size='large'
                                value={patientDetails && patientDetails.blood_group && patientDetails.blood_group ? patientDetails.blood_group : "NA"}
                            />
                        </Col>
                        <Col span={7} xs={7} md={7}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                                Gender
                            </div>
                            <Input size='large'
                                value={patientDetails && patientDetails.gender && patientDetails.gender ? patientDetails.gender : "NA"}
                            />
                        </Col>
                    </Row>


                    <Row style={{ marginTop: '3%' }}>

                        <Col span={24} xs={24} md={24}>
                            <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%' }}>
                                Address
                            </div>
                            <TextArea rows={3} size='large'
                                value={patientDetails && patientDetails.residential_Address && patientDetails.residential_Address[0] && patientDetails.residential_Address[0].street ? patientDetails.residential_Address[0].street : "NA"}
                            />
                        </Col>

                    </Row>

                </Form>

            </Modal>
        </>
    )
}

