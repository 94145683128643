const influencerActions ={
    INFLUENCER_REQUEST : "INFLUENCER_REQUEST",
    INFLUENCER_REQUEST_SUCCESS : "INFLUENCER_REQUEST_SUCCESS",

    EDIT_INFLUENCER : "EDIT_INFLUENCER",
    EDIT_INFLUENCER_SUCESS : "EDIT_INFLUENCER_SUCESS",
    

    EDIT_INTRODUCER : "EDIT_INTRODUCER",
    EDIT_INTRODUCER_SUCESS : "EDIT_INTRODUCER_SUCESS",

    ADD_INTRODUCER : "ADD_INTRODUCER",
    ADD_INTRODUCER_SUCESS : "ADD_INTRODUCER_SUCESS",

    GET_INFLUENCERS : "GET_INFLUENCERS",
    GET_INFLUENCERS_SUCCESS : "GET_INFLUENCERS",

    ON_ERROR: "ON_ERROR",

    register: (payload) =>{
        return{
        type: influencerActions.INFLUENCER_REQUEST,
        payload
    };
    },

    IntroducerRegister: (payload) =>{
      return{
      type: influencerActions.ADD_INTRODUCER,
      payload
  };
  },

    // editInfluencer: (data , uuid) =>{
    //     return{
    //         type: influencerActions.EDIT_INFLUENCER,
    //         data,
    //         uuid,
    //     };
    // },
    
    editIntoducer: (payLoad, uuid) => {
        console.log(payLoad,"Apathalogy")
        return {
            type: influencerActions.EDIT_INTRODUCER,
          payLoad,
          uuid,
      
        };
      },

      editInfluencer: (payLoad, uuid) => {
        console.log(payLoad,"Apathalogy")
        return {
            type: influencerActions.EDIT_INFLUENCER,
          payLoad,
          uuid,
      
        };
      },
      
  getInfluencers : ( pageNumber1, searchQuery1) => {
      return{
          type: influencerActions.GET_INFLUENCERS,
          pageNumber1,
          searchQuery1,
      };
  },


}
export default influencerActions;