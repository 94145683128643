import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import ComposeNotification from 'components/push-notifications/ComposeNotification';
import NotificationTable from 'components/push-notifications/notifications-table/NotificationTable'
import actions from "actions";
import { Helmet } from 'react-helmet';


const { pushNotificationAction } = actions;

export default function PushNotificationPage() {
    const dispatch = useDispatch();
    const [composeModelVisibility, setComposeModelVisibility] = useState(false);
    const notificationsData = useSelector((state) => state.pushNotificationReducer);

    useEffect(() => {
        dispatch(pushNotificationAction.getNotification(0));
    }, [])


    return (
        <div className="pictogram-container">
            <Helmet>
                <title>Doctall Super Admin Push Notification
</title>
<meta 
            name='description' 
            content='Create and send push notifications to patients. View status sent notifications'
         

   />
            </Helmet>
            <div className="pictogram-header flex-x align-center ptb--15">
                <div className="fs--16 font-semibold flex-1">{/* Users */}</div>
                <Button
                    style={{ height: 42 }}
                    type="primary"
                    onClick={() => setComposeModelVisibility(true)}
                >
                    Compose
                </Button>
            </div>
            <div>
                <NotificationTable
                    data={notificationsData}
                />
            </div>

            <ComposeNotification
                show={composeModelVisibility}
                close={() => { setComposeModelVisibility(false) }}
                onSubmit={(value, selectedCustomers, selectOption, key) => {
                    dispatch(pushNotificationAction.sendNotifications(value, selectedCustomers, selectOption, key));
                }}
            />

        </div>
    );
}
