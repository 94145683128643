import React, { useState, useEffect } from 'react';
import { Form, Modal, Input, Row, Col, Button, Select ,} from 'antd';
import DownloadOutlined from  '@ant-design/icons'
import axios from "axios"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

import 'antd/dist/antd.css';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { notification } from 'antd'

const WEB_API_BASE = process.env.REACT_APP_WEB_APP+`/user/auth/upload/company/consumer/data`;






const AddCompanyData = ({ visible, handleOk, handleCancel, onGetarea, newcity, newareas, onSubmit }) => {
  const [selectedFile, setSelectedFile] = React.useState(null);
//  console.log(selectedFile,"select")
const handleChange=(event)=> {

  setSelectedFile(event.target.files[0])
  }



  function handleSubmit(event) {
    event.preventDefault()
    const url = WEB_API_BASE;
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('fileName', selectedFile.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios.post(url, formData, config).then((response) => {
      console.log(response.data,"res12312");
      notification.open({ message: response.data.message, description: 'File Uploaded Successfully!' })
    }).catch((error)=>{
      notification.open({ message: error.data.message, description: 'Something went wrong' })
    });

  }
    

  




  
  return (
    <Modal
      className='no-modal-footer'
      title='Upload Users'
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={900}
      okText='Add'
      footer={[
        <Button className='login-form-button' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          type='primary'
          htmlType='submit'
          className='login-form-button ml--10'
        >
          Add
        </Button>
      ]}
    >
     <div className="App">
        <form >
          <h1>Upload File</h1>
          <br/>
          <input type="file" onChange={handleChange}/>
          <Button variant="contained" type="primary" onClick={handleSubmit}>Upload</Button>
          <br/><br/><br/>
        </form>
    </div>
    <p>Download Sample Format &nbsp; <span><a href="https://s3.eu-west-1.amazonaws.com/doctall.storage.com/new-doctall/bulk_upload_sample_format.xlsx">

    <SimCardDownloadIcon style={{ fill: '#0072ea' }} />
    </a>
    </span> </p>
    </Modal>
  );
};

export default AddCompanyData;
