const couponsActions = {
    GET_COUPONS: "GET_COUPONS",
    GET_COUPONS_SUCCESS: "GET_COUPONS_SUCCESS",

    GET_PROMOCODE: "GET_PROMOCODE",
    GET_PROMOCODE_SUCCESS: "GET_PROMOCODE_SUCCESS",


    CREATE_COUPONS: "CREATE_COUPONS",
    CREATE_COUPONS_SUCCESS: "CREATE_COUPONS_SUCCESS",
    CREATE_COUPONS_FAILURE: "CREATE_COUPONS_FAILURE",
    
    EDIT_COUPONS: "EDIT_COUPONS",
    EDIT_COUPONS_SUCCESS: "EDIT_COUPONS_SUCCESS",

    GET_USED_COUPONS: "GET_USED_COUPONS",
    GET_USED_COUPONS_SUCCESS: "GET_USED_COUPONS_SUCCESS",


    getCoupons: (pageNumber) => {
        return {
            type: couponsActions.GET_COUPONS,
            pageNumber
        };
    },

    getPromoCodes: (pageNumber, searchQuery) => {
        return {
            type: couponsActions.GET_PROMOCODE,
            pageNumber,
            searchQuery
        };
    },

    createCoupons: (payLoad) => {
        return {
            type: couponsActions.CREATE_COUPONS,
            payLoad,
        };
    },
    editCoupon: (payLoad, couponId) => {
        return {
            type: couponsActions.EDIT_COUPONS,
            payLoad,
            couponId
        };
    }
};

export default couponsActions;