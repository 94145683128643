import React, { useState } from 'react';
import './login.scss';
import { yoopha, doctall } from 'helper/constant';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import authActions from 'actions';
import { Form, Input, Row, Col, Button } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

import { useDispatch } from 'react-redux';
import SaasPatientView from 'components/saas/views/SaasPatientView';
import { Helmet } from 'react-helmet';

const suffix = (
  <EyeOutlined
    style={{
      fontSize: 16,
      color: '#1890ff',
      cursor: "pointer"
    }}
  />
);

const { login } = authActions.authActions;

const Login = () => {
  const dispatch = useDispatch();

  const handleLoginMethod = values => {
    dispatch(login({
      username: values.username,
      password: values.password
    }))
  };
  const [view, setView] = useState(false)
  const viewPass = () => {
    setView(!view)
  }

  return (
    <div className='login-container flex'>
      <Helmet>
        <title>Doctall Super Admin Login
</title>
<meta 
            name='description' 
            content='Doctall Super Admin platform created to spport the app. Create accounts for users, pharmacies, doctors, specialists, etc View insight on the number of sign-ups, booked appointments, purchased treatment plans, completed orders and. much more '
         

   />
      </Helmet>
      <div className='right-side'>
        <div className='form-container'>
          <div className='logo'>
            <img src={ doctall && doctall.default?doctall.default:doctall} alt="logo" />
          </div>
          <div className='mt--24'>
            <Form
              onFinish={handleLoginMethod}
              layout='vertical'
            >
              <Row>
                <Col span={24} xs={24} md={24}>
                  <Form.Item
                    label='User Name'
                    name='username'
                    rules={[{ required: true, message: 'Please enter username!' }]}
                  >
                    <Input
                      size='large'
                    />
                  </Form.Item>
                </Col>
                <Col span={24} xs={24} md={24}>
                  <Form.Item
                    label='Password'
                    name='password'
                    rules={[
                      { required: true, message: 'Please enter password!' }
                    ]}
                  >
                    <Input type={view == true ? "text" : "password"} size='large'
                      suffix={
                        view == true ?
                          <EyeOutlined
                            style={{
                              fontSize: 16,
                              color: '#1890ff',
                              cursor: "pointer"
                            }}
                            onClick={viewPass}
                          />
                          :
                          <EyeInvisibleOutlined
                            style={{
                              fontSize: 16,
                              color: '#1890ff',
                              cursor: "pointer"
                            }}
                            onClick={viewPass}
                          ></EyeInvisibleOutlined>
                      } />
                  </Form.Item>

                </Col>
                <Col span={24} xs={24} md={24}>
                  <Button type='submit' htmlType="submit" className='login-button'>
                    LOG IN
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withRouter)(Login);
