import React, { useState, useEffect } from "react";
import { Button, Input } from "antd";
import AddUser from "components/users/AddUser";
import AddPartner from "components/users/AddPartner";
import Users from "components/users/Users";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import actions from "actions";
import moment from "moment";
import { DatePicker, InputNumber, Space, Select, notification } from 'antd';
import axios from 'axios';
import { exportToExcel } from "services/ExportServices";
import { Helmet } from 'react-helmet';
const Search = Input.Search;

const { Option } = Select;
const { RangePicker } = DatePicker;

const { userActions, commonActions } = actions;

const UsersView = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('')
  const [visible, setvisible] = useState(false);
  const [exptData, setExptData] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [appointmentNumber, setAppointmentNumber] = useState();
  const [visiblePartner, setvisiblePartner] = useState(false);
  const users = useSelector((state) => state.users.data);
  const count = useSelector((state) => state.users.count);
  const cities = useSelector((state) => state.common.cities);
  const states = useSelector((state) => state.common.states);
  const partners = useSelector((state) => state.users.partners);
  const authToken = useSelector((state) => state.auth.accessToken);
  const users1 = useSelector((state) => state.auth);


  const loader = useSelector((state) => state.users.loader);
  const skip = useSelector((state) => state.users.skip);
  const limit = useSelector((state) => state.users.limit);
  const citiesnew = useSelector((state) => state.common.newcities);
  const areasnew = useSelector((state) => state.common.newareas);




  const test = useSelector((state) => state.common);
  console.log(test,"test")
  const areas = useSelector((state) => state.common.areas);

  useEffect(() => {
    dispatch(userActions.getUsers(searchQuery));
  }, [skip, limit, searchQuery]);

//  useEffect(() => {
//    dispatch()
//  })
  useEffect(() => {
    dispatch(userActions.getPartner());
  }, []);

  useEffect(() => {
    dispatch(userActions.getUsers(searchQuery));
    dispatch(commonActions.getMetadata());
    dispatch(commonActions.getallnewcities());
  }, [dispatch, searchQuery]);

  const handleOnSearch = (value) => {
    setSearchQuery(value)
  }



  const onExportClick = async () => {
    try {
      let APIENDPOINT = ''
      if (toDate === undefined || fromDate === undefined) {
        notification.open({
          message: "Please Select Dates",
          duration: 2,
        });
      } 
      else {
        // if (moment(toDate).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD") || moment(fromDate).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
        //   notification.open({
        //     message: "Invalid/future Date",
        //     duration: 1,
        //   });
        // }
        // else {
          if (appointmentNumber === undefined || appointmentNumber === '' || appointmentNumber === null) {
            APIENDPOINT = `/user/export/all/userlist/${fromDate}/${toDate}`
          } else {
            APIENDPOINT = `/user/export/userlist/${fromDate}/${toDate}/${appointmentNumber}`
          }
          await exportToExcel(APIENDPOINT, "Users_", authToken)
        // }
      }

    } catch (error) {
      notification.open({
        message: "Failed to export",
        duration: 1,
      });
    }
  }

  function onChange(dates, dateStrings) {
    setFromDate(dateStrings[0])
    setToDate(dateStrings[1])
  }



  return (
    <>
     <Helmet>
    <title>Doctall Super Admin Users
</title>
<meta 
            name='description' 
            content='View details on all users at a glance. See name, contact information and status of users. Add new users to the platform'
         

   />
  </Helmet>
      <div className="pictogram-container">

        <div className="pictogram-header flex-x align-center ptb--15">

          <div>

            <Space direction="vertical" size={12}>

              <RangePicker
                ranges={{
                  Today: [moment(), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}
                format="YYYY-MM-DD"
                onChange={onChange}
              />

            </Space>
            {/* <InputNumber style={{ width: 150, marginLeft: 20 }} placeholder='No of Appointments' min={0} max={100} value={appointmentNumber} onChange={setAppointmentNumber} /> */}

            <Button style={{ height: 32, marginRight: 10, marginLeft: 20 }} type="primary" onClick={onExportClick}>
              Export
            </Button>


          </div>



          <div className="fs--16 font-semibold flex-1">{/* Users */}</div>
          {users1 && users1.admin && users1.admin.role !== "analytics-admin" ?
          <>
          <Button
            style={{ height: 42, marginRight: 10 }}
            type="primary"
            onClick={() => setvisiblePartner(true)}
          >
            Add Partner Details
          </Button>
          <Button
            style={{ height: 42 }}
            type="primary"
            onClick={() => setvisible(true)}
          >
            Add User
          </Button>
          </>
          :
          ''
          }
        </div>
        <div> <Space direction="vertical">
          <Search
            size="large"
            enterButton="Search"
            placeholder="Search User"
            onSearch={handleOnSearch}
          />
        </Space></div>
        <div>
          <Users data={users} limit={limit} count={count} loader={loader} />
        </div>
        <AddUser
          visible={visible}
          handleOk={() => setvisible(false)}
          onSubmit={(value) => {
            dispatch(userActions.addUser(value));
          }}
          handleCancel={() => setvisible(false)}
        />
        <AddPartner
          visible={visiblePartner}
          partnerUser={partners}
          handleOk={() => setvisiblePartner(false)}
          cities={cities}
          states={states}
          newcity={citiesnew}
          newareas={areasnew}
          areas={areas}
          onGetarea={(value) => {
            console.log("area222", value);
            dispatch(commonActions.getallnewarea(value));
          }}
          onSubmit={(value, type) => {
            console.log("easy", value, type);
            switch (type) {
              case "addpartner":
                dispatch(userActions.addPartners(value));
                break;
              case "clinic":
                dispatch(userActions.addClinic(value));
                break;
              case "lab":
                dispatch(userActions.addLab(value));
                break;
              case "pharmacy":
                dispatch(userActions.addPharmacy(value));
                break;
              default:
                console.log("invalid type");
            }
          }}
          handleCancel={() => setvisiblePartner(false)}
        />
      </div>
    </>
  );

};

export default UsersView;
