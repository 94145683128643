import React, { useState, useEffect } from 'react';
// import { Button } from 'antd';
import { Form, Modal, Input, Row, Col, Button, Select } from 'antd';
// import AddPharmacy from 'components/pharmacies/AddPharmacy';
// import PartnerName from "components/Partners_name/AddPartners"
import AddHomeSampleCollection from "components/homeSampleCollections/addHomeSampleCollection"
import HomeSampleCollection from 'components/homeSampleCollections/HomeSampleCollections';
import { useDispatch, useSelector } from "react-redux";
import actions from "actions";
import pharmacyActions from 'actions/pharmacy';
import { notification } from 'antd'
import axios from 'axios';

const { commonActions } = actions;
const { master } = actions
console.log(master, "master")
const Nationala = () => {
    const dispatch = useDispatch();
    const [visible, setvisible] = useState(false);
    const [open, setOpen] = useState(false);
    const citiesnew = useSelector((state) => state.common.newcities);
    const areasnew = useSelector((state) => state.common.newareas);
    const masterPartner = useSelector((state) => state.master);

    useEffect(() => {
        dispatch(master.getCityDta());
    }, []);

    useEffect(() => {
        dispatch(commonActions.getMetadata());
        dispatch(commonActions.getallnewcities());
    }, [dispatch]);

    const [file, setFile] = useState()
    console.log(file, "dfsfsdfsffsfs");
    function handleChange(event) {
        setFile(event.target.files[0])
    }

    // function handleSubmit(event) {
    //     if (!file) {
    //         console.log("zxczxczxcz")
    //         // toast.error("Please Select File")
    //     }
    //     else {
    //         const formData = new FormData();
    //         formData.append('file', file);
    //         formData.append('fileName', file.name);
    //         axios.post(`${process.env.REACT_APP_WEB_APP}/uploadDoc/newmasterpathalogy`, formData)
    //             .then((res) => {
    //                 notification.open({ message: 'Success', description: 'Details Updated Successfully' })
    //                 setTimeout(() => {
    //                     // console.log('Hello, World!')
    //                     setOpen(false)
    //                     setFile()
    //                 }, 3000);
    //             })
    //             .catch((err) => {
    //                 notification.open({ message: 'Failed!', description: err })
    //             })
    //     }
    // }

    return (
        <div className="pictogram-container">
            <div className="pictogram-header flex-x align-center ptb--15">
                <div className="fs--16 font-semibold flex-1">
                    {/* Pharmacist Partners */}
                    Home Sample Collection
                </div>
                {/* <Button style={{ height: 42 }} type="primary" onClick={() => setOpen(true)}>Bulk Upload</Button> */}
                &nbsp;&nbsp;&nbsp;
                <Button style={{ height: 42 }} type="primary" onClick={() => setvisible(true)}>Add</Button>
            </div>
            <div>
                <HomeSampleCollection
                    city={masterPartner}
                />
            </div>
            <AddHomeSampleCollection
                city={masterPartner}
                visible={visible}
                handleOk={() => setvisible(false)}
                // handleCancel={() => setvisible(false)}
                handleCancel={() => { setvisible(false); window.location.reload(false) }}
                newcity={citiesnew}
                newareas={areasnew}
                // onGetarea={(value) => {
                //     console.log("area222", value);
                //     dispatch(commonActions.getallnewarea(value));
                // }}
                onSubmit={(value, type) => {
                    console.log("easy", value, type);
                    dispatch(master.addhomeSampleCollection(value));
                }}
            />
            {/* <Modal
                className='no-modal-footer'
                title='Add Pathology Bulk Upload'
                visible={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                // onClick={() => setvisible(true)}
                width={900}
                okText='Add'
                footer={[
                    <Button className='login-form-button'
                        // onClick={handleCancel}
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </Button>,
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='login-form-button ml--10'
                    >
                        Add
                    </Button>
                ]}
            >
                <div className="App">
                    <Row className="space-between">
                        <Col span={14} xs={14} md={14}>
                            <input type="file" onChange={handleChange} className="form-control" />
                        </Col>
                        <br />
                        <br />
                        <Col span={14} xs={14} md={14}>
                            <p><a href="https://s3.eu-west-1.amazonaws.com/doctall.storage.com/new-doctall/sample-pathalogy-file+(1).xlsx">Download Sample File  </a> </p>
                        </Col>
                        <br />
                        <br />
                        <Col span={18} xs={18} md={18}>
                            <Button className='login-form-button'
                                // onClick={handleCancel}
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col span={3} xs={3} md={3}>
                            <Button
                                type='primary'
                                htmlType='submit'
                                className='login-form-button ml--10'
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal> */}
        </div>
    );
};

export default Nationala;