import actions from 'actions';

const { partnersActions } = actions;

const initState = {
    loader: false,
    data: [],
    limit: 10,
    skip: 0,
    count: 0,
};

export default function rootReducer(state = initState, action) {
    switch (action.type) {

        case partnersActions.GET_PARTNERS:
            return {
                ...state,
                loader: true,
                data: action.data,
                count: action.count,
            };
        case partnersActions.GET_PARTNERS_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.data,
                count: action.count,
            };
        case partnersActions.ON_ERROR:
            return {
                ...state,
                loader: false
            };
        case partnersActions.ADDING_PARTNER:
            return {
                ...state,
                loader: true
            };
        case partnersActions.EDIT_PARTNER:
            return {
                ...state,
                loader: true
            };
        case partnersActions.ADDING_PARTNER_SUCCESS:
            return {
                ...state,
                loader: false
            };
        case partnersActions.EDIT_PARTNER_SUCCESS:
            return {
                ...state,
                loader: false
            };

        default:
            return state;
    }
}
