import axios from 'axios';
import { setHeadersWithAccessToken } from './index';
const WEB_API_BASE = process.env.REACT_APP_WEB_APP;

export const getFeedbacks = (auth,query) => {
  // console.log("service feed",query.type)
  // console.log("service feed2",query)
  setHeadersWithAccessToken(auth)
  // http://test-api.doctall.com/api/v1/booking/feedback/feedBackFilterForAdmin?type=doctor&doctor_uuid=DR-B9196AE3
  // return axios.get(`${WEB_API_BASE}/booking/feedback/getAllFeedbacks`,{
    // return axios.get(`${WEB_API_BASE}/booking/feedback/feedBackFilterForAdmin/?type=${query.type}`).then(e => e);

    if(query.type=="patient"){
      return axios.get(`${WEB_API_BASE}/booking/feedback/feedBackFilterForAdmin/?type=${query.type}&patient_uuid=${query.patient_uuid}`).then(e => e);

  }
    else if(query.type=="doctor"){
      return axios.get(`${WEB_API_BASE}/booking/feedback/feedBackFilterForAdmin/?type=${query.type}&doctor_uuid=${query.doctor_uuid}`).then(e => e);

    }
    else{
      return axios.get(`${WEB_API_BASE}/booking/feedback/feedBackFilterForAdmin/?type=${query.type}`).then(e => e);

    }
    
};