import axios from 'axios';
import { setHeadersWithAccessToken } from './index';
import { useDispatch, useSelector } from "react-redux";

const WEB_API_BASE = process.env.REACT_APP_WEB_APP;


export const getorderservice = (auth, pageNumber, user_id) => {
  // console.log(query)
  setHeadersWithAccessToken(auth);

  let limit = "10"
  let page;
  if (pageNumber === undefined) {
      page = 0
  } else {
      page = pageNumber
  }
    return axios.get(`${WEB_API_BASE}/doctallnew/order/admin/treatmentplanlist?limit=${limit}&pagenum=${page}&user_uuid=${user_id}&search=`, {
    }).then(e => e);
//   } else {
//     alert("else");
//     return axios.get(`${WEB_API_BASE}/order/admin/fullorderdetails?partnerName=${query}&search=${queryText}`, {
//     }).then(e => e);
//   }

};


export const changeOrderstatus = (auth, orderId, status, itemId) => {
  setHeadersWithAccessToken(auth);

  let payLoad = {
    "order_id": orderId.orderId,
    "doctall_action": orderId.status,
    "test_id": orderId.itemId
  }
  return axios.put(`${WEB_API_BASE}/order/admin/update/doctalAction`, payLoad).then(e => e);
};


