const GETPATIENTSBYCON = {
  GET_UNIQUE_PATIENTS: "GET_UNIQUE_PATIENTS",
  GET_UNIQUE_PATIENTS_SUCCESS: "GET_UNIQUE_PATIENTS_SUCCESS",
  getPatientsByConsumer: (params) => {
    console.log(params, "actionparams");
    return {
      type: GETPATIENTSBYCON.GET_UNIQUE_PATIENTS,
      params,
    };
  },
};

export default GETPATIENTSBYCON;
