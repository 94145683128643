import auth from "./auth";
import users from "./users";
import common from "./common";
import feedback from "./feedback";
import doctors from "./doctor";
import pharmacies from "./pharmacy";
import appointements from "./appointments";
import patients from "./patients";
import transaction from "./transactions";
import consumer from "./consumer";
import bookingAppointment from './bookApointment';
import getPatients from './getPatients';
import allSlots from './getSlots';
import allSlotsByDoctor from './getSlotsDoctor';
import order from './order';
import tratmentorder from './treatment_order';
import { combineReducers } from "redux";
import PostAppointment from './PostAppointment';
import { routerReducer } from "react-router-redux";
import pushNotificationReducer from "./pushnotification";
import couponsReducer from './couponsReducer';
import usedCouponsReducer from './UsedCouponsReducer';
import partnersReducers from './partnersReducer';
import saasReducer from './saasReducer';
import { snackBarReducer } from "./snackBarReducer";
import master from "./masterData";
import influencerReducer  from "./influencerReducer";

const createReducer = asyncReducers =>
  combineReducers({
    auth,
    users,
    common,
    feedback,
    doctors,
    pharmacies,
    appointements,
    patients,
    saasReducer,
    transaction,
    consumer,
    bookingAppointment,
    getPatients,
    allSlots,
    allSlotsByDoctor,
    PostAppointment,
    order,
    tratmentorder,
    snackBarReducer,
    pushNotificationReducer,
    couponsReducer,
    usedCouponsReducer,
    partnersReducers,
    routing: routerReducer,
    master,
    influencerReducer,

    ...asyncReducers
  });

export default createReducer;
