import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Space } from "antd";
import actions from "actions";
import axios from 'axios';
import CouponsTable from 'components/coupons/coupon-table/CouponsTable';
import CreateCoupon from 'components/coupons/CreateCoupon';
import { exportToExcel } from 'services/ExportServices';

const Search = Input.Search;
const { couponsActions } = actions;

export default function PromoCodePage() {

    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState('')
    const [createCouponVisibility, setCreateCouponVisibility] = useState(false);

    const couponsData = useSelector((state) => state.couponsReducer);
    const authToken = useSelector((state) => state.auth.accessToken);


    useEffect(() => {
        dispatch(couponsActions.getPromoCodes(0, searchQuery))
    }, [searchQuery])

    const handleOnSearch = (value) => {
        setSearchQuery(value)
    }

    const handleExport = async () => {
        await exportToExcel('coupon/dmp/export/toexcel', "DMP-REPORT", authToken)
    }

    return (
        <>
            <div className="pictogram-container">
                <div className="pictogram-header flex-x align-center ptb--15">
                    <Space direction="vertical">
                        <Search
                            size="large"
                            enterButton="Search"
                            placeholder="Search Record"
                            onSearch={handleOnSearch}
                        />
                    </Space>
                    <div className="fs--16 font-semibold flex-1">{/* Users */}</div>

                    <Button type="primary" onClick={handleExport}>Export</Button>

                </div>
                <div>
                    <CouponsTable
                        couponsData={couponsData}
                        isPromoCode={true}
                    />
                </div>
                <CreateCoupon
                    show={createCouponVisibility}
                    close={() => { setCreateCouponVisibility(false) }}
                    onSubmit={(payLoad) => {
                        dispatch(couponsActions.createCoupons(payLoad));
                    }}
                />
            </div>
        </>
    )
}

