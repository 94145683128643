import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Form, Modal, Input, Row, Col, Button, Select } from "antd";
import { Spin } from "antd";
import { view_log, green_dot, red_dot } from "helper/constant";
import ViewAppointments from "./ViewAppointments";
import { useDispatch, useSelector } from "react-redux";
import actions from "actions";
import moment from "moment";
import { CSVLink } from "react-csv";
import { DatePicker } from "antd";
import { Helmet } from "react-helmet";
const { appointmentActions } = actions;
const { Option } = Select;

const {
  BookAppointments,
  GETPATIENTSBYCON,
  GETSLOTBYNAME,
  GETSLOTBYDOCTOR,
  POST_APPOINTMENT,
} = actions;

const Appointments = () => {
  const [visible, setvisible] = useState(false);
  const [user, setuser] = useState(null);
  const [date, setDate] = useState(new Date());
  const [dd, setDD] = useState(new Date());
  const [date1, setDate1] = useState(new Date());
  const [consumerId, setConsumerId] = useState("");
  const [exptData, setExptData] = useState([]);
  const [query, setQuery] = useState({
    booked_date: moment(date).format("YYYY-MM-DD"),
  });
  const [query1, setQuery1] = useState({
    booked_end_date: moment(date1).format("YYYY-MM-DD"),
  });

  const dispatch = useDispatch();

  const columns = [
    {
      title: "Booking Id",
      dataIndex: "uuid",
      key: "uuid",
    },
    {
      title: "Booking Date",
      dataIndex: "booking_date",
      key: "booking_date",
      render: (text, row) => (
        <div>{moment(row.createdAt).format("DD/MM/yyyy")}</div>
      ),
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
      render: (text, row) => (
        <div>{moment(row.start_time).format("DD/MM/yyyy hh:mm a")}</div>
      ),
    },
    // {
    //   title: 'End Time',
    //   dataIndex: 'end_time',
    //   key: 'end_time',
    //   render: (text, row) => (
    //     <div>
    //       {moment(row.end_time).format('DD/MM/yyyy hh:mm a')}
    //     </div>
    //   )
    // },
    {
      title: "Appointment Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Appointment Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "View",
      dataIndex: "eye",
      key: "eye",
      render: (text, row) => (
        <img
          className="ml--10 cursor-pointer"
          src={view_log && view_log.default?view_log.default:view_log}
          alt="view"
          onClick={() => {
            console.log("view");
            setuser(row);
            setvisible(true);
          }}
        />
      ),
    },
  ];

  const payload = useSelector((state) => state.appointements);
  console.log(payload,"payload")
  const conPayload = useSelector((state) => state.bookingAppointment);
  const payloadDoctor = useSelector((state) => state.allSlots);
  const dataSrc = useSelector((state) => state.appointements.appointements);

  useEffect(() => {
    dispatch(BookAppointments.getAppointment(0));
  }, []);

  useEffect(() => {
    dispatch(GETSLOTBYNAME.getSlots());
  }, []);

  function onChange(date, dateString) {
    setDate(date);
    setDD(date)
    setQuery({ booked_date: moment(date).format("YYYY-MM-DD") });
  }
  function onChange1(date, dateString) {
    setDate1(date);
    setQuery({ booked_date: moment(dd).format("YYYY-MM-DD"), booked_end_date: moment(date).format("YYYY-MM-DD") });
  }

  useEffect(() => {
    dispatch(
      appointmentActions.getAppointments(
        // booking_date:moment(date).format('YYYY-MM-DD')
        // booked_date:moment(date).format('YYYY-MM-DD'),
        query
      )
    );
  }, [date, query]);
  console.log(dataSrc);
  let exptHead = [
    "Booking Id",
    "Booking Date",
    "Start Time",
    "end_time",
    "Appointment Type",
    "Appointment Status",
    "Patient Full Name",
    "Patient Email",
    "Patient Phone Number",
    "Doctor Name",
    "Amount",
    "Number of Prescription",
    "Number of Lab Test",
    "Reference Id",
    "Transaction Id",
  ];

  useEffect(() => {
    let exportDataForming = [];
    dataSrc.map((user) => {
      console.log(user,"sdd")
      let innerActivityRow = [
        user && user.uuid,
        user && moment(user.createdAt).format("DD/MM/yyyy"),
        user && moment(user.start_time).format("DD/MM/yyyy hh:mm a"),
        user && moment(user.end_time).format("DD/MM/yyyy hh:mm a"),
        user && user.type,
        user && user.status,
        user && user.patient && user.patient.full_name,
        user && user.patient && user.patient.email,
        user && user.patient && user.patient.mobile,
        user && user.doctors[0] && user.doctors[0].full_name,
        user && user.amount,
        user &&
          user.eprescriptions_count &&
          user.eprescriptions_count &&
          user.eprescriptions_count.total_eprescription !== undefined
          ? user.eprescriptions_count.total_eprescription
          : "No Data",
        user &&
          user.eprescription_labs_count &&
          user.eprescription_labs_count &&
          user.eprescription_labs_count.total_eprescription_lab !== undefined
          ? user.eprescription_labs_count.total_eprescription_lab
          : "No Data",
        user && user.ref_id !== undefined ? user.ref_id : "No Data",
        user && user.transaction !== undefined
          ? user.transaction.reference_id
          : "No Data",
      ];
      exportDataForming.push(innerActivityRow);
    });
    setExptData(exportDataForming);
  }, [dataSrc]);

  return (
    <div>
      <Helmet>
        <title>Doctall Super Admin Appointments
</title>
<meta 
            name='description' 
            content='View details on appointments. See booking date, appoinment type as well as status of the appointment'
         

   />
      </Helmet>
      <DatePicker onChange={onChange} placeholder="Start Date"/>
      <DatePicker onChange={onChange1} style={{  marginRight: 10, marginLeft: 10 }} placeholder="End Date"/>
      <Select
        placeholder=" Appointment Status"
        size=",medium"
        style={{ width: 200, marginRight: 10, marginLeft: 10 }}
        showSearch
        autoClearSearchValue
        allowClear
        optionFilterProp="children"
        labelInValue
        onChange={(value, other) => {
          if (value && value.value) {
            setQuery({ status: value.value });
            console.log("statys", value.value);
          }
        }}
      // value={group}
      >
        <Option value="booked">Booked</Option>
        <Option value="rescheduled">Rescheduled</Option>

        {/* <Option  value='pending'>Pending</Option> */}
        <Option value="cancelled">Cancelled</Option>
        <Option value="declined">Declined</Option>
        <Option value="closed">Closed</Option>
        <Option value="missed">Missed</Option>
        <Option value="extended">Extended</Option>
        <Option value="followup">Followup</Option>
        {/* 'booked', 'rescheduled', 'pending', 'cancelled', 'declined', 'closed', 'missed', 'extended','followup' */}
      </Select>

      <Select
        placeholder="Appointment Type"
        size=",medium"
        style={{ width: 200, marginRight: 10, marginLeft: 10 }}
        showSearch
        autoClearSearchValue
        allowClear
        optionFilterProp="children"
        labelInValue
        onChange={(value, other) => {
          if (value && value.value) {
            setQuery({ type: value.value });
            console.log("type", value.value);
          }
        }}
      >
        <Option value="free">Free</Option>
        <Option value="sadn">See A Doctor Now</Option>
        <Option value="Find a doctor">Find a Doctor</Option>
        <Option value="international">International</Option>
      </Select>

      <Select
        placeholder="Consumer Mobile"
        size=",medium"
        style={{ width: 250, marginRight: 10 }}
        showSearch
        autoClearSearchValue
        allowClear
        optionFilterProp="children"
        labelInValue
        onChange={(value, other) => {
          if (value && value.value) {
            setQuery({ "created_by.uuid": value.value });
            console.log("created_by.uuid", value.value);
          }
        }}
      >
        {conPayload.allconsumers &&
        conPayload.allconsumers.data &&
          conPayload.allconsumers.data.map((option, i) => (
            <Option key={option._id} value={option.uuid}>
              {option.mobile}
            </Option>
          ))}
      </Select>

      <Select
        placeholder="Doctor name"
        size="medium"
        style={{ width: 250, marginRight: 10 }}
        showSearch
        autoClearSearchValue
        allowClear
        optionFilterProp="children"
        labelInValue
        onChange={(value, other) => {
          if (value && value.value) {
            setQuery({ doctor_uuid: value.value });
            console.log("doctor_uuid", value.value);
          }
        }}
      >
        {payloadDoctor.allSlots.data &&
          payloadDoctor.allSlots.data.map((option, i) => (
            <Option key={option._id} value={option.uuid}>
              {option.full_name}
            </Option>
          ))}
      </Select>

      <CSVLink
        data={exptData}
        headers={exptHead}
        filename={`User${moment().format("DD/MM/YYYY hh/mm")}.csv`}
        style={{ color: "#ffff" }}
      >
        <Button style={{ height: 42, marginRight: 10 }} type="primary"
          disabled={dataSrc.length !== 0 ? false : true}>
          Export
        </Button>
      </CSVLink>

      <Spin spinning={payload.loader}>
        <Table
          columns={columns}
          dataSource={payload.appointements}
          loader={true}
          size="middle"
          pagination={{
            defaultPageSize: 12,
          }}
        />
      </Spin>
      <ViewAppointments
        visible={visible}
        loader={false}
        handleOk={() => setvisible(false)}
        handleCancel={() => setvisible(false)}
        user={user}
      />
    </div>
  );
};

export default Appointments;
