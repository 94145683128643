import React, { useState } from 'react';
import { Button } from 'antd';
import AddDoctor from 'components/doctors/AddDoctor';
import Doctors from 'components/doctors/Doctors';

const DoctorsView = () => {
    const [visible, setvisible] = useState(false);
    return (
        <div className="pictogram-container">
            <div className="pictogram-header flex-x align-center ptb--15">
                <div className="fs--16 font-semibold flex-1">
                    {/* Doctors */}
                </div>
                {/* <Button style={{ height: 42 }} type="primary" onClick={() => setvisible(true)}>Add</Button> */}
            </div>
            <div>
                <Doctors />
            </div>
            <AddDoctor
                visible={visible}
                handleOk={() => setvisible(false)}
                handleCancel={() => setvisible(false)}
            />
        </div>
    );
};

export default DoctorsView;