import { all, takeEvery, put, fork,select } from 'redux-saga/effects';
import actions from 'actions';
import { getAppointments,cancelAppointmentsService,reScheduledAppointmentsService } from 'services/appointement-services';
import { notification } from 'antd';
const { appointmentActions, authActions } = actions;

export function* getAppointmentsSaga() {
  yield takeEvery(appointmentActions.GET_APPOINTMENT, function*({params}) {
    try{
      const token = yield select(state => state.auth.accessToken);
      const response = yield getAppointments({token},params);
        const { data } = response;
        yield put({
          type: appointmentActions.GET_APPOINTMENT_SUCCESS,
          data
        });
    } catch(error){
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* cancelAppointmentsSaga() {
  yield takeEvery(appointmentActions.CANCEL_APPOINTMENT, function*({params}) {
    try{
      const token = yield select(state => state.auth.accessToken);
      const response = yield cancelAppointmentsService({token},params);

        const { data } = response;
        yield put({
          type: appointmentActions.CANCEL_APPOINTMENT_SUCCESS,
          data
        });
        if (response.status === 200) {
            yield notification.open({
              message: 'Success!',
              description: 'booking cancelled Successfully!'
            });
        }
                    
    } catch(error){
      // console.log("eeeeeeeee",error.response.data)
      yield notification.open({ message: 'Failed!', description: error.response.data })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
   
  });
}



export function* rescheduleAppointmentsSaga() {
  yield takeEvery(appointmentActions.RE_APPOINTMENT, function*({datas}) {
    try{
      const token = yield select(state => state.auth.accessToken);
      console.log("sagdatasuccess oppitment",datas);

      const response = yield reScheduledAppointmentsService({token},datas);
        const { data } = response;
        yield put({
          type: appointmentActions.RE_APPOINTMENT_SUCCESS,
          data
        });
        if (response.status === 201) {

          
          
            yield notification.open({
              message: 'Success!',
              description: 'booking Rescheduled Successfully!'
            });
        }
          
                  
    } catch(error){
      // yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      yield notification.open({ message: 'Failed!', description: error.response.data })

      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}


export default function* rootSaga() {
  yield all([fork(getAppointmentsSaga),fork(cancelAppointmentsSaga),fork(rescheduleAppointmentsSaga)]);
}
