import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { Table, Checkbox } from 'antd';
import moment from "moment";
import { view_log, green_dot, red_dot, triple_dots } from 'helper/constant';
import actions from "actions";
import UsedCouponView from '../coupon-view/UsedCouponView';


const { usedCouponsActions } = actions;

export default function UsedCouponsTable(props) {
    const dispatch = useDispatch();
    const { couponsData } = props;
    const [couponView, setCouponView] = useState(false);
    const [couponEdit, setCouponEdit] = useState(false);
    const [couponDetails, setCouponDetails] = useState();

    const columns = [
        {
            title: 'Date',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text, row) => (
                <div>{row && row.updatedAt && moment(row.updatedAt).format('ll')}</div>
            ),

        },
        {
            title: 'User Name',
            dataIndex: 'user_uuid',
            key: 'user_uuid',
            render: (text, row) => (
                <div>{row && 0 in row.user && row.user[0].full_name}</div>
            ),
        },
        {
            title: 'Coupon Code',
            dataIndex: 'coupon_code',
            key: 'coupon_code',

        },
        {
            title: 'Coupon Type',
            dataIndex: 'coupon[0].category',
            key: 'coupon_code',
            render: (text, row) => (
                <div>{row && 0 in row.coupon && row.coupon[0].category}</div>
            ),
        },
        {
            title: 'Appointment Type',
            dataIndex: 'coupon_code',
            key: 'coupon_code',
            render: (text, row) => (
                <div>{row && 0 in row.coupon && row.coupon[0].sub_category && row.coupon[0].sub_category === "sadn" ? "SADN" : "FADN"}</div>
            ),
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount'
        },
        {
            title: 'Total Amount',
            dataIndex: 'total_amount',
            key: 'total_amount'
        },

        {
            title: 'View',
            dataIndex: 'eye',
            key: 'eye',
            render: (text, row) => (
                <img
                    className='ml--10 cursor-pointer'
                    src={view_log && view_log.default?view_log.default:view_log}
                    alt='view'
                    onClick={() => {
                        setCouponDetails(row)
                        setCouponView(true)
                    }}
                />
            )
        }

    ];



    return (
        <div>
            <Table
                columns={columns}
                bordered={true}
                dataSource={couponsData && couponsData.data && couponsData.data.data}
                size='middle'
                loading={couponsData && couponsData.loader}
                pagination={{
                    total: couponsData && couponsData.count,
                    showSizeChanger: false,
                    pageSize: 20
                }}

                onChange={(pagination) => {
                    let pageNumber = ''
                    if (pagination.current === 1) {
                        pageNumber = 0
                    } else {
                        pageNumber = pagination.current - 1
                    }
                    dispatch(usedCouponsActions.getUsedCoupons(pageNumber, ""));
                }}
            />

            <UsedCouponView
                couponsDetails={couponDetails}
                visible={couponView}
                close={() => { setCouponView(false) }}
            />

        </div>
    )
}
