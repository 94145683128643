import React, { useState, useEffect } from "react";
import {
  Form,
  Modal,
  Input,
  Space,
  Row,
  Col,
  Button,
  Select,
  Popover,
  Table,
} from "antd";
import { Spin } from "antd";
import { NavLink } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import { view_log, triple_dots, green_dot, red_dot } from "helper/constant";
import ViewPatients from "./ViewPatients";
import { useDispatch, useSelector } from "react-redux";
import actions from "actions";
import moment from "moment";
import { PAUSE } from "redux-persist";
import { Helmet } from "react-helmet";
const { patientActions } = actions;

const Search = Input.Search;
const { Option } = Select;

const Patients = ({ tableData }) => {
  const [visible, setvisible] = useState(false);
  const [user, setuser] = useState(null);
  const [query, setQuery] = useState({});
  const [expData, setExptData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('')


  const dispatch = useDispatch();
  const patientdocview = (pid) => {
    console.log("ppppid", pid);
    dispatch(patientActions.getHistroricaldocumentdetail(pid));
  };

  const handleOnSearch = (value) => {
    console.log(value)
    setSearchQuery(value)
    dispatch(patientActions.getPatients(0,value,10));
  }
  const columns = [
    {
      title: "Patient Id",
      dataIndex: "uuid",
      key: "uuid",
    },
    {
      title: "Patient Name",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Patient Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Patient Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (text, row) => (
        <div className="tbl-user-photo">
          {row.active ? (
            <img src={green_dot && green_dot.default?green_dot.default:green_dot} alt="active" />
          ) : (
            <img src={red_dot && red_dot.default?red_dot.default:red_dot} alt="inactive" />
          )}
        </div>
      ),
    },
    {
      title: "View",
      dataIndex: "eye",
      key: "eye",
      render: (text, row) => (
        <img
          className="ml--10 cursor-pointer"
          src={view_log && view_log.default?view_log.default:view_log}
          alt="view"
          onClick={() => {
            console.log("view");
            setuser(row);
            setvisible(true);
          }}
        />
      ),
    },
    {
      title: "Document",
      dataIndex: "eye",
      key: "eye",
      render: (text, row) => (
        // console.log("view documet",row.uuid)

        <NavLink
          to={"/viewpatientDocs"}
          onClick={() => patientdocview(row.uuid)}
          className="nav-link main-list"
        >
          <div className="sidebar-icon">
            <img className="ml--10 cursor-pointer" src={view_log && view_log.default?view_log.default:view_log} alt="view" />{" "}
          </div>
        </NavLink>
        // <img
        //   className='ml--10 cursor-pointer'
        //   src={view_log}
        //   alt='view'
        //   onClick={() => {
        //     console.log('view');
        //     setuser(row);
        //     setvisible(true);
        //   }}
        // />
      ),
    },
    // {
    //   title: 'Actions',
    //   dataIndex: 'actions',
    //   key: 'actions',
    //   render: (text, row) => (
    //     <div className='ml--20'>
    //       <Popover
    //         trigger='hover'
    //         placement='bottom'
    //         content={
    //           <div>
    //             <div
    //               className='cursor-pointer'
    //               onClick={() => {
    //                 console.log('edit');
    //               }}
    //             >
    //               Edit
    //             </div>
    //             <div
    //               className='pt--7 cursor-pointer'
    //               onClick={() => {
    //                 console.log('delete');
    //               }}
    //             >
    //               Delete
    //             </div>
    //           </div>
    //         }
    //       >
    //         <img src={triple_dots} alt='action' />
    //       </Popover>
    //     </div>
    //   )
    // }
  ];

  const payload = useSelector((state) => state.patients);
  const payloadClone = useSelector((state) => state.patients);

  console.log(payload, "payloadClone")

  useEffect(() => {
    dispatch(patientActions.getPatients(0, searchQuery, 10));
  }, []);

  useEffect(() => {
    dispatch(patientActions.getclonePatients(query));
  }, [query]);
  useEffect(() => {
    console.log(payload.patients, "7639245873");
  }, [payload.patientsClone]);
  console.log(payloadClone.patientsClone);
  let exptHead = [
    "Patient Id",
    "Patient Name",
    "Patient Email",
    "Patient Mobile",
    "status",
    "Other Mobile Number",
    "Date Of Birth",
    "Gender",
    "Blood Group",
    "Marital Status",
    "Total Appointment",
    "Last Appointment",
    "Total Orders",
  ];
  useEffect(() => {
    let exportDataForming = [];
    payload &&
    payload.patients &&
    payload.patients.data &&
    payload.patients.data.map((i) => {
        let innerActivityRow = [
          i.uuid,
          i.full_name,
          i.email,
          i.mobile,
          i.active,
          i.other_mobile,
          i.dob,
          i.gender,
          i.blood_group,
          i.marital_status,
          i && i.bookings[0] ? i.bookings[0].totalBookings : "",
          i && i.bookings[0] ? moment(i.bookings[0].lastAppointmentDate).format('DD/MM/YYYY hh:mm a') : 'No appointment',
          i && i.orders[0] ? i.orders[0].totalOrders : 0,
        ];
        exportDataForming.push(innerActivityRow);
      });
    setExptData(exportDataForming);
  }, [payload.patients]);

  return (
    <div>
      <Helmet>
        <title>Doctall Super Admin Patients
        </title>
        <meta
          name='description'
          content='View patient ID, email and contact number. Search and filter list by provided detail'


        />
      </Helmet>
      {/* <Select
        placeholder="Patient Name"
        size="medium"
        style={{ width: 250, marginRight: 10 }}
        showSearch
        autoClearSearchValue
        allowClear
        optionFilterProp="children"
        labelInValue
        // value={null}
        onChange={(value) => {
          if (value && value.value) {
            setQuery({ patientname: value.value });
            console.log("value.patientname", value.value);
          }
        }}
      >
        {payload &&
        payload.patients.data &&
          payload.patients.data.map((option, i) => (
            <Option key={option._id} value={option.full_name}>
              {option.full_name}
            </Option>
          ))}
      </Select>

      <Select
        placeholder="Patient Email"
        size=",medium"
        style={{ width: 250, marginRight: 10 }}
        showSearch
        autoClearSearchValue
        allowClear
        optionFilterProp="children"
        labelInValue
        onChange={(value) => {
          if (value && value.value) {
            setQuery({ patientemail: value.value });
            console.log("value.patientemail", value.value);
          }
        }}
      >
        {payload &&
        payload.patients.data &&
        payload.patients.data.map((option, i) => (
            <Option key={option._id} value={option.email}>
              {option.email}
            </Option>
          ))}
      </Select>

      <Select
        placeholder="Patient Mobile"
        size=",medium"
        style={{ width: 250, marginRight: 10 }}
        showSearch
        autoClearSearchValue
        allowClear
        optionFilterProp="children"
        labelInValue
        onChange={(value) => {
          if (value && value.value) {
            setQuery({ patientmobile: value.value });
            console.log("value.patientmobile", value.value);
          }
        }}
      >
        {payload &&
         payload.patients &&
         payload.patients.data &&
          payload.patients.data.map((option, i) => (
            <Option key={option._id} value={option.mobile}>
              {option.mobile}
            </Option>
          ))}
      </Select> */}
      <Row>
        <Col md={15}>

        </Col>
        <Col md={6}>
          <div className="fs--16 font-semibold flex-1">
            <Space direction="vertical">
              <Search
                size="large"
                // enterButton="Search"
                placeholder="Search Patient"
                enterButton
                allowClear
                // onSearch={handleOnSearch}
                onChange={e => handleOnSearch(e.target.value)}
              />

            </Space>
          </div>
        </Col>
        <Col md={1}>

        </Col>
        <Col md={2}>
          <CSVLink
            data={expData}
            headers={exptHead}
            filename={`Patient${moment().format("DD/MM/YYYY hh/mm")}.csv`}
            style={{ color: "#ffff" }}
          ><Button style={{ height: 42, marginRight: 10 }} type="primary">
              Export
            </Button>
          </CSVLink>
        </Col>
      </Row>


      <Spin spinning={payload.loader}>
        <Table
          columns={columns}
          dataSource={payload.patients.data}
          loader={payload.loader}
          size="middle"
          // pagination={{
          //   defaultPageSize: 12,
          // }}
          pagination={{
            total: payload.patients.count,
            defaultPageSize: 10,
            showSizeChanger: false
          }}
          onChange={(pagination) => {
            let pageNumber = ''
            if (pagination.current === 1) {
              pageNumber = 0
            } else {
              pageNumber = pagination.current - 1
            }
            // dispatch(consumerActions.getConsumers(pageNumber, '', query));
            dispatch(patientActions.getPatients(pageNumber, searchQuery, 10))
          }}
        />
      </Spin>
      <ViewPatients
        visible={visible}
        loader={false}
        handleOk={() => setvisible(false)}
        handleCancel={() => setvisible(false)}
        user={user}
      />
    </div>
  );
};

export default Patients;

const data = [
  {
    key: "1",
    fullName: "John Brown",
    phoneNo: "9898145895",
    email: "test@mailinator.com",
    dob: "13/05/1993",
    gender: "Male",
    status: true,
    education: "M.B.B.S.",
    registrationNo: "GDC:1645",
    registrationCouncil: "GDC",
    licence: "GSDC",
    practiceStandardYear: "2018",
    speciality: "dentist",
    languages: ["gujrati", "hindi", "english"],
    shortBio: "i am doctor for 10 years",
  },
  {
    key: "2",
    email: "test@mailinator.com",
    fullName: "John Brown",
    phoneNo: "9898145895",
    dob: "13/05/1993",
    gender: "Male",
    status: false,
    education: "M.B.B.S.",
    registrationNo: "GDC:1645",
    registrationCouncil: "GDC",
    licence: "GSDC",
    practiceStandardYear: "2018",
    speciality: "dentist",
    languages: ["gujrati", "hindi", "english"],
    shortBio: "i am doctor for 10 years",
  },
  {
    key: "3",
    email: "test@mailinator.com",
    fullName: "John Brown",
    phoneNo: "9898145895",
    dob: "13/05/1993",
    gender: "Male",
    status: false,
    education: "M.B.B.S.",
    registrationNo: "GDC:1645",
    registrationCouncil: "GDC",
    licence: "GSDC",
    practiceStandardYear: "2018",
    speciality: "dentist",
    languages: ["gujrati", "hindi", "english"],
    shortBio: "i am doctor for 10 years",
  },
  {
    key: "4",
    email: "test@mailinator.com",
    fullName: "John Brown",
    phoneNo: "9898145895",
    dob: "13/05/1993",
    gender: "Male",
    status: true,
    education: "M.B.B.S.",
    registrationNo: "GDC:1645",
    registrationCouncil: "GDC",
    licence: "GSDC",
    practiceStandardYear: "2018",
    speciality: "dentist",
    languages: ["gujrati", "hindi", "english"],
    shortBio: "i am doctor for 10 years",
  },
  {
    key: "5",
    email: "test@mailinator.com",
    fullName: "John Brown",
    phoneNo: "9898145895",
    dob: "13/05/1993",
    gender: "Male",
    status: true,
    education: "M.B.B.S.",
    registrationNo: "GDC:1645",
    registrationCouncil: "GDC",
    licence: "GSDC",
    practiceStandardYear: "2018",
    speciality: "dentist",
    languages: ["gujrati", "hindi", "english"],
    shortBio: "i am doctor for 10 years",
  },
  {
    key: "6",
    email: "test@mailinator.com",
    fullName: "John Brown",
    phoneNo: "9898145895",
    dob: "13/05/1993",
    gender: "Male",
    status: true,
    education: "M.B.B.S.",
    registrationNo: "GDC:1645",
    registrationCouncil: "GDC",
    licence: "GSDC",
    practiceStandardYear: "2018",
    speciality: "dentist",
    languages: ["gujrati", "hindi", "english"],
    shortBio: "i am doctor for 10 years",
  },
  {
    key: "7",
    email: "test@mailinator.com",
    fullName: "John Brown",
    phoneNo: "9898145895",
    dob: "13/05/1993",
    gender: "Male",
    status: true,
    education: "M.B.B.S.",
    registrationNo: "GDC:1645",
    registrationCouncil: "GDC",
    licence: "GSDC",
    practiceStandardYear: "2018",
    speciality: "dentist",
    languages: ["gujrati", "hindi", "english"],
    shortBio: "i am doctor for 10 years",
  },
  {
    key: "8",
    email: "test@mailinator.com",
    fullName: "John Brown",
    phoneNo: "9898145895",
    dob: "13/05/1993",
    gender: "Male",
    status: true,
    education: "M.B.B.S.",
    registrationNo: "GDC:1645",
    registrationCouncil: "GDC",
    licence: "GSDC",
    practiceStandardYear: "2018",
    speciality: "dentist",
    languages: ["gujrati", "hindi", "english"],
    shortBio: "i am doctor for 10 years",
  },
];
