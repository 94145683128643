import actions from 'actions';

const initState = {
    appointements: [],
    loader: false,
    cancelAppointments:[],
    reAppointments:[]
};

export default function rootReducer(state = initState, action) {
    switch (action.type) {
        case actions.appointmentActions.GET_APPOINTMENT:
            return {
                ...state,
                loader: true
            };
        case actions.appointmentActions.GET_APPOINTMENT_SUCCESS:
            return {
                ...state,
                loader: false,
                appointements: action.data
            };

            case actions.appointmentActions.CANCEL_APPOINTMENT:
                return {
                    ...state,
                    loader: true
                };
            case actions.appointmentActions.CANCEL_APPOINTMENT_SUCCESS:
                return {
                    ...state,
                    loader: false,
                    cancelAppointments: action.data
                };


                case actions.appointmentActions.RE_APPOINTMENT:
                    return {
                        ...state,
                        loader: true
                    };
                case actions.appointmentActions.RE_APPOINTMENT_SUCCESS:
                    return {
                        ...state,
                        loader: false,
                        reAppointments: action.data
                    };
        default:
            return state;
    }
}
