const usedCouponsActions = {

    GET_USED_COUPONS: "GET_USED_COUPONS",
    GET_USED_COUPONS_SUCCESS: "GET_USED_COUPONS_SUCCESS",

    GET_COUPONS_STATS: "GET_COUPONS_STATS",
    GET_COUPONS_STATS_SUCCESS: "GET_COUPONS_STATS_SUCCESS",



    getUsedCoupons: (pageNumber, searchQuery) => {
        return {
            type: usedCouponsActions.GET_USED_COUPONS,
            pageNumber,
            searchQuery
        };
    },

    getCouponStats: (searchQuery1) => {
        return {
            type: usedCouponsActions.GET_COUPONS_STATS,
            searchQuery1,
        };
    },
};

export default usedCouponsActions;