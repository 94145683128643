import React, { useEffect, useState } from 'react';
import { Modal, Table } from 'antd';
import { view_log, triple_dots } from 'helper/constant';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';

const PharmacyReferedUsers = ({ visible, handleOk, handleCancel, user, data,influencerViewData1, count, fun1, ReferedUserdata,refUserData}) => {
const[ReferedUserdata1,setReferedUsersData]=useState([])
  console.log(refUserData,"refUserData")
  const Auth = useSelector((state) => state.auth);
 
  let pageNumber ;

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name',
      render: (text, item) => (
        <div>{item.user[0].full_name}</div>
      )
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile',
      key: 'mobile',
      render: (text, item) => (
        <div>{item.user[0].mobile}</div>
      )
    },
    {
      title: 'Email Id',
      dataIndex: 'email',
      key: 'email',
      render: (text, item) => (
        <div>{item.user[0].email}</div>
      )
    },
    {
      title: 'User Id',
      dataIndex: 'uuid',
      key: 'uuid',
      render: (text, item) => (
        <div>{item.user[0].uuid}</div>
      )
    },

  ];
// useEffect(()=>{
//   axios.get(`${process.env.REACT_APP_WEB_APP}/user/pharmacyCommission/pharmacy/register/user/list?pharmacy_uuid=${uuid}&pagenum=0&limit=10`,{
//     headers:{
//         "x-auth-token" : Auth.accessToken
//     }
// })
// .then((res) => {
//     console.log(res.data.data.user, "resapi")
//     // data.push(res)
//     setReferedUsersData(res && res.data && res.data.data )
// })
// },[])

  return (<Modal
    title={`Refered Users`}
    visible={visible}
    onOk={handleOk}
    onCancel={handleCancel}
    width={850}
    footer={[
    ]}
  >

  {
    refUserData && refUserData.length==0?
    <div style={{textAlign:"center"}}>
<h4 style={{fontFamily: "open-sans-regular", fontSize:"20px",fontWeight:"500"}}> No Referred Users </h4>
  </div>
  :
  <div> 
      {/* <table style={{ width: "100%" }}>
        <tr style={{ background: "#F0F5F5", height: "38px" }}>
          <th> &nbsp; &nbsp; Full Name</th>
          <th>Mobile No.</th>
          <th>Email Id</th>
          <th>User ID</th>
          
        </tr>    
      {
        refUserData &&  refUserData.map((item,index)=>{ 
          console.log(item,"item")
         return <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
        <td>{item.user[0].full_name}</td>
        <td>{item.user[0].mobile}</td>
        <td>{item.user[0].email}</td>
        <td>{item.user[0].uuid}</td>
        
      
      </tr>
        })
      }      
      </table>    */}
                    <Table
                columns={columns}
                dataSource={refUserData}
                size='middle'
                pagination={{
                  total: count,
                  showSizeChanger: false
                }}

                onChange={(pagination) => {

                  if (pagination.current === 1) {
                    pageNumber = 0
                  } else {
                    pageNumber = pagination.current - 1
                  }
                  fun1(influencerViewData1, pageNumber)
                  // dispatch(master.getInfluencerData(pageNumber))
                }}
              />
  </div>
  }

 

   
 
  </Modal>
  );
};

export default PharmacyReferedUsers;