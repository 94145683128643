import React, { useEffect, useState } from "react";
import { Form, Modal, Input, Row, Col, Button, Select, Table, Space } from "antd";
import { CSVLink } from "react-csv";
import { view_log, green_dot, red_dot } from "helper/constant";
import { useDispatch, useSelector } from "react-redux";
import actions from "actions";
import { Spin } from "antd";

import moment from "moment";
import { DatePicker } from "antd";
import { Helmet } from "react-helmet";
import axios from "axios";
const { consumerActions } = actions;
const { Option } = Select;

const { patientActions } = actions;
const Search = Input.Search;

const Consumers = ({ userId }) => {

  const [page, setPage] = useState(0)
  const [data, setData] = useState([])
  const [count, setCount] = useState()
  const [loader, setLoader] = useState(false)
  const [dayType, setDayType] = useState('all')


  const GetApi = (pages, day) => {
    setLoader(true)
    axios.get(`${process.env.REACT_APP_WEB_APP}/doctallnew/healthtrack/history/list?user_uuid=${userId}&pagenum=${pages}&limit=10&type=spo2&day_type=${day}`)
      .then((res) => {
        setData(res.data.data)
        setCount(res.data.count)
        setLoader(false)
      })
      .catch((err) => {
        console.log(err)
        setLoader(false)
      })
  }

  const handlechange = (event) => {
    setDayType(event)
    GetApi(page, event)
  }
  useEffect(() => {
    GetApi(page, dayType)
  }, [page])

  const dispatch = useDispatch();

  const columns = [
    {
      title: "Health Tracker Id",
      dataIndex: "uuid",
      key: "uuid",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    // {
    //   title: 'Patient Id',
    //   dataIndex: 'patients.full_name',
    //   key: 'patients.uuid',
    //   render: (text, row) => (
    //     <div className='tbl-user-photo'>
    //       {row.patients && row.patients.uuid}
    //     </div>
    //   )
    // },
    // {
    //   title: 'Patient Mobile',
    //   dataIndex: 'patients.Mobile',
    //   key: 'patients.Mobile',
    //   render: (text, row) => (
    //     <div className='tbl-user-photo'>
    //       {row.patients && row.patients.mobile}
    //     </div>
    //   )
    // },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    //   render: (text, row) => (
    //     <div className="tbl-user-photo">
    //       {row.details && row.details.type}
    //     </div>
    //   ),
    // },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (text, row) => (
        <div className="tbl-user-photo">
          {row.details && row.details.rate}
        </div>
      ),
    },
  ];



  return (
    <div>
      <Helmet>
        <title>Doctall Super Admin Consumers
        </title>
        <meta
          name='description'
          content='View consumer ID, username and email at a glance. Also search and export list'
        />
      </Helmet>

      <Select
        placeholder="Select"
        size=",medium"
        style={{ width: 250, marginRight: 10 }}
        showSearch
        autoClearSearchValue
        allowClear
        // value={dayType}
        optionFilterProp="children"
        // labelInValue
        onSearch={(event) => { handlechange(event) }}
        onSelect={(event) => { handlechange(event) }}
        onChange={(value) => handlechange(value)}
      >
        <Option key="all" value="all">
          All
        </Option>
        <Option key="today" value="today">
          Today
        </Option>
        <Option key="lastweek" value="lastweek">
          Last Week
        </Option>
        <Option key="lastmonth" value="lastmonth">
          Last Month
        </Option>
      </Select>

      <Spin spinning={loader}>
        <Table
          columns={columns}
          dataSource={data}
          loader={loader}
          size="middle"
          pagination={{
            total: count,
            defaultPageSize: 10,
            showSizeChanger: false
          }}
          onChange={(pagination) => {
            let pageNumber = ''
            if (pagination.current === 1) {
              pageNumber = 0
            } else {
              pageNumber = pagination.current - 1
            }
            GetApi(pageNumber, dayType)
          }}
        />
      </Spin>
    </div>
  );
};

export default Consumers;
