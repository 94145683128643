import React, { useState, useEffect } from 'react';
import { Table, Popover } from 'antd';
import { view_log, triple_dots } from 'helper/constant';
import EditePartner from './EditeCompany';
import ViewReports from './ViewReports';
import { green_dot, red_dot } from 'helper/constant';
import actions from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { EditTwoTone } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import ViewCompanyUsers from "./ViewCompanyUsers"


const { master } = actions;

const CompanyName = ({newcity, onGetarea, newareas}) => {
  const dispatch = useDispatch();
  const [visible, setvisible] = useState(false);
  const [reportsvisible, setreportsvisible] = useState(false);
  const [editvisible, setEditvisible] = useState('');
  const [user, setuser] = useState(null);
  const [data, setData] = useState("")
  const Pharma = useSelector((state) => state.pharmacies);
  console.log(Pharma && Pharma.pharmacies && Pharma.pharmacies.data, "phar")
  const PharmaData = Pharma && Pharma.pharmacies && Pharma.pharmacies.data;
  const Auth = useSelector((state) => state.auth);
  const masterPartner = useSelector((state) => state.master);

  console.log(masterPartner.CompanyData,"555")



  const [companyUserData, setcompanyUserData] = useState([])
  const [referdUsersVisible, setreferdUsersVisible] = useState(false);
  const [ViewId, setID] = useState('')
  const [count, setCounts] = useState('')
  // const [pagenum, setPagenum] = useState(0)


  // const Api = (row) => {
  //   axios.get(`${process.env.REACT_APP_WEB_APP}/user/pharmacyCommission/pharmacy/metrics/data?pharmacy_uuid=${row && row.uuid}`,{
  //       headers:{
  //           "x-auth-token" : Auth.accessToken
  //       }
  //   })
  //   .then((res) => {
  //       console.log(res, "res")
  //       // data.push(res)
  //       setData(res.data)
  //   })
  // }
  // useEffect(() => {
  //   dispatch( MasterAction && MasterAction.getPartnersDta());
  // }, []);

  useEffect(() => {
    
    dispatch(master.getCompanyData());
  }, []);
  
  const columns = [
    // {
    //   title: 'Logo',
    //   dataIndex: 'logo',
    //   key: 'logo',
    //   render: (text, row) => (
    //     <img
    //       className='ml--10 cursor-pointer'
    //       src={row.logo}
    //       style={{width:"100px"}}
    //       // alt='view'
    //       onClick={() => {
    //         console.log('view');
    //         setuser(row);
    //         setvisible(true);
    //         setEditvisible('view')
    //       }}
    //     />
    //   )
    // },
    {
      title: 'Company ID',
      dataIndex: 'company_reg_id',
      key: 'company_reg_id'
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name'
    },
   
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name:',
    },
    {
      title: 'Email ID',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile',
      key: 'mobile'
    },
   

    {
      title: 'View',
      dataIndex: 'eye',
      key: 'eye',
      render: (text, row) => (
        <img
          className='ml--10 cursor-pointer'
          src={view_log && view_log.default?view_log.default:view_log}
          alt='view'
          onClick={() => {
            console.log('view');
            setuser(row);
            setvisible(true);
            setEditvisible('view')
          }}
        />
      )
    },
    {
      title: 'View Users',
      dataIndex: 'eye',
      key: 'eye',
      render: (text, row) => (
        <img
          className='ml--10 cursor-pointer'
          src={view_log && view_log.default?view_log.default:view_log}
          alt='view'
          onClick={() => {
            // console.log('view');
            setuser(row);
            getInfluencerDataView(row);
            setreferdUsersVisible(true);
            // setEditvisible('view')
          }}
        />
      )
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, row) => (
        <div className='ml--20'>
          <EditTwoTone
          onClick={() => {
            setuser(row);
            setvisible(true);
            setEditvisible('edit')
          }}
          />
          {/* <Popover
            trigger='hover'
            placement='bottom'
            content={
              <div>
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    setuser(row);
                    setvisible(true);
                    setEditvisible('edit')
                  }}
                >
                  Edit
                </div>
              </div>
            }
          >
            <img src={triple_dots} alt='action' />
          </Popover> */}
        </div>
      )
    }
  ];


  // ${row && row.uuid}

  const getInfluencerDataView = (row, pagenum) => {
    let page ;
    if(pagenum === undefined){
      page = 0;
    }
    else{
      page = pagenum
    }
    setID(row && row.uuid)
    axios.get(`${process.env.REACT_APP_WEB_APP}/user/auth/company/upload/user/list?pagenum=${page}&limit=10&search=&company_uuid=${row && row.uuid}`,{

        headers:{
            "x-auth-token" : Auth.accessToken
        }
    })
    .then((res) => {
        console.log(res, "12asdsad")
        // data.push(res)
        setcompanyUserData(res && res.data && res.data.data)
        setCounts(res && res.data && res.data.count)
    })
  }


  return (
    <div>
      <Table
        columns={columns}
        dataSource={masterPartner.CompanyData.data}
        size='middle'
        pagination={{
          defaultPageSize: 10
        }}
      />
      <EditePartner
        visible={visible}
        diff={editvisible}
        newcity={newcity}
        onGetarea={onGetarea}
        newareas={newareas}
        loader={false}
        handleOk={() => setvisible(false)}
        handleCancel={() => {setvisible(false); window.location.reload(false)}}
        user={user}
      />
      {/* <ViewReports
      visible={reportsvisible}
      loader={false}
      handleOk={() => setreportsvisible(false)}
      handleCancel={() => {setreportsvisible(false); window.location.reload(false)}}
      data={data}
      >
      </ViewReports> */}
      <ViewCompanyUsers
      visible={referdUsersVisible}
      loader={false}
      handleOk={() => setreferdUsersVisible(false)}
      handleCancel={() => {setreferdUsersVisible(false)}}
      companyUserData={companyUserData}
      fun={getInfluencerDataView}
      ViewId={ViewId}
      count={count}
      />
    </div>
  );
};

export default CompanyName;