import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Sidebar from 'components/sidebar/Sidebar';
import Topbar from 'components/topbar/Topbar';
import dashboardRoutes from 'routes/dashboardRoutes';
import { connect } from 'react-redux';
import { ProtectedRoute } from './../routes/ProtectedRoute';
import RoleSideBar from './RoleSideBar';
import dmpRoutes from 'routes/dmpRoutes';
import supportRoutes from 'routes/supportRoutes';




class DashboardLayout extends Component {


    render() {
        return (
            <div>
                <RoleSideBar />
                <div id="main-panel" className="main-panel flex-y" ref="mainPanel">
                    <div>
                        {window.location.pathname !== "/login" && <Topbar />}
                    </div>

                    <div className="route-height flex-1" style={{ backgroundColor: '#F0F5F' }}>
                        <Switch>
                            <ProtectedRoute {...this.props} >
                                {

                                    dashboardRoutes.map((props, key) => {
                                        return <Route path={props.path} component={props.component} key={key} />;
                                    })
                                }
                            </ProtectedRoute>

                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: {
            token: state.auth.accessToken,
            isLogin: state.auth.isLogin,
            role: state.auth && state.auth.admin && state.auth.admin.role
        }
    };
}



export default connect(mapStateToProps, null)(DashboardLayout);
