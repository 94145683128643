import React, { Component, useState, useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { store, persistor, history } from "store";
import { Provider } from "react-redux";
import layoutRoutes from "routes/index";
import { useDispatch, useSelector } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { getToken, onMessageListener } from './firebase';
import Pubnub from "pubnub";
// import { createPubNubListener } from "pubnub-redux";
import { PubNubProvider } from "pubnub-react";
import keyConfiguration from "config/pubnub-keys.json";

import SnackBarActions from "./actions/snackBarAction";
import "assets/scss/_app.scss";
import 'antd/dist/antd.css';

const pubnubConfig = Object.assign(
  {}, {
  restore: true,
  heartbeatInterval: 0
},
  { ...keyConfiguration }
);

const pubnub = new Pubnub(pubnubConfig);

const App = () => {

  const [isTokenFound, setTokenFound] = useState(false);

  const leaveApplication = () => {
    // This is required to show the current user leave immediately rather than
    // wating for the timeout period
    pubnub.unsubscribeAll();
  };

  useEffect(() => {
    window.addEventListener("beforeunload", leaveApplication);
  }, []);

  // useEffect(() => {
  //   pubnub.addListener(createPubNubListener(store.dispatch));
  //   return leaveApplication;
  // }, []);

  useEffect(() => {
    getToken(setTokenFound);
  }, [])

  console.log(isTokenFound)
  onMessageListener().then(message => {
    console.log('message listener:', message);
    // dispatch(SnackBarActions.showSuccessSnackbar(message.notification.title));
  }).catch(err => {
    // console.log('failed: ', err)
  });


  return (
    <div className="App">
      <Router history={history}>
        <Provider store={store}>
          <PubNubProvider client={pubnub}>
            <PersistGate loading={null} persistor={persistor}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => <Redirect to="/dashboard" />}
                />
                {
                  layoutRoutes.map((prop, key) => {
                    return (
                      <Route
                        path={prop.path}
                        component={prop.component}
                        key={key}
                      />
                    );
                  })
                }
              </Switch>
            </PersistGate>
          </PubNubProvider>
        </Provider>
      </Router>
    </div>
  );

}

export default App;
