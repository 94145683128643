import React, { useState, useEffect } from 'react';
import { Form, Modal, Input, Row, Col, Button, Select, Checkbox } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import 'antd/dist/antd.css';

const { Option } = Select;

const EditPartner = ({
    visible,
    partnerUser,
    handleOk,
    onGetarea,
    onSubmit,
    states,
    cities,
    newcity,
    newareas,
    handleCancel,
    updateFlags,
    exDetails

}) => {
    // const [inhouse_pharmacy, setinhousepharmacy] = useState(false);
    // const [inhouse_lab, setinhouselab] = useState(false);

    const partners = useSelector((state) => state.users.partners);

    const [partnertype, setpartnertype] = useState(null);
    const [state, setstate] = useState(null);
    const [city, setcity] = useState(null);
    const [uuid, setuuid] = useState(null);
    const [newcityuuid, setnewcityuuid] = useState('');
    const [newcityuuid2, setnewcityuuid2] = useState('');
    const [partnercity, setpartnercity] = useState('');
    const [partnerarea, setpartnerarea] = useState('');
    const [partnerarea2, setpartnerarea2] = useState('');
    const [branchname, setbranchname] = useState()
    const [branchlocationcityId, setbranchlocationcityId] = useState('')
    const [branchlocationcityname, setbranchlocationcityname] = useState('')

    const [branchlocationcityname1, setbranchlocationcityname1] = useState('')
    const [branchlocationareaname1, setbranchlocationareaname1] = useState('')

    const [branchlocationCitydefaultId, setbranchlocationdefaultCityId] = useState('')
    const [branchlocationDefaultareaId, setbranchlocationDefaultareaId] = useState('')
    const [branchlocationareaId, setbranchlocationareaId] = useState('')
    const [branchlocationareaname, setbranchlocationareaname] = useState('')
    const [typesubmit, settypesubmit] = useState("addpartner");
    const [country, setCountry] = useState();
    const [form] = Form.useForm();


    const errorstyle = {
        border: "1px solid #ADC2C2",
        outline: "none !important",
        borderRadius: "5px",
        width: '20px'
    };

    const areasubmit = values => {
        console.log("area submit", values)
        onGetarea(values)
    }

    console.log(exDetails && exDetails.branchLocation && exDetails.branchLocation.area, "aid")

    useEffect(() => {
        reset();
        fetch("https://ipapi.co/json/")
            .then((res) => res.json())
            .then((res) => {
                setCountry(res.country_code.toLowerCase());
            })
            .catch((data, status) => {
                console.log("Request failed:", data);
            });

        let branchAreaId = exDetails && exDetails.address && exDetails.address[0] && exDetails.address[0].area ? exDetails.address[0].area : "NA"
        let branhAreaName = exDetails && exDetails.address && exDetails.address[0] && exDetails.address[0].area_name ? exDetails.address[0].area_name : "NA"

        let branhCityName = exDetails && exDetails.address && exDetails.address[0] && exDetails.address[0].city_name ? exDetails.address[0].city_name : "NA"
        let branchCityId = exDetails && exDetails.address && exDetails.address[0] && exDetails.address[0].city ? exDetails.address[0].city : "NA"

        console.log(exDetails && exDetails.address, "efgjbhkm")
        setbranchlocationDefaultareaId(branchAreaId)
        setbranchlocationareaname(branhAreaName)
        setbranchlocationdefaultCityId(branchCityId)
        setbranchlocationcityname(branhCityName)

        setnewcityuuid2(exDetails && exDetails.branchLocation && exDetails.branchLocation.city && exDetails.branchLocation.city.id)
        
        setpartnerarea2(exDetails && exDetails.branchLocation && exDetails.branchLocation.area && exDetails.branchLocation.area.name)
        // setbranchname(exDetails && exDetails.address && exDetails.address[0] && exDetails.address[0].city)
        let userId = exDetails && exDetails.uuid
        settypesubmit(userId)

    }, [exDetails]);


    const handleSubmit = values => {
        console.log(values)

        let payLoad = {
            "firstName": values.firstName,
            "middleName": values.middleName,
            "lastName": values.lastName,
            "email": values.email,
            "branch_name": values.branch_name,
            "group": values.group,
            "role": values.role,
            "partner_name": values.partner_name,
            "mobile": values.mobile,
            "verified": true,
            "username": values.firstName,
            "branchLocation": {
                "city": {
                    "id": branchlocationcityId,
                    "name": branchlocationcityname
                },
                "area": {
                    "id": branchlocationareaId,
                    "name":branchlocationareaname
                }
            },
            "address": [{
                city_name: branchlocationcityname,
                area_name: branchlocationareaname,
                city: branchlocationCitydefaultId,
                area: branchlocationDefaultareaId,
                pincode: values.pincode,
                street: values.street,
                door_no: values.door_no
            }]
        }



        values.verified = true
        values.username = values.email
        values.open_close_time = values.open_close_time


        values.address = ([{
            city_name: branchlocationcityname,
            area_name: branchlocationareaname,
            city: branchlocationCitydefaultId,
            area: branchlocationDefaultareaId,
            pincode: values.pincode,
            street: values.street,
            door_no: values.door_no
        }])



        onSubmit(
            payLoad,
            typesubmit

        );
        reset();
        handleOk();
        handleCancel();
    };

    const reset = () => {
        setpartnertype(null);
        setstate(null);
        setcity(null);
        setuuid(null);
        setbranchname(null)
        setnewcityuuid(null)
        setnewcityuuid2(null)
        setpartnerarea(null)
        setpartnerarea2(null)
        form.resetFields();
    };

    const onCancel = () => {
        reset();
        handleCancel();
    };

    const onOk = () => {
        reset();
        handleOk();
    };

    return (
        <Modal
            className='no-modal-footer'
            title='Edit Partner Details'
            visible={visible}
            onOk={onOk}
            width={900}
            onCancel={onCancel}
            okText='Edit'
        >
            <Form onFinish={handleSubmit} form={form} className='gx-form-row0' layout='vertical'>
                <Row className='space-between'>

                    <Col span={7} xs={7} md={7}>
                        <Form.Item
                            label='First Name'
                            name='firstName'
                            rules={[{ required: false, message: 'Please enter firstName!' }]}
                            initialValue={exDetails && exDetails.firstName ? exDetails.firstName : "NA"}
                        >
                            <Input size='large'
                                defaultValue={exDetails && exDetails.firstName ? exDetails.firstName : "NA"}
                            />
                        </Form.Item>
                    </Col>


                    <Col span={7} xs={7} md={7}>
                        <Form.Item
                            label='Middle Name'
                            name='middleName'
                            rules={[{ required: false, message: 'Please enter Middlename!' }]}
                            initialValue={exDetails && exDetails.middleName ? exDetails.middleName : "NA"}
                        >
                            <Input size='large'
                                defaultValue={exDetails && exDetails.middleName ? exDetails.middleName : "NA"}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <Form.Item
                            label='Last Name'
                            name='lastName'
                            rules={[{ required: false, message: 'Please enter Lastname!' }]}
                            initialValue={exDetails && exDetails.lastName ? exDetails.lastName : "NA"}
                        >
                            <Input size='large'
                                defaultValue={exDetails && exDetails.lastName ? exDetails.lastName : "NA"}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={7} xs={7} md={7}>
                        <Form.Item
                            label='Email'
                            name='email'
                            rules={[{ required: true, message: 'Please enter email!' }]}
                            initialValue={exDetails && exDetails.email ? exDetails.email : "NA"}
                        >
                            <Input size='large'
                            // defaultValue={exDetails && exDetails.email ? exDetails.email : "NA"}
                            />
                        </Form.Item>
                    </Col>


                    <Col span={7} xs={7} md={7}>
                        <Form.Item
                            label='City'
                            initialValue={exDetails && exDetails.address && exDetails.address[0] && exDetails.address[0].city ? exDetails.address[0].city : "NA"}>
                            <Select
                                showSearch
                                autoClearSearchValue
                                allowClear
                                optionFilterProp="children"

                                onChange={(value, secondvalue) => {
                                    console.log("cityyy", value
                                    )
                                    setnewcityuuid(value)
                                    areasubmit(value)

                                    if (value && secondvalue)
                                        setpartnercity(secondvalue.key)

                                }}
                                // value={city}
                                value={newcityuuid}

                            >
                                {newcity.map(city => (
                                    <Option key={city._id} value={city.uuid}>
                                        {city.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={7} xs={7} md={7}>
                        <Form.Item label='Area'>
                            <Select
                                showSearch
                                autoClearSearchValue
                                allowClear
                                optionFilterProp="children"
                                name="area"
                                onChange={(value, secondvalue) => {
                                    console.log("areaaaaa", value
                                    )
                                    if (value && secondvalue) {
                                        setpartnerarea(secondvalue.key)
                                    }
                                }}
                                defaultValue={exDetails && exDetails.branchLocation && exDetails.branchLocation.area ? exDetails.branchLocation.area.id : "NA"}

                            >
                                {newareas.map(area => (
                                    <Option key={area._id} value={area.uuid}>
                                        {area.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>



                    <Col span={7} xs={7} md={7}>
                        <Form.Item name="branch_name"
                            rules={[{ required: true, message: 'Please Select Branch Name!' }]}
                            initialValue={exDetails && exDetails.branch_name ? exDetails.branch_name : "NA"}

                            label='Branch name'>
                            <Select
                                showSearch
                                autoClearSearchValue
                                allowClear
                                optionFilterProp="children"
                                onChange={value => {
                                    console.log("branchcityyy", value
                                    )
                                    setbranchname(value);
                                }}
                                value={branchname}
                                defaultValue={exDetails && exDetails.branch_name ? exDetails.branch_name : "NA"}
                            >
                                {partners &&
                                    partners.map((partner) => (
                                        <option key={partner.name} value={partner.name}>
                                            {`${partner.name}`}
                                        </option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <Form.Item label='Branch City'
                            rules={[{ required: true, message: 'Please Select Branch City!' }]}
                            initialValue={exDetails && exDetails.branchLocation && exDetails.branchLocation.city ? exDetails.branchLocation.city.id : "NA"}>
                            <Select
                                showSearch
                                autoClearSearchValue
                                allowClear
                                optionFilterProp="children"
                                onChange={(value, second) => {
                                    console.log("branchcityyy", value
                                    )
                                    console.log(value, second)
                                    setnewcityuuid2(value)
                                    areasubmit(value)
                                    if (value && second) {
                                        // alert(second.value)
                                        setbranchlocationdefaultCityId(second.key)
                                        setbranchlocationcityId(second.value)
                                        setbranchlocationcityname(second.children)
                                    }

                                }}
                                value={newcityuuid2}
                                defaultValue={exDetails && exDetails.branchLocation && exDetails.branchLocation.city ? exDetails.branchLocation.city.id : "NA"}
                            >
                                {newcity.map(city => (
                                    <Option key={city._id} value={city.uuid}>
                                        {city.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={7} xs={7} md={7}>
                        <Form.Item
                            rules={[{ required: true, message: 'Please Select Branch Area!' }]}
                            label='Branch Area'
                            initialValue={exDetails && exDetails.address && exDetails.branchLocation && exDetails.branchLocation.area ? exDetails.branchLocation.area.id : "NA"}
                        >
                            <Select
                                showSearch
                                autoClearSearchValue
                                allowClear
                                optionFilterProp="children"
                                onChange={(value, second) => {
                                    console.log("branchcityyy", value)
                                    setpartnerarea2(value)
                                    if (value && second) {
                                        setbranchlocationDefaultareaId(second.key)
                                        setbranchlocationareaId(second.value)
                                        setbranchlocationareaname(second.children)
                                    }
                                }}
                                value={partnerarea2}
                                defaultValue={exDetails && exDetails.address && exDetails.branchLocation && exDetails.branchLocation.area ? exDetails.branchLocation.area.id : "NA"}
                            >
                                {newareas.map(area => (
                                    <Option key={area._id} value={area.uuid}>
                                        {area.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>


                    {/* New Fields */}
                    <Col span={7} xs={7} md={7}>
                        <Form.Item
                            initialValue={exDetails && exDetails.address && exDetails.address[0] && exDetails.address[0].door_no ? exDetails.address[0].door_no : "NA"}
                            label='Door no'
                            name='door_no'
                            rules={[{ required: true, message: 'Please enter door no ' }]}
                        >
                            <Input type='number' size='sm'
                                defaultValue={exDetails && exDetails.address && exDetails.address[0] && exDetails.address[0].door_no ? exDetails.address[0].door_no : "NA"}
                            />
                        </Form.Item>

                    </Col>
                    <Col span={7} xs={7} md={7}>

                        <Form.Item
                            initialValue={exDetails && exDetails.address && exDetails.address[0] && exDetails.address[0].street ? exDetails.address[0].street : "NA"}
                            label='Street'
                            name='street'
                            rules={[{ required: true, message: 'Please enter street!' }]}
                        >
                            <Input size='sm'
                                defaultValue={exDetails && exDetails.address && exDetails.address[0] && exDetails.address[0].street ? exDetails.address[0].street : "NA"}
                            />
                        </Form.Item>
                    </Col>


                    <Col span={7} xs={7} md={7}>


                        <Form.Item
                            label='Working Time'
                            name='open_close_time'
                            rules={[{ required: false, message: 'Please enter working time !' }]}
                        >
                            <Input size='sm'

                            />
                        </Form.Item>
                    </Col>



                    <Col span={7} xs={7} md={7}>


                        <Form.Item
                            label='Pin Code'
                            name='pincode'
                            rules={[{ required: true, message: 'Please enter pincode!' }]}
                            initialValue={exDetails && exDetails.address && exDetails.address[0] && exDetails.address[0].pincode ? exDetails.address[0].pincode : "NA"}
                        >
                            <Input size='sm' type='number'
                                defaultValue={exDetails && exDetails.address && exDetails.address[0] && exDetails.address[0].pincode ? exDetails.address[0].pincode : "NA"}
                            />
                        </Form.Item>
                    </Col>


                    {/*  */}


                    <Col span={7} xs={7} md={7}>
                        <Form.Item name="group" rules={[{ required: true, message: 'Please Select Group!' }]}
                            label='Group'
                            initialValue={exDetails && exDetails.group ? exDetails.group : "NA"}>
                            <Select
                                showSearch
                                autoClearSearchValue
                                allowClear
                                optionFilterProp="children"
                                defaultValue={exDetails && exDetails.group ? exDetails.group : "NA"}
                            >
                                <Option key={1} value='diagnostic'>
                                    Diagnostic
                                </Option>
                                <Option key={2} value='pharmacy'>
                                    Pharmacy
                                </Option>

                            </Select>
                        </Form.Item>

                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <Form.Item name="role" rules={[{ required: true, message: 'Please Select Role!' }]}
                            label='Role'
                            initialValue={exDetails && exDetails.role ? exDetails.role : "NA"}
                        >
                            <Select
                                showSearch
                                autoClearSearchValue
                                allowClear
                                optionFilterProp="children"
                                defaultValue={exDetails && exDetails.role ? exDetails.role : "NA"}
                            >
                                <Option key={1} value='admin'>
                                    Admin
                                </Option>
                                <Option key={2} value='user'>
                                    User
                                </Option>

                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <Form.Item name="partner_name" rules={[{ required: true, message: 'Please Select partner name!' }]}
                            label='Partner name'
                            initialValue={exDetails && exDetails.partner_name ? exDetails.partner_name : "NA"}>
                            <Select
                                showSearch
                                autoClearSearchValue
                                allowClear
                                optionFilterProp="children"
                                defaultValue={exDetails && exDetails.partner_name ? exDetails.partner_name : "NA"}
                            >

                                {partners &&
                                    partners.map((partner) => (
                                        <option key={partner.name} value={partner.name}>
                                            {`${partner.name}`}
                                        </option>
                                    ))}

                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={7} xs={7} md={7} style={{ marginLeft: '3%' }}>
                        <Form.Item
                            label='Phone'
                            name='mobile'
                            rules={[{ required: true, message: 'Please enter phone number' }]}
                            initialValue={exDetails && exDetails.mobile ? exDetails.mobile : "NA"}
                        >
                            <Input size='sm' type='number'
                                defaultValue={exDetails && exDetails.mobile ? exDetails.mobile : "NA"}
                            />

                        </Form.Item>
                    </Col>




                    <Col className='ml-auto'>
                        <br />
                        <br />
                        <Button className='login-form-button' onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button
                            type='primary'
                            htmlType='submit'
                            className='login-form-button ml--10'
                        >
                            Update
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default EditPartner;
