import axios from 'axios';
import { setHeadersWithAccessToken } from './index';
const WEB_API_BASE = process.env.REACT_APP_WEB_APP;

export const getAppointment = (pagenum, search, auth) => {
  console.log("search", pagenum)
  let SearchItem ;
  if(pagenum.search === undefined){
    SearchItem = ''
  }
  else {
    SearchItem = pagenum.search
  }
  setHeadersWithAccessToken(auth)
  // return axios.get("http://localhost/api/v1/user/getallconsumer").then(e => console.log(e,"123")
  return axios.get(`${WEB_API_BASE}/user/getallconsumer?pagenum=${pagenum.pagenum}&limit=10&search=${SearchItem}`).then(e => e
  );
};




export const getPatientsByConsumer = (auth, params) => {
  console.log("pataParams", params)
  setHeadersWithAccessToken(auth)
  // return axios.get("http://localhost/api/v1/user/getallconsumer").then(e => console.log(e,"123")
  return axios.get(`${WEB_API_BASE}/patient/consumer/${params.params}`).then(p => p);
};

// return axios.get(`${WEB_API_BASE}/user/consumers/data?search=${params.search}`).then(e => e);



export const getSlotsByType = (auth) => {
  // console.log("pataParams2",params)
  setHeadersWithAccessToken(auth)

  // if(params.params == "international") {params.params = "nigerian"}
  // return axios.get("http://localhost/api/v1/user/getallconsumer").then(e => console.log(e,"123")

  // return axios.get(`${WEB_API_BASE}/slot/slotTypes/${params.params}`).then(p => p);

  return axios.get(`${WEB_API_BASE}/user/admin/doctors/list`).then(p => p);

  // http://test-api.doctall.com/api/v1/user/admin/doctors/list

};




export const getSlotsByDoctor = (auth, params, param2) => {
  console.log("pataParamsDoctor", param2)
  // if(param2 == "international" || param2 == "nigerian"){
  //   param2 = "nigerian"

  // }
  setHeadersWithAccessToken(auth)
  // return axios.get("http://localhost/api/v1/user/getallconsumer").then(e => console.log(e,"123")
  // return axios.get(`${WEB_API_BASE}/slot/getslotBydoctor/${params.params}`).then(p => p);
  // return axios.get(`${WEB_API_BASE}/slot/getslotBydoctor/${params.params}/${params.param2}`).then(p => p);
  return axios.get(`${WEB_API_BASE}/slot/getslotBydoctor/${params}/${param2}`).then(p => p);
  // http://localhost/api/v1/slot/slotdoctor/DR-352BEDAF/free
};

// http://test-api.doctall.com/api/v1/booking/doctall/admin/new/free

export const addAppointment = (auth, data) => {
  setHeadersWithAccessToken(auth);
  console.log("service api", data)
  // if(data.type == "international" || data.type == "nigerian")
  if (data.type == "nigerian") { data.type = 'Find a doctor' }

  const apiname = data.type == "free" || data.type == "sadn" ? data.type : "normal"
  console.log("service api type", data.type)

  console.log("service api apiname", apiname)

  // if (data.type == "free" || data.type== "sadn"){
  //   return axios.post(`${WEB_API_BASE}/booking/doctall/admin/ne/${data.type}`, data).then(e => e);}
  // else if(data.type =="Find a doctor"  ){
  //   return axios.post(`${WEB_API_BASE}/booking/doctall/admin/ne/normal`, data).then(e => e);}

  // }

  return axios.post(`${WEB_API_BASE}/booking/doctall/admin/new/${apiname}`, data).then(e => e);

  // return axios.post(`http://localhost/api/v1/booking/doctall/admin/new/free`, data).then(e => e);
  // http://test-api.doctall.com/api/v1/booking/doctall/admin/new/free


  // http://localhost/api/v1/booking/BK-D2094E7772F9/booked
};




export const statusChange = (auth, result) => {
  setHeadersWithAccessToken(auth);
  console.log("servicebook", result)

  // return axios.put(`${WEB_API_BASE}/booking/admin/${result}/booked`).then(e => e);
  // return axios.put(`http://localhost/api/v1/booking/adminBooking/admin/${result}/booked`).then(e => e);
  return axios.put(`${WEB_API_BASE}/booking/adminBooking/admin/${result}/booked`).then(e => e);

  // return axios.post(`http://localhost/api/v1/booking/doctall/admin/new/free`, data).then(e => e);
  // http://test-api.doctall.com/api/v1/booking/doctall/admin/new/free


  // http://localhost/api/v1/booking/BK-D2094E7772F9/booked
};


