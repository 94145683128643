import axios from 'axios';
import { setHeadersWithAccessToken } from './index';
const WEB_API_BASE = process.env.REACT_APP_WEB_APP;


export const fetchPartners = (auth, pageNumber, searchQuery) => {
    setHeadersWithAccessToken(auth);
    let search = ''

    if (searchQuery) {
        try {
            search = searchQuery.toString().trim()
        } catch (error) {
            search = ''
        }
    }

    let limit = "10"
    let page;
    if (pageNumber === undefined) {
        page = 0
    } else {
        page = pageNumber
    }

    return axios
        .get(`${WEB_API_BASE}/user/partner/getPartnerdetail?skip=${page}&limit=${limit}&search=${search}`)
        .then(e => e);
};
