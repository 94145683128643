import React, { useState, useEffect } from 'react';
import { Form, Modal, Input, Row, Col, Button, Select } from 'antd';
import "react-phone-input-2/lib/style.css";
import axios from 'axios';
import 'antd/dist/antd.css';
import { message, Upload } from 'antd';



const { Option } = Select;

const AddPathalogy = ({ visible, handleOk, handleCancel, onGetarea, newcity, newareas, onSubmit, city }) => {

  const [active, setActive] = useState();
  console.log(city, "Active")
  const [Availablity,setAvailablity] = useState('')

  const AvailablityData=[{name:"AVAILABLE"},{name:"NOT AVAILABLE"}];
  const populerTestData=[{name:"Yes"},{name:"No"}];

  const handleChange = (event) => {
    console.log(event, "adasd")
    setActive(event);
  };
  const [popular, setPopular] = useState();
  // console.log(active,"Active")

  const handleChangePapular = (event) => {
    console.log(event, "adasd")
    setPopular(event);
  };
  const handleSubmit = values => {
    // let values = values;
    console.log(values, "active123")


    const payload = {
      city: newcityuuid.name,
      area: areaData.name,
      diagnosticspartner: partnerData.name,
      standardiseddoctalltat: values.standardiseddoctalltat,
      doctallsexclusivediscountedprice: values.doctallsexclusivediscountedprice,
      discountofferedforretail: values.discountofferedforretail,
      doctallspurchasingprice: values.doctallspurchasingprice,
      homeserviceavailability: values.homeserviceavailability,
      labretailprice: values.labretailprice,
      partnerlabtat: values.partnerlabtat,
      partnerlabtatindays: values.partnerlabtatindays,
      partnerstestname: values.partnerstestname,
      populartest_y_n: values.populartest_y_n,
      populartestdescription: values.populartestdescription,
      productcode: values.productcode,
      standardisedtestname: values.standardisedtestname,
      testdiscipline: values.testdiscipline,
      testname_uuid: nationalPathData,
      areaRef: areaData.uuid,
      partner_uuid: partnerData.uuid,
    }

    console.log(payload, "payloadpayload")
    onSubmit(payload);
    // reset();
    // handleOk();


    // window.location.reload('');
  };


  const [typesubmit, settypesubmit] = useState("addpartner");

  const [newcityuuid, setnewcityuuid] = useState('');
  const [areaData, setAreaData] = useState('');
  const [areaList, setAreaList] = useState([])
  const [partnersList, setPartnerList] = useState([]);
  const [partnerData, setPartnerData] = useState('');
  const [nationaPathList, setNationalPathList] = useState([])
  const [nationalPathData, setNationalPathData] = useState('')
  const [StandardisedTest, setStandardisedTest] = useState('');


  console.log(StandardisedTest, "StandardisedTest")
  const CityChange = (uuid) => {

    {
      city && city.cityName && city.cityName.data && city.cityName.data.map((item) => {
        if (uuid === item.uuid) {
          setnewcityuuid(item)
        }
      })
    }
    // console.log(item, "item")
    arealist(uuid)
  }
  const ArearChange = (uuid) => {

    {
      areaList && areaList.map((item) => {
        if (uuid === item.uuid) {
          setAreaData(item)
        }
      })
    }
  }

  const partnerChange = (uuid) => {

    {
      partnersList && partnersList.map((item) => {
        if (uuid === item.uuid) {
          setPartnerData(item)
        }
      })
    }
  }


  const nationalPathChange = (uuid) => {
    setNationalPathData(uuid)
  }
  const arealist = (id) => {
    axios.get(`${process.env.REACT_APP_WEB_APP}/uploadDoc/getArea/${id}`)
      .then((res) => {
        console.log(res, "areaList")
        setAreaList(res.data)
      })
      .catch((err) => {
        console.log(err, "err")
      })
  }

  const partnerList = () => {
    axios.get(`${process.env.REACT_APP_WEB_APP}/metadata/partner/getpartner`)
      .then((res) => {
        console.log(res.data, "areaList")
        setPartnerList(res.data)
      })
      .catch((err) => {
        console.log(err, "err")
      })
  }

  // const nationalPathologyList = () => {
  //   axios.get(`${process.env.REACT_APP_WEB_APP}/upload/nationalPathalogy?limit=10&page=0&search=`)
  //     .then((res) => {
  //       console.log(res.data.data, "areaList")
  //       setNationalPathList(res.data.data)
  //     })
  //     .catch((err) => {
  //       console.log(err, "err")
  //     })
  // }

  // useEffect(() => {
  //   partnerList();
  //   nationalPathologyList();
  // }, [])

  const [pageNumberData, setPageNumber] = useState(0)
  const [radiologyCount, setRadiologyCount] = useState('')
  const [search, setSearch] = useState('')

  const nationalPathologyList = (pageNumberData, search) => {
    axios.get(`${process.env.REACT_APP_WEB_APP}/upload/nationalPathalogy?limit=10&page=${pageNumberData}&search=${search}`)
      .then((res) => {
        console.log(res.data.data, "areaList")
        setRadiologyCount(res.data.count)
        setNationalPathList(res.data.data)
      })
      .catch((err) => {
        console.log(err, "err")
      })
  }

  useEffect(() => {
    partnerList();
    nationalPathologyList(pageNumberData, search);
  }, [])

  const scrollFun = () => {
    console.log(radiologyCount, pageNumberData, "Sat")
    if (Math.ceil(Number(radiologyCount) / 10) > pageNumberData && Number(radiologyCount) >= 10) {
      setPageNumber(pageNumberData + 1)
      nationalPathologyList(pageNumberData + 1, '')
    }
  }
  const handleSearch = (event) => {
    console.log(event, "event")
    setSearch(event)
    nationalPathologyList(0, event)

  }


  const handleChangeSDSD = async (event) => {
    try {
      // setLoading(true)
      let response = await nationalPathologyList(0, event)
      if (response) {
        setRadiologyCount(response.data.count)
        setNationalPathList(response.data.data)
        // setLoading(false)
      }
    } catch (error) {
      console.log(error)
      // setLoading(false)
    }
  }





  const [formFields, setFormFields] = useState([
    { city: '', area: '', areaRef: '', productcode: '', testdiscipline: '',  partnerstestname: '',homeserviceavailability:'', populartestdescription: '', labretailprice: '', doctallspurchasingprice: '', discountofferedforretail: '', partnerlabtat: '', partnerlabtatindays: '', standardiseddoctalltat: '', doctallsexclusivediscountedprice: '',populartest_y_n:'' },
  ])
  console.log(formFields, "formFieldsformFields")
  const handleFormChange = (event, index, type, subType) => {
    let data = [...formFields];
    if (type === "select") {
      if (subType === "city") {
        console.log(event, index, "sdfsdfsdf")
        // data[index][event.target.name] = event.target.value;
        data[index]["city"] = event;
        setFormFields(data);
        arealist(event)
      }
      else if (subType === "area") {
        {
          areaList && areaList.map((item) => {
            if (event === item.uuid) {
              // setAreaData(item)
              data[index]["area"] = item.name;
              data[index]["areaRef"] = item.uuid
              setFormFields(data);
            }
          })
        }
      }
      // else if (subType === "Diagnostics") {

      //   {
      //     partnersList && partnersList.map((item) => {
      //       if (event === item.uuid) {
      //         // setAreaData(item)
      //         data[index]["Diagnostics"] = item.uuid;
      //         // data[index]["arearef"]=item.uuid
      //         setFormFields(data);
      //       }
      //     })
      //   }
      // }
      else if (subType==="homeserviceavailability") {

        {
          AvailablityData && AvailablityData.map((item) => {
            console.log(item,"homeserviceavailabilityhomeserviceavailability")
            if (event === item.name) {
              // setAreaData(item)
              data[index]["homeserviceavailability"] = item.name;
              // data[index]["arearef"]=item.uuid
              setFormFields(data);
            }
          })
        }
      }else if (subType==="populartest_y_n") {
        {
          populerTestData && populerTestData.map((item) => {
            console.log(item,"homeserviceavailabilityhomeserviceavailability")
            if (event === item.name) {
              // setAreaData(item)
              data[index]["populartest_y_n"] = item.name;
              // data[index]["arearef"]=item.uuid
              setFormFields(data);
            }
          })
        }
      }
      
    }
    else {
      console.log(event, index, "sdfsdfsdf")
      let data = [...formFields];
      data[index][event.target.name] = event.target.value;

      setFormFields(data);
    }
  }


  const submit = (e) => {
    e.preventDefault();
    console.log(formFields)
  }

  const addFields = () => {
    let object = {
      city: '',
      area: '',
      areaRef: '',
      // Diagnostics: '',
      productcode: '',
      testdiscipline: '',
      // standardisedtestname: '',
      partnerstestname: '',
      homeserviceavailability:'',
      populartestdescription: '',
      doctallspurchasingprice: '',
      discountofferedforretail: '',
      partnerlabtat: '',
      partnerlabtatindays: '',
      standardiseddoctalltat: '',
      doctallsexclusivediscountedprice: '',

      populartest_y_n:'',


    }

    setFormFields([...formFields, object])
  }

  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1)
    setFormFields(data)
  }


  const handleSubmit1 = values => {
    // let values = values;
    console.log(values, "active123")


    const payload = {

      diagnosticspartner: partnerData.name,
      standardisedtestname: StandardisedTest,
      partner_uuid: partnerData.uuid,
      testname_uuid: nationalPathData,




      //      "diagnosticspartner" : "Sai diagnostic center",
      // "standardisedtestname" : "standardised test name",
      // "partner_uuid" :"PTN-345634",
      // "testname_uuid" : "LP-186D51BD",
      testData: formFields,

    }

    // console.log(payload, "payloadpayload")
    onSubmit(payload);

  };


  return (
    <Modal
      className='no-modal-footer'
      title='Add Pathalogy'
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={900}
      okText='Add'
      footer={[
        <Button className='login-form-button' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          type='primary'
          htmlType='submit'
          className='login-form-button ml--10'
        >
          Add
        </Button>
      ]}
    >

      <div className="App">
      <Form  className='gx-form-row0' layout='vertical'>
        <Row className="space-between">
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Diagnostics Partner"
              name="diagnosticspartner"
              // initialValue={user && user.diagnosticspartner}
              rules={[{ required: true, message: "Please enter Partner Name !" }]}
            >
              <Select
                label='Partner Name'
                size="large"
                // showSearch
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                placeholder="Select Partner"
                onChange={partnerChange}
              >
                {partnersList.map((item) => {
                  return <Option value={item.uuid}>{item.name}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Standardised Doctall Tat"
              name="standardiseddoctalltat"
              // initialValue={user && user.standardisedtestname}
              rules={[{ required: true, message: "Please enter Standardised Doctall Tat !" }]}
              onChange={(e) => { setStandardisedTest(e.target.value) }}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Test Name"
              name="testname_uuid"
              // initialValue={user && user.diagnosticspartner}
              rules={[{ required: true, message: "Please enter Test Name!" }]}
            >
              <Select
                label='Test Name'
                size="large"
                showSearch
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                onPopupScroll={scrollFun}
                onSearch={(event) => { handleSearch(event); handleChangeSDSD(event) }}
                placeholder="Select Test"
                onChange={nationalPathChange}
              >
                {nationaPathList.map((item) => {
                  return <Option value={item.uuid}>{item.standardisedtestname}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        </Form>
        <Form onSubmit={submit} className='gx-form-row0' layout='vertical'>
          {formFields.map((form, index) => {
            return (
              <div key={index} className='gx-form-row0' layout='vertical'>
                <Row className="space-between">
                  <Col span={7} xs={7} md={7}>
                    <Form.Item
                      label='City Name'
                      size="large"
                      // name='area_name'
                      name='name'
                      rules={[{ required: true, message: 'Please enter City!' }]}
                    // style={{width:"100%"}}
                    >

                      <Select

                        label='City Name'
                        size="large"
                        // name='name'
                        // showSearch
                        // size="large"
                        style={{
                          width: "100%", borderRadius: "6px",
                        }}
                        placeholder="Select City"
                        // onChange={(item) => CityChange(item)}
                        onChange={event => handleFormChange(event, index, "select", "city")}

                      // value={form.}
                      >
                        {city && city.cityName && city.cityName.data && city.cityName.data.map((item) => {
                          return <Option value={item.uuid} >{item.name}</Option>
                        })}
                      </Select>
                    </Form.Item>

                  </Col>

                  <Col span={7} xs={7} md={7}>
                    <Form.Item
                      label="Area Name"
                      name="area"
                      // initialValue={user && user.area}
                      rules={[{ required: true, message: "Please enter Area name!" }]}
                    >
                      <Select
                        label='Area Name'
                        size="large"
                        // showSearch
                        style={{
                          width: "100%", borderRadius: "6px",
                        }}
                        placeholder="Select Area"
                        // onChange={ArearChange}
                        onChange={event => handleFormChange(event, index, "select", "area")}
                      >
                        {areaList && areaList.map((item) => {
                          return <Option value={item.uuid}>{item.name}</Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* <Col span={7} xs={7} md={7}>
                    <Form.Item
                      label="Diagnostics Partner"
                      name="Diagnostics"
                      rules={[{ required: true, message: "Please enter Partner Name !" }]}
                    >
                      <Select
                        label='Partner Name'

                        style={{
                          width: "100%", borderRadius: "6px",
                        }}
                        placeholder="Select Partner"
                        onChange={event => handleFormChange(event, index, "select", "Diagnostics")}
                      >
                        {partnersList.map((item) => {
                          return <Option value={item.uuid}>{item.name}</Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col> */}
                  <Col span={7} xs={7} md={7}>
                    <Form.Item
                      label="Product Code"
                      name="productcode"
                      rules={[{ required: true, message: "Please enter Area name!" }]}
                    >
                      <Input size="large"
                        name="productcode"
                        onChange={event => handleFormChange(event, index)}
                        value={form.productcode} />
                    </Form.Item>
                  </Col>
                  <Col span={7} xs={7} md={7}>
                    <Form.Item
                      label="Test Discipline "
                      name="testdiscipline"
                      // initialValue={user && user.testdiscipline}
                      rules={[{ required: true, message: "Please enter Area name!" }]}
                    >
                      <Input size="large" name='testdiscipline'
                        onChange={event => handleFormChange(event, index)}
                        value={form.testdiscipline}
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col span={7} xs={7} md={7}>
                    <Form.Item
                      label="Standardised Test Name"
                      name="standardisedtestname"
                      // initialValue={user && user.standardisedtestname}
                      rules={[{ required: true, message: "Please enter Area name!" }]}
                    >
                      <Input size="large"
                        name="standardisedtestname"
                        onChange={event => handleFormChange(event, index)}
                        value={form.standardisedtestname}
                      />
                    </Form.Item>
                  </Col> */}
                  <Col span={7} xs={7} md={7}>
                    <Form.Item
                      label="Partner Test Name"
                      // name="partnerstestname"
                      // initialValue={user && user.standardisedtestname}
                      rules={[{ required: true, message: "Please enter Area name!" }]}
                    >
                      <Input size="large"
                        name="partnerstestname"
                        onChange={event => handleFormChange(event, index)}
                        value={form.partnerstestname}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} xs={7} md={7}>
                    <Form.Item
                      label="Home Service Availablity"
                      name="homeserviceavailability"
                      // initialValue={user && user.standardisedtestname}
                      rules={[{ required: true, message: "Please enter Area name!" }]}
                    >
                      <Select
                        label='Home Service'
                        size="large"
                        // showSearch
                        style={{
                          width: "100%", borderRadius: "6px",
                        }}
                        placeholder="Select City"
                        // onChange={CityChange}
                        onChange={event => handleFormChange(event, index, "select", "homeserviceavailability")}
                      >
                      

                      {AvailablityData && AvailablityData.map((item) => {
                          return <Option value={item.name}>{item.name}</Option>
                        })}
                        {/* <Option value="AVAILABLE">AVAILABLE</Option>
                        <Option value="NOT AVAILABLE">NOT AVAILABLE</Option> */}

                        {/* {city && city.cityName && city.cityName.data && city.cityName.data.map((item) => {
                  return <Option value={item.name}>{item.name}</Option>
                })} */}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={7} xs={7} md={7}>
                    <Form.Item
                      label="Popular Test Description"
                      name="populartestdescription"
                      // initialValue={user && user.standardisedtestname}
                      rules={[{ required: true, message: "Please enter Area name!" }]}
                    >
                      <Input size="large"
                        name="populartestdescription"
                        onChange={event => handleFormChange(event, index)}
                        value={form.populartestdescription}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} xs={7} md={7}>
                    <Form.Item
                      label="Lab Retail Price"
                      name="labretailprice"
                      // initialValue={user && user.standardisedtestname}
                      rules={[{ required: true, message: "Please enter Area name!" }]}
                    >
                      <Input size="large"
                        name="labretailprice"
                        onChange={event => handleFormChange(event, index)}
                        value={form.labretailprice}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} xs={7} md={7}>
                    <Form.Item
                      label="Doctall Purchasing Price"
                      name="doctallspurchasingprice"
                      // initialValue={user && user.standardisedtestname}
                      rules={[{ required: true, message: "Please enter Area name!" }]}
                    >
                      <Input size="large"
                        name="doctallspurchasingprice"
                        onChange={event => handleFormChange(event, index)}
                        value={form.doctallspurchasingprice}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} xs={7} md={7}>
                    <Form.Item
                      label="Discount offered for Retail"
                      name="discountofferedforretail"
                      // initialValue={user && user.standardisedtestname}
                      rules={[{ required: true, message: "Please enter Area name!" }]}
                    >
                      <Input size="large"

                        name="discountofferedforretail"
                        onChange={event => handleFormChange(event, index)}
                        value={form.discountofferedforretail}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} xs={7} md={7}>
                    <Form.Item
                      label="Partner Lab Tat"
                      name="partnerlabtat"
                      // initialValue={user && user.standardisedtestname}
                      rules={[{ required: true, message: "Please enter Area name!" }]}
                    >
                      <Input size="large"

                        name="partnerlabtat"
                        onChange={event => handleFormChange(event, index)}
                        value={form.partnerlabtat}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} xs={7} md={7}>
                    <Form.Item
                      label="Partner Lab Tat in Days"
                      name="partnerlabtatindays"
                      // initialValue={user && user.standardisedtestname}
                      rules={[{ required: true, message: "Please enter Area name!" }]}
                    >
                      <Input size="large"

                        name="partnerlabtatindays"
                        onChange={event => handleFormChange(event, index)}
                        value={form.partnerlabtatindays}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} xs={7} md={7}>
                    <Form.Item
                      label="Standardised Doctall Tat"
                      name="standardiseddoctalltat"
                      // initialValue={user && user.standardisedtestname}
                      rules={[{ required: true, message: "Please enter Area name!" }]}
                    >
                      <Input size="large"
                        name="standardiseddoctalltat"
                        onChange={event => handleFormChange(event, index)}
                        value={form.standardiseddoctalltat}

                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} xs={7} md={7}>
                    <Form.Item
                      label="Doctall Exclusived Discount Price"
                      name="doctallsexclusivediscountedprice"
                      // initialValue={user && user.standardisedtestname}
                      rules={[{ required: true, message: "Please enter Doctall Exclusived Discount Price!" }]}
                    >
                      <Input size="large"
                        name="doctallsexclusivediscountedprice"
                        onChange={event => handleFormChange(event, index)}
                        value={form.doctallsexclusivediscountedprice}

                      />
                    </Form.Item>
                  </Col>
                  {/* <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Standardised Doctall Tat"
              name="standardiseddoctalltat"
              // initialValue={user && user.standardisedtestname}
              rules={[{ required: true, message: "Please enter Standardised Doctall Tat !" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col> */}

                  <Col span={7} xs={7} md={7}>
                    <Form.Item
                      label='populartest_y_n'
                      name='populartest_y_n'
                      rules={[{ required: true, message: 'Please input pharmacy name!' }]}
                    >
                      <Select
                        label='Area Name'
                        size="large"
                        rules={[{ required: true, message: 'Please input pharmacy name!' }]}
                        style={{
                          width: "100%", borderRadius: "6px",
                        }}
                        placeholder="Select City"
                  

                      onChange={event => handleFormChange(event, index, "select", "populartest_y_n")}
                      >
                      

                      {populerTestData && populerTestData.map((item) => {
                          return <Option value={item.name}>{item.name}</Option>
                        })}



                      </Select>
                    </Form.Item>

                  </Col>

                  <Col span={7} xs={7} md={7}>
                    <Button
                      type='danger'
                      // htmlType='submit'
                      className='login-form-button ml--10'
                      onClick={() => removeFields(index)}
                    >
                      Remove
                    </Button>
                  </Col>





                </Row>

                <hr />


                {/* <input
                name='name'
                placeholder='Name'
                onChange={event => handleFormChange(event, index)}
                value={form.name}
              />
              <input
                name='age'
                placeholder='Age'
                onChange={event => handleFormChange(event, index)}
                value={form.age}
              />

<input
                name='number'
                placeholder='number'
                onChange={event => handleFormChange(event, index)}
                value={form.number}
              /> */}


              </div>
            )
          })}
        </Form>



        <br />
        {/* <button onClick={submit}>Submit</button> */}
      </div>








      <Col span={12} xs={12} md={12}>
        <Button className='login-form-button' onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          type='primary'
          htmlType='submit'
          className='login-form-button ml--10'
          onClick={handleSubmit1}
        >
          Submit
        </Button>

        <Button
          type='primary'
          // htmlType='submit'
          className='login-form-button ml--10'
          onClick={addFields}
        >
          Add More..
        </Button>

      </Col>



      {/* <Form onFinish={handleSubmit} className='gx-form-row0' layout='vertical'>
        <Row className="space-between">
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='City Name'
              // name='area_name'
              rules={[{ required: true, message: 'Please enter City!' }]}
            >
              <Select
                label='City Name'
                // showSearch
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                placeholder="Select City"
                onChange={(item) => CityChange(item)}
              >
                {city && city.cityName && city.cityName.data && city.cityName.data.map((item) => {
                  return <Option value={item.uuid} >{item.name}</Option>
                })}
              </Select>
            </Form.Item>

          </Col>

          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Area Name"
              name="area"
              // initialValue={user && user.area}
              rules={[{ required: true, message: "Please enter Area name!" }]}
            >
              <Select
                label='Area Name'
                // showSearch
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                placeholder="Select Area"
                onChange={ArearChange}
              >
                {areaList && areaList.map((item) => {
                  return <Option value={item.uuid}>{item.name}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Diagnostics Partner"
              name="diagnosticspartner"
              // initialValue={user && user.diagnosticspartner}
              rules={[{ required: true, message: "Please enter Partner Name !" }]}
            >
              <Select
                label='Partner Name'
                // showSearch
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                placeholder="Select Partner"
                onChange={partnerChange}
              >
                {partnersList.map((item) => {
                  return <Option value={item.uuid}>{item.name}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Test Name"
              name="testname_uuid"
              // initialValue={user && user.diagnosticspartner}
              rules={[{ required: true, message: "Please enter Test Name!" }]}
            >
              <Select
                label='Test Name'
                showSearch
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                onPopupScroll={scrollFun}
                onSearch={(event) => { handleSearch(event); handleChangeSDSD(event) }}
                placeholder="Select Test"
                onChange={nationalPathChange}
              >
                {nationaPathList.map((item) => {
                  return <Option value={item.uuid}>{item.standardisedtestname}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Product Code "
              name="productcode"
              // initialValue={user && user.productcode}
              rules={[{ required: true, message: "Please enter Area name!" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Test Discipline "
              name="testdiscipline"
              // initialValue={user && user.testdiscipline}
              rules={[{ required: true, message: "Please enter Area name!" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Standardised Test Name"
              name="standardisedtestname"
              // initialValue={user && user.standardisedtestname}
              rules={[{ required: true, message: "Please enter Area name!" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Partner Test Name"
              name="partnerstestname"
              // initialValue={user && user.standardisedtestname}
              rules={[{ required: true, message: "Please enter Area name!" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Home Service Availablity"
              name="homeserviceavailability"
              // initialValue={user && user.standardisedtestname}
              rules={[{ required: true, message: "Please enter Area name!" }]}
            >
              <Select
                label='Home Service'
                // showSearch
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                placeholder="Select City"
                onChange={CityChange}
              >
                <Option value="AVAILABLE">AVAILABLE</Option>
                <Option value="NOT AVAILABLE">NOT AVAILABLE</Option>

                {city && city.cityName && city.cityName.data && city.cityName.data.map((item) => {
                  return <Option value={item.name}>{item.name}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Popular Test Description"
              name="populartestdescription"
              // initialValue={user && user.standardisedtestname}
              rules={[{ required: true, message: "Please enter Area name!" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Lab Retail Price"
              name="labretailprice"
              // initialValue={user && user.standardisedtestname}
              rules={[{ required: true, message: "Please enter Area name!" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Doctall Purchasing Price"
              name="doctallspurchasingprice"
              // initialValue={user && user.standardisedtestname}
              rules={[{ required: true, message: "Please enter Area name!" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Discount offered for Retail"
              name="discountofferedforretail"
              // initialValue={user && user.standardisedtestname}
              rules={[{ required: true, message: "Please enter Area name!" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Partner Lab Tat"
              name="partnerlabtat"
              // initialValue={user && user.standardisedtestname}
              rules={[{ required: true, message: "Please enter Area name!" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Partner Lab Tat in Days"
              name="partnerlabtatindays"
              // initialValue={user && user.standardisedtestname}
              rules={[{ required: true, message: "Please enter Area name!" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Standardised Doctall Tat"
              name="standardiseddoctalltat"
              // initialValue={user && user.standardisedtestname}
              rules={[{ required: true, message: "Please enter Area name!" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Doctall Exclusived Discount Price"
              name="doctallsexclusivediscountedprice"
              // initialValue={user && user.standardisedtestname}
              rules={[{ required: true, message: "Please enter Doctall Exclusived Discount Price!" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Standardised Doctall Tat"
              name="standardiseddoctalltat"
              // initialValue={user && user.standardisedtestname}
              rules={[{ required: true, message: "Please enter Standardised Doctall Tat !" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>

          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='populartest_y_n'
              name='populartest_y_n'
              rules={[{ required: true, message: 'Please input pharmacy name!' }]}
            >
              <Select
                label='Area Name'
                rules={[{ required: true, message: 'Please input pharmacy name!' }]}
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                placeholder="Select City"
                onChange={handleChangePapular}
              // defaultValue={user && user.active}
              // initialValue={user && user.city_uuid}

              >

                <Option value={"YES"}>Yes</Option>
                <Option value={"NO"}>No</Option>



              </Select>
            </Form.Item>

          </Col>
          <Col span={7} xs={7} md={7}>
          </Col>

        </Row>




        <Button className='login-form-button' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          type='primary'
          htmlType='submit'
          className='login-form-button ml--10'
        >
          Add
        </Button>
      </Form> */}


    </Modal>
  );
};

export default AddPathalogy;
