const MasterAction = {
    GET_PARTNERS_NAME: "GET_PARTNERS_NAME",
    GET_PARTNERS_SUCCESS: "GET_PARTNERS_SUCCESS",

    GET_CITY_NAME: "GET_CITY_NAME",
    GET_CITY_SUCCESS: "GET_CITY_SUCCESS",

    GET_AREA_NAME: "GET_AREA_NAME",
    GET_AREA_SUCCESS: " GET_AREA_SUCCESS",

    EDITE_PARTNER:"EDITE_PARTNER",
    EDITE_PARTNER_SUCCESS: "EDITE_PARTNER_SUCCESS",

    
    EDITE_CITY:"EDITE_CITY",
    EDITE_CITY_SUCCESS: "EDITE_CITY_SUCCESS",


    EDITE_AREA_NAME:"EDITE_AREA_NAME",
    EDITE_AREA_SUCCESS: "EDITE_AREA_SUCCESS",


    EDITE_COMPANY:"EDITE_COMPANY",
    EDITE_COMPANY_SUCCESS: "EDITE_COMPANY_SUCCESS",

    ADD_PARTNER1: "ADD_PARTNER1",

    ADD_COMPANY: "ADD_COMPANY",

    ADD_CITY_NAME: "ADD_CITY_NAME",
    // ADD_PARTNER_SUCCES: "ADD_PARTNER_SUCCES",

    ADD_AREA_NAME:"ADD_AREA_NAME",



    GET_NATIONAL_PATHALOGY: "GET_NATIONAL_PATHALOGY",
    GET_NATIONAL_PATHALOGY_SUCCESS: "GET_NATIONAL_PATHALOGY_SUCCESS",

    GET_MASTER_PATHALOGY: "GET_MASTER_PATHALOGY",
    GET_MASTER_PATHALOGY_SUCCESS: "GET_MASTER_PATHALOGY_SUCCESS",

    GET_MASTER_HOMESAMPLE: "GET_MASTER_HOMESAMPLE",
    GET_MASTER_HOMESAMPLE_SUCCESS: "GET_MASTER_HOMESAMPLE_SUCCESS",

    GET_MASTER_RADIOLOGY: "GET_MASTER_RADIOLOGY",
    GET_MASTER_RADIOLOGY_SUCCESS: "GET_MASTER_RADIOLOGY_SUCCESS",

    GET_NATIONAL_RADIOLOGY: "GET_NATIONAL_RADIOLOGY",
    GET_NATIONAL_RADIOLOGY_SUCESS: "GET_NATIONAL_RADIOLOGY_SUCESS",

    EDITE_NATIONAL_PATHALOGY:"EDITE_NATIONAL_PATHALOGY",
    EDITE_NATIONAL_PATHALOGY_SUCCESS: "EDITE_NATIONAL_PATHALOGY_SUCCESS",

    EDITE_MASTER_PATHALOGY : "EDITE_MASTER_PATHALOGY",
    EDITE_MASTER_PATHALOGY_SUCCESS: "EDITE_MASTER_PATHALOGY_SUCCESS",

    EDITE_MASTER_HOMESAMPLE : "EDITE_MASTER_HOMESAMPLE",
    EDITE_MASTER_HOMESAMPLE_SUCCESS: "EDITE_MASTER_HOMESAMPLE_SUCCESS",

    EDITE_MASTER_RADIOLOGY : "EDITE_MASTER_RADIOLOGY",
    EDITE_MASTER_RADIOLOGY_SUCCESS: "EDITE_MASTER_RADIOLOGY_SUCCESS",


    EDITE_NATIONAL_RADIOLOGY:"EDITE_NATIONAL_RADIOLOGY",
    EDITE_NATIONAL_RADIOLOGY_SUCCESS: "EDITE_NATIONAL_RADIOLOGY_SUCCESS",

    
    ADD_NATIONAL_PATHALOGY: "ADD_NATIONAL_PATHALOGY",

    ADD_MASTER_PATHALOGY: "ADD_MASTER_PATHALOGY",

    ADD_MASTER_RADIOLOGY: "ADD_MASTER_RADIOLOGY",

    ADD_MASTER_HOME_SAMPLE_COLLECTION: "ADD_MASTER_HOME_SAMPLE_COLLECTION",



    ADD_NATIONAL_RADIOLOGY: "ADD_NATIONAL_RADIOLOGY",

    GET_INFLUENCER_DATA: "GET_INFLUENCER_DATA",
    GET_INFLUENCER_SUCCESS: "GET_INFLUENCER_SUCCESS",

    GET_PHARMACY_NEW_DATA: "GET_PHARMACY_NEW_DATA",
    GET_PHARMACY_NEW_SUCCESS: "GET_PHARMACY_NEW_SUCCESS",

    GET_COMPANY_DATA: "GET_COMPANY_DATA",
    GET_COMPANY_DATA_SUCCESS: "GET_COMPANY_DATA_SUCCESS",



    GET_INTRODUCER_DATA: "GET_INTRODUCER_DATA",
    GET_INTRODUCER_SUCCESS: "GET_INTRODUCER_SUCCESS",


    


    getCompanyData: () => {
      console.log("GET_doc1132")
      return {
        type: MasterAction.GET_COMPANY_DATA
      };
    },
 
    getPartnersDta: (searchData) => {
      console.log("GET_doc1")
      return {
        type: MasterAction.GET_PARTNERS_NAME,
        searchData
      };
    },
    
  
    getCityDta: (  searchData) => {
      console.log("GET_doc2")
      return {
        type: MasterAction.GET_CITY_NAME,
        searchData
      };
    },

    getAreaDta: (searchData) => {
      console.log("asdlas")
      return {
        type: MasterAction.GET_AREA_NAME,
        searchData
      };
      
    },


    editPartnerName: (payLoad) => {
      console.log(payLoad,"ppp")
      return {
        type: MasterAction.EDITE_PARTNER,
        payLoad,
    
      };
    },
    editCompany: (payLoad) => {
      console.log(payLoad,"ppp")
      return {
        type: MasterAction.EDITE_COMPANY,
        payLoad,
    
      };
    },
    
    editCityName: (payLoad) => {
      console.log(payLoad,"ppp")
      return {
        type: MasterAction.EDITE_CITY,
        payLoad,
    
      };
    },

    editAreaName: (payLoad) => {
      console.log(payLoad,"ppp12")
      return {
        type: MasterAction.EDITE_AREA_NAME,
        payLoad,
    
      };
    },

    
      addPArtner: (data) => {
        console.log("add pharmacy action", data)
        return {
          type: MasterAction.ADD_PARTNER1,
          data
        };
      },

      addCompany: (data) => {
        console.log("add pharmacy action", data)
        return {
          type: MasterAction.ADD_COMPANY,
          data
        };
      },

      addCityName: (data) => {
        console.log("add pharmacy action", data)
        return {
          type: MasterAction.ADD_CITY_NAME,
          data
        };
      },

      addAreaName: (data) => {
        console.log("add area", data)
        return {
          type: MasterAction.ADD_AREA_NAME,
          data
        };
      },

      

      
    getNationalPathalogy: (page,pageSiz, searchData) => {
      console.log("GET_doc1")
      return {
        type: MasterAction.GET_NATIONAL_PATHALOGY,
        page,
        pageSiz,
        searchData
      };
    },
    getMasterPathalogy: (page,pageSize, searchData,partnerData,TestNameData,areaData) => {
      console.log("GET_doc1")
      return {
        type: MasterAction.GET_MASTER_PATHALOGY,
        
        page,
        pageSize,
        searchData,
        partnerData,
        TestNameData,
        areaData,
      };
    },

    getMasterHomeSample: (page,pageSize, searchData,partnerData,TestNameData,areaData) => {
      console.log("GET_doc1")
      return {
        type: MasterAction.GET_MASTER_HOMESAMPLE, 
        page,
        pageSize,
        searchData,
        // partnerData,
        // TestNameData,
        // areaData,
      };
    },

    getMasterRadiology: (page, searchData,partnerData,TestNameData,areaData) => {
      console.log("GET_doc1")
      return {
        type: MasterAction.GET_MASTER_RADIOLOGY,
        page,
        searchData,
        partnerData,
        TestNameData,
        areaData,
      };
    },



    editPathalogy: (payLoad) => {
      console.log(payLoad,"Apathalogy")
      return {
        type: MasterAction.EDITE_NATIONAL_PATHALOGY,
        payLoad,
    
      };
    },

    editMasterPathalogy: (uuid,uuid1,payLoad) => {
      console.log(payLoad,"Apathalogy")
      return {
        type: MasterAction.EDITE_MASTER_PATHALOGY,
        uuid,
        uuid1,
        payLoad,
    
      };
    },

    editMasterRadiology: (uuid,uuid1,payLoad) => {
      console.log(payLoad,"Apathalogy")
      return {
        type: MasterAction.EDITE_MASTER_RADIOLOGY,
        uuid,
        uuid1,
        payLoad,
    
      };
    },

    editMasterHomeSample: (uuid,payLoad) => {
      console.log(payLoad,"Apathalogy")
      return {
        type: MasterAction.EDITE_MASTER_HOMESAMPLE,
        uuid,
        payLoad,
    
      };
    },

    addPathalogy: (data) => {
      console.log("add pharmacy action", data)
      return {
        type: MasterAction.ADD_NATIONAL_PATHALOGY,
        data
      };
    },

    addMasterPathalogy: (data) => {
      console.log("add pharmacy action", data)
      return {
        type: MasterAction.ADD_MASTER_PATHALOGY,
        data
      };
    },

    addhomeSampleCollection: (data) => {
      console.log("add pharmacy action", data)
      return {
        type: MasterAction.ADD_MASTER_HOME_SAMPLE_COLLECTION,
        data
      };
    },

    addMasterRadiology: (data) => {
      console.log("add pharmacy action", data)
      return {
        type: MasterAction.ADD_MASTER_RADIOLOGY,
        data
      };
    },

    getNationalRadiology: (pagenum,pageSize, searchDataradio) => {
      console.log("doc_2")
      return {
        type: MasterAction.GET_NATIONAL_RADIOLOGY,
        pagenum,
        pageSize,
        searchDataradio,
      };

    },

    editRadiology: (payload) => {
      console.log(payload,"Radiology")
      return {
        type: MasterAction.EDITE_NATIONAL_RADIOLOGY,
        payload
      };
    },

    addRadiology: (data) => {
      console.log("add pharmacy action" , data)
      return {
        type: MasterAction.ADD_NATIONAL_RADIOLOGY,
        data
      };
    },



    getInfluencerData: (page, searchData) => {
      console.log("GET_doc1123")
      return {
        type: MasterAction.GET_INFLUENCER_DATA,
        page,
        searchData
      };
    },
    getPharmacyNewData: (page, searchData) => {
      console.log("GET_doc1123")
      return {
        type: MasterAction.GET_PHARMACY_NEW_DATA,
        page,
        searchData
      };
    },
    getIntroducerData: (page, searchData) => {
      console.log("GET_doc1123")
      return {
        type: MasterAction.GET_INTRODUCER_DATA,
        page,
        searchData
      };
    },
    
    


  };
  
  export default MasterAction;
  