import React, { useState, useEffect } from "react";
import { userImage } from "helper/constant";
import notify from "../../assets/images/notify.svg";
import actions from "actions";
import { withRouter } from "react-router-dom";
import { Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import "./style.css";



const Topbar = (props) => {
  console.log(userImage,"userImage")
  const dispatch = useDispatch();
  const { logout } = actions.authActions;
  const [profilePopover, setProfilePopover] = useState(false);
  const { location } = props;
  const path = location.pathname;
  let heading = "";
  const user = useSelector((state) => state.auth);
  const [notifylist, setnotifylist] = useState([]);
  const [notifyPopupScreen, setNotifyPopupScreen] = useState(false);
  const [notificationPopup, setNotificationPopup] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState(true);


  useEffect(() => {
    (async () => {
      await axios
        .get(
          process.env.REACT_APP_WEB_APP + `/notification/list/${user && user.admin && user.admin.uuid}`,
          {
            headers: {
              "x-auth-token": user.accessToken,
            },
          }
        )
        .then((res) => {
          setnotifylist(res.data);
          console.log(res.data);
        });
    })();
  }, []);


  try {
    if (path === "/users") {
      heading = "Users";
    } else if (path === "/dashboard") {
      heading = "Dashboard";
    } else if (path === "/doctors") {
      heading = "Doctors";
    } else if (path === "/appointments") {
      heading = "Appointments";
    } else if (path === "/consumers") {
      heading = "Consumers";
    } else if (path === "/patients") {
      heading = "Patients";
    } else if (path === "/bookAppointment") {
      heading = "Book Appointment";
    } else if (path === "/viewpatientDocs") {
      heading = "Patient Documents";
    } else if (path === "/transactions") {
      heading = "Transactions";
    } else if (path === "/clinics") {
      heading = "Clinics";
    } else if (path === "/pharmacies") {
      heading = "Pharmacies";
    } else if (path === "/labs") {
      heading = "Labs";
    } else if (path === "/feedbacks") {
      heading = "FeedBack";
    } else if (path === "/push-notification") {
      heading = "Push Notifications";
    } else if (path === "/coupons") {
      heading = "Coupons";
    } else if (path === "/support") {
      heading = "Support";
    } else if (path === "/saas") {
      heading = "Third Party Doctors";
    }
    else if (path === "/partners") {
      heading = "Partners";
    }
    else if(path === "/Settings"){
      heading = "Settings"
    }

  } catch (error) {
    console.log(error)
  }


  const userSignout = () => {
    dispatch(logout());
  };

  const profilePopovertoggle = () => {
    setProfilePopover(!profilePopover);
  };
  const handleCountData = () => {
    let filterData = [];
    for (let x of notifylist) {
      filterData.push(x._id);
    }

    const data = JSON.stringify({ id: filterData, status: "read" });

    var config = {
      method: "put",
      url: process.env.REACT_APP_WEB_APP + "/notification/status/all",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  var filterCount = notifylist.filter((item) => {
    if (item.status == "unread") return item;
  });

  return (
    <div className="headerBack flex-x center">
      <div className="topbar--title flex-1">
        {heading !== "" && (
          <div className="mini-drawer-menu-icon">
            <span className="topbar-app-title fs--20 font-bold">{heading}</span>
          </div>
        )}
      </div>
      <div>
        <img
          src={ notify && notify.default?notify.default:notify}
          alt=""
          style={{ marginRight: "20px", cursor: "pointer" }}
          onClick={() => {
            setNotifyPopupScreen(!notifyPopupScreen);
            setNotificationPopup(false);
            handleCountData();
            setNotificationStatus(false);
          }}
        />
        {filterCount.length === 0 ? (
          ""
        ) : (
          <div className="NOTIFY_POPUP_COUNT" style={{ top: "10px" }}>
            {filterCount.length}
          </div>
        )}
        {notifyPopupScreen ? (
          <div
            className="notificationPopup NotifyPopupScreen"
            style={{ right: "96px", top: "58px", zIndex: "1000" }}
          >
            {notifylist.length !== 0 ? (
              notifylist.map((value, index) => (
                <div className="notificationList">
                  <div style={{ display: "flex" }} key={index}>
                    <div className="NOTIFY_POPUP_GREEN_DESIGN"></div>
                    <div style={{ width: "100%", height: "auto" }}>
                      <div className="NOTIFY_POPUP_PRIMARY_TEXT">
                        {value.message}
                      </div>
                      <div className="NOTIFY_POPUP_DATE">
                        {moment(value.createdAt).format("ddd, DD MMM YYYY")}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (<>
              <div className="notificationList">
                <div style={{ display: "flex" }}>
                  <div className="NOTIFY_POPUP_GREEN_DESIGN"></div>
                  <div style={{ width: "100%", height: "auto" }}>
                    <div className="NOTIFY_POPUP_PRIMARY_TEXT">
                      No Notification
                    </div>
                  </div>
                </div>
              </div>
            </>)}
          </div>
        ) : (
          ""
        )}
      </div>
      <Popover
        content={
          <div className="container-fluid grid-popover pa--0">
            <div className="d-flex  ma--0 cursor-pointer" onClick={userSignout}>
              <div className="pa--5">
                <i className="fas fa-sign-out-alt"></i>
              </div>
              <div className="pa--5">Logout</div>
            </div>
          </div>
        }
        trigger="click"
        visible={profilePopover}
        onVisibleChange={profilePopovertoggle}
        className="language-popover-width"
        placement="bottom"
      >
        <div className="cursor-pointer user--data">
          <img
            className="user-proifle-icon"
            id="profilePopover"
            onClick={profilePopovertoggle}
            src={userImage && userImage.default ? userImage.default : userImage}
            alt="user"
          />
        </div>
      </Popover>
    </div>
  );
};

export default withRouter(Topbar);
