import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import actions from "actions";
import CouponStatsTable from 'components/coupons/coupon-table/couponsStatTable'
import { Button, Input, Space, Form, Select } from "antd";
import { exportToExcel } from 'services/ExportServices';
import axios from 'axios';

const Search = Input.Search;

const { usedCouponsActions } = actions;
const { Option } = Select;

export default function UsedCouponsPage() {
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState('')
    const couponsData = useSelector((state) => state.usedCouponsReducer.stats);
    const authToken = useSelector((state) => state.auth.accessToken);
    const [customers, setCustomers] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState([])
    const [consumername, setConsumerName] = useState('')
    const [ids, setIds] = useState()

    const consumerChange = (event) => {
        console.log(event)
        setConsumerName(event)
        axios.get(`${process.env.REACT_APP_WEB_APP}/user/all/consumer/getconsumers?search=${event}`)
            .then((res) => {
                setCustomers(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
        // setCustomers([])
    }
    var selectedValues = [];
    const handleSelection = (event) => {
        //Add Data To Selected List
        dispatch(usedCouponsActions.getCouponStats(event))
        console.log(event, "event")
        setIds(event)
        selectedValues.push(event)
        setSelectedCustomer((prev) => [...prev, ...selectedValues])
        console.log(selectedCustomer)
    }
    const handleDeselection = (event) => {
        //Delete Data From Selected List
        var newArray = [...selectedCustomer]; // make a separate copy of the array
        var index = newArray.indexOf(event)
        if (index !== -1) {
            newArray.splice(index, 1);
            setSelectedCustomer(newArray)
        }
    }

    useEffect(() => {
        dispatch(usedCouponsActions.getCouponStats(searchQuery));
    }, [searchQuery])

    // useEffect(() => {
    //     axios.get(`${process.env.REACT_APP_WEB_APP}/user/all/consumer/getconsumers?search=${consumername}`)
    //         .then((res) => {
    //             setCustomers(res.data)
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // })

    console.log(useSelector((state) => state.usedCouponsReducer.stats), "afcshgav")
    const handleOnSearch = (value) => {
        setSearchQuery(value)
    }


    const handleExport = async () => {
        await exportToExcel("/coupon/export/toexcel", "TeleMedicine_Report", authToken);
    }

    return (
        <>
            <div className="pictogram-container">
                <div className="pictogram-header flex-x align-center ptb--15">
                    <Space direction="vertical">
                        {/* <Search
                            size="large"
                            enterButton="Search"
                            placeholder="Search Record"
                            onSearch={handleOnSearch}
                        /> */}
                        <Form.Item
                            style={{ width: 320 }}
                            label='Select Consumer'
                            name='user_uuid'
                            rules={[{ required: false }]}
                        >
                            {/* <Select
                                    showSearch
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    size="large"
                                    placeholder="Please select"
                                    onSearch={consumerChange}
                                    // loading={loading}
                                    onSelect={(event) => { handleSelection(event) }}
                                    onDeselect={(event) => { handleDeselection(event) }}
                                    onClear={() => { setSelectedCustomer([]) }}
                                >
                                    {customers &&
                                        customers.map((details) => (
                                            <option key={details.uuid} value={details.uuid}
                                                disabled={selectedCustomer.length >= 1}
                                            >
                                                {`${details.full_name}`}
                                            </option>
                                        ))}
                                </Select> */}
                            <Select
                                showSearch
                                placeholder="Select a person"
                                optionFilterProp="children"
                                // onChange={consumerChange}
                                onSearch={consumerChange}
                                onSelect={(event) => { handleSelection(event) }}
                                onDeselect={(event) => { handleDeselection(event) }}
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            >
                                {customers &&
                                    customers.map((details) => (
                                        <Option key={details.uuid} value={details.uuid}
                                        // disabled={selectedCustomer.length >= 1}
                                        >
                                            {`${details.full_name}`}
                                        </Option>
                                    ))}
                                {/* <Option value="jack">Jack</Option>
    <Option value="lucy">Lucy</Option>
    <Option value="tom">Tom</Option> */}
                            </Select>
                        </Form.Item>
                    </Space>
                    <div className="fs--16 font-semibold flex-1">{/* Users */}</div>

                    {/* <Button type="primary" onClick={handleExport}>Export</Button> */}

                </div>
                <div>
                    <CouponStatsTable couponsStatsData={couponsData && couponsData} />
                </div>

            </div>
        </>
    )
}
