import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from 'actions';
import { notification } from 'antd';
import { getNotifications, sendNotifications, sendDrafts } from 'services/pushNotificationServices';

const { userActions, pushNotificationAction, authActions } = actions;

export function* getNotificationSaga() {
    console.log('saga');
    yield takeEvery(pushNotificationAction.GET_NOTIFICATIONS, function* ({ pageNumber }) {
        try {
            const token = yield select(state => state.auth.accessToken);
            const response = yield getNotifications({ token }, pageNumber);
            if (response.status === 200) {
                yield put({
                    type: pushNotificationAction.GET_NOTIFICATIONS_SUCCESS,
                    data: response.data.notification,
                    count: response.data.count,
                });
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
        }
    });
}


export function* sendNotificationSaga() {

    console.log('saga');
    yield takeEvery(pushNotificationAction.ADD_NOTIFICATION, function* ({ data, consumers, selectOption, key }) {
        try {
            const token = yield select(state => state.auth.accessToken);

            const response = yield sendNotifications({ token }, data, consumers, selectOption, key);
            if (response.status === 200) {
                yield notification.open({
                    message: 'Success!',
                    description: `${key === "SEND" ? "Notification Sent Successfully" : "Notification Saved Successfully As Draft"}`
                });
                yield put({
                    type: pushNotificationAction.GET_NOTIFICATIONS
                });
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error);
            yield notification.open({
                message: 'Failed!',
                description: error.response.data
            });
            yield put({ type: userActions.ON_ERROR });
            if (error.response.status === 401) {
                yield put({ type: authActions.AUTH_ERROR });
            }
        }
    });
}


export function* sendDraft() {

    console.log('saga');
    yield takeEvery(pushNotificationAction.SEND_FROM_DRAFT, function* ({ data }) {
        try {
            const token = yield select(state => state.auth.accessToken);

            const response = yield sendDrafts({ token }, data);
            if (response.status === 200) {
                yield notification.open({
                    message: 'Success!',
                    description: "Notification Sent Successfully"
                });
                yield put({
                    type: pushNotificationAction.GET_NOTIFICATIONS
                });
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error);
            yield notification.open({
                message: 'Failed!',
                description: error.response.data
            });
            yield put({ type: userActions.ON_ERROR });
            if (error.response.status === 401) {
                yield put({ type: authActions.AUTH_ERROR });
            }
        }
    });
}


export default function* rootSaga() {
    yield all([fork(getNotificationSaga), fork(sendNotificationSaga), fork(sendDraft)]);
}