// import { Modal } from 'antd';
import React, { useState, useEffect } from "react";
import actions from "actions";
// import { Button } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { Spin, notification } from "antd";
import { green_dot, red_dot } from "helper/constant";
import moment from "moment";
import axios from "axios";
import { Form, Modal, Input, Row, Col, Button, Select } from "antd";
const { appointmentActions } = actions;

const { Option } = Select;

const { confirm } = Modal;

const ViewOrder = ({ user, visible, loader, handleOk, handleCancel, val }) => {
  const [form] = Form.useForm();
  const [group, setgroup] = useState("");
  const [test, setTest] = useState(val);
  const [disable, setDisable] = useState(false)

  const dispatch = useDispatch();

  const doctallAction = [
    "Doctor Will Confirm  Refund Request",
    "Confirm Result Received",
    "Confirm Result Accepted",
    "Result Rejected",
    "Confirm Refund Completed",
    "Confirm Receipt of Result",
  ];
  const handleChanger = (val, e) => {

    axios.put(`${process.env.REACT_APP_WEB_APP}/order/updateOrderStatusDoctall_action?doctall_action=${e.value}&uuid=${e.dataid}`, '', { headers: {} }).then((i =>
      setDisable(true),
      notification.open({ message: 'Success!', description: 'Status Changed' }))).catch(err => { console.log(err) })

  };
  console.log(disable)


  return (
    <Modal
      title={`Order Details`}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="submit" type="primary" onClick={handleOk}>
          Ok
        </Button>,
      ]}
    >
      <Spin spinning={loader}>
        <div className="flex-y align-left">
          <div className="read-form-input flex-1 mr--10 mt--15">
            <div className="read-form-label fs--14 font-semibold">
              Appointment Id
            </div>
            <div className="read-form-value">{user && user.uuid}</div>
          </div>
          {user && user.full_order_details.length > 1 ? (
            <>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  Test Name
                </div>
                <div className="read-form-value">
                  <Select
                    style={{ width: 300 }}
                    showSearch
                    autoClearSearchValue
                    allowClear
                    optionFilterProp="children"
                    onChange={(value) => {
                      setTest(value);
                    }}
                    value={test}
                  >
                    {user &&
                      user.full_order_details.map((i, ind) => (
                        <Option key={ind} value={ind}>
                          {i.test_info.standardisedtestname}
                        </Option>
                      ))}
                  </Select>
                </div>
              </div>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  Product Code
                </div>
                <div className="read-form-value">
                  {user &&
                    user.full_order_details &&
                    user.full_order_details[test].test_info &&
                    user.full_order_details[test].test_info.productcode}
                </div>
              </div>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  Clinical Advice
                </div>
                <div className="read-form-value">
                  {user &&
                    user.full_order_details &&
                    user.full_order_details[test].clinical_advice}
                </div>
              </div>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  No Of Days
                </div>
                <div className="read-form-value">
                  {user &&
                    user.full_order_details &&
                    user.full_order_details[test].test_info &&
                    user.full_order_details[test].test_info.partnerlabtat}
                </div>
              </div>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  MAX TAT
                </div>
                <div className="read-form-value">
                  {user &&
                    user.full_order_details &&
                    user.full_order_details[test].test_info &&
                    user.full_order_details[test].test_info.partnerlabtatindays}
                </div>
              </div>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  Acceptance Status
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <div className="read-form-value">
                    {user &&
                      user.full_order_details &&
                      user.full_order_details[test].test_info &&
                      user.full_order_details[test].acceptance_status &&
                      user.full_order_details[test].acceptance_status.reason}
                  </div>
                  <div className="read-form-value">
                    {user &&
                      user.full_order_details &&
                      user.full_order_details[test].test_info &&
                      user.full_order_details[test].acceptance_status &&
                      user.full_order_details[test].acceptance_status.status}
                  </div>
                </div>
              </div>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  Interim Progress{" "}
                </div>
                <div className="read-form-value">
                  {user &&
                    user.full_order_details &&
                    user.full_order_details[test].interim_progress &&
                    user.full_order_details[test].interim_progress.status}
                </div>
              </div>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  Sample Collection{" "}
                </div>
                <div className="read-form-value">
                  {user &&
                    user.full_order_details &&
                    user.full_order_details[test].sample_collection_progress}
                </div>
              </div>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  Final Action status
                </div>
                <div className="read-form-value">
                  {user &&
                    user.full_order_details &&
                    user.full_order_details[test].final_action &&
                    user.full_order_details[test].final_action.status}
                </div>
              </div>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  Final Action Results
                </div>
                <div>
                  {user &&
                    user.full_order_details &&
                    user.full_order_details[test].final_action &&
                    user.full_order_details[test].final_action.result.map(
                      (i) => (
                        <div
                          className="read-form-value"
                          style={{ cursor: "pointer" }}
                        >
                          <a href={i.url} target="_blank">
                            {i.filename}
                          </a>
                        </div>
                      )
                    )}
                </div>
              </div>
              {user &&
                user.full_order_details &&
                user.full_order_details[test].doctall_action === "" ?
                <div className="read-form-input flex-1 mr--10 mt--15">
                  <div className="read-form-label fs--14 font-semibold">
                    Doctall Action
                  </div>
                  <div>
                    <Select
                      disabled={disable}
                      style={{ width: 300 }}
                      showSearch
                      autoClearSearchValue
                      allowClear
                      optionFilterProp="children"
                      onChange={handleChanger}
                      labelInValue
                      defaultValue={{ value: "Select" }}
                    >
                      {doctallAction.map((i, ind) => (
                        <Option key={ind} value={i} dataid={user &&
                          user.full_order_details &&
                          user.full_order_details[test].uuid}>
                          {i}
                        </Option>
                      ))}
                    </Select>
                    {/* {console.log('doctall',)} */}
                  </div>
                </div>
                :
                <div className="read-form-input flex-1 mr--10 mt--15">
                  <div className="read-form-label fs--14 font-semibold">
                    Doctall Action
                  </div>
                  <div className="read-form-value">
                    {user &&
                      user.full_order_details &&
                      user.full_order_details[test].doctall_action}
                  </div>
                </div>
              }

            </>
          ) : (
            <>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  Test Name
                </div>
                <div className="read-form-value">
                  {user &&
                    user.full_order_details &&
                    user.full_order_details[0].test_info &&
                    user.full_order_details[0].test_info.standardisedtestname}
                </div>
              </div>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  Product Code
                </div>
                <div className="read-form-value">
                  {user &&
                    user.full_order_details &&
                    user.full_order_details[0].test_info &&
                    user.full_order_details[0].test_info.productcode}
                </div>
              </div>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  Clinical Advice
                </div>
                <div className="read-form-value">
                  {user &&
                    user.full_order_details &&
                    user.full_order_details[0].clinical_advice}
                </div>
              </div>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  No Of Days
                </div>
                <div className="read-form-value">
                  {user &&
                    user.full_order_details &&
                    user.full_order_details[0].test_info &&
                    user.full_order_details[0].test_info.partnerlabtat}
                </div>
              </div>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  MAX TAT
                </div>
                <div className="read-form-value">
                  {user &&
                    user.full_order_details &&
                    user.full_order_details[0].test_info &&
                    user.full_order_details[0].test_info.partnerlabtatindays}
                </div>
              </div>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  Acceptance Status
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <div className="read-form-value">
                    {user &&
                      user.full_order_details &&
                      user.full_order_details[0].test_info &&
                      user.full_order_details[0].acceptance_status &&
                      user.full_order_details[0].acceptance_status.reason}
                  </div>
                  <div className="read-form-value">
                    {user &&
                      user.full_order_details &&
                      user.full_order_details[0].test_info &&
                      user.full_order_details[0].acceptance_status &&
                      user.full_order_details[0].acceptance_status.status}
                  </div>
                </div>
              </div>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  Interim Progress{" "}
                </div>
                <div className="read-form-value">
                  {user &&
                    user.full_order_details &&
                    user.full_order_details[0].interim_progress &&
                    user.full_order_details[0].interim_progress.status}
                </div>
              </div>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  Sample Collection{" "}
                </div>
                <div className="read-form-value">
                  {user &&
                    user.full_order_details &&
                    user.full_order_details[0].sample_collection_progress}
                </div>
              </div>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  Final Action status
                </div>
                <div className="read-form-value">
                  {user &&
                    user.full_order_details &&
                    user.full_order_details[0].final_action &&
                    user.full_order_details[0].final_action.status}
                </div>
              </div>
              <div className="read-form-input flex-1 mr--10 mt--15">
                <div className="read-form-label fs--14 font-semibold">
                  Final Action Results
                </div>
                <div>
                  {user &&
                    user.full_order_details &&
                    user.full_order_details[0].final_action &&
                    user.full_order_details[0].final_action.result.map((i) => (
                      <div
                        className="read-form-value"
                        style={{ cursor: "pointer" }}
                      >
                        <a href={i.url} target="_blank">
                          {i.filename}
                        </a>
                      </div>
                    ))}
                </div>
              </div>
              {user &&
                user.full_order_details &&
                user.full_order_details[0].doctall_action === '' ?
                <div className="read-form-input flex-1 mr--10 mt--15">
                  <div className="read-form-label fs--14 font-semibold">
                    Doctall Action
                  </div>
                  <div>
                    <Select
                      disabled={disable}
                      style={{ width: 300 }}
                      showSearch
                      autoClearSearchValue
                      allowClear
                      optionFilterProp="children"
                      onChange={handleChanger}
                      labelInValue
                      defaultValue={{ value: "Select" }}
                    >
                      {doctallAction.map((i, ind) => (
                        <Option key={ind} value={i} dataid={user &&
                          user.full_order_details &&
                          user.full_order_details[0].uuid}>
                          {i}
                        </Option>
                      ))}
                    </Select>

                  </div>
                </div>
                :
                <div className="read-form-input flex-1 mr--10 mt--15">
                  <div className="read-form-label fs--14 font-semibold">
                    Doctall Action
                  </div>
                  <div className="read-form-value">
                    {user &&
                      user.full_order_details &&
                      user.full_order_details[0].doctall_action}
                  </div>
                </div>

              }

            </>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default ViewOrder;
