import React, { useState, useEffect } from 'react';
// import { Table, Popover,Input } from 'antd';
import { view_log, triple_dots } from 'helper/constant';
import EditePathalogy from './EditePathalogy';
import ViewReports from './ViewReports';
import { green_dot, red_dot } from 'helper/constant';
import actions from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { EditTwoTone } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import { Table, Form, Modal, Input, Row, Col, Button, Select } from 'antd';



const { master } = actions;

const { Option } = Select;

const Pathalogy = ({newcity, onGetarea, newareas, city}) => {
  const dispatch = useDispatch();
  const [visible, setvisible] = useState(false);
  const [reportsvisible, setreportsvisible] = useState(false);
  const [editvisible, setEditvisible] = useState('');
  const [user, setuser] = useState(null);
  const [data, setData] = useState("")
  const [search,setSearch]=useState(" ")
  const [partnersList, setPartnerList] = useState([]);
  const [partnerData, setPartnerData] = useState('');
  const [areaList, setAreaList] = useState([])  ;
  const [areaData, setAreaData] = useState('');
  const [pathalogyList, setPathalogyList] = useState([])  ;
  const [pathalogyData, setPathalogyData] = useState('');
  
  const [newcityuuid, setnewcityuuid] = useState('');

  console.log(newcity,"newcity")
  const Pharma = useSelector((state) => state.pharmacies);
  console.log(Pharma && Pharma.pharmacies && Pharma.pharmacies.data, "phar")
  const PharmaData = Pharma && Pharma.pharmacies && Pharma.pharmacies.data;
  const Auth = useSelector((state) => state.auth);
  const masterPartner = useSelector((state) => state.master);

  console.log(masterPartner.MasterPathalogy,"551")
  // MasterPathalogy
  // const Api = (row) => {
  //   axios.get(`${process.env.REACT_APP_WEB_APP}/user/pharmacyCommission/pharmacy/metrics/data?pharmacy_uuid=${row && row.uuid}`,{
  //       headers:{
  //           "x-auth-token" : Auth.accessToken
  //       }
  //   })
  //   .then((res) => {
  //       console.log(res, "res")
  //       // data.push(res)
  //       setData(res.data)
  //   })
  // }
  // useEffect(() => {
  //   dispatch( MasterAction && MasterAction.getPartnersDta());
  // }, []);

  useEffect(() => {
    dispatch(master.getMasterPathalogy(0,'','',partnerData && partnerData.uuid,pathalogyData && pathalogyData.uuid, areaData && areaData.uuid));
  }, [partnerData,pathalogyData, areaData]);
  
  const columns = [
   
    {
      title: 'productcode',
      dataIndex: 'productcode',
      key: 'productcode'
    },
    {
      title: 'Partners name',
      dataIndex: 'diagnosticspartner',
      key: 'diagnosticspartner'
    },
    
    {
      title: 'standardised test name',
      dataIndex: 'standardisedtestname',
      key: 'standardisedtestname'
    },
    
    {
      title: 'Partner test name',
      dataIndex: 'partnerstestname',
      key: 'partnerstestname'
    },
    
    {
      title: 'city ',
      dataIndex: 'city',
      key: 'city'
    },
    {
      title: 'area',
      dataIndex: 'area',
      key: 'area'
    },   
    {
      title: 'Price',
      dataIndex: 'doctallsexclusivediscountedprice',
      key: 'doctallsexclusivediscountedprice'
    },   
      
    // {
    //   title: 'test discipline',
    //   dataIndex: 'testdiscipline',
    //   key: 'testdiscipline:',
    // },


    // {
    //   title: 'View',
    //   dataIndex: 'eye',
    //   key: 'eye',
    //   render: (text, row) => (
    //     <img
    //       className='ml--10 cursor-pointer'
    //       src={view_log}
    //       alt='view'
    //       onClick={() => {
    //         console.log('view');
    //         setuser(row);
    //         setvisible(true);
    //         setEditvisible('view')
    //       }}
    //     />
    //   )
    // },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions', 
      render: (text, row) => (
        <div className='ml--20'>
          <EditTwoTone
          onClick={() => {
            setuser(row);
            setvisible(true);
            setEditvisible('edit')
          }}
          />
        </div>
      )
    }
  ];
  let pageNumber = ''
console.log(masterPartner.NationalPathalogy, "hgjkl")

const onChangesearch = (searchData) => {
  setSearch(searchData)
  dispatch(master.getMasterPathalogy(0,'', searchData, partnerData && partnerData.uuid,pathalogyData && pathalogyData.uuid, areaData && areaData.uuid))
}
// useEffect(()=>{
//   dispatch(master.getMasterPathalogy(0," ",partnerData))
// },[])

const partnerList = () => {
  axios.get(`${process.env.REACT_APP_WEB_APP}/metadata/partner/getpartner`)
    .then((res) => {
      console.log(res.data, "areaList")
      setPartnerList(res.data)
    })
    .catch((err) => {
      console.log(err, "err")
    })
}





const partnerChange = (uuid) => {
    
  {
    partnersList && partnersList.map((item) => {
      if (uuid === item.uuid) {
        setPartnerData(item)
      }
    })
  }
}


// const arealist = () => {
//   axios.get(`${process.env.REACT_APP_WEB_APP}/upload/newarea?limit=10&pagenum=0&search=`)
//     .then((res) => {
//       console.log(res, "asdasdasdasd")
//       setAreaList(res.data.data)
//     })
//     .catch((err) => {
//       console.log(err, "err")
//     })
// }

const CityChange = (uuid) => {

  {
    city && city.cityName && city.cityName.data && city.cityName.data.map((item) => {
      if (uuid === item.uuid) {
        setnewcityuuid(item)
      }
    })
  }
  // console.log(item, "item")
  arealist(uuid)
}




const arealist = (id) => {
  axios.get(`${process.env.REACT_APP_WEB_APP}/uploadDoc/getArea/${id}`)
    .then((res) => {
      console.log(res, "areaList")
      setAreaList(res.data)
    })
    .catch((err) => {
      console.log(err, "err")
    })
}

const ArearChange = (uuid) => {

  {
    areaList && areaList.map((item) => {
      if (uuid === item.uuid) {
        setAreaData(item)
      }
    })
  }
}






const nationalPathalogyList = () => {
  axios.get(`${process.env.REACT_APP_WEB_APP}/upload/nationalPathalogy?limit=100&page=0&search=`)
    .then((res) => {
      console.log(res, "asdasdaadfsdfdsfsdasd")
      setPathalogyList(res.data.data)
    })
    .catch((err) => {
      console.log(err, "err")
    })
}
const pathalogyChange = (uuid) => {

  {
    pathalogyList && pathalogyList.map((item) => {
      if (uuid === item.uuid) {
        setPathalogyData(item)
      }
    })
  }
}



// /upload/nationalPathalogy?limit=10&page=0&search=
useEffect(() => {
  partnerList();
  // arealist();
  nationalPathalogyList();
  // nationalPathologyList(pageNumberData, search);
}, [])
  return (
    <div>
    {/* <Form  className='gx-form-row0' layout='vertical'> */}
    <div layout='vertical' className='gx-form-row0'>
<Row className="space-between">

<Col span={4} xs={4} md={4}>
          <Input 
    onChange={ e => onChangesearch(e.target.value)}
    type="text"
    placeholder='Search'  
/>
          </Col>
<Col span={4} xs={4} md={4}>
            {/* <Form.Item
              label="Diagnostics Partner"
              name="diagnosticspartner"
              // initialValue={user && user.diagnosticspartner}
              rules={[{ required: true, message: "Please enter Partner Name !" }]}
            > */}
              <Select
                label='Diagnostics Partner'
                // showSearch
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                placeholder="Select Partner"
                onChange={partnerChange}
              >
                {partnersList.map((item) => {
                  return <Option value={item.uuid}>{item.name}</Option>
                })}
              </Select>
            {/* </Form.Item> */}
          </Col>

          
<Col span={4} xs={4} md={4}>
            {/* <Form.Item
              label="national Pathalogy "
              name="diagnosticspartner"
              // initialValue={user && user.diagnosticspartner}
              rules={[{ required: true, message: "Please enter Partner Name !" }]}
            > */}
              <Select
                label='national Pathalogy '
                // showSearch
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                placeholder="Select Test Name"
                onChange={pathalogyChange}
              >
                {pathalogyList && pathalogyList.map((item) => {
                  return <Option value={item.uuid}>{item.standardisedtestname}</Option>
                })}
              </Select>
            {/* </Form.Item> */}
          </Col>
          <Col span={4} xs={4} md={4}>
       
            {/* <Form.Item
              label='City Name'
              // name='area_name'
              rules={[{ required: true, message: 'Please enter City!' }]}
            > */}
              <Select
                label='City Name'
                // showSearch
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                placeholder="Select City"
                onChange={(item) => CityChange(item)}
              >
                {city && city.cityName && city.cityName.data && city.cityName.data.map((item) => {
                  return <Option value={item.uuid} >{item.name}</Option>
                })}
              </Select>
            {/* </Form.Item> */}

          </Col>

                 

          
          <Col span={4} xs={4} md={4}>
            {/* <Form.Item
              label="Area Name"
              name="area"
              // initialValue={user && user.area}
              rules={[{ required: true, message: "Please enter Area name!" }]}
            > */}
              <Select
                label='Area Name'
                // showSearch
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                placeholder="Select Area"
                onChange={ArearChange}
              >
                {areaList && areaList.map((item) => {
                  return <Option value={item.uuid}>{item.name}</Option>
                })}
              </Select>
            {/* </Form.Item> */}
          </Col>


         
          </Row>
          </div>
          {/* </Form> */}
   

<br/><br/>
      <Table
        columns={columns}
        dataSource={masterPartner.MasterPathalogy.data}
        size='middle'
        pagination={{
                    total: masterPartner.MasterPathalogy.count,
                    showSizeChanger: true,
                    
                }}

                onChange={(pagination) => {
                    console.log(pagination.pageSize,"pagination")
                    if (pagination.current === 1) {
                        pageNumber = 0
                    } else {
                        pageNumber = pagination.current - 1
                    }
                    dispatch(master.getMasterPathalogy(pageNumber,pagination.pageSize,'',partnerData && partnerData.uuid,pathalogyData && pathalogyData.uuid, areaData && areaData.uuid))
                }}
      />
      <EditePathalogy
        visible={visible}
        diff={editvisible}
        newcity={newcity}
        city={city}
        onGetarea={onGetarea}
        newareas={newareas}
        loader={false}
        handleOk={() => setvisible(false)}
        handleCancel={() => {setvisible(false); window.location.reload(false)}}
        user={user}
      />
      {/* <ViewReports
      visible={reportsvisible}
      loader={false}
      handleOk={() => setreportsvisible(false)}
      handleCancel={() => {setreportsvisible(false); window.location.reload(false)}}
      data={data}
      >
      </ViewReports> */}
    </div>
  );
};

export default Pathalogy;