import { Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  Tabs,
  notification,
} from 'antd';
import { useDispatch } from 'react-redux';
import actions from 'actions';
import "react-phone-input-2/lib/style.css";

const { Option } = Select;

const { master } = actions;
const EditeCity = ({ user, visible, handleOk, handleCancel, diff, city }) => {
  console.log('user420',user);
  const dispatch = useDispatch()
  const [newcityuuid, setnewcityuuid] = useState('');
console.log(newcityuuid,"newcity")

 

const [active, setActive] = useState();
const [popular , setPopular] = useState();
console.log(active,"Active")

const handleChange = (event) => {
  console.log(event,"adasd")
  setActive(event);
};

const handleChange1 = (event) =>{
  console.log(event,"tttt")
  setPopular(event)
}

  const { TabPane } = Tabs;


 
  const handleSubmit1 = values => {
    console.log(values)

    var payLoad = {
      
      "uuid" : user.uuid,
      "active" : active == null ? user && user.active : active,
      "city" : values.city,
      "area" : values.area,
      "diagnosticspartner" : values.diagnosticspartner,
      "productcode" : values.productcode,
      "testdiscipline" : values.testdiscipline,
      "standardisedtestname" : values.standardisedtestname,
      "populartest_y_n" : popular,

  
     
  }
   console.log(payLoad,"payLoad")
    dispatch(master.editRadiology(payLoad));
  }

  


  return <Modal
    title={`Radiology`}
    visible={visible}
    onOk={handleOk}
    onCancel={handleCancel}
    width={850}
    footer={[
    
      ]}
    
  >
   
    <Tabs defaultActiveKey="1" >
      <TabPane tab="General" key="1">
        <Form onFinish={handleSubmit1} className='gx-form-row0' layout='vertical'>
          <Row className="space-between">
          
        
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Id"
                name="uuid"
                initialValue={user && user.uuid}
                rules={[{ required: true, message: "Please enter Area name!" }]}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>

            {/* <span style={{ color: "red", fontSize: "20px", marginTop: "10px" }}>*</span><span style={{ marginBottom: "20px" }}> City Name</span> */}
            <Form.Item
              label='Active'
              // name='area_name'
              rules={[{ required: true, message: 'Please input pharmacy name!' }]}
            >
              <Select
                label='Area Name'
                
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                placeholder="Select City"
                onChange={handleChange}
                defaultValue={user && user.active}
                disabled={diff == 'view'}
                // initialValue={user && user.city_uuid}
                
              >
           
          <Option value={true}>True</Option>
          <Option value={false}>False</Option>



              </Select>
            </Form.Item>

          </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="City Name"
                name="city"
                initialValue={user && user.city}
                rules={[{ required: true, message: "Please enter Area name!" }]}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Area Name"
                name="area"
                initialValue={user && user.area}
                rules={[{ required: true, message: "Please enter Area name!" }]}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Diagnostics Partner"
                name="diagnosticspartner"
                initialValue={user && user.diagnosticspartner}
                rules={[{ required: true, message: "Please enter Area name!" }]}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Product Code "
                name="productcode"
                initialValue={user && user.productcode}
                rules={[{ required: true, message: "Please enter Area name!" }]}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Test Discipline "
                name="testdiscipline"
                initialValue={user && user.testdiscipline}
                rules={[{ required: true, message: "Please enter Area name!" }]}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Standardised Test Name"
                name="standardisedtestname"
                initialValue={user && user.standardisedtestname}
                rules={[{ required: true, message: "Please enter Area name!" }]}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
            <Form.Item
                label="Popular Test_y_n"
                // name="populartest_y_n"
                // initialValue={user && user.populartest_y_n}
                rules={[{ required: true, message: "Please enter popular test!" }]}
              >
                 <Select
                label='Area Name'
                rules={[{ required: true, message: 'Please input popular test!' }]}
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                placeholder="Select popular test"
                onChange={handleChange1}
               defaultValue={user && user.populartest_y_n}
               disabled={diff == "view"}
              >
                <Option value={"YES"}>Yes</Option>
                <Option value={"NO"}>No</Option>
                </Select>
              </Form.Item>
            </Col>
        
          </Row>
          {diff === "edit" &&
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button ml--10'>
              Submit
            </Button>
          }
        </Form>
      </TabPane>

    

    </Tabs>
  </Modal>
  // );
};

export default EditeCity;
