import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import actions from "actions";
import UsedCouponsTable from 'components/coupons/coupon-table/UsedCouponsTable'
import { Button, Input, Space } from "antd";
import { exportToExcel } from 'services/ExportServices';

const Search = Input.Search;

const { usedCouponsActions } = actions;

export default function UsedCouponsPage() {
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState('')
    const couponsData = useSelector((state) => state.usedCouponsReducer);
    const authToken = useSelector((state) => state.auth.accessToken);

    useEffect(() => {
        dispatch(usedCouponsActions.getUsedCoupons(0, searchQuery));
    }, [searchQuery])

    const handleOnSearch = (value) => {
        setSearchQuery(value)
    }


    const handleExport = async () => {
        await exportToExcel("/coupon/export/toexcel", "TeleMedicine_Report", authToken);
    }

    return (
        <>
            <div className="pictogram-container">
                <div className="pictogram-header flex-x align-center ptb--15">
                    <Space direction="vertical">
                        <Search
                            size="large"
                            enterButton="Search"
                            placeholder="Search Record"
                            onSearch={handleOnSearch}
                        />
                    </Space>
                    <div className="fs--16 font-semibold flex-1">{/* Users */}</div>

                    <Button type="primary" onClick={handleExport}>Export</Button>

                </div>
                <div>
                    <UsedCouponsTable couponsData={couponsData && couponsData} />
                </div>

            </div>
        </>
    )
}
