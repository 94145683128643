import React,{useState,useEffect} from "react";
import { Tabs } from "antd";
import HomeLayout from "components/dashboard/homeLayout";
import UserLayout from "components/dashboard/userLayout";
import DoctorLayout from "components/dashboard/doctorLayout";
import PartnerLayout from "components/dashboard/partnerLayout";
import PharmacyLayout from "components/dashboard/pharmacy";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { getToken, onMessageListener } from '../../firebase';
import SnackBarActions from '../../actions/snackBarAction';


function Dashboard() {

  const auth = useSelector((state) => state.auth.admin);
  // const api = process.env.REACT_APP_WEB_APP;
  // const [totalData,setTotalData] =useState([])
  // const apiFetch = async () => {
  //     await axios
  //       .get(api + "/user/admin/users", {
  //         headers: { "x-auth-token": auth },
  //       })
  //       .then((res) => {
  //         setTotalData(res.data)
  //       });
  //   };
  //   useEffect(()=>{
  //     apiFetch()
  //   },[])

  const { TabPane } = Tabs;
  return (
    <div>
  {auth && auth.role !== "dmp-admin" ?
      <Tabs defaultActiveKey="1">
        <TabPane tab={<h3>Home</h3>} key="1">
          <HomeLayout />
        </TabPane>
        <TabPane tab={<h3>Patient</h3>} key="2">
          <UserLayout />
        </TabPane>
        <TabPane tab={<h3>Doctors</h3>} key="3">
          <DoctorLayout/>
        </TabPane>
        <TabPane tab={<h3>Partner</h3>} key="4">
          <PartnerLayout/>
        </TabPane>
        <TabPane tab={<h3>Treatment Plans</h3>} key="5">
          <PharmacyLayout/>
        </TabPane>
      </Tabs>
      :
      <Tabs defaultActiveKey="1">
        <TabPane key="1">
        <PartnerLayout/>
        </TabPane>
      </Tabs>
  }
    </div>
  );
}

export default Dashboard;
