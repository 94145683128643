import { all, takeEvery, put, fork, select } from "redux-saga/effects";
import actions from "actions";
import { addAppointment,statusChange } from "services/bookAppointment";
import { notification } from "antd";
const { POST_APPOINTMENT,appointmentActions, authActions } = actions;

export function* postAppointmentSaga() {

  yield takeEvery(POST_APPOINTMENT.POST_APPOINTMENT_ACTION, function* ({data}) {
    try {
      const token = yield select(state => state.auth.accessToken);
      console.log("sagdatasuccess oppitment",data);
      const response = yield addAppointment({token},data);
      const result = response.data.uuid;

      const resp = yield statusChange({token},result);

      
      if (resp.status === 201) {

// console.log("resss",result)
// console.log("resss",result.uuid)




  yield notification.open({
    message: 'Success!',
    description: 'booking added Successfully!'
  });


        
      
      } 
      // else {
      //   throw response;
      // }
    }catch(error){
      // yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      yield notification.open({ message: 'Failed!', description: error.response.data })

      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}


// if(error.response.status === 400){
//   yield notification.open({
//     message: 'Failed!',
//     description: "This slot is not valid now"
//   });

// }
// else{
//   yield notification.open({
//     message: 'Failed!',
//     description: error.message
//   });
// }

     
//       yield put({ type: POST_APPOINTMENT.ON_ERROR });
//       if (error.response.status === 401) {
//         yield put({ type: authActions.AUTH_ERROR });
//       }
//     }
//   });
// }




export default function* rootSaga() {
  yield all([fork(postAppointmentSaga)]);
}





