import React, { useState, useEffect } from 'react';
import { Form, Modal, Input, Row, Col, Button, Select,notification } from 'antd';
import PhoneInput from "react-phone-input-2";
const { Option } = Select;

const AddUser = ({ visible, handleOk, onSubmit, handleCancel }) => {
  const [group, setgroup] = useState(null);
  const [form] = Form.useForm();
  console.log(form,"das")

  const [countryDialCode, setCountryDialCode] = useState();
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState()



  const _onPhoneChange = (value, country, e, formattedValue) => {
    setPhone(value)
    setCountryDialCode(country.dialCode)
    console.log("change")
  }

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(res => res.json())
      .then(res => {
        setCountry(res.country_code.toLowerCase());
      })
      .catch((data, status) => {
        console.log('Request failed:', data);
      });
  }, [])

  const handleSubmit = values => {

    if (countryDialCode === "234" && phone.length < 13) {
      notification.open({ message: 'Error', description: 'please enter valid phone number' })
      return false;
    } else if (countryDialCode === "91" && phone.length < 12) {
      notification.open({ message: 'Error', description: 'please enter valid phone number' })
      return false;
    }
    else if (phone.length < 10) {
      notification.open({ message: 'Error', description: 'please enter valid phone number' })
      return false;
    }else {
    onSubmit({ ...values, group });
    reset();
    handleOk();
          }
  };

  const reset = () => {
    setgroup(null);
    form.resetFields();
  };

  const onCancel = () => {
    reset();
    handleCancel();
  };

  const onOk = () => {
    reset();
    handleOk();
  };

  return (
    <Modal
      className='no-modal-footer'
      title='Add User'
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText='Add'
    >

      <Form onFinish={handleSubmit} form={form} className='gx-form-row0' layout='vertical'>
        <Row>
          <Col span={24} xs={24} md={24}>
            <Form.Item
              label='First Name'
              name='firstName'
              rules={[{ required: true, message: 'Please enter first name!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col span={24} xs={24} md={24}>
            <Form.Item
              label='Middle Name'
              name='middleName'
              rules={[{ required: false, message: 'Please enter Middle name!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col span={24} xs={24} md={24}>
            <Form.Item
              label='Last Name'
              name='lastName'
              rules={[{ required: true, message: 'Please enter Last name!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          {/* <Col span={24} xs={24} md={24}>
            <Form.Item
              label='Middle Name'
              name='middleName'
              rules={[{ required: true, message: 'Please enter Middle name!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col> */}
          {/* <Col span={24} xs={24} md={24}>
            <Form.Item
              label='Last Name'
              name='lastName'
              rules={[{ required: true, message: 'Please enter Last name!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col> */}
          <Col span={24} xs={24} md={24}>
            <Form.Item
              label='Email'
              name='email'
              rules={[{ required: true, message: 'Please enter email!' }, { type: 'email', message: 'Invalid email' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col span={24} xs={24} md={24}>
            <Form.Item
              label='Mobile No.'
              name='mobile'
              rules={[{ required: true, message: 'Please enter Mobile No.!' }]}
            >
              {/* <Input size='large' /> */}
              <PhoneInput
                inputStyle={{
                  width: "470px",
                  height: "42px",
                  fontSize: "13px",
                  paddingLeft: "48px",
                  borderRadius: "5px"
                }}
                countryCodeEditable={true}
                country={country}
                value={phone}
                onChange={(value, country, e, formattedValue) => { _onPhoneChange(value, country, e, formattedValue) }}
              />
            </Form.Item>
          </Col>
          {/* <Col span={24} xs={24} md={24}>
            <Form.Item
              label='Password'
              name='password'
              rules={[{ required: true, max: 4, message: 'Please enter password with maximum length 4!' }]}
            >
              <Input size='large'
              />
            </Form.Item>
          </Col> */}

          <Col span={24} xs={24} md={24}>
            <Form.Item label='User Type'>
              <Select
                onChange={value => {
                  setgroup(value);
                }}
                value={group}
              >
                {/* <Option value='doctor'>Doctor</Option> */}
                <Option value='consumer'>Consumer</Option>
                {/* <Option value='partner'>Partner</Option> */}
              </Select>
            </Form.Item>
          </Col>
          <Col className="ml-auto">
            <Button className='login-form-button' onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button ml--10'
            >
              Add
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddUser;
