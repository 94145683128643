import React, { useState, useEffect } from 'react' 
import { useDispatch, useSelector } from "react-redux";
import InfluencerTable from 'components/Influencer/InfluencerTable'
import EditInfluencers from "components/Influencer/editInfluencer"
import actions from "actions";

const { userActions, commonActions, influencerActions } = actions;

export default function InfluencerPage() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(commonActions.getMetadata());
        dispatch(commonActions.getallnewcities());
        //dispatch(influencerActions.getInfluencers());
    }, [dispatch]);

    return (
        <div className="pictogram-container">

            <InfluencerTable />

        </div>

    )
}
