import axios from 'axios';
const API_BASE = process.env.REACT_APP_METADATA;
const WEB_API_BASE = process.env.REACT_APP_WEB_APP;

export const getStates = () => {
  return axios.get(`${API_BASE}/states`).then(e => e);
};

export const getCities = () => {
  return axios.get(`${API_BASE}/cities`).then(e => e);
};


export const getCitiesnew = () => {
  return axios.get(`${WEB_API_BASE}/uploadDoc/region/list`).then(e => e);
};



export const getAreasnew = (cityuuid) => {
  return axios.get(`${WEB_API_BASE}/uploadDoc/region/city/list/${cityuuid}`).then(e => e);
};





export const getCitiesnewForPartners = () => {
  // return axios.get(`${WEB_API_BASE}/uploadDoc/region/list`).then(e => e);
  return axios.get(`${WEB_API_BASE}/uploadDoc/allcities`).then(e => e);
};



export const getAreasnewForPartners = (cityuuid) => {
  // return axios.get(`${WEB_API_BASE}/uploadDoc/region/city/list/${cityuuid}`).then(e => e);
  return axios.get(`${WEB_API_BASE}/uploadDoc/getArea/${cityuuid}`).then(e => e);
};
