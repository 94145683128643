import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from 'actions';
import { getDoctors,editDoctors,getDoctorsclone } from 'services/doctor-services';
import { notification } from 'antd'
const { doctorActions,authActions } = actions;

export function* getDoctorscloneSaga() {
  yield takeEvery(doctorActions.GET_CLONE_DOCTORS, function*(pagenum, params) {
    console.log(pagenum, "asdgdf")
    // 

    const searchData = pagenum.searchQuery !== undefined ? pagenum.searchQuery : ''

    try{
      const token = yield select(state => state.auth.accessToken);
      const response = yield getDoctorsclone(pagenum.pagenum, searchData, {token},pagenum.params);
        const { data } = response;
        yield put({
          type: doctorActions.GET_CLONE_DOCTORS_SUCCESS,
          data
        });
    }catch(error){
      console.log(error,"error")
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}


export function* getDoctorsSaga() {
  yield takeEvery(doctorActions.GET_DOCTORS, function*(pagenum,) {
    console.log(pagenum, "GET_doc1")

    try{
      const token = yield select(state => state.auth.accessToken);
      const response = yield getDoctors(pagenum.pagenum, pagenum.search,  {token});
        const { data } = response;
        yield put({
          type: doctorActions.GET_DOCTORS_SUCCESS,
          data
        });
    }catch(error){
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}


export function* editDoctorSaga() {
  yield takeEvery(doctorActions.EDIT_DOCTORS, function*({uuid,doctor}) {
    try{
      const token = yield select(state => state.auth.accessToken);
      const response = yield editDoctors({token},uuid,doctor);
        const { data } = response;
        // console.log("object",response.status)
        // console.log("object",response.statusText)

//         status: 200
// statusText: "OK
if(response.status == 200 && response.statusText =="OK"){
  yield notification.open({ message: 'Success!', description: 'Doctor Profile Edited successfully' })

}

        yield put({
          type: doctorActions.GET_DOCTORS,
        });
        yield put({
          type: doctorActions.GET_CLONE_DOCTORS,
        });
    }catch(error){
      yield notification.open({ message: 'Failed!', description: error.response.data })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(editDoctorSaga),fork(getDoctorsSaga),fork(getDoctorscloneSaga)]);
}
