import React from 'react';
import BookAppointment from 'components/bookAppointment/bookAppointment';

const BookAppointmentsView = () => {
    return (
        <div className="pictogram-container">
            <div className="pictogram-header flex-x align-center ptb--15">
                <div className="fs--16 font-semibold flex-1">
                   {/* Book Appointments */}
                </div>
                {/* < style={{ height: 42 }} type="primary" onClick={() => setvisible(true)}>Add</Button> */}
            </div>
            <div>
                <BookAppointment />
            </div>
        </div>
    );
};

export default BookAppointmentsView;