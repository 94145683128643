import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import actions from "actions";

export default function UsedCouponsTable(props) {
    const dispatch = useDispatch();
    const { couponsStatsData } = props;

    return (
        <div>
            <table style={{ width: "100%" }}>
                <tr style={{ background: "#F0F5F5", height: "38px" }}>
                    <th>&nbsp;&nbsp;&nbsp;&nbsp;Name</th>
                    <th>Total Used</th>
                    <th>Most Used</th>
                </tr>
                <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;Dmp</td>
                    <td>{couponsStatsData && couponsStatsData.total_dmp_used_coupon}</td>
                    {couponsStatsData && couponsStatsData.total_dmp_used_coupon !== 0 ?
                    <td>{couponsStatsData && couponsStatsData.most_used_dmp_coupon && couponsStatsData.most_used_dmp_coupon[0] && couponsStatsData.most_used_dmp_coupon[0]._id}({couponsStatsData && couponsStatsData.most_used_dmp_coupon && couponsStatsData.most_used_dmp_coupon[0] && couponsStatsData.most_used_dmp_coupon[0].count}) </td>
               :
               <td></td>
            }
                    </tr>
                <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;SADN</td>
                    <td>{couponsStatsData && couponsStatsData.total_sadn_used_coupon}</td>
                    {couponsStatsData && couponsStatsData.total_sadn_used_coupon !== 0 ?
                    <td>{couponsStatsData && couponsStatsData.most_used_sadn_coupon &&
                        couponsStatsData.most_used_sadn_coupon[0] &&
                        couponsStatsData.most_used_sadn_coupon[0]._id}({couponsStatsData &&
                            couponsStatsData.most_used_sadn_coupon &&
                            couponsStatsData.most_used_sadn_coupon[0] &&
                            couponsStatsData.most_used_sadn_coupon[0].count})
                    </td>
                    :
                    <td></td>
                }
                </tr>
                <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;FADN</td>
                    <td>{couponsStatsData && couponsStatsData.total_fadn_used_coupon}</td>
                    {couponsStatsData && couponsStatsData.total_fadn_used_coupon == 0 ? <td></td> :
                    <td>{couponsStatsData && couponsStatsData.most_used_fadn_coupon &&
                        couponsStatsData.most_used_fadn_coupon[0] &&
                        couponsStatsData.most_used_fadn_coupon[0]._id}({couponsStatsData &&
                            couponsStatsData.most_used_fadn_coupon &&
                            couponsStatsData.most_used_fadn_coupon[0] &&
                            couponsStatsData.most_used_fadn_coupon[0].count})
                    </td>
}
                </tr>
                <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;Specaility</td>
                    <td>{couponsStatsData && couponsStatsData.total_speciality_used_coupon}</td>
                    {couponsStatsData && couponsStatsData.total_speciality_used_coupon !== 0 ?
                    <td>{couponsStatsData && couponsStatsData.most_used_speciality_coupon &&
                        couponsStatsData.most_used_speciality_coupon[0] &&
                        couponsStatsData.most_used_speciality_coupon[0]._id}({couponsStatsData &&
                            couponsStatsData.most_used_speciality_coupon &&
                            couponsStatsData.most_used_speciality_coupon[0] &&
                            couponsStatsData.most_used_speciality_coupon[0].count})
                    </td>
                    :
                    <td> </td>
                        }
                </tr>
                <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;TREATMENT PLAN</td>
                    <td>{couponsStatsData && couponsStatsData.total_treatment_plan_used_coupon}</td>
                    {couponsStatsData && couponsStatsData.total_treatment_plan_used_coupon !== 0 ? 
                    <td>{
                    couponsStatsData && couponsStatsData.most_used_treatment_plan_coupon &&
                        couponsStatsData.most_used_treatment_plan_coupon[0] &&
                        couponsStatsData.most_used_treatment_plan_coupon[0]._id}({couponsStatsData &&
                            couponsStatsData.most_used_treatment_plan_coupon &&
                            couponsStatsData.most_used_treatment_plan_coupon[0] &&
                            couponsStatsData.most_used_treatment_plan_coupon[0].count})
                        
                    </td>
                    :
                    <td></td>
                }
                </tr>
                <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;LAB PACKAGE</td>
                    <td>{couponsStatsData && couponsStatsData.total_lab_package_used_coupon}</td>
                    {couponsStatsData && couponsStatsData.total_lab_package_used_coupon !==0 ? 
                    <td>{couponsStatsData && couponsStatsData.most_used_lab_package_coupon &&
                        couponsStatsData.most_used_lab_package_coupon[0] &&
                        couponsStatsData.most_used_lab_package_coupon[0]._id}({couponsStatsData &&
                            couponsStatsData.most_used_lab_package_coupon &&
                            couponsStatsData.most_used_lab_package_coupon[0] &&
                            couponsStatsData.most_used_lab_package_coupon[0].count})
                    </td>
                    :
                    <td></td>
                }
                </tr>
                <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;Total</td>
                    <td>{couponsStatsData && couponsStatsData.total_no_of_used_coupon}</td>
                    {/* <td>{couponsStatsData && couponsStatsData.most_used_sadn_coupon &&
                        couponsStatsData.most_used_sadn_coupon[0] &&
                        couponsStatsData.most_used_sadn_coupon[0]._id}({couponsStatsData &&
                            couponsStatsData.most_used_sadn_coupon &&
                            couponsStatsData.most_used_sadn_coupon[0] &&
                            couponsStatsData.most_used_sadn_coupon[0].count})
                    </td> */}
                </tr>
            </table>
        </div>
    )
}
