import axios from 'axios';
import { setHeadersWithAccessToken } from './index';
const API_BASE = process.env.REACT_APP_APIBASE;
const WEB_API_BASE = process.env.REACT_APP_WEB_APP;

export const getNotifications = (auth, pageNumber) => {
    setHeadersWithAccessToken(auth);
    let page;
    if (pageNumber === undefined) {
        page = 0
    } else {
        page = pageNumber
    }
    return axios
        .get(`${WEB_API_BASE}/bulknotification/list/${page}`)
        .then(e => e);
};

export const getConsumer = async (searchQuery) => {
    let response = await axios.get(`${WEB_API_BASE}/user/all/consumer/getconsumers?search=${searchQuery}`)
    if (response) {
        return response;
    } else {
        throw new Error("Consumer Not Found");
    }
}


export const sendNotifications = (auth, data, consumers, selectOption, key) => {
    setHeadersWithAccessToken(auth);

    let selectedUserPayLoad = {
        "type": "information",
        user: consumers,
        "title": data.title,
        "message": data.description
    }

    let sendAllPayLoad = {
        "type": "information",
        "title": data.title,
        "user": ["ALL"],
        "message": data.description
    }

    if (key === "SEND") {
        if (selectOption === 1) {
            return axios.post(`${WEB_API_BASE}/bulknotification/send/selected`, selectedUserPayLoad).then(e => e);
        } else {
            return axios.post(`${WEB_API_BASE}/bulknotification/send/all`, sendAllPayLoad).then(e => e);
        }
    } else {
        if (selectOption === 1) {
            return axios.post(`${WEB_API_BASE}/bulknotification/draft/save`, selectedUserPayLoad).then(e => e);
        } else {
            return axios.post(`${WEB_API_BASE}/bulknotification/draft/save`, sendAllPayLoad).then(e => e);
        }
    }
};


export const sendDrafts = (auth, data) => {
    setHeadersWithAccessToken(auth);

    let selectedUserPayLoad = {
        "type": "information",
        user: data.user,
        "title": data.title,
        "message": data.message
    }

    let sendAllPayLoad = {
        "type": "information",
        "title": data.title,
        "user": ["ALL"],
        "message": data.message
    }


    if (data.user.length > 1) {
        return axios.post(`${WEB_API_BASE}/bulknotification/send/selected`, selectedUserPayLoad).then(e => e);
    } else {
        return axios.post(`${WEB_API_BASE}/bulknotification/send/all`, sendAllPayLoad).then(e => e);
    }

};



