import actions from "actions";

const initState = {
  allSlctor: [],
  loader: false,
};

export default function rootReducer(state = initState, action) {

  switch (action.type) {
    case actions.POST_APPOINTMENT.POST_APPOINTMENT_ACTION:
        console.log("reducPostAppo",action)

      return {
        ...state,
        loader: true,
      };
   
    default:
      return state;
  }
}
