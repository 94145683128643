import actions from 'actions';

const { usedCouponsActions } = actions;

const initState = {
    loader: false,
    stats: [],
    data: [],
    limit: 10,
    skip: 0,
    count: 0,
};

export default function rootReducer(state = initState, action) {

    switch (action.type) {

        case usedCouponsActions.GET_USED_COUPONS:
            return {
                ...state,
                loader: true,
                data: action.data,
                count: action.count,

            };

        case usedCouponsActions.GET_USED_COUPONS_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.data,
                count: action.count,
            };
        case usedCouponsActions.GET_COUPONS_STATS:
            return {
                ...state,
                loader: true,
                stats: action.data,
            };
        case usedCouponsActions.GET_COUPONS_STATS_SUCCESS:
            console.log(action, "action")
            return {
                ...state,
                loader: false,
                stats: action.stats,
            };
        default:
            return state;
    }
}
