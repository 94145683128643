import React from 'react';
import Patients from 'components/patients/Patients';

const PatientView = () => {
    return (
        <div className="pictogram-container">
            <div className="pictogram-header flex-x align-center ptb--15">
                <div className="fs--16 font-semibold flex-1">
                    {/* Patients */}
                </div>
                
                {/* < style={{ height: 42 }} type="primary" onClick={() => setvisible(true)}>Add</Button> */}
            </div>
            <div>
                <Patients />
            </div>
        </div>
    );
};

export default PatientView;