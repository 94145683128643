import axios from 'axios';
import { setHeadersWithAccessToken } from './index';
const WEB_API_BASE = process.env.REACT_APP_WEB_APP;

export const getConsumers = (pageNumber,searchQuery,auth,params) => {
  console.log("service",params)

  setHeadersWithAccessToken(auth)
  // return axios.get(`${WEB_API_BASE}/user/consumers/data?search=${params.search}`).then(e => e);
  return axios.get(`${WEB_API_BASE}/user/admin/consumers/data?pagenum=${pageNumber}&limit=10&search=${searchQuery}`,{
    params
}).then(e => e);
};

