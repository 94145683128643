import actions from 'actions';

const initState = {
  states: [],
  cities: [],
  newcities:[],
  newareas:[],
  newcitiesForPartners:[],
  newareasForPartners:[]
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case actions.commonActions.GET_STATES_SUCCESS:
      return {
        ...state,
        states: action.data
      };
    case actions.commonActions.GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.data
      };

      case actions.commonActions.GET_CITIES_NEW_SUCCESS:
        return {
          ...state,
          newcities: action.data
        };
        case actions.commonActions.GET_AREAS_NEW_SUCCESS:
          return {
            ...state,
            newareas: action.data
          };


          case actions.commonActions.GET_CITIES_NEW_SUCCESS_FOR_PARTNER:
        return {
          ...state,
          newcitiesForPartners: action.data
        };
        case actions.commonActions.GET_AREAS_NEW_SUCCESS_FOR_PARTNER:
          return {
            ...state,
            newareasForPartners: action.data
          };
    default:
      return state;
  }
}
