const saasActions = {
    GET_SAAS_DOCTORS: "GET_SAAS_DOCTORS",
    GET_SAAS_DOCTORS_SUCCESS: "GET_SAAS_DOCTORS_SUCCESS",


    GET_SAAS_PATIENT: "GET_SAAS_PATIENT",
    GET_SAAS_PATIENT_SUCCESS: "GET_SAAS_PATIENT_SUCCESS",

    GET_SAAS_ALL_DOCTOR: "GET_SAAS_ALL_DOCTOR",
    GET_SAAS_ALL_DOCTOR_SUCCESS: "GET_SAAS_ALL_DOCTOR_SUCCESS",




    getSaasDoctors: (pageNumber, search) => {
        return {
            type: saasActions.GET_SAAS_DOCTORS,
            pageNumber,
            search
        };
    },

    getSaasPatients: (pageNumber, searchQuery, doctorId) => {
        return {
            type: saasActions.GET_SAAS_PATIENT,
            pageNumber,
            searchQuery,
            doctorId
        };
    },

    getSaasAllDoctor: (pageNumber) => {
        return {
            type: saasActions.GET_SAAS_ALL_DOCTOR,
            pageNumber
        };
    },
};

export default saasActions;