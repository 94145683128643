import authActions from "./auth";
import userActions from "./users";
import commonActions from "./common";
import feebackActions from "./feedback";
import doctorActions from "./doctors";
import pharmacyActions from "./pharmacy";
import appointmentActions from './appointment';
import pushNotificationAction from './pushNotificationAction'
import patientActions from './patients';
import transactionActions from './transactions';
import consumerActions from './consumer';
import BookAppointments from './bookingAppointment';
import GETPATIENTSBYCON from './getPatients';
import GETSLOTBYNAME from './getSlotByname';
import GETSLOTBYDOCTOR from './getSlotBydoctor';
import POST_APPOINTMENT from './postAppointment';
import ORDERACTION from "./order";
import TREATMENTORDERACTION from "./treatment_order";
import SnackBarActions from './snackBarAction';
import couponsActions from "./couponsActions";
import usedCouponsActions from './usedCouponsAction';
import partnersActions from "./partnersAction";
import saasActions from "./saasActions";
import master from "./masterData";
import influencerActions from "./influencerRegistration";


const actions = {
  authActions,
  userActions,
  saasActions,
  commonActions,
  feebackActions,
  doctorActions,
  pharmacyActions,
  appointmentActions,
  patientActions,
  transactionActions,
  consumerActions,
  BookAppointments,
  GETPATIENTSBYCON,
  GETSLOTBYNAME,
  GETSLOTBYDOCTOR,
  influencerActions,
  POST_APPOINTMENT,
  ORDERACTION,
  pushNotificationAction,
  couponsActions,
  SnackBarActions,
  usedCouponsActions,
  partnersActions,
  TREATMENTORDERACTION,
  master,

};

export default actions;
