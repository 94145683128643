import { Modal } from 'antd';
import React from 'react';
import { Button } from 'antd';
import { Spin } from 'antd';
import moment from 'moment';

const ViewPatients = ({ user, visible, loader, handleOk, handleCancel }) => {

  const getPercentage = (user)=>{
    let percentage = 15;
    if(!user) return 0;
    const { personalQuestions = [], medicalQuestions = [], lifestyleQuestions = [] } = user;
    if (personalQuestions && personalQuestions.length > 1) {
      percentage = 60;
    } if (medicalQuestions && medicalQuestions.length > 1) {
      percentage = 80;
    }
    if (lifestyleQuestions && lifestyleQuestions.length > 1) {
      percentage = 100;
    }
    return percentage;
  }
console.log(user)
 console.log(user && user.patients)
  return (
    <Modal
      title={`Patient Details`}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key='submit' type='primary' onClick={handleOk}>
          Ok
        </Button>
      ]}
    >
      <Spin spinning={loader}>
        <div className='flex-y align-left'>
        <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Patient Id
            </div>
            <div className='read-form-value'>{user && user.uuid}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Full Name
            </div>
            <div className='read-form-value'>{user && user.full_name}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Email
            </div>
            <div className='read-form-value'>{user && user.email}, {user && user.secondary_email}</div>
          </div>
          {/* <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>Email</div>
            <div className='read-form-value'>{user && user.email}</div>
          </div> */}
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Phone Number
            </div>
            <div className='read-form-value'>{user && user.mobile}, {user && user.other_mobile}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Date Of Birth
            </div>
            <div className='read-form-value'>{moment(user && user.dob).format('DD/MM/YYYY')}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Gender
            </div>
            <div className='read-form-value'>{user &&  user.patients && user.patients.gender}</div>
          </div>
          {/* <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Choice Doctor
            </div>
            <div className='read-form-value'>{user && user.choiceDoctor}</div> */}
          {/* </div> */}
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Blood Group
            </div>
            <div className='read-form-value'>{user &&  user.patients && user.patients.blood_group}</div>
          </div>
          {/* <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Languages
            </div>
            <div className='read-form-value'>{user && user.languagues.join(',')}</div>
          </div> */}
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Marital Status
            </div>
            <div className='read-form-value'>{user &&  user.patients && user.patients.marital_status}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Profile Percentage
            </div>
            <div className='read-form-value'>{getPercentage(user)} %</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Total Appointment
            </div>
            <div className='read-form-value'>{user && user.bookings && user.bookings.totalBookings}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Last Appointment
            </div>
            <div className='read-form-value'>{user && user.bookings && moment(user.bookings.lastAppointmentDate).format('DD/MM/YYYY hh:mm a')}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Amount Spent On Appointment
            </div>
            
            <div className='read-form-value'>{user && user.bookings_details && user.bookings_details[0] && user.bookings_details[0].totalAmountConsumer  !== undefined ?
            user.bookings_details[0].totalAmountConsumer :"No Data"}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Amount Spent On Orders
            </div>
            <div className='read-form-value'>{user && user.orders_details && user.orders_details[0] && user.orders_details[0].totalAmountConsumersOrder  !== undefined ?
            user.orders_details[0].totalAmountConsumersOrder  :"No Data"}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Date Of Sign up
            </div>
            <div className='read-form-value'>
              {user && user.user_details && user.user_details[0] && user.user_details[0].user_signed_up  !== undefined ? moment(user.user_details[0].user_signed_up).format('DD/MM/YYYY'):"No Data"}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Total Orders
            </div>
            <div className='read-form-value'>{user && user.orders && user.orders.totalOrders}</div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default ViewPatients;
