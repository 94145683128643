import React, { useEffect, useState } from "react";
import { Table, Select, Button, Input, Spin, Modal } from "antd";
import { view_log, green_dot, red_dot } from "helper/constant";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import actions from "actions";
import { Link,useHistory } from "react-router-dom";
import moment from "moment";
import { DatePicker } from "antd";
import Form from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import "./viewpatientdocs.css";
import { Helmet } from "react-helmet";

const ViewpatientdocsComponent = () => {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
    const history =useHistory()
  const payload = useSelector((state) => state.patients.histrodocument);
  const payloadloader =
    payload === undefined ||
    payload === [] ||
    payload === "" ||
    payload.length === 0
      ? true
      : false;

  console.log("viewdoc pat payload", payload);

  const [isModalVisible, setIsModalVisible] = useState(false);
 const [caution,setCaution] = useState(false)
  const showModal = (id) => {
    // console.log("jhuihui",id)
    setIsModalVisible(true);
    //  handleHistoryCaseNote(id)
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //pdf handler doctor case note
  const handleHistoryCaseNote = (e) => {
    showModal();
    console.log("idd", e);
    const caseid = e;
    let dt;
    let url;
    setLoader(true);
    async function getpdf(id) {
      try {
        await axios
          .get(
            `${process.env.REACT_APP_WEB_APP}/ep-lab/caseNotepreviewejs/${id}`
          )
          .then((res) => (dt = res.data));
        //  handlePdfOpen();
        await fetch("data:application/pdf;base64," + dt)
          .then((res) => res.blob())
          .then((blob) => {
            url = window.URL.createObjectURL(blob);
          });
        console.log("urllll", url);
        const iframe = document.querySelector("#pdf");
        iframe.setAttribute("src", url);
        setLoader(false);
      } catch (error) {
        //   console.error(error);
        //  failurePdfOpen();
        // handleCancel()
        handleCancel();
        setLoader(true);
      }
    }

    getpdf(caseid);
  };

  //pdf handler phy case note
  const handlePhYcaseNote = (e) => {
    showModal();

    const caseid = e;
    let dt;
    let url;
    setLoader(true);
    async function getpdf(id) {
      try {
        await axios
          .get(
            `${process.env.REACT_APP_WEB_APP}/ep-lab/psyNotePreviewejs/${id}`
          )
          .then((res) => (dt = res.data));
        // handlePdfOpen();
        // showModal()

        await fetch("data:application/pdf;base64," + dt)
          .then((res) => res.blob())
          .then((blob) => {
            url = window.URL.createObjectURL(blob);
          });
        const iframe = document.querySelector("#pdf");
        iframe.setAttribute("src", url);
        setLoader(false);
      } catch (error) {
        //   console.error(error);
        //  failurePdfOpen();
        handleCancel();
        setLoader(true);
      }
    }

    getpdf(caseid);
  };

  //pdf handler medicine case note
  const handleMedicineReport = (e) => {
    showModal();

    const caseid = e;
    let dt;
    let url;
    setLoader(true);

    async function getpdf(id) {
      try {
        await axios
          .get(`${process.env.REACT_APP_WEB_APP}/ep/preview/${id}`)
          .then((res) => (dt = res.data));
        // handlePdfOpen();
        // showModal()

        await fetch("data:application/pdf;base64," + dt)
          .then((res) => res.blob())
          .then((blob) => {
            url = window.URL.createObjectURL(blob);
          });
        const iframe = document.querySelector("#pdf");
        iframe.setAttribute("src", url);
        setLoader(false);
      } catch (error) {
        //   console.error(error);
        //  failurePdfOpen();
        handleCancel();
        setLoader(true);
      }
    }

    getpdf(caseid);
  };

  //pdf handler lab case note
  const handleHistoryLabNote = (e) => {
    showModal();

    const caseid = e;
    let dt;
    let url;
    setLoader(true);

    async function getpdf(id) {
      try {
        await axios
          .get(`${process.env.REACT_APP_WEB_APP}/ep-lab/preview/${id}`)
          .then((res) => (dt = res.data));
        // showModal()

        await fetch("data:application/pdf;base64," + dt)
          .then((res) => res.blob())
          .then((blob) => {
            url = window.URL.createObjectURL(blob);
          });
        const iframe = document.querySelector("#pdf");
        iframe.setAttribute("src", url);
        setLoader(false);
      } catch (error) {
        handleCancel();
        setLoader(true);
      }
    }

    getpdf(caseid);
  };
useEffect(()=>{
    if(payload === undefined ||
        payload === [] ||
        payload === "" ||
        payload.length === 0 ){
            setCaution(true)
        }
    
},[payload])
  return (
    <div>
      <>
      <Helmet>
        <title>Doctall Super Admin Patients Documents
</title>
<meta 
            name='description' 
            content='View patient documents upload and stored on the platform'
         

   />
      </Helmet>
        {/* <Spin spinning={loader}> */}
        <Modal title="Caution" 
        visible={caution} 
        onOk={()=>{
            setCaution(false)
            history.push('/patients')
        }} 
        onCancel={()=>{setCaution(false)}}
        >
        <p>Please Select the Patient first </p>
      </Modal>
        <Modal
          title="Document view"
          width="80%"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={
            [
              // <Button
              //   key="link"
              //   href="https://google.com"
              //   type="primary"
              // //   loading={loading}
              // //   onClick={this.handleOk}
              // >
              //   Search on Google
              // </Button>,
            ]
          }
        >
          <Spin spinning={loader}>
            <iframe width="100%" height="600px" id="pdf"></iframe>
          </Spin>
        </Modal>
        {/* </Spin> */}
      </>

      <div>
        <Spin spinning={payloadloader}>
          <div className="HAs_Container_Wraper">
            <div className="HAs_History">
              {payload === undefined ||
              payload === [] ||
              payload === "" ||
              payload.length === 0 ? (
                "Case Notes Not Found"
              ) : (
                <>
                  <div className="title">Case Notes </div>
                  <div class="HASC_Dot history"></div>
                  {payload.doctor_case_note.length !== 0 ? (
                    payload.doctor_case_note
                      .concat()
                      .reverse()
                      .map((i) => (
                        <>
                          <div className="value">
                            <div className="name">
                              {/* <div className="text">Case Notes</div> */}
                              {/* <div className="btn" id={i.uuid} onClick={handleHistoryCaseNote}>View</div> */}
                              {/* <div className="btn" id={i.uuid}>View</div> */}
                              <div className="dateandtime">
                                {moment(i.createdAt).format(
                                  "ddd, DD MMM, YYYY"
                                )}
                              </div>

                              <button
                                className="viewbutton"
                                type="primary"
                                onClick={() => handleHistoryCaseNote(i.uuid)}
                              >
                                {/* <Button type="primary" onClick={()=>showModal(i.uuid)}> */}
                                View
                              </button>
                            </div>
                          </div>
                        </>
                      ))
                  ) : (
                    <div className="value">
                      <div className="name">
                        <div className="text">No Past data found</div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="HAs_Container_Wraper">
            <div className="HAs_History">
              {payload === undefined ||
              payload === [] ||
              payload === "" ||
              payload.length === 0 ? (
                "Psychiatry Notes not Found"
              ) : (
                <>
                  <div className="title">Psychiatry Case Notes</div>
                  <div class="HASC_Dot history"></div>
                  {payload.psychiatry_case_note.length !== 0 ? (
                    payload.psychiatry_case_note
                      .concat()
                      .reverse()
                      .map((i) => (
                        <>
                          <div className="value">
                            <div className="name">
                              {/* <div className="text">Notes</div> */}
                              {/* <div className="btn" id={i.uuid} onClick={handlePhYcaseNote}>View</div> */}
                              <div className="dateandtime">
                                {moment(i.createdAt).format(
                                  "ddd, DD MMM, YYYY"
                                )}
                              </div>

                              <button
                                className="viewbutton"
                                type="primary"
                                onClick={() => handlePhYcaseNote(i.uuid)}
                              >
                                {/* <Button type="primary" onClick={()=>showModal(i.uuid)}> */}
                                View
                              </button>
                            </div>
                          </div>
                        </>
                      ))
                  ) : (
                    <div className="value">
                      <div className="name">
                        <div className="text">No Past data found</div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="HAs_Container_Wraper">
            <div className="HAs_History">
              {payload === undefined ||
              payload === [] ||
              payload === "" ||
              payload.length === 0 ? (
                "Medical report not Found"
              ) : (
                <>
                  <div className="title">Medicine Reports</div>
                  <div class="HASC_Dot history"></div>
                  {payload.medicine.length !== 0 ? (
                    payload.medicine
                      .concat()
                      .reverse()
                      .map((i) => (
                        <>
                          <div className="value">
                            <div className="name">
                              {/* <div className="text">Medicine report      </div> */}

                              {/* <div className="btn" id={i.uuid} onClick={handleMedicineReport}>View</div> */}
                              <div className="dateandtime">
                                {moment(i.createdAt).format(
                                  "ddd, DD MMM, YYYY"
                                )}
                              </div>

                              <button
                                className="viewbutton"
                                type="primary"
                                onClick={() => handleMedicineReport(i.uuid)}
                              >
                                {/* <Button type="primary" onClick={()=>showModal(i.uuid)}> */}
                                View
                              </button>
                            </div>
                          </div>
                        </>
                      ))
                  ) : (
                    <div className="value">
                      <div className="name">
                        <div className="text">No Past data found</div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="HAs_Container_Wraper">
            <div className="HAs_History">
              {payload === undefined ||
              payload === [] ||
              payload === "" ||
              payload.length === 0 ? (
                "Lab report not Found"
              ) : (
                <>
                  <div className="title">Lab Reports</div>
                  <div class="HASC_Dot history"></div>
                  {payload.lab.length !== 0 ? (
                    payload.lab
                      .concat()
                      .reverse()
                      .map((i) => (
                        <>
                          <div className="value">
                            <div className="name">
                              {/* <div className="text">Lab report</div> */}
                              <div className="dateandtime">
                                {moment(i.createdAt).format(
                                  "ddd, DD MMM, YYYY"
                                )}
                              </div>

                              <button
                                className="viewbutton"
                                type="primary"
                                onClick={() => handleHistoryLabNote(i.uuid)}
                              >
                                View
                              </button>
                              {/* <div className="btn" id={i.uuid} onClick={handleHistoryLabNote}>View</div> */}
                              {/* <Button type="primary" onClick={()=>showModal(i.uuid)}> */}
                            </div>
                          </div>
                        </>
                      ))
                  ) : (
                    <div className="value">
                      <div className="name">
                        <div className="text">No Past data found</div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default ViewpatientdocsComponent;
