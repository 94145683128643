import React, { useEffect, useState, useRef } from "react";
import {
  Form,
  Modal,
  Input,
  Row,
  Col,
  Button,
  Select,
  Tabs,
  notification,
} from "antd";
import { DatePicker } from "antd";
import axios from "axios";
import { green_dot, red_dot, view_log, triple_dots } from "helper/constant";
import userDommyImg from "../../assets/images/avatar.jpg";
import { useDispatch, useSelector } from "react-redux";
import actions from "actions";
import moment from "moment";
import { Spin } from "antd";

const { doctorActions, saasActions } = actions;

const { Option } = Select;
const { TabPane } = Tabs;

const AddDoctor = ({
  visible,
  action,
  values,
  // onSubmit,
  handleOk,
  handleCancel,
  commissions
}) => {

  console.log(values, "valuesvalues")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [gender, setGender] = useState(null);
  const [loading, setLoading] = useState(false);

  const [finalsubmit, setfinalsubmit] = useState([]);

  const [profilePic, setProfilePic] = useState(userDommyImg);
  const [profilePicBin, setProfilePicBin] = useState(null);

  const [signPic, setSignPic] = useState(userDommyImg);
  const [signed, setSigned] = useState(null);
  const [signpicBin, setSignPicBIn] = useState(null)

  const [newprofilePicBin, setnewProfilePicBin] = useState(null);
  const [hideSubmit, setHideSubmit] = useState(false);


  const auth = useSelector((state) => state.auth.accessToken);

  const uploadImg = useRef(null);
  const signUpload = useRef(null)

  const handleImage = (e) => {
    const [file] = e.target.files;
    setProfilePicBin(e.target.files[0]);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfilePic(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const HandleSign = (e) => {
    const [file] = e.target.files;
    setSigned(e.target.files[0]);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSignPic(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }
  const styles = {
    padding: "5px 0px",
    borderBottom: "1px solid green",
    marginBottom: "3px",
  };
  // console.log(values)
  useEffect(() => {
    setGender(values && values.profile && values.profile.gender);
    values && values.profile_pic
      ? setProfilePic(values.profile_pic)
      : setProfilePic(userDommyImg);
    values && values.profile && values.profile.sign_pic ? setSignPic(values.profile.sign_pic) : setSignPic(userDommyImg)
    console.log("values", values);
  }, [values]);

  const fields = [

    {
      name: 'SignPic',
      values: values && values.profile && values.profile.sign_pic

    },
    {
      name: "full_name",
      value: values && values.full_name,
    },
    {
      name: "profile_pic",
      value: values && values.profile_pic,
    },
    {
      name: "email",
      value: values && values.email,
    },
    {
      name: "mobile",
      value: values && values.mobile,
    },
    {
      name: "username",
      value: values && values.username,
    },
    {
      name: "consultation_fee",
      value: String(values?.consultation_fee),
    },
    {
      name: "dob",
      value: values && values.profile && values.profile.dob,
    },
    {
      name: "gender",
      value: values && values.profile && values.profile.gender,
    },
    {
      name: "qualification",
      value: values && values.profile && values.profile.professional_detail && values.profile.professional_detail.qualification ? values.profile.professional_detail.qualification : "N/A"
    },
    {
      name: "education",
      value: values && values.profile && values.profile.education ? values.profile.education : "N/A",
    },
    {
      name: "folioNumber",
      value: values && values.profile && values.profile.folioNumber,
    },
    {
      name: "practice_started_year",
      value: values && values.profile && values.profile.practice_started_year ? values.profile.practice_started_year : new Date().getFullYear(),
    },
    {
      name: "license",
      value: values && values.profile && values.profile.license,
    },
    {
      name: "speciality",
      value: values && values.profile && values.profile.speciality,
    },
    {
      name: "languages",
      value: values && values.profile && values.profile.languages,
    },
    {
      name: "bio",
      value: values && values.profile && values.profile.bio,
    },
    {
      name: "city",
      value:
        values &&
        values.profile &&
        values.profile.address &&
        values.profile.address.city,
    },
    {
      name: "country",
      value:
        values &&
          values.profile &&
          values.profile.address &&
          values.profile.address.country ? values.profile.address.country : "N/A",
    },
    {
      name: "houseNumber",
      value:
        values &&
        values.profile &&
        values.profile.address &&
        values.profile.address.houseNumber,
    },
    {
      name: "state",
      value:
        values &&
        values.profile &&
        values.profile.address &&
        values.profile.address.state,
    },
    {
      name: "postalcode",
      value:
        values &&
        values.profile &&
        values.profile.address &&
        values.profile.address.postalcode,
    },
    {
      name: "acc_name",
      value:
        values &&
        values.profile &&
        values.profile.bank_detail &&
        values.profile.bank_detail.acc_name,
    },
    {
      name: "acc_number",
      value:
        values &&
        values.profile &&
        values.profile.bank_detail &&
        values.profile.bank_detail.acc_number,
    },
    {
      name: "acc_type",
      value:
        values &&
        values.profile &&
        values.profile.bank_detail &&
        values.profile.bank_detail.acc_type,
    },
    {
      name: "bank_name",
      value:
        values &&
        values.profile &&
        values.profile.bank_detail &&
        values.profile.bank_detail.bank_name,
    },
    {
      name: "bankIban",
      value:
        values &&
        values.profile &&
        values.profile.bank_detail &&
        values.profile.bank_detail.bankIban,
    },
    {
      name: "bankSwiftIcd",
      value:
        values &&
        values.profile &&
        values.profile.bank_detail &&
        values.profile.bank_detail.bankSwiftIcd,
    },
    {
      name: "branch",
      value:
        values &&
        values.profile &&
        values.profile.bank_detail &&
        values.profile.bank_detail.branch,
    },
    {
      name: "documents",
      value: values && values.profile && values.profile.doctorDocuments,
    },
    {
      name: "profileVideoUrl1",
      value:
        values &&
        values.profile &&
        values.profile.profileVideoUrl &&
        values.profile.profileVideoUrl[0],
    },
    {
      name: "profileVideoUrl2",
      value:
        values &&
        values.profile &&
        values.profile.profileVideoUrl &&
        values.profile.profileVideoUrl[1],
    },
    {
      name: "profileVideoUrl3",
      value:
        values &&
        values.profile &&
        values.profile.profileVideoUrl &&
        values.profile.profileVideoUrl[2],
    },
    {
      name: "social_mediaFb",
      value:
        values &&
        values.profile &&
        values.profile.social_media &&
        values.profile.social_media.fb,
    },
    {
      name: "social_mediaIn",
      value:
        values &&
        values.profile &&
        values.profile.social_media &&
        values.profile.social_media.insta,
    },
    {
      name: "social_mediaLi",
      value:
        values &&
        values.profile &&
        values.profile.social_media &&
        values.profile.social_media.linkedin,
    },
    {
      name: "social_mediatw",
      value:
        values &&
        values.profile &&
        values.profile.social_media &&
        values.profile.social_media.tw,
    },
    {
      name: "social_mediaYt",
      value:
        values &&
        values.profile &&
        values.profile.social_media &&
        values.profile.social_media.youtube,
    },
    {
      name: "social_mediaOt",
      value:
        values &&
        values.profile &&
        values.profile.social_media &&
        values.profile.social_media.other,
    },
    {
      name: "hrInfoFullname",
      value:
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.full_name,
    },
    {
      name: "hrInfoEmail",
      value:
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.email,
    },
    {
      name: "hrInfoMobile",
      value:
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.mobile,
    },
    {
      name: "hrInfoCity",
      value:
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.address &&
        values.profile.hr_info.address.city,
    },
    {
      name: "hrInfoCountry",
      value:
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.address &&
        values.profile.hr_info.address.country,
    },
    {
      name: "hrInfoHouseNumber",
      value:
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.address &&
        values.profile.hr_info.address.houseNumber,
    },
    {
      name: "hrInfoPostalcode",
      value:
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.address &&
        values.profile.hr_info.address.postalcode,
    },
    {
      name: "hrInfoState",
      value:
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.address &&
        values.profile.hr_info.address.state,
    },
    {
      name: "ECState",
      value:
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.emergencyContact &&
        values.profile.hr_info.emergencyContact.address &&
        values.profile.hr_info.emergencyContact.address.state,
    },
    {
      name: "ECHouseNumber",
      value:
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.emergencyContact &&
        values.profile.hr_info.emergencyContact.address &&
        values.profile.hr_info.emergencyContact.address.houseNumber,
    },
    {
      name: "ECCity",
      value:
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.emergencyContact &&
        values.profile.hr_info.emergencyContact.address &&
        values.profile.hr_info.emergencyContact.address.city,
    },
    {
      name: "ECCountry",
      value:
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.emergencyContact &&
        values.profile.hr_info.emergencyContact.address &&
        values.profile.hr_info.emergencyContact.address.country,
    },
    {
      name: "ECPostalCode",
      value:
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.emergencyContact &&
        values.profile.hr_info.emergencyContact.address &&
        values.profile.hr_info.emergencyContact.address.postalcode,
    },
    {
      name: "ECEmail",
      value:
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.emergencyContact &&
        values.profile.hr_info.emergencyContact.email,
    },
    {
      name: "ECFullName",
      value:
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.emergencyContact &&
        values.profile.hr_info.emergencyContact.full_name,
    },
    {
      name: "ECMobile",
      value:
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.emergencyContact &&
        values.profile.hr_info.emergencyContact.mobile,
    },
    {
      name: "LiAuth",
      value:
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.license &&
        values.profile.hr_info.license.li_authority,
    },
    {
      name: "LiDateIssue",
      value: moment(
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.license &&
        values.profile.hr_info.license.dateIssue
      ).format("DD-MM-YYYY"),
    },
    {
      name: "LiDateExp",
      value: moment(
        values &&
        values.profile &&
        values.profile.hr_info &&
        values.profile.hr_info.license &&
        values.profile.hr_info.license.dateExpiry
      ).format("DD-MM-YYYY"),
    },

  ];

  // console.log(values && values.profile && values.profile.hr_info);
  const handleformsubmit = async (submitvalues) => {

    let special = submitvalues.speciality
    if (typeof special === 'string') {
      special = special.split(',')
    }
    let lang = submitvalues.languages;
    if (typeof lang === "string") {
      lang = lang.split(",");
    }
    if (profilePicBin) {
      setLoading(true);
      const formData = new FormData();
      formData.append("media", profilePicBin);
      await axios
        .post(`${process.env.REACT_APP_WEB_APP}/upload/avatar`, formData, {
          headers: { "x-auth-token": auth },
        })
        .then((res) => {
          setLoading(false);
          setnewProfilePicBin(res.data.url);
          submitvalues.profile_pic = res.data.url;
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    if (signed) {
      setLoading(true);
      const SignData = new FormData();
      SignData.append("media", signed);
      await axios
        .post(`${process.env.REACT_APP_WEB_APP}/upload/avatar`, SignData, {
          headers: { "x-auth-token": auth },
        })
        .then((res) => {
          setLoading(false);
          setSignPicBIn(res.data.url);
          submitvalues.SignPic = res.data.url;
        })
        .catch((err) => {
          setLoading(false);
        });
    }

    const formValues = {


      consultation_fee: submitvalues.consultation_fee,
      avatar: '404',
      // avatar:
      //   submitvalues.profile_pic === undefined
      //     ? profilePic
      //     : submitvalues.profile_pic,
      practice_started_year: submitvalues.practice_started_year,
      gender:
        gender === null
          ? values && values.profile && values.profile.gender
          : gender,
      languages: lang,
      bio: submitvalues.bio,
      speciality: special,
      folioNumber: submitvalues.folioNumber,
      advice_commission: submitvalues.advCommition,
      medicine_prescription_commision: submitvalues.medCommition,

      social_media: {
        fb:
          submitvalues.social_mediaFb === undefined
            ? values &&
            values.profile &&
            values.profile.social_media &&
            values.profile.social_media.fb
            : submitvalues.social_mediaFb,

        tw:
          submitvalues.social_mediatw === undefined
            ? values &&
            values.profile &&
            values.profile.social_media &&
            values.profile.social_media.tw
            : submitvalues.social_mediatw,

        insta:
          submitvalues.social_mediaIn === undefined
            ? values &&
            values.profile &&
            values.profile.social_media &&
            values.profile.social_media.insta
            : submitvalues.social_mediaIn,

        linkedin:
          submitvalues.social_mediaLi === undefined
            ? values &&
            values.profile &&
            values.profile.social_media &&
            values.profile.social_media.linkedin
            : submitvalues.social_mediaLi,

        youtube:
          submitvalues.social_mediaYt === undefined
            ? values &&
            values.profile &&
            values.profile.social_media &&
            values.profile.social_media.youtube
            : submitvalues.social_mediaYt,

        other:
          submitvalues.social_mediaOt === undefined
            ? values &&
            values.profile &&
            values.profile.social_media &&
            values.profile.social_media.other
            : submitvalues.social_mediaOt,
      },


      "professional_detail": {
        "professional": submitvalues.qualification !== undefined && submitvalues.qualification,
        "qualification": submitvalues.qualification !== undefined && submitvalues.qualification
      },

      education: submitvalues.education,
      profileVideoUrl: [
        submitvalues.profileVideoUrl1 === undefined
          ? values &&
          values.profile &&
          values.profile.profileVideoUrl &&
          values.profile.profileVideoUrl[0]
          : submitvalues.profileVideoUrl1,
        submitvalues.profileVideoUrl2 === undefined
          ? values &&
          values.profile &&
          values.profile.profileVideoUrl &&
          values.profile.profileVideoUrl[1]
          : submitvalues.profileVideoUrl2,
        submitvalues.profileVideoUrl1 === undefined
          ? values &&
          values.profile &&
          values.profile.profileVideoUrl &&
          values.profile.profileVideoUrl[2]
          : submitvalues.profileVideoUrl3,
      ],
      address: {
        houseNumber:
          submitvalues.houseNumber === undefined
            ? values &&
            values.profile &&
            values.profile.address &&
            values.profile.address.houseNumber
            : submitvalues.houseNumber,

        city:
          submitvalues.city === undefined
            ? values &&
            values.profile &&
            values.profile.address &&
            values.profile.address.city
            : submitvalues.city,

        state:
          submitvalues.houseNumber === undefined
            ? values &&
            values.profile &&
            values.profile.address &&
            values.profile.address.state
            : submitvalues.state,

        postalcode:
          submitvalues.houseNumber === undefined
            ? values &&
            values.profile &&
            values.profile.address &&
            values.profile.address.postalcode
            : submitvalues.postalcode,

        country:
          submitvalues.country === undefined
            ? values &&
            values.profile &&
            values.profile.address &&
            values.profile.address.city
            : submitvalues.country,
      },
      bank_detail: {
        acc_name:
          submitvalues.acc_name === undefined
            ? values &&
            values.profile &&
            values.profile.bank_detail &&
            values.profile.bank_detail.acc_name
            : submitvalues.acc_name,
        bank_name:
          submitvalues.bank_name === undefined
            ? values &&
            values.profile &&
            values.profile.bank_detail &&
            values.profile.bank_detail.bank_name
            : submitvalues.bank_name,
        branch:
          submitvalues.branch === undefined
            ? values &&
            values.profile &&
            values.profile.bank_detail &&
            values.profile.bank_detail.branch
            : submitvalues.branch,
        acc_number:
          submitvalues.acc_number === undefined
            ? values &&
            values.profile &&
            values.profile.bank_detail &&
            values.profile.bank_detail.acc_number
            : submitvalues.acc_number,
        acc_type:
          submitvalues.acc_type === undefined
            ? values &&
            values.profile &&
            values.profile.bank_detail &&
            values.profile.bank_detail.acc_type
            : submitvalues.acc_type,
        // "routingNumber" : submitvalues,
        bankSwiftIcd:
          submitvalues.bankSwiftIcd === undefined
            ? values &&
            values.profile &&
            values.profile.bank_detail &&
            values.profile.bank_detail.bankSwiftIcd
            : submitvalues.bankSwiftIcd,
        bankIban:
          submitvalues.bankIban === undefined
            ? values &&
            values.profile &&
            values.profile.bank_detail &&
            values.profile.bank_detail.bankIban
            : submitvalues.bankIban,
      },
      hr_info: {
        full_name:
          submitvalues.hrInfoFullname === undefined
            ? values &&
            values.profile &&
            values.profile.hr_info &&
            values.profile.hr_info.full_name
            : submitvalues.hrInfoFullname,
        email:
          submitvalues.hrInfoEmail === undefined
            ? values &&
            values.profile &&
            values.profile.hr_info &&
            values.profile.hr_info.email
            : submitvalues.hrInfoEmail,
        mobile:
          submitvalues.hrInfoMobile === undefined
            ? values &&
            values.profile &&
            values.profile.hr_info &&
            values.profile.hr_info.mobile
            : submitvalues.hrInfoMobile,
        address: {
          houseNumber:
            submitvalues.hrInfoHouseNumber === undefined
              ? values &&
              values.profile &&
              values.profile.hr_info &&
              values.profile.hr_info.address &&
              values.profile.hr_info.address.houseNumber
              : submitvalues.hrInfoHouseNumber,
          city:
            submitvalues.hrInfoCity === undefined
              ? values &&
              values.profile &&
              values.profile.hr_info &&
              values.profile.hr_info.address &&
              values.profile.hr_info.address.city
              : submitvalues.hrInfoCity,
          state:
            submitvalues.hrInfoState === undefined
              ? values &&
              values.profile &&
              values.profile.hr_info &&
              values.profile.hr_info.address &&
              values.profile.hr_info.address.state
              : submitvalues.hrInfoState,
          postalcode:
            submitvalues.hrInfoPostalcode === undefined
              ? values &&
              values.profile &&
              values.profile.hr_info &&
              values.profile.hr_info.address &&
              values.profile.hr_info.address.postalcode
              : submitvalues.hrInfoPostalcode,
          country:
            submitvalues.hrInfoCountry === undefined
              ? values &&
              values.profile &&
              values.profile.hr_info &&
              values.profile.hr_info.address &&
              values.profile.hr_info.address.country
              : submitvalues.hrInfoCountry,
        },
        emergencyContact: {
          full_name:
            submitvalues.ECFullName === undefined
              ? values &&
              values.profile &&
              values.profile.hr_info &&
              values.profile.hr_info.emergencyContact &&
              values.profile.hr_info.emergencyContact.full_name
              : submitvalues.ECFullName,
          email:
            submitvalues.ECEmail === undefined
              ? values.profile &&
              values.profile.hr_info &&
              values.profile.hr_info.emergencyContact &&
              values.profile.hr_info.emergencyContact.email
              : submitvalues.ECEmail,
          mobile:
            submitvalues.ECMobile === undefined
              ? values.profile &&
              values.profile.hr_info &&
              values.profile.hr_info.emergencyContact &&
              values.profile.hr_info.emergencyContact.mobile
              : submitvalues.ECMobile,
          address: {
            houseNumber:
              submitvalues.ECHouseNumber === undefined
                ? values &&
                values.profile &&
                values.profile.hr_info &&
                values.profile.hr_info.emergencyContact &&
                values.profile.hr_info.emergencyContact.address &&
                values.profile.hr_info.emergencyContact.address.houseNumber
                : submitvalues.ECHouseNumber,
            city:
              submitvalues.ECCity === undefined
                ? values &&
                values.profile &&
                values.profile.hr_info &&
                values.profile.hr_info.emergencyContact &&
                values.profile.hr_info.emergencyContact.address &&
                values.profile.hr_info.emergencyContact.address.city
                : submitvalues.ECCity,
            state:
              submitvalues.ECState === undefined
                ? values &&
                values.profile &&
                values.profile.hr_info &&
                values.profile.hr_info.emergencyContact &&
                values.profile.hr_info.emergencyContact.address &&
                values.profile.hr_info.emergencyContact.address.state
                : submitvalues.ECState,
            postalcode:
              submitvalues.ECPostalCode === undefined
                ? values &&
                values.profile &&
                values.profile.hr_info &&
                values.profile.hr_info.emergencyContact &&
                values.profile.hr_info.emergencyContact.address &&
                values.profile.hr_info.emergencyContact.address.postalcode
                : submitvalues.ECPostalCode,
            country:
              submitvalues.ECCountry === undefined
                ? values &&
                values.profile &&
                values.profile.hr_info &&
                values.profile.hr_info.emergencyContact &&
                values.profile.hr_info.emergencyContact.address &&
                values.profile.hr_info.emergencyContact.address.country
                : submitvalues.ECCountry,
          },
        },

        license: {
          li_authority:
            submitvalues.LiAuth === undefined
              ? values &&
              values.profile &&
              values.profile.hr_info &&
              values.profile.hr_info.license &&
              values.profile.hr_info.license.li_authority
              : submitvalues.LiAuth,
          dateIssue:
            values &&
            values.profile &&
            values.profile.hr_info &&
            values.profile.hr_info.license &&
            values.profile.hr_info.license.dateIssue,
          dateExpiry:
            values &&
            values.profile &&
            values.profile.hr_info &&
            values.profile.hr_info.license &&
            values.profile.hr_info.license.dateExpiry,
        },
      },
      sign_pic: submitvalues.SignPic === undefined ? signPic : submitvalues.SignPic,
      profile_pic:
        submitvalues.profile_pic === undefined
          ? profilePic
          : submitvalues.profile_pic,
      full_name: submitvalues.full_name,
    };

    dispatch(doctorActions.editDoctors(values && values.uuid, formValues));
    dispatch(saasActions.getSaasDoctors(0));
    reset();
    handleOk();
  };

  const reset = () => {
    form.resetFields();
  };

  const onCancel = () => {
    reset();
    handleCancel();
  };

  const onOk = () => {
    reset();
    handleOk();
  };


  const handleTabs = (event) => {
    if (event === "6") {
      setHideSubmit(true)
    } else {
      setHideSubmit(false)
    }
  }

  const handleErrors = ({ values, errorFields, outOfDate }) => {

    if (errorFields.length > 0) {
      notification.open({
        message: 'Alert !',
        description: 'Please check all the tabs and fill mandatory values..'
      })
    }
  }

  return (
    <Modal
      className="no-modal-footer footerDoc"
      title="Edit Doctor"
      visible={visible}
      width={750}
      onOk={onOk}
      key={values && values._id}
      onCancel={onCancel}
      okText="Edit"
      footer={[
        <Button className="login-form-button" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button ml--10"
        >
          Edit
        </Button>,
      ]}
    >
      <Form
        onFinish={handleformsubmit}
        onFinishFailed={handleErrors}
        fields={fields}
        className="gx-form-row0"
        layout="vertical"
      >
        <Tabs defaultActiveKey="1" onChange={handleTabs}>
          <TabPane tab="General" key="1">
            <Row className="space-between">
              <Col span={7} xs={7} md={7}>
                <img
                  src={profilePic && profilePic.default?profilePic.default:profilePic}
                  onClick={() => uploadImg.current.click()}
                  alt="user profile pic"
                  width="150px"
                  height="150px"
                />
                <br />

                <input
                  accept="image/*"
                  onChange={(e) => handleImage(e)}
                  id="myInput"
                  type="file"
                  ref={uploadImg}
                  style={{ display: "none" }}
                />
              </Col>

              <Col span={7} xs={7} md={7}>
                <Form.Item
                  label="Full Name"
                  name="full_name"
                  rules={[{ required: true, message: "Please enter name!" }]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={7} xs={7} md={7}>
                <Form.Item
                  label="Phone Number"
                  name="mobile"
                  rules={[{ required: true, message: "Please enter phone!" }]}
                >
                  <Input disabled={true} size="large" />
                </Form.Item>
              </Col>
              <Col span={7} xs={7} md={7}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: "Please enter email!" }]}
                >
                  <Input disabled={true} size="large" />
                </Form.Item>
              </Col>
              <Col span={7} xs={7} md={7}>
                <Form.Item
                  label="Practice Started Year"
                  name="practice_started_year"
                  rules={[
                    {
                      required: true,
                      message: "Please enter practice_started_year!",
                    },
                  ]}
                  initialValue={'N/A'}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={7} xs={7} md={7}>
                <Form.Item
                  label="Folio Number"
                  name="folioNumber"
                  rules={[
                    { required: true, message: "Please enter Folio Number!" },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={7} xs={7} md={7}>
                <Form.Item label="Bio" name="bio">
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={7} xs={7} md={7}>
                <Form.Item label="Consultation Fee" name="consultation_fee">
                  <Input type="number" size="large" />
                </Form.Item>
              </Col>
              <Col span={7} xs={7} md={7}>
                <Form.Item label="education" name="education">
                  <Input size="large" />
                </Form.Item>
              </Col>

              <Col span={7} xs={7} md={7}>
                <Form.Item label="speciality" name="speciality">
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={7} xs={7} md={7}>
                <Form.Item label="languages" name="languages">
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col className="ml--0" span={7} xs={7} md={7}>
                <Form.Item label="Gender">
                  <Select
                    id="gender"
                    onChange={(value) => {
                      setGender(value);
                    }}
                    defaultValue={gender}
                    value={gender}
                  >
                    <Option key={"male"} value="male">
                      Male
                    </Option>
                    <Option key={"female"} value="female">
                      Female
                    </Option>
                    <Option key={"others"} value="others">
                      Others
                    </Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={7} xs={7} md={7}>
                <Form.Item label="Qualification" name="qualification"
                  rules={[{ required: true, message: "Please enter qualification !" }]}
                  initialValue={"N/A"}
                >
                  <Input size="large"
                  />
                </Form.Item>
              </Col>

            </Row>
          </TabPane>
          <TabPane tab="Contact" key="2">
            <Row className="space-between">
              <Col span={12} xs={10} md={10}>
                <h3 style={styles}>Address</h3>
                <Form.Item
                  label="House Number"
                  name="houseNumber"
                // rules={[{ required: true, message: "Please enter houseNumber!" }]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="City"
                  name="city"
                // rules={[{ required: true, message: "Please enter city!" }]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="State"
                  name="state"
                // rules={[{ required: true, message: "Please enter state!" }]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[{ required: true, message: "Please enter Country!" }]}
                  initialValue={"N/A"}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="Postal Code"
                  name="postalcode"
                // rules={[{ required: true, message: "Please enter postalcode!" }]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={12} xs={10} md={10}>
                <h3 style={styles}>Bank Details</h3>
                {/* bank_name bankIban bankSwiftIcd branch */}
                <Form.Item
                  label="Account Name"
                  name="acc_name"
                // rules={[{ required: true, message: "Please enter Account Name!" }]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="Account Number"
                  name="acc_number"
                // rules={[{ required: true, message: "Please enter Account Number!" }]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Bank Name"
                  name="bank_name"
                // rules={[{ required: true, message: "Please enter state!" }]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="Branch Name"
                  name="branch"
                // rules={[{ required: true, message: "Please enter state!" }]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="Bank Iban"
                  name="bankIban"
                // rules={[{ required: true, message: "Please enter state!" }]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="Bank Icd"
                  name="bankSwiftIcd"
                // rules={[{ required: true, message: "Please enter state!" }]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Profile" key="3">
            <Row className="space-between">
              <Col span={12} xs={10} md={10}>
                <h3 style={styles}>Profile Video Url</h3>
                <Form.Item
                  label="Urls"
                  name="profileVideoUrl1"
                // rules={[{ required: true, message: "Please enter state!" }]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="Urls"
                  name="profileVideoUrl2"
                // rules={[{ required: true, message: "Please enter state!" }]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="Urls"
                  name="profileVideoUrl3"
                // rules={[{ required: true, message: "Please enter state!" }]}
                >
                  <Input size="large" />
                </Form.Item>
                <h3 style={styles}>Signature</h3>
                <img
                  src={signPic && signPic.default?signPic.default:signPic}
                  onClick={() => signUpload.current.click()}
                  alt="user profile pic"
                  width="150px"
                  height="150px"
                />
                <br />

                <input
                  accept="image/*"
                  onChange={(e) => HandleSign(e)}
                  id="myInput"
                  type="file"
                  ref={signUpload}
                  style={{ display: "none" }}
                />
              </Col>
              <Col span={12} xs={10} md={10}>
                <h3 style={styles}>Social Media</h3>
                <Form.Item label="FaceBook" name="social_mediaFb">
                  <Input size="large" />
                </Form.Item>
                <Form.Item label="Instagram" name="social_mediaIn">
                  <Input size="large" />
                </Form.Item>
                <Form.Item label="Twitter" name="social_mediatw">
                  <Input size="large" />
                </Form.Item>
                <Form.Item label="Youtube" name="social_mediaYt">
                  <Input size="large" />
                </Form.Item>
                <Form.Item label="linkedin" name="social_mediaLi">
                  <Input size="large" />
                </Form.Item>
                <Form.Item label="Others" name="social_mediaOt">
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
          {/*  LiDateIssue     */}
          <TabPane tab="Office" key="4">
            <Row className="space-between">
              <Col span={7} xs={7} md={7}>
                <h3 style={styles}>Hr Info</h3>
                <Form.Item
                  label="Full Name"
                  name="hrInfoFullname"
                // rules={[{ required: true, message: "Please enter name!" }]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item label="Email" name="hrInfoEmail">
                  <Input size="large" disabled={true} />
                </Form.Item>
                <Form.Item label="Mobile" name="hrInfoMobile">
                  <Input size="large" disabled={true} />
                </Form.Item>
                <Form.Item label="House Number" name="hrInfoHouseNumber">
                  <Input size="large" />
                </Form.Item>
                <Form.Item label="City" name="hrInfoCity">
                  <Input size="large" />
                </Form.Item>
                <Form.Item label="State" name="hrInfoState">
                  <Input size="large" />
                </Form.Item>
                <Form.Item label="Country" name="hrInfoCountry">
                  <Input size="large" />
                </Form.Item>
                <Form.Item label="Postal Code" name="hrInfoPostalcode">
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={7} xs={7} md={7}>
                <h3 style={styles}>Emergency Info</h3>
                <Form.Item label="Full Name" name="ECFullName">
                  <Input size="large" />
                </Form.Item>
                <Form.Item label="Email" name="ECEmail">
                  <Input size="large" disabled={true} />
                </Form.Item>
                <Form.Item label="Mobile" name="ECMobile">
                  <Input size="large" disabled={true} />
                </Form.Item>
                <Form.Item label="House Number" name="ECHouseNumber">
                  <Input size="large" />
                </Form.Item>
                <Form.Item label="City" name="ECCity">
                  <Input size="large" />
                </Form.Item>
                <Form.Item label="State" name="ECState">
                  <Input size="large" />
                </Form.Item>
                <Form.Item label="Country" name="ECCountry">
                  <Input size="large" />
                </Form.Item>
                <Form.Item label="Postal Code" name="ECPostalCode">
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={7} xs={7} md={7}>
                <h3 style={styles}>Lisence Info</h3>
                <Form.Item label="Lisence Auth" name="LiAuth">
                  <Input size="large" disabled={true} />
                </Form.Item>
                <Form.Item label="Date Issued" name="LiDateIssue">
                  <Input size="large" disabled={true} />
                </Form.Item>
                <Form.Item label="Date Exp" name="LiDateExp">
                  <Input size="large" disabled={true} />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Documents" key="5">
            <Row className="space-between">
              <Col span={24} xs={24} md={24}>
                <h3 style={styles}>Documents</h3>
                {values &&
                  values.profile && values.profile.doctorDocuments &&
                  values.profile.doctorDocuments.length !== 0
                  ? values.profile.doctorDocuments.map((i) => (
                    <>
                      <div>{i && i?.docType}</div>
                    </>
                  ))
                  : "No Doucments to Show"}
              </Col>
            </Row>
          </TabPane>
          {commissions && <TabPane tab="Commission" key="6">

            <Form.Item label="Advice Commission (%)" name="advCommition">
              <Input type="number" step="0.01" size="large"
                defaultValue={values && values.profile && values.profile.advice_commission ? values.profile.advice_commission : "NA"}
              />
            </Form.Item>
            <Form.Item type="number" step="0.01" label="Medicine Commission (%)" name="medCommition">
              <Input size="large"
                defaultValue={values && values.profile && values.profile.medicine_prescription_commision ? values.profile.medicine_prescription_commision : "NA"}
              />
            </Form.Item>

          </TabPane>}
        </Tabs>
        <Col span={24} xs={24} md={24}>
          <div className="pull-right">
            <Button className="login-form-button" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="login-form-button ml--10"
            >
              Submit
            </Button>
          </div>
        </Col>
      </Form>
    </Modal>
  );
};

export default AddDoctor;
