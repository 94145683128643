import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from 'actions';
import {
  getUsers,
  addUser,
  verifyUser,
  addPharmacy,
  addClinic,
  addLab,
  activeUser,
  addpartnerservice,
  getPartners
} from 'services/user-services';
import { notification } from 'antd';
import { getToken } from '../firebase';



const { userActions, authActions, partnersActions } = actions;


export function* addPartnerSaga() {

  console.log('saga');
  yield takeEvery(userActions.ADD_PARTNER, function* ({ data }) {
    try {
      const token = yield select(state => state.auth.accessToken);

      // console.log(token);
      const response = yield addpartnerservice({ token }, data);
      if (response.status === 200) {
        localStorage.setItem("AddPartner", JSON.stringify(response.data))
        getToken()
        yield notification.open({
          message: 'Success!',
          description: 'Partner added Successfully!'
        });
        yield put({
          type: userActions.GET_USERS
        });
      } else {
        throw response;
      }
    } catch (error) {
      console.log(error);
      yield notification.open({
        message: 'Failed!',
        description: error.message
      });
      yield put({ type: userActions.ON_ERROR });
      if (error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}


export function* getUsersSaga() {
  console.log('saga');
  yield takeEvery(userActions.GET_USERS, function* ({ searchQuery }) {
    try {
      const token = yield select(state => state.auth.accessToken);
      const limit = yield select(state => state.users.limit);
      const skip = yield select(state => state.users.skip);
      const response = yield getUsers({ token }, skip, limit, searchQuery);
      if (response.status === 200) {
        yield put({
          type: userActions.GET_USERS_SUCCESS,
          data: response.data.users,
          count: response.data.count,
          // partners: response.data.users.filter(user => user.group === 'partner')
        });
      } else {
        throw response;
      }
    } catch (error) {
      console.log(error);
      yield notification.open({
        message: 'Failed!',
        description: error.message
      });
      yield put({ type: userActions.ON_ERROR });
      if (error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}


export function* getPartnerSaga() {
  console.log('saga');
  yield takeEvery(userActions.GET_PARTNER, function* () {
    try {
      const token = yield select(state => state.auth.accessToken);
      const limit = yield select(state => state.users.limit);
      const skip = yield select(state => state.users.skip);
      const response = yield getPartners({ token });
      if (response.status === 200) {
        yield put({
          type: userActions.GET_PARTNER_SUCCESS,
          partners: response.data
        });
      } else {
        throw response;
      }
    } catch (error) {
      yield put({ type: userActions.ON_ERROR });
      if (error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* addUserSaga() {
  console.log('saga');
  yield takeEvery(userActions.ADD_USER, function* ({ data }) {
    try {
      const token = yield select(state => state.auth.accessToken);
      console.log(token);
      const response = yield addUser({ token }, data);
      if (response.status === 200) {
        yield notification.open({
          message: 'Success!',
          description: 'User added Successfully!'
        });
        yield put({
          type: userActions.GET_USERS
        });
      } 
      else {
        throw response;
      }
    } catch (error) {
      // console.log(error.response, "error");
      yield notification.open({
        message: 'Failed!',
        description: error.response.data
      });
      yield put({ type: userActions.ON_ERROR });
      if (error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* addClinicSaga() {
  console.log('saga');
  yield takeEvery(userActions.ADD_CLINIC, function* ({ data }) {
    try {
      const token = yield select(state => state.auth.accessToken);

      console.log(token);
      const response = yield addClinic({ token }, data);
      if (response.status === 200) {
        yield notification.open({
          message: 'Success!',
          description: 'Clinic added Successfully!'
        });
        yield put({
          type: userActions.ADD_PARTNER_SUCCESS
        });
        yield put({
          type: userActions.GET_USERS
        });
      } else {
        throw response;
      }
    } catch (error) {
      console.log(error);
      yield notification.open({
        message: 'Failed!',
        description: error.message
      });
      yield put({ type: userActions.ON_ERROR });
      if (error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* addLabSaga() {
  console.log('saga');
  yield takeEvery(userActions.ADD_LAB, function* ({ data }) {
    try {
      const token = yield select(state => state.auth.accessToken);
      console.log(token);
      const response = yield addLab({ token }, data);
      if (response.status === 200) {
        yield notification.open({
          message: 'Success!',
          description: 'Lab added Successfully!'
        });
        yield put({
          type: userActions.GET_USERS
        });
      } else {
        throw response;
      }
    } catch (error) {
      console.log(error);
      yield notification.open({
        message: 'Failed!',
        description: error.message
      });
      yield put({ type: userActions.ON_ERROR });
      if (error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* addPharmacySaga() {
  console.log('saga');
  yield takeEvery(userActions.ADD_PHARMACY, function* ({ data }) {
    try {
      const token = yield select(state => state.auth.accessToken);
      console.log(token);
      const response = yield addPharmacy({ token }, data);
      if (response.status === 200) {
        yield notification.open({
          message: 'Success!',
          description: 'Lab added Successfully!'
        });
        yield put({
          type: userActions.GET_USERS
        });
      } else {
        throw response;
      }
    } catch (error) {
      console.log(error);
      yield notification.open({
        message: 'Failed!',
        description: error.message
      });
      yield put({ type: userActions.ON_ERROR });
      if (error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* verifyUserSaga() {
  console.log('saga');
  yield takeEvery(userActions.ON_VERIFIED, function* ({ email }) {
    try {
      const token = yield select(state => state.auth.accessToken);
      console.log(token);
      const response = yield verifyUser({ token }, { email });
      if (response.status === 200) {
        yield notification.open({
          message: 'Success!',
          description: 'User verified Successfully!'
        });
        yield put({
          type: userActions.GET_USERS
        });
      } else {
        throw response;
      }
    } catch (error) {
      console.log(error);
      yield notification.open({
        message: 'Failed!',
        description: error.message
      });
      yield put({ type: userActions.ON_ERROR });
      if (error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* activeUserSaga() {
  yield takeEvery(userActions.ACTIVE_USER, function* ({ data }) {
    try {
      const token = yield select(state => state.auth.accessToken);
      console.log(token);
      const response = yield activeUser({ token }, data);
      if (response.status === 200) {
        yield notification.open({
          message: 'Success!',
          description: 'User Status Changed Successfully!'
        });
        yield put({
          type: userActions.GET_USERS
        });
      } else {
        throw response;
      }
    } catch (error) {
      console.log(error);
      yield notification.open({
        message: 'Failed!',
        description: error.message
      });
      yield put({ type: userActions.ON_ERROR });
      if (error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getUsersSaga),
    fork(addUserSaga),
    fork(verifyUserSaga),
    fork(addClinicSaga),
    fork(addLabSaga),
    fork(addPharmacySaga),
    fork(activeUserSaga),
    fork(addPartnerSaga),
    fork(getPartnerSaga)
  ]);
}
