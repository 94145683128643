const SnackBarActions = {
    SNACKBAR_SUCCESS: "SNACKBAR_SUCCESS",
    SNACKBAR_CLEAR: "SNACKBAR_CLEAR",

    showSuccessSnackbar: (message) => {
        return {
            type: SnackBarActions.SNACKBAR_SUCCESS,
            message,
        }
    },
    clearSnackbar: () => {
        return {
            type: SnackBarActions.SNACKBAR_CLEAR,
        };
    }
};

export default SnackBarActions;
