import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
// import AddPharmacy from 'components/pharmacies/AddPharmacy';
// import PartnerName from "components/Partners_name/AddPartners"
import NationalPathalogy from "components/National_Radiology/AddRadiology"
import Radiology from 'components/National_Radiology/NationalRadiology';
import { useDispatch, useSelector } from "react-redux";
import actions from "actions";
import pharmacyActions from 'actions/pharmacy';

const { commonActions } = actions;
const { master } = actions
console.log(master,"master")
const Nationala = () => {
    const dispatch = useDispatch();
    const [visible, setvisible] = useState(false);
    const citiesnew = useSelector((state) => state.common.newcities);
    const areasnew = useSelector((state) => state.common.newareas);

    useEffect(() => {
        dispatch(commonActions.getMetadata());
        dispatch(commonActions.getallnewcities());
    }, [dispatch]);
    return (
        <div className="pictogram-container">
            <div className="pictogram-header flex-x align-center ptb--15">
                <div className="fs--16 font-semibold flex-1">
                {/* Pharmacist Partners */}
                National Radiology
                </div>
                <Button style={{ height: 42 }} type="primary" onClick={() => setvisible(true)}>Add</Button>
            </div>
            <div>
                <Radiology/>
            </div>
            <NationalPathalogy
                visible={visible}
                handleOk={() => setvisible(false)}
                handleCancel={() => setvisible(false)}
                newcity={citiesnew}
                newareas={areasnew}
                // onGetarea={(value) => {
                //     console.log("area222", value);
                //     dispatch(commonActions.getallnewarea(value));
                // }}
                onSubmit={(value, type) => {
                    console.log("easy", value, type);
                    dispatch(master.addRadiology(value));
                }}
            />
        </div>
    );
};

export default Nationala;