import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from 'actions';
import { notification } from 'antd';
import { getSaasDoctors, getSaasPatient, getSaasAllDoctors } from 'services/saas-services';

const { userActions, saasActions, authActions } = actions;

export function* fetchSaasDoctors() {
    yield takeEvery(saasActions.GET_SAAS_DOCTORS, function* ({ pageNumber, search }) {
        try {
            const token = yield select(state => state.auth.accessToken);
            const response = yield getSaasDoctors(pageNumber,search, { token });
            if (response.status === 200) {
                yield put({
                    type: saasActions.GET_SAAS_DOCTORS_SUCCESS,
                    data: response.data.data,
                    count: response.data.count,
                });
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
        }
    });
}

export function* fetchSaasPatients() {
    yield takeEvery(saasActions.GET_SAAS_PATIENT, function* ({ pageNumber, searchQuery, doctorId }) {
        try {
            const token = yield select(state => state.auth.accessToken);
            const response = yield getSaasPatient({ token }, pageNumber, searchQuery, doctorId);
            if (response.status === 200) {
                yield put({
                    type: saasActions.GET_SAAS_PATIENT_SUCCESS,
                    patientsData: response.data.data,
                    patientCount: response.data.total,
                });
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
        }
    });
}


export function* fetchSaasAllDoctor() {
    yield takeEvery(saasActions.GET_SAAS_ALL_DOCTOR, function* (pageNumber) {
        try {
            const token = yield select(state => state.auth.accessToken);
            // TODO : ALL REMAINING...
            const response = yield getSaasAllDoctors(pageNumber, { token });
            if (response.status === 200) {
                yield put({
                    type: saasActions.GET_SAAS_ALL_DOCTOR_SUCCESS,
                    allDoctorData: response.data.data,
                });
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
        }
    });
}


export default function* rootSaga() {
    yield all([fork(fetchSaasDoctors), fork(fetchSaasPatients)]);
}