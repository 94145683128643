import actions from "actions";

const { influencerActions } = actions;

const initState ={
    influencers :[],
    loader: false,
    Limit: 10,
    skip: 0,
    count: 0,

}
export default function rootReducer(state = initState, actions){
    // console.log("reducers",actions.data)
    switch(actions.type){
        case influencerActions.INFLUENCER_REQUEST:
            console.log("reducers",actions)
            return{
                ...state,
                loader: true,
            };
            case influencerActions.INFLUENCER_REQUEST_SUCCESS:
                console.log("reducers",actions.data)
                return {
                    ...state,
                    data:actions.data,
                    loader : false,
                };


                case influencerActions.ADD_INTRODUCER:
                    console.log("reducers",actions)
                    return{
                        ...state,
                        loader: true,
                    };
                    case influencerActions.ADD_INTRODUCER_SUCESS:
                        console.log("reducers",actions.data)
                        return {
                            ...state,
                            data:actions.data,
                            loader : false,
                        };
                

            case influencerActions.EDIT_INFLUENCER:
                console.log("reducers", actions) 
                return{
                    ...state,
                    loader: true,
                };
            case influencerActions.EDIT_INFLUENCER_SUCESS:
                return{
                    ...state,
                    loader: false,
                }; 


                case influencerActions.EDIT_INTRODUCER:
                    console.log("reducers", actions) 
                    return{
                        ...state,
                        loader: true,
                    };
                case influencerActions.EDIT_INTRODUCER_SUCESS:
                    return{
                        ...state,
                        loader: false,
                    }; 
                
               
            case influencerActions.GET_INFLUENCERS:
                return{
                    ...state,
                    loader: true,
                    influencers: actions.data,
                    count: actions.count,
                };  
            case influencerActions.GET_INFLUENCERS_SUCCESS:
                return{
                    ...state,
                    loader: false,
                    influencers: actions.data,
                    count: actions.count,
                };
            case influencerActions.ON_ERROR:
                return{
                    ...state,
                    loader: false,
                };          
                       
            default:
                return state;
    };
    
};