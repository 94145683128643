const TREATMENTORDERACTION = {
  GET_OREDER1: "GET_OREDER1",
  GET_OREDER_SUCCESS1: "GET_OREDER_SUCCESS1",
  CHANGE_OREDER_STATUS1: "CHANGE_OREDER_STATUS1",
  CHANGE_OREDER_STATUS_SUCCESS1: "CHANGE_OREDER_STATUS_SUCCESS1",

  getorderbysuperadmin: (pageNumber, user_id) => {
    console.log(pageNumber,"sgh")
    return {
      type: TREATMENTORDERACTION.GET_OREDER1,
      pageNumber,
      user_id,
    };
  },

  updateOrderStatus: (orderId, status, itemId) => {
    return {
      type: TREATMENTORDERACTION.CHANGE_OREDER_STATUS1,
      orderId,
      status,
      itemId
    };
  },
};

export default TREATMENTORDERACTION;
