import { Modal } from 'antd';
import React from 'react';
import { Button } from 'antd';
import { Spin } from 'antd';
import moment from 'moment';

const ViewPatients = ({ user, visible, loader, handleOk, handleCancel }) => {
  const getPercentage = (user)=>{
    let percentage = 15;
    if(!user) return 0;
    const { personalQuestions = [], medicalQuestions = [], lifestyleQuestions = [] } = user;
    if (personalQuestions && personalQuestions.length > 1) {
      percentage = 60;
    } if (medicalQuestions && medicalQuestions.length > 1) {
      percentage = 80;
    }
    if (lifestyleQuestions && lifestyleQuestions.length > 1) {
      percentage = 100;
    }
    return percentage;
  }

  return (
    <Modal
      title={`Patient Details`}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key='submit' type='primary' onClick={handleOk}>
          Ok
        </Button>
      ]}
    >
      <Spin spinning={loader}>
        <div className='flex-y align-left'>
        {/* <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Patient Id
            </div>
            <div className='read-form-value'>{user && user.uuid}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Full Name
            </div>
            <div className='read-form-value'>{user && user.full_name}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Email
            </div>
            <div className='read-form-value'>{user && user.email}, {user && user.secondary_email}</div>
          </div> */}
          {/* <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>Email</div>
            <div className='read-form-value'>{user && user.email}</div>
          </div> */}
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Other Mobile Number
            </div>
            {/* <div className='read-form-value'>{user && user.mobile}, {user && user.other_mobile}</div> */}
            <div className='read-form-value'>{user && user.other_mobile}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Date Of Birth
            </div>
            <div className='read-form-value'>{moment(user && user.dob).format('DD/MM/YYYY')}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Gender
            </div>
            <div className='read-form-value'>{user && user.gender}</div>
          </div>
          {/* <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Choice Doctor
            </div>
            <div className='read-form-value'>{user && user.choiceDoctor}</div>
          </div> */}
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Blood Group
            </div>
            <div className='read-form-value'>{user && user.blood_group}</div>
          </div>
          {/* <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Languages
            </div>
            <div className='read-form-value'>{user && user.languagues.join(',')}</div>
          </div> */}
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Marital Status
            </div>
            <div className='read-form-value'>{user && user.marital_status}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Profile Percentage
            </div>
            <div className='read-form-value'>{getPercentage(user)} %</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Total Appointment
            </div>
            <div className='read-form-value'>{user && user.bookings[0] ? user.bookings[0].totalBookings : 0}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Last Appointment
            </div>
            <div className='read-form-value'>{user && user.bookings[0] ? moment(user.bookings[0].lastAppointmentDate).format('DD/MM/YYYY hh:mm a') : 'No appointment'}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Total Orders
            </div>
            <div className='read-form-value'>{user && user.orders[0] ? user.orders[0].totalOrders: 0}</div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default ViewPatients;
