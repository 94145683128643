import actions from 'actions';
const { userActions } = actions;
const initState = {
  loader: false,
  data: [],
  partners: [],
  limit: 10,
  skip: 0,
  count: 0,
  partnersdetails: []
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case userActions.ADD_PARTNER:
      return {
        ...state,
        loader: true
      };
    case userActions.ADD_PARTNER_SUCCESS:
      return {
        ...state,
        loader: false
      };

    case userActions.GET_USERS:
      return {
        ...state,
        loader: true,
        partnersdetails: action.data
      };
    case userActions.GET_PARTNER:
      return {
        ...state,
        loader: true,
        // partnersdetails:action.data
      };

    case userActions.GET_USERS_SUCCESS:
      return {
        ...state,
        loader: false,
        data: action.data,
        count: action.count,
        // partners: action.partners
      };
    case userActions.GET_PARTNER_SUCCESS:
      return {
        ...state,
        loader: false,
        // data: action.data,
        count: action.count,
        partners: action.partners
      };
    case userActions.ADD_USERS:
      return {
        ...state,
        loader: true
      };
    case userActions.ADD_LAB:
      return {
        ...state,
        loader: true
      };
    case userActions.ADD_CLINIC:
      return {
        ...state,
        loader: true
      };
    case userActions.ADD_PHARMACY:
      return {
        ...state,
        loader: true
      };
    case userActions.ON_VERIFIED:
      return {
        ...state,
        loader: true
      };
    case userActions.ON_ERROR:
      return {
        ...state,
        loader: false
      };
    case userActions.SET_SKIP:
      return {
        ...state,
        skip: action.skip
      };
    case userActions.SET_LIMIT:
      return {
        ...state,
        limit: action.limit
      };
    default:
      return state;
  }
}
