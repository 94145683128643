import React, { useState, useEffect } from 'react'
import { DatePicker, Form, Modal, Input, Spin, Row, Col, Button, Select, notification } from 'antd';
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;

export default function CouponsEdit(props) {
    const { couponsDetails, visible, close, onSubmit } = props;
    const [form] = Form.useForm();
    const [validFromDate, setValidFromDate] = useState()
    const [validTillDate, setValidTillDate] = useState()
    const [couponStatus, seCouponsStatus] = useState()
    const [couponUse, setCouponUse] = useState()

    const handleValidFromDate = (date, dateString) => {
        setValidFromDate(dateString)
    }

    const handleValidTillDate = (date, dateString) => {
        setValidTillDate(dateString)
    }

    const handleCoupanStatus = (event) => {
        console.log(event, "shgvj")
        seCouponsStatus(event)
    }

    const handleCouponUse = (event) => {
        console.log(event.target.value)
        setCouponUse(event)
    }

    const handleSubmit = () => {

        let status = couponStatus;
        let payLoad = {
            "coupon_code": couponsDetails && couponsDetails.coupon_code,
            "coupon_type": couponsDetails && couponsDetails.coupon_type,
           " no_of_times_of_use_per_user" : couponStatus,
            "category": couponsDetails && couponsDetails.category,
            "description": couponsDetails && couponsDetails.description,
            "valid_from": validFromDate ? validFromDate : moment(couponsDetails && couponsDetails.valid_from).format("YYYY-MM-DD"),
            "valid_to": validTillDate ? validTillDate : moment(couponsDetails && couponsDetails.valid_to).format("YYYY-MM-DD"),
            "is_active": status
        }
        onSubmit(payLoad, couponsDetails && couponsDetails._id)
        close()
    }
console.log(couponsDetails && couponsDetails.no_of_times_of_use_per_user)
    return (
        <Modal
            className='no-modal-footer'
            title={`Edit Coupon`}
            visible={visible}
            onOk={close}
            onCancel={close}
            width={800}
        >

            <Form form={form} className='gx-form-row0' layout='vertical'>
                <Row className='space-between'>

                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%' }}>
                            Valid From
                        </div>
                        <DatePicker defaultValue={moment(couponsDetails && couponsDetails.valid_from, "YYYY-MM-DD")}
                            onChange={handleValidFromDate}
                        />

                    </Col>


                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%' }}>
                            Valid Til
                        </div>
                        <DatePicker
                            defaultValue={moment(couponsDetails && couponsDetails.valid_to, "YYYY-MM-DD")}
                            onChange={handleValidTillDate}
                        />
                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%' }}>
                            Status
                        </div>
                        <Select
                            style={{ width: 150 }}
                            defaultValue={couponsDetails && couponsDetails.is_active}
                            onChange={(e) => { handleCoupanStatus(e) }}
                        >
                            <Option value="select">Select..</Option>
                            <Option value={true}>Active</Option>
                            <Option value={false}>Inactive</Option>
                        </Select>

                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%' }}>
                            Coupon Code
                        </div>
                        <Input size='large'
                            disabled
                            value={couponsDetails && couponsDetails.coupon_code}
                        />
                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Coupon Type
                        </div>
                        <Input size='large'
                            disabled
                            value={couponsDetails && couponsDetails.coupon_type}
                        />
                    </Col>

                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Coupon Category
                        </div>
                        <Input size='large'

                            disabled
                            value={couponsDetails && couponsDetails.category}
                        />
                    </Col>





                    <Col span={7} xs={7} md={7}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Appointment Type
                        </div>
                        <Input size='large'
                            disabled
                            value={couponsDetails && couponsDetails.sub_category && couponsDetails.sub_category === "sadn" ? "SADN" : "FADN"}
                        />
                    </Col>
                    <Col span={7} xs={7} md={7}>
                        <Form.Item
                            style={{ width: 220 }}
                            label='No of Times Use'
                            name='no_of_times_of_use_per_user'
                            rules={[{ required: false}]}
                            defaultValue = { couponsDetails && couponsDetails.no_of_times_of_use_per_user }
                            value={couponsDetails && couponsDetails.no_of_times_of_use_per_user}
                            onChange={(e) => { handleCouponUse(e) }}
                        >
                            <Input size='large' 
                            defaultValue = { couponsDetails && couponsDetails.no_of_times_of_use_per_user }
                            value={couponsDetails && couponsDetails.no_of_times_of_use_per_user}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={7} xs={7} md={7}>

                    </Col>

                </Row>
                <Row>
                    <Col span={24} xs={24} md={24}>
                        <div className='read-form-label fs--14 font-semibold' style={{ marginBottom: '1%', marginTop: '2%' }}>
                            Description
                        </div>
                        <TextArea disabled size='large' value={couponsDetails && couponsDetails.description} />
                    </Col>


                    <Col className='ml-auto'>
                        <br />
                        <br />
                        <Button className='login-form-button' onClick={() => { close() }}>
                            Cancel
                        </Button>
                        <Button
                            type='primary'
                            className='login-form-button ml--10'
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>

                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

