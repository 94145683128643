import axios from 'axios';
import { setHeadersWithAccessToken } from './index';
const WEB_API_BASE = process.env.REACT_APP_WEB_APP;


export const getDoctorsclone = (pagenum, searchQuery, auth,params) => {
  console.log("getDoctors",params,pagenum)
  // console.log(" getDoctors service token" ,auth)
  let page ; 
  if(pagenum == undefined){
    page = 0 ;
  }
  else {
    page = pagenum ;
  }

  setHeadersWithAccessToken(auth);
  return axios.get(`${WEB_API_BASE}/user/admin/search/doctors?pagenum=${page}&limit=10&search=${searchQuery}`
  // ,{
  //   params
  // }
  ).then(e => e);
};


export const getDoctors = (pagenum,search, auth) => {

  console.log(search)
  let page ; 
  if(pagenum == undefined){
    page = 0 ;
  }
  else {
    page = pagenum ;
  }
  let SearchItem ; 
  if(search == undefined){
    SearchItem = '' ;
  }
  else {
    SearchItem = search ;
  }

  setHeadersWithAccessToken(auth);
  // return axios.get(`${WEB_API_BASE}/user/search/doctors`).then(e => e);
  return axios.get(`${WEB_API_BASE}/user/admin/search/doctors?pagenum=${page}&limit=10&search=${SearchItem}`).then(e => e);
};

export const editDoctors = (auth,uuid,doctor) => {
  setHeadersWithAccessToken(auth)
  // http://test-api.doctall.com/api/v1/user/updateDetailsByAdmin/DR-70004CD5
  // return axios.put(`${WEB_API_BASE}/user/details/${uuid}`,doctor).then(e => e);
  return axios.put(`${WEB_API_BASE}/user/updateDetailsByAdmin/${uuid}`,doctor).then(e => e);
};