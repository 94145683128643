import React, { useState, useEffect } from 'react';
import { Form, Modal, Input, Row, Col, Button, Select } from 'antd';
import axios from "axios"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const { Option } = Select;

const AddArea = ({ visible, handleOk, handleCancel, onGetarea, newcity, newareas, onSubmit, city }) => {
  console.log(city, "city111")
  const [country, setCountry] = useState();

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((res) => {
        setCountry(res.country_code.toLowerCase());
      })
      .catch((data, status) => {
        console.log("Request failed:", data);
      });
  }, []);
  const handleSubmit = values => {
    // let values = values;
    onSubmit(
      {
        "city_uuid": newcityuuid,
        "name": values.area_name,


      },
      typesubmit

    );
    // reset();
    handleOk();
    // window.location.reload('');
  };

  const [newcityuuid, setnewcityuuid] = useState(null);
  const [partnercity, setpartnercity] = useState('');
  const [partnerarea, setpartnerarea] = useState('');
  const [typesubmit, settypesubmit] = useState("addpartner");
  const [countrylist, setCountrylist] = useState([]);
  const [areaId, setareaId] = useState();

  const CityChange = (item) => {
    console.log(item, "item")
    setnewcityuuid(item)
  }

  const areasubmit = values => {
    console.log("area submit", values)
    onGetarea(values)
  }



  // rest Api For Countries

  // useEffect(() => {
  //     axios.get(`${"https://restcountries.com/v3.1/all"}`)
  //     .then((res) => {
  //       console.log(res, "res")
  //       setCountrylist(res)
  //     })
  //     .catch((err) => {
  //       console.log(err, "res")
  //     });
  //   }, []);



  const [selectedCity, setSelectedCity] = useState(null);
  const cityData = {
    cityName: {
      data: [
        { uuid: '1', name: 'New York' },
        { uuid: '2', name: 'Los Angeles' },
        { uuid: '3', name: 'Chicago' },
        { uuid: '4', name: 'Houston' },
        // ... other city objects
      ],
    },
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setnewcityuuid(value)
  };





  return (
    <Modal
      className='no-modal-footer'
      title='Add Area'
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={900}
      okText='Add'
      footer={[
        <Button className='login-form-button' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          type='primary'
          htmlType='submit'
          className='login-form-button ml--10'
        >
          Add
        </Button>
      ]}
    >
      <Form onFinish={handleSubmit} className='gx-form-row0' layout='vertical'>
        <Row>
          <Col span={7} xs={7} md={7}>

            {/* <span style={{ color: "red", fontSize: "20px", marginTop: "10px" }}>*</span><span style={{ marginBottom: "20px" }}> City Name</span> */}
            <Form.Item
              label='City Name'
              // name='area_name'
              rules={[{ required: true, message: 'Please input pharmacy name!' }]}
            >
              {/* <Select
                label='Area Name'
                showSearch
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                placeholder="Select City"
                onChange={CityChange}
              >

                {city && city.cityName && city.cityName.data && city.cityName.data.map((item) => {
                  return <Option value={item.uuid}>{item.name}</Option>
                })}


              </Select> */}

              <Select
                label='Area Name'
                showSearch
                style={{ width: '100%', borderRadius: '6px' }}
                placeholder="Select City"
                onChange={handleCityChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={newcityuuid}
              >
                {city && city.cityName && city.cityName.data && city.cityName.data.map((item) => (
                  <Option key={item.uuid} value={item.uuid}>
                    {item.name}
                  </Option>
                ))}
              </Select>

            </Form.Item>

          </Col>



          &nbsp;  &nbsp;  &nbsp;  &nbsp;
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='Area Name'
              name='area_name'
              rules={[{ required: true, message: 'Please input pharmacy name!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>



        </Row>
        <Button className='login-form-button' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          type='primary'
          htmlType='submit'
          className='login-form-button ml--10'
        >
          Add
        </Button>
      </Form>
    </Modal>
  );
};

export default AddArea;
