import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Radio } from "antd";
import actions from "actions";
import UsedCouponsPage from './UsedCouponsPage';
import CouponsListPage from './CouponsListPage';
import PromoCodePage from './PromoCodePage';
import CouponStat from './CouponStats';

const { TabPane } = Tabs;

const { couponsActions, usedCouponsActions } = actions;
export default function CouponsPage() {

    const dispatch = useDispatch();
    const [createCouponVisibility, setCreateCouponVisibility] = useState(false);

    const couponsData = useSelector((state) => state.couponsReducer);

    useEffect(() => {
        dispatch(couponsActions.getCoupons(0));
    }, [])

    return (
        <>
            <Tabs defaultActiveKey="1" type="card" size={'small'}>
                <TabPane tab="Coupons" key="1">
                    <CouponsListPage />
                </TabPane>
                <TabPane tab="DMP Reports" key="3">
                    <PromoCodePage />
                </TabPane>
                <TabPane tab="Telemedicine Reports" key="2">
                    <UsedCouponsPage />
                </TabPane>
                <TabPane tab="Stats" key="4">
                    <CouponStat />
                </TabPane>
            </Tabs>

        </>
    )
}
