import React, { useState, } from 'react';
import { Table, Popover, Checkbox } from 'antd';
import { green_dot, red_dot, view_log, triple_dots } from 'helper/constant';
import ViewUser from './ViewUser';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import actions from 'actions';
import { List } from 'antd/lib/form/Form';
const { userActions } = actions;


const Users = ({ data, loader, count, limit }) => {
  const dispatch = useDispatch();
  const [visible, setvisible] = useState(false);
  const [user, setuser] = useState(null);
  const { successSnackbarOpen, successSnackbarMessage } = useSelector(
    state => state.snackBarReducer
  );

  const users = useSelector((state) => state.auth);
  
  React.useEffect(() => {
    if (successSnackbarOpen) {
      notification.open({
        message: 'Notification',
        description: successSnackbarMessage,
      });
    }
  }, [successSnackbarOpen, successSnackbarMessage])


  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group'
    },
    {
      title: 'Phone Number',
      dataIndex: 'mobile',
      key: 'mobile'
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (text, row) => (
        <div className='tbl-user-photo'>
          {row.verified ? (
            <img src={green_dot && green_dot.default?green_dot.default:green_dot} alt='active' />
          ) : (
            <img src={red_dot && red_dot.default?red_dot.default:red_dot} alt='inactive' />
          )}
        </div>
      )
    },
    {
      title: 'View',
      dataIndex: 'eye',
      key: 'eye',
      render: (text, row) => (
        <img
          className='ml--10 cursor-pointer'
          src={view_log && view_log.default?view_log.default:view_log}
          alt='view'
          onClick={() => {
            console.log('view');
            setuser(row);
            setvisible(true);
          }}
        />
      )
    },
    {
      title: 'Varification',
      dataIndex: 'Varification',
      key: 'Varification',
      render: (text, row) => (
        <div className='ml--20'>
          {users && users.admin && users.admin.role !== "analytics-admin" ?
          <Checkbox
            checked={row.verified === true ? true : false}
            disabled={row.verified === true ? true : false}
            onChange={() => {
              console.log('called', row.mobile);
              dispatch(userActions.onVerified(row.email))
            }}
          >
            {row.verified === true ? 'Verified' : 'Not Verified'}
          </Checkbox>
          :
          <Checkbox
            checked={row.verified === true ? true : false}
            disabled={true}
            // onChange={() => {
            //   console.log('called', row.mobile);
            //   dispatch(userActions.onVerified(row.email))
            // }}
          >
            {row.verified === true ? 'Verified' : 'Not Verified'}
          </Checkbox>
    }
        </div>
      )
    },
    {
      title: 'Active/InActive',
      dataIndex: 'active',
      key: 'active',
      render: (text, row) => (
        <div className='ml--20'>
          {users && users.admin && users.admin.role !== "analytics-admin" ?
          <Checkbox
            checked={row.active === true ? true : false}
            onChange={() => {
              console.log('called', row.uuid, !row.active);
              // dispatch(userActions.onVerified(row.email))
              dispatch(userActions.activeUser({
                uuid: row.uuid,
                active: !row.active
              }))
            }}
          >
            {row.active === true ? 'Active' : 'In Active'}
          </Checkbox>
          :
          <Checkbox
          checked={row.active === true ? true : false}
          disabled
          // onChange={() => {
          //   console.log('called', row.uuid, !row.active);
          //   // dispatch(userActions.onVerified(row.email))
          //   dispatch(userActions.activeUser({
          //     uuid: row.uuid,
          //     active: !row.active
          //   }))
          // }}
        >
          {row.active === true ? 'Active' : 'In Active'}
        </Checkbox>
    }
        </div>
      )
    },
    // {
    //   title: 'Actions',
    //   dataIndex: 'actions',
    //   key: 'actions',
    //   render: (text, row) => (
    //     <div className='ml--20'>
    //       <Popover
    //         trigger='hover'
    //         placement='bottom'
    //         content={
    //           <div>
    //             <div
    //               className='cursor-pointer'
    //               onClick={() => {
    //                 console.log('edit');
    //               }}
    //             >
    //               Edit
    //             </div>
    //             <div
    //               className='pt--7 cursor-pointer'
    //               onClick={() => {
    //                 console.log('delete');
    //               }}
    //             >
    //               Delete
    //             </div>
    //           </div>
    //         }
    //       >
    //         <img src={triple_dots} alt='action' />
    //       </Popover>
    //     </div>
    //   )
    // }
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        size='middle'
        loading={loader}
        pagination={{
          // defaultPageSize:25,
          // defaultPageSize: 20

          total: count
        }}

        onChange={(pagination) => {
          dispatch(userActions.setSkip((pagination.current - 1) * limit));
          dispatch(userActions.setLimit(pagination.pageSize));
        }}
      />
      <ViewUser
        visible={visible}
        loader={false}
        handleOk={() => setvisible(false)}
        handleCancel={() => setvisible(false)}
        user={user}
      />
    </div>
  );
};

export default Users;
