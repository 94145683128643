import { Modal } from 'antd';
import React from 'react';
import { Button } from 'antd';
import { Spin } from 'antd';
import { green_dot, red_dot } from 'helper/constant';
import moment from 'moment';

const ViewDoctor = ({ user, visible, loader, handleOk, handleCancel }) => {
  return (
    <Modal
      title={`Doctor Details`}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key='submit' type='primary' onClick={handleOk}>
          Ok
        </Button>
      ]}
    >
      <Spin spinning={loader}>
        <div className='flex-y align-left'>
          {/* <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Doctor Id
            </div>
            <div className='read-form-value'>{user && user.uuid}</div>
          </div> */}
          {/* <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Full Name
            </div>
            <div className='read-form-value'>{user && user.full_name}</div>
          </div> */}
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Consultation Fee
            </div>
            <div className='read-form-value'>{user && user?.consultation_fee}</div>
          </div>
          {/* <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>Email</div>
            <div className='read-form-value'>{user && user.email}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Phone Number
            </div>
            <div className='read-form-value'>{user && user.mobile}</div>
          </div> */}
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Date Of Birth
            </div>
            <div className='read-form-value'>{user && moment(user?.profile?.dob).format('DD/MM/YYYY')}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Gender
            </div>
            <div className='read-form-value'>{user && user?.profile?.gender}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Education
            </div>
            <div className='read-form-value'>{user && user?.profile?.education}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Qualification
            </div>
            <div className='read-form-value'>{user && user.profile && user.profile.professional_detail && user.profile.professional_detail.qualification}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Registration Number
            </div>
            <div className='read-form-value'>{user && user?.profile?.registration_no}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Registration Council
            </div>
            <div className='read-form-value'>{user && user?.profile?.registration_council}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Licence
            </div>
            <div className='read-form-value'>{user && user?.profile?.license}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Experience
            </div>
            <div className='read-form-value'>{user && parseInt(moment(new Date).format('yyyy')) - parseInt(user?.profile?.practice_started_year)}</div>
          </div>
          {/* <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Speciality
            </div>
            <div className='read-form-value'>{user && user.profile.speciality.join(',')}</div>
          </div> */}
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Short Bio
            </div>
            <div className='read-form-value'>{user && user?.profile?.bio}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Total Appointments
            </div>
            <div className='read-form-value'>{user && user?.total_appointments}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Total Recommendation Eprescription
            </div>
            <div className='read-form-value'>{user && user?.total_recommendation_lab}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Total Recommendation Lab
            </div>
            <div className='read-form-value'>{user && user?.total_recommendation_eprescription}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Total Recommendation Eprescription Purchased
            </div>
            <div className='read-form-value'>{user && user?.total_recommendation_purchased_eprescription}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Total Recommendation Lab Purchased
            </div>
            <div className='read-form-value'>{user && user?.total_recommendation_purchased_lab}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Total Revenue
            </div>
            <div className='read-form-value'>{user && user?.total_revenue}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Slots booked
            </div>
            <div className='read-form-value'>{user && user?.total_slots_booked}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Slots Opened
            </div>
            <div className='read-form-value'>{user && user?.total_slots_opened}</div>
          </div>
          <div className='read-form-input flex-1 mr--10 mt--15'>
            <div className='read-form-label fs--14 font-semibold'>
              Last Appointment
            </div>
            <div className='read-form-value'>{user && user?.last_appointments_date ? moment(user && user?.last_appointments_date).format('DD/MM/YYYY hh:mm a') : 'NO Appointment'}</div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default ViewDoctor;
