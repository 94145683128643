import React, { useState, useEffect } from 'react';
import { Form, Modal, Input, Row, Col, Button, Select, Checkbox } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import 'antd/dist/antd.css';

const { Option } = Select;


const AddPartner = ({
  visible,
  partnerUser,
  handleOk,
  onGetarea,
  onSubmit,
  states,
  cities,
  newcity,
  newareas,
  handleCancel,
  updateFlags,
  exDetails

}) => {
  // const [inhouse_pharmacy, setinhousepharmacy] = useState(false);
  // const [inhouse_lab, setinhouselab] = useState(false);

  const partners = useSelector((state) => state.users.partners);
  console.log(updateFlags, exDetails)

console.log(partners,"part")

  const [partnertype, setpartnertype] = useState(null);
  const [state, setstate] = useState(null);
  const [city, setcity] = useState(null);
  const [uuid, setuuid] = useState(null);
  const [newcityuuid, setnewcityuuid] = useState('');
  const [newcityuuid2, setnewcityuuid2] = useState('');
  const [partnercity, setpartnercity] = useState('');
  const [partnerarea, setpartnerarea] = useState('');
  const [partnerarea2, setpartnerarea2] = useState('');
  const [branchname, setbranchname] = useState()
  const [branchlocationcityId, setbranchlocationcityId] = useState('')
  const [branchlocationcityname, setbranchlocationcityname] = useState('')
  const [branchlocationCitydefaultId, setbranchlocationdefaultCityId] = useState('')
  const [branchlocationDefaultareaId, setbranchlocationDefaultareaId] = useState('')
  const [branchlocationareaId, setbranchlocationareaId] = useState('')
  const [branchlocationareaname, setbranchlocationareaname] = useState('')
  const [typesubmit, settypesubmit] = useState("addpartner");
  const [country, setCountry] = useState();
  const [form] = Form.useForm();


  const errorstyle = {
    border: "1px solid #ADC2C2",
    outline: "none !important",
    borderRadius: "5px",
    width: '20px'
  };

  const areasubmit = values => {
    console.log("area submit", values)
    onGetarea(values)
  }


  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((res) => {
        setCountry(res.country_code.toLowerCase());
      })
      .catch((data, status) => {
        console.log("Request failed:", data);
      });
  }, []);


  const handleSubmit = values => {
    values.verified = true
    values.username = values.email
    values.open_close_time = values.open_close_time

    // values.address ? values.address = ([{
    //   city: partnercity,
    //   area: partnerarea
    // }]) : values.address = []
    values.branchLocation = ({
      city: {
        id: branchlocationcityId, name: branchlocationcityname
      },
      area:
        { id: branchlocationareaId, name: branchlocationareaname }
    })

    values.address = ({
      city_name: branchlocationcityname,
      area_name: branchlocationareaname,
      city: branchlocationCitydefaultId,
      area: branchlocationDefaultareaId,
      pincode: values.pincode,
      street: values.street,
      door_no: values.door_no
    })



    onSubmit(
      {
        ...values,
      },
      typesubmit

    );
    reset();
    handleOk();
  };

  const reset = () => {
    setpartnertype(null);
    setstate(null);
    setcity(null);
    setuuid(null);
    setbranchname(null)
    setnewcityuuid(null)
    setnewcityuuid2(null)
    setpartnerarea(null)
    setpartnerarea2(null)
    form.resetFields();
  };

  const onCancel = () => {
    reset();
    handleCancel();
  };

  const onOk = () => {
    reset();
    handleOk();
  };

  return (
    <Modal
      className='no-modal-footer'
      title='Add Partner'
      visible={visible}
      onOk={onOk}
      width={900}
      onCancel={onCancel}
      okText='Add'
    >
      <Form onFinish={handleSubmit} form={form} className='gx-form-row0' layout='vertical'>
        <Row className='space-between'>

          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='First Name'
              name='firstName'
              rules={[{ required: false, message: 'Please enter firstName!' }]}

            >
              <Input size='large'
              />
            </Form.Item>
          </Col>


          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='Middle Name'
              name='middleName'
              rules={[{ required: false, message: 'Please enter Middlename!' }]}
            >
              <Input size='large'
              />
            </Form.Item>
          </Col>

          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='Last Name'
              name='lastName'
              rules={[{ required: false, message: 'Please enter Lastname!' }]}
            >
              <Input size='large'
              />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='Email'
              name='email'
              rules={[{ required: true, message: 'Please enter email!' }]}
            >
              <Input size='large'
              />
            </Form.Item>
          </Col>


          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='City'>
              <Select
                showSearch
                autoClearSearchValue
                allowClear
                optionFilterProp="children"

                onChange={(value, secondvalue) => {
                  console.log("cityyy", value
                  )
                  setnewcityuuid(value)
                  areasubmit(value)

                  if (value && secondvalue)
                    setpartnercity(secondvalue.key)

                }}
                // value={city}
                value={newcityuuid}
              >
                {newcity.map(city => (
                  <Option key={city._id} value={city.uuid}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item label='Area'>
              <Select
                showSearch
                autoClearSearchValue
                allowClear
                optionFilterProp="children"
                name="area"
                onChange={(value, secondvalue) => {
                  if (value && secondvalue) {
                    setpartnerarea(secondvalue.key)
                  }
                }}
              >
                {newareas.map(area => (
                  <Option key={area._id} value={area.uuid}>
                    {area.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>


          <Col span={7} xs={7} md={7}>
            <Form.Item name="branch_name"
              rules={[{ required: true, message: 'Please Select Branch Name!' }]}

              label='Branch name'>
              <Select
                showSearch
                autoClearSearchValue
                allowClear
                optionFilterProp="children"
                onChange={value => {
                  setbranchname(value);
                }}
                value={branchname}
              >
                {partners &&
                  partners.map((partner) => (
                    <option key={partner.name} value={partner.name}>
                      {`${partner.name}`}
                    </option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={7} xs={7} md={7}>
            <Form.Item label='Branch City'
              name='branchCity'
              rules={[{ required: true, message: 'Please Select Branch City!' }]}>
              <Select
                name="branchcity"
                showSearch
                autoClearSearchValue
                allowClear
                optionFilterProp="children"
                onChange={(value, second) => {
                  console.log(second.value)
                  setnewcityuuid2(value)
                  areasubmit(value)
                  if (value && second) {
                    setbranchlocationdefaultCityId(second.key)
                    setbranchlocationcityId(second.value)
                    setbranchlocationcityname(second.children)
                  }

                }}
                value={newcityuuid2}
              >
                {newcity.map(city => (
                  <Option key={city._id} value={city.uuid}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              name='brancharea'
              rules={[{ required: true, message: 'Please Select Branch Area!' }]}
              label='Branch Area'
            >
              <Select
                showSearch
                autoClearSearchValue
                allowClear
                optionFilterProp="children"
                onChange={(value, second) => {
                  setpartnerarea2(value)
                  if (value && second) {
                    setbranchlocationDefaultareaId(second.key)
                    setbranchlocationareaId(second.value)
                    setbranchlocationareaname(second.children)
                  }
                }}
                value={partnerarea2}
              >
                {newareas.map(area => (
                  <Option key={area._id} value={area.uuid}>
                    {area.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>


          {/* New Fields */}
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='Door no'
              name='door_no'
              rules={[{ required: true, message: 'Please enter door no ' }]}
            >
              <Input type='number' size='sm' />
            </Form.Item>

          </Col>
          <Col span={7} xs={7} md={7}>

            <Form.Item
              label='Street'
              name='street'
              rules={[{ required: true, message: 'Please enter street!' }]}
            >
              <Input size='sm' />
            </Form.Item>
          </Col>


          <Col span={7} xs={7} md={7}>


            <Form.Item
              label='Working Time'
              name='open_close_time'
              rules={[{ required: true, message: 'Please enter working time !' }]}
            >
              <Input size='sm' />
            </Form.Item>
          </Col>



          <Col span={7} xs={7} md={7}>


            <Form.Item
              label='Pin Code'
              name='pincode'
              rules={[{ required: true, message: 'Please enter pincode!' }]}
            >
              <Input size='sm' type='number' />
            </Form.Item>
          </Col>



          {/*  */}


          <Col span={7} xs={7} md={7}>
            <Form.Item name="group" rules={[{ required: true, message: 'Please Select Group!' }]}
              label='Group'>
              <Select
                showSearch
                autoClearSearchValue
                allowClear
                optionFilterProp="children"
                onChange={value => {

                }}

              >
                <Option key={1} value='diagnostic'>
                  Diagnostic
                </Option>
                <Option key={2} value='pharmacy'>
                  Pharmacy
                </Option>

              </Select>
            </Form.Item>

          </Col>

          <Col span={7} xs={7} md={7}>
            <Form.Item name="role" rules={[{ required: true, message: 'Please Select Role!' }]}
              label='Role'>
              <Select
                showSearch
                autoClearSearchValue
                allowClear
                optionFilterProp="children"
                onChange={value => {
                  // setpartnertype(value);
                }}
              // value={partnertype}
              >
                <Option key={1} value='admin'>
                  Admin
                </Option>
                <Option key={2} value='user'>
                  User
                </Option>

              </Select>
            </Form.Item>
          </Col>

          <Col span={7} xs={7} md={7}>
            <Form.Item name="partner_name" rules={[{ required: true, message: 'Please Select partner name!' }]}
              label='Partner name'>
              <Select
                showSearch
                autoClearSearchValue
                allowClear
                optionFilterProp="children"
                onChange={value => {
                  // setpartnertype(value);
                }}
              // value={partnertype}
              >

                {partners &&
                  partners.map((partner) => (
                    <option key={partner.name} value={partner.name}>
                      {`${partner.name}`}
                    </option>
                  ))}

              </Select>
            </Form.Item>
          </Col>

          <Col span={7} xs={7} md={7} style={{ marginLeft: '3%' }}>
            <Form.Item
              label='Phone'
              name='mobile'
              rules={[{ required: true, message: 'Please enter phone number' }]}
            >
              <PhoneInput
                style={errorstyle}
                className="inputPhone"
                country={country}
                countryCodeEditable={false}
              />
            </Form.Item>
          </Col>




          <Col className='ml-auto'>
            <br />
            <br />
            <Button className='login-form-button' onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button ml--10'
            >
              Add
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddPartner;
