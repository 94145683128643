import { Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  Tabs,
  notification,
} from 'antd';
import { useDispatch } from 'react-redux';
import actions from 'actions';
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InputAdornment from '@mui/material/InputAdornment';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const { Option } = Select;

const { master, influencerActions } = actions;
const EditeInfluencer = ({ influencerRefrealCode, user, visible, handleOk, handleCancel, diff, city }) => {
  console.log('user420', user);
  const dispatch = useDispatch()
  const [newcityuuid, setnewcityuuid] = useState('');
  // const [url,setUrl]=useState("http://localhost:3000/influencerRegistration/?infRefCode=")
  console.log(newcityuuid, "newcity")
  const [copySuccess, setCopySuccess] = useState('copy');

  const url = (process.env.REACT_APP_INFLUENCER_URL + "/?referralcode=" + influencerRefrealCode.referal_code)
  console.log(url, "url")

  const [active, setActive] = useState();
  const [type, setType] = useState('');

  console.log(active, "Active")

  const handleChange = (event) => {
    console.log(event, "adasd")
    setActive(event);
  };
  const handleChange1 = (event) => {
    console.log(event, "adasd")
    setType(event);
  };
  const [popular, setPopular] = useState("");
  console.log(popular, "popular")

  const handleChangePopular = (event) => {
    console.log(event, "adasd")
    setPopular(event);
  };

  const { TabPane } = Tabs;



  const handleSubmit1 = values => {
    console.log(values, "value")

    var payLoad = {
      "uuid": user.uuid,
      "influencer_name": user.influencer_name,
      "firstName": values && values.firstName,
      "lastName": values.lastName,
      "email": values.email,
      "mobile": values.mobile,
      "active": active == null ? user && user.active : active,
      "influencer_type": type == '' ? user && user.influencer_type : type,


    }
    console.log(payLoad, "payLoad", values)
    dispatch(influencerActions.editInfluencer(payLoad));
  }


  const [country, setCountry] = useState();

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((res) => {
        setCountry(res.country_code.toLowerCase());
      })
      .catch((data, status) => {
        console.log("Request failed:", data);
      });
  }, []);


  return <Modal
    title={`Influencer`}
    visible={visible}
    onOk={handleOk}
    onCancel={handleCancel}
    width={850}
    footer={[

    ]}

  >

    <Tabs defaultActiveKey="1" >
      <TabPane tab=" " key="1">
        <Form onFinish={handleSubmit1} className='gx-form-row0' layout='vertical'>
          <Row className="space-between">
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="First Name"
                name="firstName"
                initialValue={user && user.firstName}
                rules={[{ required: true, message: "Please enter Frist name!" }]}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Last Name"
                name="lastName"
                initialValue={user && user.lastName}
                rules={[{ required: true, message: "Please enter Last name!" }]}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>
            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Email ID"
                name="email"
                initialValue={user && user.email}
                rules={[{ required: true, message: "Please enter Email Id!" }]}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col>


            <Col span={7} xs={7} md={7}>

              {/* <span style={{ color: "red", fontSize: "20px", marginTop: "10px" }}>*</span><span style={{ marginBottom: "20px" }}> City Name</span> */}
              <Form.Item
                label='Active'
                // name='area_name'
                rules={[{ required: true, message: 'Please input pharmacy name!' }]}
              >
                <Select
                  style={{
                    width: "100%", borderRadius: "6px",
                  }}
                  placeholder="Select Status"
                  onChange={handleChange}
                  defaultValue={user && user.active}
                  disabled={diff == 'view'}
                // initialValue={user && user.city_uuid}
                >
                  <Option value={true}>True</Option>
                  <Option value={false}>False</Option>
                </Select>
              </Form.Item>

            </Col>
            {/* <Col span={7} xs={7} md={7}>
              <Form.Item
                label="First Name"
                name="firstNameadas"
                initialValue={influencerRefrealCode && influencerRefrealCode.referal_code}
                rules={[{ required: true, message: "Please enter Frist name!" }]}
              >
                <Input size="large" disabled={diff == 'view'} />
              </Form.Item>
            </Col> */}

            <Col span={7} xs={7} md={7}>

              <p style={{ paddingBottom: "10px" }}>Refferal Code</p>
              <Input
                value={influencerRefrealCode && influencerRefrealCode.referal_code}
                // disabled
                size="large"
              />
            </Col>




            <Col span={7} xs={7} md={7}>
              <Form.Item
              // label="Url1"
              // name="Url"
              // value={influencerRefrealCode && influencerRefrealCode.referal_code}
              // rules={[{ required: true, message: "Please enter Frist name!" }]}
              >
                <p style={{ paddingBottom: "10px" }}>URL</p>
                <Input
                  value={url}
                  size="large"
                // onCopy={() => setCopySuccess('Copied!')}


                />


                <CopyToClipboard
                  text={url}
                  onCopy={() => setCopySuccess('Copied!')}>
                  <Button edge="end"
                    style={{ color: "#fffF", background: "#004451" }} >
                    {copySuccess}
                  </Button>

                </CopyToClipboard>



              </Form.Item>
            </Col>




            <Col span={7} xs={7} md={7}>
              <Form.Item
                label="Mobile Number"
                name="mobile"
                initialValue={user && user.mobile}
                rules={[{ required: true, message: "Please enter contact no!" }]}
              // style={{width:"50px"}}
              >
                <PhoneInput
                  country={country}
                  countryCodeEditable={false}
                  size="large"
                  style={{ width: "50px !importent" }}
                  disabled={diff == 'view'}
                />


              </Form.Item>


            </Col>
            <Col span={7} xs={7} md={7}>
            <Form.Item
                label="User Type"
                name="influencer_type"
                // initialValue={user && user.influencer_type}
                // rules={[{ required: true, message: "Please enter contact no!" }]}
              // style={{width:"50px"}}
              >
            <Select
                  style={{
                    width: "100%", borderRadius: "6px",
                  }}
                  placeholder="Select Status"
                  onChange={handleChange1}
                  defaultValue={user && user.influencer_type}
                  disabled={diff == 'view'}
                >
                  <Option value="influencer">Influencer</Option>
                  <Option value="nurse">Nurse</Option>
                </Select>


              </Form.Item>

            </Col>





          </Row>
          {diff === "edit" &&
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button ml--10'>
              Submit
            </Button>
          }
        </Form>
      </TabPane>



    </Tabs>
  </Modal>
  // );
};

export default EditeInfluencer;
