import actions from 'actions';

const initState = {
    patients: [],
    patientsClone: [],
    histrodocument:[],
    loader: false
};

export default function rootReducer(state = initState, action) {
    switch (action.type) {
        case actions.patientActions.GET_PATIENTS:
            return {
                ...state,
                loader: true
            };
        case actions.patientActions.GET_PATIENTS_SUCCESS:
            return {
                ...state,
                loader: false,
                patients: action.data
            };
            case actions.patientActions.GET_CLONE_PATIENTS:
                return {
                    ...state,
                    loader: true
                };
            case actions.patientActions.GET__CLONE_PATIENTS_SUCCESS:
                return {
                    ...state,
                    loader: false,
                    patientsClone: action.data
                };
                case actions.patientActions.GET_HISTRO_PATIENTS:
                    return {
                        ...state,
                        loader: true
                    };
                case actions.patientActions.GET_HISTRO_PATIENTS_SUCCESS:
                    return {
                        ...state,
                        loader: false,
                        histrodocument: action.data
                    };
        default:
            return state;
    }
}
