const appointmentActions = {
    GET_APPOINTMENT: "GET_APPOINTMENT",
    GET_APPOINTMENT_SUCCESS: "GET_APPOINTMENT_SUCCESS",

    CANCEL_APPOINTMENT: "CANCEL_APPOINTMENT",
    CANCEL_APPOINTMENT_SUCCESS: "CANCEL_APPOINTMENT_SUCCESS",
    CANCEL_APPOINTMENT_ERROR: "CANCEL_APPOINTMENT_ERROR",

    RE_APPOINTMENT: "RE_APPOINTMENT",
    RE_APPOINTMENT_SUCCESS: "RE_APPOINTMENT_SUCCESS",
    RE_APPOINTMENT_ERROR: "RE_APPOINTMENT_ERROR",
    getAppointments: (params) => {
      return {
        type: appointmentActions.GET_APPOINTMENT,
        params
      };
    },

    CancelAppointment: (params) => {
      // console.log("cancel action paramas",params)
      return {
        type: appointmentActions.CANCEL_APPOINTMENT,
        params
      };
    },

    reScheduleAppointment: (datas) => {
      // console.log("re action paramas",datas)
      return {
        type: appointmentActions.RE_APPOINTMENT,
        datas
      };
    }
};
  
export default appointmentActions;
  