import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { Table, Checkbox } from 'antd';
import moment from "moment";
import { view_log, green_dot, red_dot, triple_dots } from 'helper/constant';
import CouponsView from '../coupon-view/CouponsView';
import CouponsEdit from '../coupond-edit/CouponsEdit';
import {
    EditOutlined
} from '@ant-design/icons';

import actions from "actions";
import UsedCouponView from '../coupon-view/UsedCouponView';


const { couponsActions } = actions;

export default function CouponsTable(props) {
    const dispatch = useDispatch();
    const { couponsData } = props;
    const [couponView, setCouponView] = useState(false);
    const [couponEdit, setCouponEdit] = useState(false);
    const [couponDetails, setCouponDetails] = useState();

    const getCouponType = (row) => {
        if (props.isPromoCode) {
            return row.coupon && 0 in row.coupon && row.coupon[0].coupon_type
        } else {
            return row.coupon_type
        }
    }





    const columns = [
        {
            title: 'Coupon Code',
            dataIndex: 'coupon_code',
            key: 'coupon_code',
        },
        {
            title: 'Type ',
            dataIndex: 'category',
            key: 'coupon_type',
            render: (text, row) => (
                <div>{row && row.category && row.category === 'labtest' ? "DMP" : "Telemedicine"}</div>
            ),
        },
        {
            title: 'Coupon Type ',
            dataIndex: 'coupon_type',
            key: 'coupon_type',
            render: (text, row) => (
                <div>{getCouponType(row)}</div>
            ),
        },
        {
            title: 'Valid From',
            dataIndex: 'valid_from',
            key: 'valid_from',
            render: (text, row) => (
                <div>{moment(row.valid_from).format("DD/MM/yyyy hh:mm a")}</div>
            ),
        },
        {
            title: 'Valid Till',
            dataIndex: 'valid_to',
            key: 'valid_to',
            render: (text, row) => (
                <div>{moment(row.valid_to).format("DD/MM/yyyy hh:mm a")}</div>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            render: (text, row) => (
                <div className="tbl-user-photo">
                    {row.is_active ? (
                        <img src={green_dot && green_dot.default?green_dot.default:green_dot} alt="active" />
                    ) : (
                        <img src={red_dot && red_dot.default?red_dot.default:red_dot} alt="inactive" />
                    )}
                </div>
            ),
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (text, row) => (
                <div style={{ justifyContent: 'center', display: 'flex' }}>

                    <EditOutlined style={{ color: "#1974D2" }}
                        onClick={() => {
                            setCouponDetails(row)
                            setCouponEdit(true)
                        }} />
                </div>
            )
        },
        {
            title: 'View',
            dataIndex: 'eye',
            key: 'eye',
            render: (text, row) => (
                <img
                    className='ml--10 cursor-pointer'
                    src={view_log && view_log.default?view_log.default:view_log}
                    alt='view'
                    onClick={() => {
                        setCouponDetails(row)
                        setCouponView(true)
                    }}
                />
            )
        },

    ];


    const promoCodeColumns = [
        {
            title: 'Coupon Code',
            dataIndex: 'coupon_code',
            key: 'coupon_code',
        },
        {
            title: 'Coupon Type ',
            dataIndex: 'coupon_type',
            key: 'coupon_type',
            render: (text, row) => (
                <div>{getCouponType(row)}</div>
            ),
        },
        {
            title: 'Valid From',
            dataIndex: 'valid_from',
            key: 'valid_from',
            render: (text, row) => (
                <div>{moment(row.valid_from).format("DD/MM/yyyy hh:mm a")}</div>
            ),
        },
        {
            title: 'Valid Till',
            dataIndex: 'valid_to',
            key: 'valid_to',
            render: (text, row) => (
                <div>{moment(row.valid_to).format("DD/MM/yyyy hh:mm a")}</div>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            render: (text, row) => (
                <div className="tbl-user-photo">
                    {row.is_active ? (
                        <img src={green_dot && green_dot.default?green_dot.default:green_dot} alt="active" />
                    ) : (
                        <img src={red_dot && red_dot.default?red_dot.default:red_dot} alt="inactive" />
                    )}
                </div>
            ),
        },
        {
            title: 'View',
            dataIndex: 'eye',
            key: 'eye',
            render: (text, row) => (
                <img
                    className='ml--10 cursor-pointer'
                    src={view_log && view_log.default?view_log.default:view_log}
                    alt='view'
                    onClick={() => {
                        setCouponDetails(row)
                        setCouponView(true)
                    }}
                />
            )
        }

    ];


    const getTableData = () => {
        if (props.isPromoCode) {
            return couponsData.promoCodeData
        } else {
            return couponsData && couponsData.data.coupon;
        }
    }

    const getTotalCount = () => {
        if (props.isPromoCode) {
            return couponsData.promoCodeCount;
        } else {
            return couponsData && couponsData.count;
        }
    }



    return (
        <div>
            <Table
                columns={props && props.isPromoCode ? promoCodeColumns : columns}
                bordered={true}
                dataSource={getTableData()}
                size='middle'
                loading={couponsData && couponsData.loader}
                pagination={{
                    total: getTotalCount(),
                    showSizeChanger: false
                }}

                onChange={(pagination) => {
                    let pageNumber = ''
                    if (pagination.current === 1) {
                        pageNumber = 0
                    } else {
                        pageNumber = pagination.current - 1
                    }
                    props && props.isPromoCode ? dispatch(couponsActions.getPromoCodes(pageNumber)) : dispatch(couponsActions.getCoupons(pageNumber));

                }}
            />

            {props && props.isPromoCode === false && <CouponsView
                couponsDetails={couponDetails}
                visible={couponView}
                close={() => { setCouponView(false) }}
                isPromoCode={props.isPromoCode}
            />}


            {props && props.isPromoCode && <UsedCouponView
                couponsDetails={couponDetails}
                visible={couponView}
                close={() => { setCouponView(false) }}
            />}



            <CouponsEdit
                couponsDetails={couponDetails}
                visible={couponEdit}
                close={() => { setCouponEdit(false) }}
                onSubmit={(payLoad, id) => {
                    dispatch(couponsActions.editCoupon(payLoad, id));
                }}
            />


        </div>
    )
}
