import actions from 'actions';

const initState = {
    doctors: [],
    doctorsclone: [],
    loader: false
};

export default function rootReducer(state = initState, action) {
    switch (action.type) {
        case actions.doctorActions.GET_DOCTORS:
            return {
                ...state,
                loader: true
            };
        case actions.doctorActions.GET_DOCTORS_SUCCESS:
            return {
                ...state,
                loader: false,
                doctors: action.data
            };
            case actions.doctorActions.GET_CLONE_DOCTORS:
            return {
                ...state,
                loader: true
            };
        case actions.doctorActions.GET_CLONE_DOCTORS_SUCCESS:
            return {
                ...state,
                loader: false,
                doctorsclone: action.data
            };
        default:
            return state;
    }
}
