import React, { useState, useEffect } from 'react'
import { Form, Modal, Input, Row, Col, Button, Select, DatePicker, Space } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;


export default function CreateCoupon(props) {
    const { show, close, onSubmit, infName } = props;
    const [form] = Form.useForm();
    const [coupanType, setCoupanType] = useState('')

    const [appointmentType, setAppointmentType] = useState('')
    const [Type, setType] = useState('')
    const [PharmType, setPharmType] = useState('')
    const [labpack, setLabpack] = useState('')
    const [dmplab, setdmplab] = useState(false)
    const [fadnType, setFadnType] = useState("")


    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const [check, setCheck] = useState(false)
    const [check1, setCheck1] = useState(false)
    const [consumername, setConsumerName] = useState('')
    const [selectedCustomer, setSelectedCustomer] = useState([])
    const [customers, setCustomers] = useState([])
    const [pharma, setPharma] = useState([])

    const [partners, setPartners] = useState([])
    const [diseases, setDiseases] = useState([])
    const [packages, setlabpackes] = useState([])
    const [specailities, setSpecailities] = useState([])
    const [specificDoctor, setSpecificDoctor] = useState([])
    const [ids, setIds] = useState()


    const [idss, setIdss] = useState()
    const [partnername, setpartnerName] = useState('')
    const [selectedpartner, setSelectedpartner] = useState([])
    const [normal, setnormal] = useState(true)
    const [influencer, setInfluncer] = useState(false)
    // const [infName, setInfName] = useState([])

    const resetFormFields = () => {
        form.resetFields();
    }

    const onCancelClick = () => {
        close()
        resetFormFields()
    };

    // http://test-api.doctall.com/api/v1/user/all/consumer/getconsumer?search=sri
    // useEffect(() => {
    //     axios.get(`${process.env.REACT_APP_WEB_APP}/user/all/consumer/getconsumers?search=${consumername}`)
    //         .then((res) => {
    //             setCustomers(res.data)
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // })
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_WEB_APP}/user/auth/pharmacy/list?limit=0&skip=10&search=`)
            .then((res) => {
                console.log(res.data.data, "pharma")
                setPharma(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    // http://test-api.doctall.com/api/v1/doctallnew/diseases/
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_WEB_APP}/metadata/partner/getpartner`)
            .then((res) => {
                console.log(res.data, "partnerijo")
                setPartners(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_WEB_APP}/doctallnew/diseases`)
            .then((res) => {
                // console.log(res.data, "partner")
                setDiseases(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_WEB_APP}/doctallnew/biocheck`)
            .then((res) => {
                // console.log(res.data, "partner")
                setlabpackes(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_WEB_APP}/metadata/specialities`)
            .then((res) => {
                // console.log(res.data, "partner")
                setSpecailities(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const auth = useSelector((state) => state.auth.accessToken);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_WEB_APP}/user/admin/search/doctors`, {
            headers: { "x-auth-token": auth }
        })
            .then((res) => {
                console.log(res, "partner")
                setSpecificDoctor(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const handleSubmit = values => {
        console.log(values, "values")
        console.log(values, values.no_of_times_of_use_per_user)
        let payLoad = {}
        let PartnerUuid
        if (dmplab === true) {
            PartnerUuid = values && values.partner_uuid && values.partner_uuid
        } else {
            PartnerUuid = "all"
        }

        if (values.category.value === 'labtest') {
            payLoad = {
                "coupon_code": values.coupon_code,
                "no_of_times_of_use_per_user": values.no_of_times_of_use_per_user,
                "point": values.coupanpoints ? values.coupanpoints : 0,
                "discount": values.coupanTypeValue ? values.coupanTypeValue : 0,
                "coupon_type": values.coupon_type.value,
                "currency_type": "NGN",
                "user_uuid": ids,
                "maxtimeuse": values.maxtimeuse,
                "category": values.category.value,
                "sub_category": 'partnerwise',
                "description": values.description,
                "valid_from": moment(fromDate).format("YYYY-MM-DD"),
                "valid_to": moment(toDate).format("YYYY-MM-DD"),
                partner_uuid: PartnerUuid,
                "coupon_user_type": influencer == true ? "influencer" : normal == true ? "normal" : (influencer == false && normal == false) ? "pharmacist" : "",

            }
            if (influencer == true) {
                payLoad.influencer_id = values.user_uuid[0]
            }
            if (influencer == false && normal == false) {
                payLoad.pharmacist_id = values.pharma_user_uuid
            }
        }
        else if (values.category.value === 'pharmacy') {
            payLoad = {
                "coupon_code": values.coupon_code,
                "no_of_times_of_use_per_user": values.no_of_times_of_use_per_user,
                "point": values.coupanpoints ? values.coupanpoints : 0,
                "discount": values.coupanTypeValue ? values.coupanTypeValue : 0,
                "coupon_type": values.coupon_type.value,
                "currency_type": "NGN",
                "user_uuid": ids,
                "maxtimeuse": values.maxtimeuse,
                "category": values.category.value,
                "sub_category": values.sub_category && values.sub_category.value ? values.sub_category.value : '',
                "description": values.description,
                "valid_from": moment(fromDate).format("YYYY-MM-DD"),
                "valid_to": moment(toDate).format("YYYY-MM-DD"),
                "disease_uuid": values && values.disease_uuid && values.disease_uuid.value,
                "coupon_user_type": influencer == true ? "influencer" : normal == true ? "normal" : (influencer == false && normal == false) ? "pharmacist" : "",
            }
            if (influencer == true) {
                payLoad.influencer_id = values.user_uuid[0]
            }
            if (influencer == false && normal == false) {
                payLoad.pharmacist_id = values.pharma_user_uuid
            }
        }
        else if (values.category.value === 'labpackage') {
            payLoad = {
                "coupon_code": values.coupon_code,
                "no_of_times_of_use_per_user": values.no_of_times_of_use_per_user,
                "point": values.coupanpoints ? values.coupanpoints : 0,
                "discount": values.coupanTypeValue ? values.coupanTypeValue : 0,
                "coupon_type": values.coupon_type.value,
                "currency_type": "NGN",
                "user_uuid": ids,
                "maxtimeuse": values.maxtimeuse,
                "category": values.category.value,
                "sub_category": values && values.sub_category && values.sub_category.value ? values.sub_category.value : '',
                "description": values.description,
                "valid_from": moment(fromDate).format("YYYY-MM-DD"),
                "valid_to": moment(toDate).format("YYYY-MM-DD"),
                "package_uuid": values && values.package_uuid && values.package_uuid.value,
                "coupon_user_type": influencer == true ? "influencer" : normal == true ? "normal" : (influencer == false && normal == false) ? "pharmacist" : " ",
            }
            if (influencer == true) {
                payLoad.influencer_id = values.user_uuid[0]
            }
            if (influencer == false && normal == false) {
                payLoad.pharmacist_id = values.pharma_user_uuid
            }
        }
        else if (values.category.value === 'appointment') {
            payLoad = {
                "coupon_code": values.coupon_code,
                "no_of_times_of_use_per_user": values.no_of_times_of_use_per_user,
                "point": values.coupanpoints ? values.coupanpoints : 0,
                "discount": values.coupanTypeValue ? values.coupanTypeValue : 0,
                "coupon_type": values.coupon_type.value,
                "currency_type": "NGN",
                "user_uuid": ids,
                "maxtimeuse": values.maxtimeuse,
                "category": values.category.value,
                "sub_category": values.sub_category && values.sub_category.value ? values.sub_category.value : '',
                "description": values.description,
                "valid_from": moment(fromDate).format("YYYY-MM-DD"),
                "valid_to": moment(toDate).format("YYYY-MM-DD"),
                "appointment_type": values && values.appointment_type && values.appointment_type.value,
                doctor_uuid: values && values.specificdoctor && values.specificdoctor.value,
                "speciality_doctor": values && values.speciality_doctor && values.speciality_doctor.value,
                "coupon_user_type": influencer == true ? "influencer" : normal == true ? "normal" : (influencer == false && normal == false) ? "pharmacist" : " ",
            }
            if (influencer == true) {
                payLoad.influencer_id = values.user_uuid[0]
            }
            if (influencer == false && normal == false) {
                payLoad.pharmacist_id = values.pharma_user_uuid
            }
        } else {
            payLoad = {
                "coupon_code": values.coupon_code,
                "no_of_times_of_use_per_user": values.no_of_times_of_use_per_user,
                "point": values.coupanpoints ? values.coupanpoints : 0,
                "discount": values.coupanTypeValue ? values.coupanTypeValue : 0,
                "coupon_type": values.coupon_type.value,
                "currency_type": "NGN",
                "category": values.category.value,
                "description": values.description,
                "valid_from": moment(fromDate).format("YYYY-MM-DD"),
                "valid_to": moment(toDate).format("YYYY-MM-DD"),
                "coupon_user_type": influencer == true ? "influencer" : normal == true ? "normal" : (influencer == false && normal == false) ? "pharmacist" : " ",
            }
            if (influencer == true) {
                payLoad.influencer_id = values.user_uuid
            }
            if (influencer == false && normal == false) {
                payLoad.pharmacist_id = values.pharma_user_uuid
            }
        }

        onSubmit(payLoad)
        onCancelClick()
    };

    const handleCoupanType = (event) => {
        setCoupanType(event.value)
    }

    const handleAppointmentType = (event) => {
        setAppointmentType(event.value)
    }
    const handleType = (event) => {
        setType(event.value)
    }
    const handlePharmType = (event) => {
        setPharmType(event.value)
    }
    const handleLabpack = (event) => {
        setLabpack(event.value)
    }
    const handledmpLAb = (event) => {
        setdmplab(!dmplab)
    }
    const handlefadnType = (event) => {
        setFadnType(event.value)
    }


    function onDateChange(dates, dateStrings) {
        setFromDate(dateStrings[0])
        setToDate(dateStrings[1])
    }

    const [page, setPage] = useState(0)
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)

    const getConsumer = async (pages, searchQuery) => {
        // let response = await axios.get(`${process.env.REACT_APP_WEB_APP}/user/all/consumer/getconsumers?search=${searchQuery}`)
        let response = await axios.get(`${process.env.REACT_APP_WEB_APP}/user/getallconsumer?pagenum=${pages}&limit=10&search=${searchQuery}`)
        if (response) {
            console.log(response, "sbbdjsj")
            setCustomers(response.data.data)
        } else {
            throw new Error("Consumer Not Found");
        }
    }
    useEffect(() => {
        getConsumer(page, search)
    }, [])

    const scrollFun = () => {
        setPage(page + 1)
        getConsumer(page + 1, search)
    }

    const handleChange = async (event) => {
        try {
            setLoading(true)
            let response = await getConsumer(event)
            if (response) {
                setCustomers(response.data)
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const handleSearchQuery = (event) => {
        // setCustomers([])
        setSearch(event)
        getConsumer(page, event)
    }
    const consumerChange = (event) => {
        console.log(event)
        setSearch(event)
        getConsumer(page, event)
        // axios.get(`${process.env.REACT_APP_WEB_APP}/user/all/consumer/getconsumers?search=${event}`)
        //     .then((res) => {
        //         setCustomers(res.data)
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //     })
        // setCustomers([])
    }
    var selectedValues = [];
    const handleSelection = (event) => {
        //Add Data To Selected List
        console.log(event)
        setIds(event)
        selectedValues.push(event)
        setSelectedCustomer((prev) => [...prev, ...selectedValues])
        console.log(selectedCustomer)
    }
    const handleDeselection = (event) => {
        //Delete Data From Selected List
        var newArray = [...selectedCustomer]; // make a separate copy of the array
        var index = newArray.indexOf(event)
        if (index !== -1) {
            newArray.splice(index, 1);
            setSelectedCustomer(newArray)
        }
    }


    const partnerChange = (event) => {
        console.log(event)
        setpartnerName(event)
        // axios.get(`${process.env.REACT_APP_WEB_APP}/user/partner/getPartnerdetail?skip=2&limit=&search=${event}`)
        axios.get(`${process.env.REACT_APP_WEB_APP}/metadata/partner/getpartner?skip=2&limit=&search=${event}`)
            .then((res) => {
                setPartners(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
        // setCustomers([])
    }
    var selectedpartnerValues = [];
    const handlepartnerSelection = (event) => {
        //Add Data To Selected List
        console.log(event)
        setIdss(event)
        selectedValues.push(event)
        setSelectedpartner((prev) => [...prev, ...selectedpartnerValues])
        console.log(selectedpartner)
    }
    const handlepartnerDeselection = (event) => {
        //Delete Data From Selected List
        var newArray = [...selectedpartner]; // make a separate copy of the array
        var index = newArray.indexOf(event)
        if (index !== -1) {
            newArray.splice(index, 1);
            setSelectedpartner(newArray)
        }
    }



    return (
        <Modal
            className='no-modal-footer'
            title='Add Coupon'
            visible={show}
            width={500}
            onCancel={onCancelClick}
            okText='Add'
        >
            <Form onFinish={handleSubmit} form={form} className='gx-form-row0' layout='vertical'>
                <Row>
                    <form>
                        <label>Normal &nbsp;&nbsp;&nbsp;</label>
                        <input type="radio" name="normal" checked={normal == true} onClick={() => { setnormal(true); setInfluncer(false) }}></input>
                        <label>&nbsp;&nbsp;&nbsp;influencer &nbsp;&nbsp;&nbsp;</label>
                        <input type="radio" name="normal" checked={influencer == true} onClick={() => { setnormal(false); setInfluncer(true) }}></input>
                        <label>&nbsp;&nbsp;&nbsp;Pharmasist &nbsp;&nbsp;&nbsp;</label>
                        <input type="radio" name="normal" onClick={() => { setnormal(false); setInfluncer(false) }}></input>
                    </form>
                </Row>
                {influencer &&
                    <Row>
                        <Form.Item
                            style={{ width: 320 }}
                            label='Select influencer'
                            name='user_uuid'
                            rules={[{ required: false }]}
                        >
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                size="large"
                                showSearch
                                // optionFilterProp="children"
                                placeholder="Please select"
                                onSearch={(event) => { consumerChange(event) }}
                                // loading={loading}
                                onSelect={(event) => { handleSelection(event) }}
                                onDeselect={(event) => { handleDeselection(event) }}
                                onClear={() => { setSelectedCustomer([]) }}
                                // onPopupScroll={scrollFun}
                            // onSearch={(event) => { consumerChange(event) }}
                            // loading={loading}
                            // onSelect={(event) => { handleSelection(event) }}
                            // onDeselect={(event) => { handleDeselection(event) }}
                            // onClear={() => { setSelectedCustomer([]) }}
                            >
                                {infName &&
                                    infName.map((details) => (
                                        <option key={details.uuid} value={details.uuid}
                                        // disabled={selectedCustomer.length >= 1}
                                        >
                                            {`${details.influencer_name}`}
                                        </option>
                                    ))}
                            </Select>

                        </Form.Item>
                    </Row>
                }
                {(influencer == false && normal == false) &&
                    <Row>
                        <Form.Item
                            style={{ width: 320 }}
                            label='Select Pharmasist'
                            name='pharma_user_uuid'
                            rules={[{ required: false }]}
                        >
                            <Select
                                // mode="multiple"
                                style={{ width: '100%' }}
                                size="large"
                                placeholder="Please select"
                                name="pharmasist_Name"
                            // onSearch={(event) => { consumerChange(event) }}
                            // loading={loading}
                            // onSelect={(event) => { handleSelection(event) }}
                            // onDeselect={(event) => { handleDeselection(event) }}
                            // onClear={() => { setSelectedCustomer([]) }}
                            >
                                {/* {console.log(selectedValues)} */}
                                {pharma &&
                                    pharma.map((details) => (
                                        <option key={details.uuid} value={details.uuid}
                                        // disabled={selectedCustomer.length >= 1}
                                        >
                                            {`${details.pharmacy_name}`}
                                        </option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Row>
                }
                <Row>
                    <Col span={24} xs={24} md={24}>
                        <Form.Item
                            style={{ width: 320 }}
                            label='Coupon Code'
                            name='coupon_code'
                            rules={[{ required: true, message: 'Enter Coupon Code' }]}
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>


                    <Col span={24} xs={24} md={24}>
                        <Form.Item
                            style={{ width: 320 }}
                            label='Usage limit per user'
                            name='no_of_times_of_use_per_user'
                            rules={[{ required: false }]}
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>

                    <Col span={24} xs={24} md={24}>
                        <input type="radio" name="radio" value={check} onChange={check === false ? () => { setCheck(true); setCheck1(false) } : () => { setCheck(false); setCheck1(true) }}></input>
                        &nbsp;Specific User
                        &nbsp;&nbsp;&nbsp;
                        <input type="radio" name="radio" value={check} onChange={check1 === false ? () => { setCheck1(true); setCheck(false) } : () => { setCheck1(false); setCheck(true) }}></input>
                        &nbsp;Usage limit per coupon
                        {check === true &&
                            <Form.Item
                                style={{ width: 320 }}
                                label='Select Consumer'
                                name='user_uuid'
                                rules={[{ required: false }]}
                            >
                                <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                size="large"
                                showSearch
                                optionFilterProp="children"
                                placeholder="Please select"
                                onSearch={(event) => { handleSearchQuery(event); handleChange(event) }}
                                loading={loading}
                                onSelect={(event) => { handleSelection(event) }}
                                onDeselect={(event) => { handleDeselection(event) }}
                                onClear={() => { setSelectedCustomer([]) }}
                                onPopupScroll={scrollFun}
                                >
                                    {/* {console.log(selectedValues)} */}
                                    {customers &&
                                        customers.map((details) => (
                                            <option key={details.uuid} value={details.uuid}
                                                disabled={selectedCustomer.length >= 1}
                                            >
                                                {`${details.full_name}`}
                                            </option>
                                        ))}
                                </Select>
                            </Form.Item>
                        }
                        {check1 === true &&
                            <Form.Item
                                style={{ width: 320 }}
                                label='Max all use'
                                name='maxtimeuse'
                                rules={[{ required: false }]}
                            >
                                <Input size='large' />
                            </Form.Item>
                        }
                    </Col>


                    <Col span={24} xs={24} md={24}>
                        <Form.Item
                            label='Coupon Validity'
                            name='dates'
                            rules={[{ required: true, message: 'Select Validity' }]}
                        >
                            <RangePicker
                                onChange={onDateChange}
                                size='large'
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24} xs={24} md={24}>
                        <Form.Item
                            label='Coupon Type'
                            name='coupon_type'
                            rules={[{ required: true, message: 'Select Coupon Type' }]}
                        >
                            <Select
                                style={{ width: 320 }}
                                labelInValue
                                defaultValue={{ value: '' }}
                                onChange={(e) => { handleCoupanType(e) }}
                            >
                                <Option value="">Select..</Option>
                                <Option value="credit_point">Credit Point</Option>
                                <Option value="cashback">Cashback</Option>
                                <Option value="percentage">Percentage</Option>
                                <Option value="flat_discount">Flat Discount</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    {
                        coupanType && coupanType === "credit_point" &&
                        <Col span={24} xs={24} md={24}>
                            <Form.Item
                                style={{ width: 320 }}
                                label="Credit Points"
                                name='coupanpoints'
                                rules={[{ required: true, message: "Enter Credit Point" }]}
                            >
                                <Input size='large' />
                            </Form.Item>
                        </Col>
                    }

                    {
                        coupanType && coupanType === "cashback" &&
                        <Col span={24} xs={24} md={24}>
                            <Form.Item
                                style={{ width: 320 }}
                                label="Cashback"
                                name='coupanTypeValue'
                                rules={[{ required: true, message: "Enter Cashback Amount" }]}
                            >
                                <Input size='large' />
                            </Form.Item>
                        </Col>
                    }

                    {
                        coupanType && coupanType === "percentage" &&
                        <Col span={24} xs={24} md={24}>
                            <Form.Item
                                style={{ width: 320 }}
                                label="Percentage"
                                name='coupanTypeValue'
                                rules={[{ required: true, message: "Enter Percentage" }]}
                            >
                                <Input size='large' />
                            </Form.Item>
                        </Col>
                    }

                    {
                        coupanType && coupanType === "flat_discount" &&
                        <Col span={24} xs={24} md={24}>
                            <Form.Item
                                style={{ width: 320 }}
                                label="Flat Discount"
                                name='coupanTypeValue'
                                rules={[{ required: true, message: "Enter Flat Discount" }]}
                            >
                                <Input size='large' />
                            </Form.Item>
                        </Col>
                    }


                    <Col span={24} xs={24} md={24}>
                        <Form.Item
                            label='Coupon Category'
                            name='category'
                            rules={[{ required: true, message: 'Select Coupon Category' }]}
                        >
                            <Select
                                style={{ width: 320 }}
                                labelInValue
                                defaultValue={{ value: '' }}
                                onChange={(e) => { handleAppointmentType(e) }}
                            >
                                <Option value="">Select..</Option>
                                <Option value="appointment">Appointment</Option>
                                <Option value="labtest">Dmp</Option>
                                <Option value="pharmacy">Pharmacy</Option>
                                <Option value="labpackage">Labpackage</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    {
                        appointmentType == "labtest" && <Col span={24} xs={24} md={24}>
                            &nbsp;&nbsp;<input name="sub_category" value={"partnerwise"} type="checkbox" checked={dmplab} onChange={(e) => { handledmpLAb(e) }}></input> Check this for Partners wise
                            {/* <Form.Item
                            label='Lab Test'
                            name='SUBCATEGORY'
                            defaultValue={{ value: '' }}
                            rules={[{ required: true, message: 'Select Appointment Type' }]}
                        >
                            <Select
                                style={{ width: 320 }}
                                labelInValue
                                defaultValue={{ value: '' }}
                                onChange={ (e) => { handledmpLAb(e) }}
                            >
                                <Option value="">Select..</Option>
                                <Option value="all">All</Option>
                                <Option value="partnerwise">Partner Wise</Option>
                            </Select>
                        </Form.Item> */}
                        </Col>
                    }
                    {appointmentType == "labtest" && dmplab === true && <Col span={24} xs={24} md={24}>
                        <Form.Item
                            label='Partners Wise'
                            name='partner_uuid'
                            defaultValue={{ value: '' }}
                            rules={[{ required: true, message: 'Select Appointment Type' }]}
                        >
                            <Select
                                // mode="multiple"
                                style={{ width: '100%' }}
                                size="large"
                                placeholder="Please select"
                                // onChange={ (e) => { handlePharmType(e) }}
                                onSearch={(event) => { partnerChange(event) }}
                                // loading={loading}
                                onSelect={(event) => { handlepartnerSelection(event) }}
                                onDeselect={(event) => { handlepartnerDeselection(event) }}
                                onClear={() => { setSelectedpartner([]) }}
                            >
                                {/* <Option value="">Select..</Option> */}
                                {partners &&
                                    partners.map((details) => (
                                        <option key={details.uuid} value={details.uuid}
                                        // disabled={selectedCustomer.length >= 1}
                                        >
                                            {/* {`${details.partner_name}`} */}
                                            {`${details.name}`}

                                        </option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    }

                    {appointmentType === "appointment" && <Col span={24} xs={24} md={24}>
                        <Form.Item
                            label='Appointment Type'
                            name='sub_category'
                            defaultValue={{ value: '' }}
                            rules={[{ required: true, message: 'Select Appointment Type' }]}
                        >
                            <Select
                                style={{ width: 320 }}
                                labelInValue
                                defaultValue={{ value: '' }}
                                value={Type}
                                onChange={(e) => { handleType(e) }}
                            >
                                <Option value="">Select..</Option>
                                <Option value="sadn">SADN</Option>
                                <Option value="fadn">FADN</Option>

                            </Select>
                        </Form.Item>
                    </Col>
                    }
                    {appointmentType === "appointment" && Type === "fadn" && <Col span={24} xs={24} md={24}>
                        <Form.Item
                            label='Fadn Type'
                            name='appointment_type'
                            defaultValue={{ value: '' }}
                            rules={[{ required: true, message: 'Select Appointment Type' }]}
                        >
                            <Select
                                style={{ width: 320 }}
                                labelInValue
                                defaultValue={{ value: '' }}
                                onChange={(e) => { handlefadnType(e) }}
                            >
                                <Option value="">Select..</Option>
                                <Option value="all">all</Option>
                                <Option value="specificdoctor">specificdoctor</Option>
                                <Option value="speciality_doctor">speciality_doctor</Option>

                            </Select>
                        </Form.Item>
                    </Col>
                    }
                    {appointmentType === "appointment" && Type === "fadn" && fadnType == "specificdoctor" && <Col span={24} xs={24} md={24}>
                        <Form.Item
                            label='Specific Doctor'
                            name='specificdoctor'
                            defaultValue={{ value: '' }}
                            rules={[{ required: true, message: 'Select Appointment Type' }]}
                        >
                            <Select
                                style={{ width: 320 }}
                                labelInValue
                                defaultValue={{ value: '' }}
                            >
                                {specificDoctor &&
                                    specificDoctor.map((details) => (
                                        <option key={details.uuid} value={details.uuid}
                                        // disabled={selectedCustomer.length >= 1}
                                        >
                                            {`${details.full_name}`}
                                        </option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    }
                    {appointmentType === "appointment" && Type === "fadn" && fadnType == "speciality_doctor" && <Col span={24} xs={24} md={24}>
                        <Form.Item
                            label='Speciality Doctor'
                            name='speciality_doctor'
                            defaultValue={{ value: '' }}
                            rules={[{ required: true, message: 'Select Appointment Type' }]}
                        >
                            <Select
                                style={{ width: 320 }}
                                labelInValue
                                defaultValue={{ value: '' }}
                            >
                                {specailities &&
                                    specailities.map((details) => (
                                        <option key={details.name} value={details.name}
                                        // disabled={selectedCustomer.length >= 1}
                                        >
                                            {`${details.name}`}
                                        </option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    }
                    {appointmentType === "pharmacy" && <Col span={24} xs={24} md={24}>
                        <Form.Item
                            label='Lab'
                            name='sub_category'
                            defaultValue={{ value: '' }}
                            rules={[{ required: true, message: 'Select Appointment Type' }]}
                        >
                            <Select
                                style={{ width: 320 }}
                                labelInValue
                                defaultValue={{ value: '' }}
                                onChange={(e) => { handlePharmType(e) }}
                            >
                                <Option value="">Select..</Option>
                                <Option value="pharmacyall">All</Option>
                                <Option value="pharmacy_diseasewise">Specific Disease</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    }
                    {appointmentType === "pharmacy" && PharmType === "pharmacy_diseasewise" && <Col span={24} xs={24} md={24}>
                        <Form.Item
                            label='Disease List'
                            name='disease_uuid'
                            defaultValue={{ value: '' }}
                            rules={[{ required: true, message: 'Select Appointment Type' }]}
                        >
                            <Select
                                style={{ width: 320 }}
                                labelInValue
                                defaultValue={{ value: '' }}
                            // onChange={ (e) => { handlePharmType(e) }}
                            >
                                {diseases &&
                                    diseases.map((details) => (
                                        <option key={details.uuid} value={details.uuid}
                                        // disabled={selectedCustomer.length >= 1}
                                        >
                                            {`${details.name}`}
                                        </option>
                                    ))}

                                {/* <Option value="sadn">All</Option>
                                <Option value="fadn">Specific Disease</Option> */}
                            </Select>
                        </Form.Item>
                    </Col>
                    }
                    {
                        appointmentType == "labpackage" && <Col span={24} xs={24} md={24}>
                            <Form.Item
                                label='Labpackages'
                                name='sub_category'
                                defaultValue={{ value: '' }}
                                rules={[{ required: true, message: 'Select Appointment Type' }]}
                            >
                                <Select
                                    style={{ width: 320 }}
                                    labelInValue
                                    defaultValue={{ value: '' }}
                                    onChange={(e) => { handleLabpack(e) }}
                                >
                                    <Option value="">Select..</Option>
                                    <Option value="labpackageall">All</Option>
                                    <Option value="labpackage_specific">Labpackage Specific</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    {appointmentType == "labpackage" && labpack === "labpackage_specific" && <Col span={24} xs={24} md={24}>
                        <Form.Item
                            label='packages List'
                            name='package_uuid'
                            defaultValue={{ value: '' }}
                            rules={[{ required: true, message: 'Select Appointment Type' }]}
                        >
                            <Select
                                style={{ width: 320 }}
                                labelInValue
                                defaultValue={{ value: '' }}
                            // onChange={(e) => { handlePharmType(e) }}
                            >
                                {packages &&
                                    packages.map((details) => (
                                        <option key={details.uuid} value={details.uuid}
                                        // disabled={selectedCustomer.length >= 1}
                                        >
                                            {`${details.panel_name}`}
                                        </option>
                                    ))}
                                {/* <Option value="sadn">All</Option>
                                <Option value="fadn">Specific Disease</Option> */}
                            </Select>
                        </Form.Item>
                    </Col>
                    }


                    <Col span={24} xs={24} md={24}>
                        <Form.Item
                            label='Description'
                            name='description'
                            rules={[{ required: true, message: 'Enter Coupon Description' }]}
                        >
                            <TextArea size='large' />
                        </Form.Item>
                    </Col>


                    <Col className='ml-auto'>
                        <br />
                        <br />
                        <Button className='login-form-button' onClick={() => { onCancelClick() }}>
                            Cancel
                        </Button>
                        <Button
                            type='primary'
                            htmlType='submit'
                            className='login-form-button ml--10'
                        >
                            Submit
                        </Button>

                    </Col>
                </Row>
            </Form>
        </Modal >
    )
}
