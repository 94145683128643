import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from 'actions';
import { notification } from 'antd';
import { getCoupons, createCoupans, updateCoupans, getPromoCode } from 'services/couponsServices';

const { userActions, couponsActions, authActions } = actions;

export function* fetchCoupons() {
    yield takeEvery(couponsActions.GET_COUPONS, function* ({ pageNumber }) {
        try {
            const token = yield select(state => state.auth.accessToken);
            const response = yield getCoupons({ token }, pageNumber);
            if (response.status === 200) {
                yield put({
                    type: couponsActions.GET_COUPONS_SUCCESS,
                    data: response.data,
                    count: response.data.count,
                });
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
        }
    });
}

export function* fetchPromoCode() {
    yield takeEvery(couponsActions.GET_PROMOCODE, function* ({ pageNumber, searchQuery }) {
        try {
            const token = yield select(state => state.auth.accessToken);
            const response = yield getPromoCode({ token }, pageNumber, searchQuery);
            if (response.status === 200) {
                console.log(response.data)
                yield put({
                    type: couponsActions.GET_PROMOCODE_SUCCESS,
                    data: response.data.data,
                    count: response.data.count,
                });
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error)
        }
    });
}



export function* createCoupan() {

    console.log('saga');
    yield takeEvery(couponsActions.CREATE_COUPONS, function* ({ payLoad }) {
        try {
            const token = yield select(state => state.auth.accessToken);
            const response = yield createCoupans({ token }, payLoad);
            if (response.status === 200) {
                yield notification.open({
                    message: 'Success!',
                    description: 'Coupon Created Successfully'
                });
                yield put({
                    type: couponsActions.GET_COUPONS
                });
                yield put({
                    type: couponsActions.GET_PROMOCODE
                });
            } else {
                throw response;
            }
        } catch (error) {

            yield notification.open({
                message: 'Failed!',
                description: error.response.data
            });
            setTimeout(yield put({
                type: couponsActions.CREATE_COUPONS_FAILURE,
            }), 3000)
            yield put({ type: userActions.ON_ERROR });
            if (error.response.status === 401) {
                yield put({ type: authActions.AUTH_ERROR });
            }
        }
    });
}



export function* editCoupan() {

    console.log('saga');
    yield takeEvery(couponsActions.EDIT_COUPONS, function* ({ payLoad, couponId }) {
        try {
            const token = yield select(state => state.auth.accessToken);
            const response = yield updateCoupans({ token }, payLoad, couponId);
            if (response.status === 200) {
                yield notification.open({
                    message: 'Success!',
                    description: 'Coupan Updated Successfully'
                });
                yield put({
                    type: couponsActions.GET_COUPONS
                });
                yield put({
                    type: couponsActions.GET_PROMOCODE
                });

            } else {
                throw response;
            }
        } catch (error) {
            yield notification.open({
                message: 'Failed!',
                description: error.response.data
            });
            yield put({ type: userActions.ON_ERROR });
            if (error.response.status === 401) {
                yield put({ type: authActions.AUTH_ERROR });
            }
        }
    });
}


export default function* rootSaga() {
    yield all([fork(fetchCoupons), fork(fetchPromoCode), fork(createCoupan), fork(editCoupan)]);
}