import { Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  Tabs,
  notification,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'actions';
import axios from 'axios';
import "react-phone-input-2/lib/style.css";

const { Option } = Select;

const { master } = actions;
const EditeCity = ({ user, visible, handleOk, handleCancel, diff, city }) => {
  // console.log('user420',master);
  const dispatch = useDispatch()
  // const [newcityuuid, setnewcityuuid] = useState('');
  const Auth = useSelector((state) => state.auth);
  console.log('user', user);

  const [active, setActive] = useState();
  console.log(active, "Active")

  const handleChange = (event) => {
    console.log(event, "adasd")
    setActive(event);
  };

  useEffect(() => {
    city && city.cityName && city.cityName.data && city.cityName.data.map((item) => {
      console.log(item, "item")
      if (user && user.city == item.name) {
        return (
          setnewcityuuid(item),
          arealist(item && item.uuid)
        )
      }
    })
    // console.log(item, "item")
  }, [city.cityName.data, user, newcityuuid])
  console.log(newcityuuid, "newcity")


  const [popular, setPopular] = useState("");
  const [activeStatus, setActiveStatus] = useState(user && user.active === true ? "true" : "false");

  console.log(popular, "popular")

  const handleChangePopular = (event) => {
    console.log(event, "adasd")
    setPopular(event);
  };

  const handleChangePapular = (event) => {
    console.log(event, "adasd")
    setPopular(event);
  };
  const handleChangeActive = (event) => {
    console.log(event, "gsdjzgfhcdbsjkf")
    setActiveStatus(event)
  }
  const { TabPane } = Tabs;

  console.log(activeStatus, "activeStatus")

  const handleSubmit1 = values => {
    console.log(values, "jhdfgjhfkjh")

    const payLoad = {
      // city : newcityuuid?.name,
      area: user?.area,
      diagnosticspartner: user?.diagnosticspartner,
      standardiseddoctalltat: values.standardiseddoctalltat,
      doctallsexclusivediscountedprice: values.doctallsexclusivediscountedprice,
      discountofferedforretail: values.discountofferedforretail,
      doctallspurchasingprice: values.doctallspurchasingprice,
      homeserviceavailability: values.homeserviceavailability,
      labretailprice: values.labretailprice,
      partnerlabtat: values.partnerlabtat,
      partnerlabtatindays: values.partnerlabtatindays,
      partnerstestname: values.partnerstestname,
      populartest_y_n: values.populartest_y_n,
      populartestdescription: values.populartestdescription,
      productcode: values.productcode,
      standardisedtestname: user?.standardisedtestname,
      testdiscipline: values.testdiscipline,
      // testname_uuid: nationalPathData,
      areaRef: user?.areaRef,
      active: activeStatus
      // partner_uuid : partnerData.uuid,
    }
    console.log(payLoad, "payLoad")
    dispatch(master.editMasterHomeSample(user?.uuid, payLoad));

    setTimeout(() => {
      handleCancel()
    }, 2500)
  }




  const [newcityuuid, setnewcityuuid] = useState(user && user.city);
  const [areaData, setAreaData] = useState();
  const [areaList, setAreaList] = useState([]);
  const [partnersList, setPartnerList] = useState([]);
  const [partnerData, setPartnerData] = useState('');
  const [nationaPathList, setNationalPathList] = useState([]);
  const [nationalPathData, setNationalPathData] = useState(user && user.testname_uuid);

  useEffect(() => {
    {
      partnersList && partnersList.map((item) => {
        if (user && user.partner_uuid === item.uuid) {
          return setPartnerData(item)
        }
      })
    }

  }, [user, partnersList])

  console.log(partnerData, "partnerData")

  const CityChange = (uuid) => {

    {
      city && city.cityName && city.cityName.data && city.cityName.data.map((item) => {
        if (uuid === item.uuid) {
          setnewcityuuid(item)
        }
      })
    }
    // console.log(item, "item")
    arealist(uuid)
  }

  useEffect(() => {
    ArearChange(user && user.areaRef)
  }, [user, areaList])
  const ArearChange = (uuid) => {

    {
      areaList && areaList.map((item) => {
        if (uuid === item.uuid) {
          setAreaData(item)
        }
      })
    }
  }

  const partnerChange = (uuid) => {

    {
      partnersList && partnersList.map((item) => {
        if (uuid === item.uuid) {
          setPartnerData(item)
        }
      })
    }
  }


  const nationalPathChange = (uuid) => {
    setNationalPathData(uuid)

  }
  const arealist = (id) => {
    axios.get(`${process.env.REACT_APP_WEB_APP}/uploadDoc/getArea/${id}`)
      .then((res) => {
        console.log(res, "areaList")
        setAreaList(res.data)
      })
      .catch((err) => {
        console.log(err, "err")
      })
  }

  const partnerList = () => {
    axios.get(`${process.env.REACT_APP_WEB_APP}/metadata/partner/getpartner`)
      .then((res) => {
        console.log(res.data, "areaList")
        setPartnerList(res.data)
      })
      .catch((err) => {
        console.log(err, "err")
      })
  }

  const nationalPathologyList = () => {
    axios.get(`${process.env.REACT_APP_WEB_APP}/upload/nationalPathalogy?limit=10&page=0&search=`, {
      headers: {
        "x-auth-token": Auth.accessToken
      }
    })
      .then((res) => {
        console.log(res.data.data, "areaList")
        setNationalPathList(res.data.data)
      })
      .catch((err) => {
        console.log(err, "err")
      })
  }

  useEffect(() => {
    partnerList();
    nationalPathologyList();
  }, [])



  return <Modal
    title={`Update Home Sample Collection`}
    visible={visible}
    onOk={handleOk}
    onCancel={handleCancel}
    width={850}
    footer={[

    ]}

  >
    <Form onFinish={handleSubmit1} className='gx-form-row0' layout='vertical'>
      <Row className="space-between">
        {/* <Col span={7} xs={7} md={7}>
            <Form.Item
              label='City Name'
              // name='area_name'
              // value={newcityuuid}

              // initialValue={newcityuuid}

              rules={[{ required: true, message: 'Please enter City!' }]}
            >
              <Select
                label='City Name'
                // showSearch
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                placeholder="Select City"
                value={newcityuuid?.uuid ? newcityuuid?.uuid : newcityuuid}
                defaultValue={newcityuuid}
                onChange={(item) => CityChange(item)}
              >
                {city && city.cityName && city.cityName.data && city.cityName.data.map((item) => {
                  return <Option value={item.uuid} >{item.name}</Option>
                })}
              </Select>
            </Form.Item>

          </Col> */}

        <Col span={7} xs={7} md={7}>
          <Form.Item
            label="Area Name"
            name="area"
            initialValue={user && user.area}
            // value={areaData}
            rules={[{ required: true, message: "Please enter Area name!" }]}
          >
            <Input size="large" disabled />

            {/* <Select
                label='Area Name'
                // showSearch
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                placeholder="Select Area"
                onChange={ArearChange}
              >
                {areaList && areaList.map((item) => {
                  return <Option value={item.uuid}>{item.name}</Option>
                })}
              </Select> */}
          </Form.Item>
        </Col>
        <Col span={7} xs={7} md={7}>
          <Form.Item
            label="Diagnostics Partner"
            name="diagnosticspartner"
            initialValue={user && user.diagnosticspartner}
          // rules={[{ required: true, message: "Please enter Partner Name !" }]}
          >
            <Input size="large" disabled />

            {/* <Select
                label='Partner Name'
                // showSearch
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                disabled
                defaultValue={user && user.partner_uuid}
                value={partnerData?.uuid}
                placeholder="Select Partner"
                onChange={partnerChange}
              >
                {partnersList.map((item) => {
                  return <Option value={item.uuid}>{item.name}</Option>
                })}
              </Select> */}
          </Form.Item>
        </Col>
        <Col span={7} xs={7} md={7}>
          <Form.Item
            label="Test Name"
            name="testname_uuid"
            initialValue={user && user.partnerstestname}
          // rules={[{ required: true, message: "Please enter Test Name!" }]}
          >
            <Input size="large" disabled />

            {/* <Select
                label='Test Name'
                // showSearch
                style={{
                  width: "100%", borderRadius: "6px",
                }}
                disabled
                defaultValue={user && user.testname_uuid}
                value={nationalPathData}
                placeholder="Select Test"
                onChange={nationalPathChange}
              >
                {nationaPathList.map((item) => {
                  return <Option value={item.uuid}>{item.standardisedtestname}</Option>
                })}
              </Select> */}
          </Form.Item>
        </Col>
        <Col span={7} xs={7} md={7}>
          <Form.Item
            label="Product Code "
            name="productcode"
            initialValue={user && user.productcode}
            rules={[{ required: true, message: "Please enter Area name!" }]}
          >
            <Input size="large" disabled />
          </Form.Item>
        </Col>
        <Col span={7} xs={7} md={7}>
          <Form.Item
            label="Test Discipline "
            name="testdiscipline"
            initialValue={user && user.testdiscipline}
            rules={[{ required: true, message: "Please enter Area name!" }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col span={7} xs={7} md={7}>
          <Form.Item
            label="Standardised Test Name"
            name="standardisedtestname"
            initialValue={user && user.standardisedtestname}
            rules={[{ required: true, message: "Please enter Area name!" }]}
          >
            <Input size="large" disabled />
          </Form.Item>
        </Col>
        <Col span={7} xs={7} md={7}>
          <Form.Item
            label="Partner Test Name"
            name="partnerstestname"
            initialValue={user && user.partnerstestname}
            rules={[{ required: true, message: "Please enter Area name!" }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col span={7} xs={7} md={7}>
          <Form.Item
            label="Home Service Availablity"
            name="homeserviceavailability"
            initialValue={user && user.homeserviceavailability}
            rules={[{ required: true, message: "Please enter Area name!" }]}
          >
            <Select
              label='Home Service'
              // showSearch
              style={{
                width: "100%", borderRadius: "6px",
              }}
              placeholder="Select City"
              defaultValue={user && user.homeserviceavailability}
              onChange={CityChange}
            >
              <Option value="AVAILABLE">AVAILABLE</Option>
              <Option value="NOT AVAILABLE">NOT AVAILABLE</Option>

              {/* {city && city.cityName && city.cityName.data && city.cityName.data.map((item) => {
                  return <Option value={item.name}>{item.name}</Option>
                })} */}
            </Select>
          </Form.Item>
        </Col>
        <Col span={7} xs={7} md={7}>
          <Form.Item
            label="Popular Test Description"
            name="populartestdescription"
            initialValue={user && user.populartestdescription}
            rules={[{ required: true, message: "Please enter Area name!" }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col span={7} xs={7} md={7}>
          <Form.Item
            label="Lab Retail Price"
            name="labretailprice"
            initialValue={user && user.labretailprice}
            rules={[{ required: true, message: "Please enter Area name!" }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col span={7} xs={7} md={7}>
          <Form.Item
            label="Doctall Purchasing Price"
            name="doctallspurchasingprice"
            initialValue={user && user.doctallspurchasingprice}
            rules={[{ required: true, message: "Please enter Area name!" }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col span={7} xs={7} md={7}>
          <Form.Item
            label="Discount offered for Retail"
            name="discountofferedforretail"
            initialValue={user && user.discountofferedforretail}
            rules={[{ required: true, message: "Please enter Area name!" }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col span={7} xs={7} md={7}>
          <Form.Item
            label="Partner Lab Tat"
            name="partnerlabtat"
            initialValue={user && user.partnerlabtat}
            rules={[{ required: true, message: "Please enter Area name!" }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col span={7} xs={7} md={7}>
          <Form.Item
            label="Partner Lab Tat in Days"
            name="partnerlabtatindays"
            initialValue={user && user.partnerlabtatindays}
            rules={[{ required: true, message: "Please enter Area name!" }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col span={7} xs={7} md={7}>
          <Form.Item
            label="Standardised Doctall Tat"
            name="standardiseddoctalltat"
            initialValue={user && user.standardiseddoctalltat}
            rules={[{ required: true, message: "Please enter Area name!" }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col span={7} xs={7} md={7}>
          <Form.Item
            label="Doctall Exclusived Discount Price"
            name="doctallsexclusivediscountedprice"
            initialValue={user && user.doctallsexclusivediscountedprice}
            rules={[{ required: true, message: "Please enter Doctall Exclusived Discount Price!" }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        {/* <Col span={7} xs={7} md={7}>
            <Form.Item
              label="Standardised Doctall Tat"
              name="standardiseddoctalltat"
              // initialValue={user && user.standardisedtestname}
              rules={[{ required: true, message: "Please enter Standardised Doctall Tat !" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col> */}

        <Col span={7} xs={7} md={7}>
          <Form.Item
            label='populartest_y_n'
            name='populartest_y_n'
            initialValue={user && user.populartest_y_n}
            rules={[{ required: true, message: 'Please input pharmacy name!' }]}
          >
            <Select
              label='Area Name'
              rules={[{ required: true, message: 'Please input pharmacy name!' }]}
              style={{
                width: "100%", borderRadius: "6px",
              }}
              placeholder="Select City"
              onChange={handleChangePapular}
              defaultValue={user && user.populartest_y_n}
              value={user && user.populartest_y_n}
            // initialValue={user && user.city_uuid}
            >
              <Option value={"YES"}>Yes</Option>
              <Option value={"NO"}>No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={7} xs={7} md={7}>
          <Form.Item
            label='Active Status'
            name='active'
            initialValue={user && user.active}
            rules={[{ required: true, message: 'Please input Active!' }]}
          >
            <Select
              label='Active Status'
              rules={[{ required: true, message: 'Please input Active!' }]}
              style={{
                width: "100%", borderRadius: "6px",
              }}
              placeholder="Select"
              onChange={handleChangeActive}
              defaultValue={user && user.active === true ? "True" : "False"}
              value={activeStatus}
            // value={user && user.active}
            // initialValue={user && user.city_uuid}
            >
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Button className='login-form-button' onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        type='primary'
        htmlType='submit'
        className='login-form-button ml--10'
      >
        Update
      </Button>
    </Form>
  </Modal>
  // );
};

export default EditeCity;
