import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from 'actions';
import { getDoctors,editDoctors,getDoctorsclone,  } from 'services/doctor-services';
import { notification } from 'antd'
import { addPharmacies, getPharmacyData, editPharmacyapi } from 'services/pharmacy-services';
const { pharmacyActions,authActions } = actions;

export function* getPharmacycloneSaga() {
  yield takeEvery(pharmacyActions.GET_CLONE_PHARMACY, function*(params) {
    try{
      const token = yield select(state => state.auth.accessToken);
      const response = yield getDoctorsclone({token},params);
        const { data } = response;
        yield put({
          type: pharmacyActions.GET_CLONE_PHARMACY_SUCCESS,
          data
        });
    }catch(error){
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}


export function* getPharmacySaga() {
  yield takeEvery(pharmacyActions.GET_PHARMACY, function*() {
    try{
      const token = yield select(state => state.auth.accessToken);
      const response = yield getPharmacyData({token});
        const { data } = response;
        yield put({
          type: pharmacyActions.GET_PHARMACY_SUCCESS,
          data
        });
    }catch(error){
      yield notification.open({ message: 'Failed!', description: 'Something went wrong' })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}


export function* editPharmacySaga() {
  yield takeEvery(pharmacyActions.EDIT_PHARMACY, function*({uuid, payLoad, type1, handleOk}) {
    
    try{
      const token = yield select(state => state.auth.accessToken);
      // const pay = payLoad
      const response = yield editPharmacyapi({token}, uuid, payLoad, type1);
      console.log(response, "dfgjh")
        // const { data } = response;
        // console.log("object",response.status)
        // console.log("object",response.statusText)

//         status: 200
// statusText: "OK
if (response.status === 200) {
  yield notification.open({
    message: 'Success!',
    description: 'Updated Successfully!'
  });
  window.location.reload(false);
  handleOk()
  yield put({
    type: pharmacyActions.GET_PHARMACY
  });
} 
        yield put({
          type: pharmacyActions.GET_PHARMACY,
        });
    }catch(error){
      yield notification.open({ message: 'Failed!', description: error })
      if (error.response && error.response.status === 401) {
        yield put({ type: authActions.AUTH_ERROR });
      }
    }
  });
}

export function* addPharmacySaga1() {
    console.log('saga');
    yield takeEvery(pharmacyActions.ADD_PHARMACY1, function* ({ data }) {
      try {
          console.log(data,"data")
        const token = yield select(state => state.auth.accessToken);
        console.log(token);
        const response = yield addPharmacies({ token }, data);
        if (response.status === 200) {
          yield notification.open({
            message: 'Success!',
            description: 'Pharmacy added Successfully!'
          });
          yield put({
            type: pharmacyActions.GET_PHARMACY
          });
          // setTimeout(function(){
          //   window.location.reload();
          // }, 3000);
        } 
        else {
          throw  response;
        }
      } catch (error) {
        console.log(error.response, "error");
        yield notification.open({
          message: 'Failed!',
          description: error.response.data
        });
        // setTimeout(function(){
        //   window.location.reload();
        // }, 1500);
        // yield put({ type: userActions.ON_ERROR });
        // if (error.response.status === 401) {
        //   yield put({ type: authActions.AUTH_ERROR });
        // }
      }
    });
  }

export default function* rootSaga() {
  yield all([fork(editPharmacySaga),fork(getPharmacySaga),fork(getPharmacycloneSaga), fork(addPharmacySaga1)]);
}
