import React from 'react';
import { Modal, Table } from 'antd';


const ViewCompanyUsers= ({ visible, handleOk, handleCancel, user, data,count,ViewId, fun, companyUserData }) => {

  console.log(companyUserData,"refered123")
  let pageNumber;
 
  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name',
      render: (text, item) => (
        <div>{item && item.full_name}</div>
      )
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile',
      key: 'mobile',
      render: (text, item) => (
        <div>{item && item.mobile}</div>
      )
    },
    {
      title: 'Email Id',
      dataIndex: 'email',
      key: 'email',
      render: (text, item) => (
        <div>{item && item.email}</div>
      )
    },
    {
      title: 'User Id',
      dataIndex: 'uuid',
      key: 'uuid',
      render: (text, item) => (
        <div>{item && item.uuid}</div>
      )
    }
  ];


 
  return (<Modal
    title={`Company Users`}
    visible={visible}
    onOk={handleOk}
    onCancel={handleCancel}
    width={850}
    footer={[
    ]}
  >

  {
    companyUserData.length==0?
    <div style={{textAlign:"center"}}>
<h4 style={{fontFamily: "open-sans-regular", fontSize:"20px",fontWeight:"500"}}> No Referred Users </h4>
  </div>
  :
  <div>   
      {/* <table style={{ width: "100%" }}>
        <tr style={{ background: "#F0F5F5", height: "38px" }}>
          <th> &nbsp; &nbsp; Full Name</th>
          <th>Mobile No.</th>
          <th>Email ID</th>
          <th>User ID</th>
        </tr>    
      {
        companyUserData.map((item,index)=>{ 
          console.log(item,"item")
         return <tr style={{ borderBottom: "2px solid #F0F5F5", height: "38px" }}>
        <td>{item.full_name}</td>
        <td>{item.mobile}</td>
        <td>{item.email}</td>
        <td>{item.uuid}</td>
      </tr>
        })
      }      
      </table>    */}
      <Table
                columns={columns}
                dataSource={companyUserData}
                size='middle'
                pagination={{
                  total: count,
                  showSizeChanger: false
                }}

                onChange={(pagination) => {

                  if (pagination.current === 1) {
                    pageNumber = 0
                  } else {
                    pageNumber = pagination.current - 1
                  }
                  fun(ViewId, pageNumber)
                  // dispatch(master.getInfluencerData(pageNumber))
                }}
              />
  </div>
  }

 

    
 
  </Modal>
  );
};

export default ViewCompanyUsers;