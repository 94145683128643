import React, { useState, useEffect } from 'react';
import { Form, Modal, Input, Row, Col, Button, Select } from 'antd';
import axios from "axios"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const { Option } = Select;

const AddPharmacy = ({ visible, handleOk, handleCancel, onGetarea, newcity, newareas, onSubmit }) => {

  const [country, setCountry] = useState();
  const [introducer, setIntroducer]=useState([])
  const [introducerReferal, setIntroducerReferal]=useState()
  const [introducerUuid, setIntroducerUuid]=useState()
  console.log(introducer,introducerReferal,introducerUuid,"int")

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((res) => {
        setCountry(res.country_code.toLowerCase());
      })
      .catch((data, status) => {
        console.log("Request failed:", data);
      });
  }, []);
  const handleSubmit = values => {
    // let values = values;
    const payLoad = {
      pharmacy_name : values.pharmacy_name,
      firstName : values.firstName,
      lastName : values.lastName,
      created_by_introducer_uuid : introducerUuid,
      refered_code : introducerReferal,
      bank_details : ({
        account_name: values.account_name,
        branch_name: values.branch_name,
        bank_name: values.bank_name,
        account_type: values.account_type,
        account_number: values.account_number,  
      }),
      address_deatils : ({
        city: values.city,
        address: values.address,
        area: values.area,
        country: values.country,
      }),
    
  
    }
    console.log('Success1', values);
   


    onSubmit(
      {
        pharmacy_name : values.pharmacy_name,
        firstName : values.firstName,
        lastName : values.lastName,
        email: values.email,
        mobile: values.mobile,
        created_by_introducer_uuid : introducerUuid,
        refered_code : introducerReferal,
        bank_details : ({
          account_name: values.account_name,
          branch_name: values.branch_name,
          bank_name: values.bank_name,
          account_type: values.account_type,
          account_number: values.account_number,  
        }),
        address_deatils : ({
          city: values.city,
          address: values.address,
          area: values.area,
          country: values.country,
        })
      

      },
      typesubmit

    );
    // reset();
    handleOk();
    // window.location.reload('');
  };
  
  const [newcityuuid, setnewcityuuid] = useState('');
  const [partnercity, setpartnercity] = useState('');
  const [partnerarea, setpartnerarea] = useState('');
  const [typesubmit, settypesubmit] = useState("addpartner");
  const [countrylist, setCountrylist] = useState([]);
  const [areaId, setareaId] = useState();

  const areasubmit = values => {
    console.log("area submit", values)
    onGetarea(values)
  }



// rest Api For Countries

// useEffect(() => {
//     axios.get(`${"https://restcountries.com/v3.1/all"}`)
//     .then((res) => {
//       console.log(res, "res")
//       setCountrylist(res)
//     })
//     .catch((err) => {
//       console.log(err, "res")
//     });
//   }, []);




useEffect(()=>{
  getintroducer()
},[])
  const getintroducer=()=>{
axios.get(`${process.env.REACT_APP_WEB_APP}/user/auth/introducer/list?limit=&pagenum=&search=`)
.then((res)=>{
  console.log(res,"intro534")
  setIntroducer(res.data.data)
})
.catch((err)=>{
  console.log(err,"err")
})
  }
  return (
    <Modal
      className='no-modal-footer'
      title='Add Pharmacy'
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={900}
      okText='Add'
      footer={[
        <Button className='login-form-button' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          type='primary'
          htmlType='submit'
          className='login-form-button ml--10'
        >
          Add
        </Button>
      ]}
    >
      <Form onFinish={handleSubmit} className='gx-form-row0' layout='vertical'>
        <Row>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='Pharmacy Name'
              name='pharmacy_name'
              rules={[{ required: true, message: 'Please input pharmacy name!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          &nbsp;&nbsp;&nbsp; 
          <Col span={7} xs={7} md={7}>
      <Form.Item
              label='Email'
              name='email'
              rules={[{ required: true, message: 'Please input email!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          &nbsp;&nbsp;&nbsp; 
          <Col span={7} xs={7} md={7}>
            {/* <Form.Item
              label='Phone Number'
              name='mobile'
              rules={[
                { required: true, message: 'Please input phone number!' }
              ]}
            >
              <Input size='large' />
            </Form.Item> */}
                        <Form.Item
              label='Phone Number'
              name='mobile'
              // rules={[{ required: true, message: 'Please enter phone number' }]}
              // initialValue={user && user.mobile}
            >
              <PhoneInput
                // style={errorstyle}
                inputStyle={{width :"230px"}}
                // disabled={diff == "view"}
                className="inputPhone"
                country={country}
                countryCodeEditable={true}
              />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='First Name'
              name='firstName'
              rules={[
                { required: true, message: 'Please input First Name!' }
              ]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          &nbsp;&nbsp;&nbsp; 
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='Last Name'
              name='lastName'
              rules={[{ required: true, message: 'Please input Last Name!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          
          <Col span={7} xs={7} md={7}></Col>
          <Col span={24} xs={24} md={24}> <h4>Bank Details</h4></Col>
          <Col span={24} xs={24} md={24}></Col>
         
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='Account Name'
              name='account_name'
              rules={[{ required: true, message: 'Please input acoount name!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          &nbsp;&nbsp;&nbsp; 
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='Branch Name'
              name='branch_name'
              rules={[{ required: true, message: 'Please input branch name!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          &nbsp;&nbsp;&nbsp;
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='Bank Name'
              name='bank_name'
              rules={[{ required: true, message: 'Please input bank name!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col> 
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='Account type'
              name='account_type'
              rules={[{ required: true, message: 'Please input account type!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          &nbsp;&nbsp;&nbsp; 
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='Account Number'
              name='account_number'
              rules={[{ required: true, message: 'Please input account number!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col span={24} xs={24} md={24}>Address Details</Col>
          <Col span={22} xs={22} md={22}>
            <Form.Item
              label='Address'
              name='address'
              rules={[{ required: true, message: 'Please input Address!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='City'
              name='city'
              rules={[{ required: true, message: 'Please input city!' }]}
            >
              <Select
                showSearch
                autoClearSearchValue
                allowClear
                optionFilterProp="children"

                onChange={(value, secondvalue) => {
                  console.log("cityyy", value
                  )
                  setnewcityuuid(value)
                  areasubmit(value)

                  if (value && secondvalue)
                    setpartnercity(secondvalue.key)

                }}
                // value={city}
                value={newcityuuid}
              >
                {newcity.map(city => (
                  <Option key={city._id} value={city.uuid}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          &nbsp;&nbsp;&nbsp; 
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='Area'
              name='area'
              rules={[{ required: true, message: 'Please input area!' }]}
            >
              <Select
                showSearch
                autoClearSearchValue
                allowClear
                optionFilterProp="children"
                name="area"
                onChange={(value, secondvalue) => {
                  if (value && secondvalue) {
                    setpartnerarea(secondvalue.key)
                  }
                }}
              >
                {newareas.map(area => (
                  <Option key={area._id} value={area.name}>
                    {area.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>


          &nbsp;&nbsp;&nbsp; 
          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='Country'
              name='country'
              rules={[{ required: true, message: 'Please input country!' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>



{/* introducer start */}

          <Col span={7} xs={7} md={7}>
            <Form.Item
              label='Introducer'
              name='introducer'
              rules={[{ required: true, message: 'Please Select Introducer ' }]}
            >
              <Select
                showSearch
                autoClearSearchValue
                allowClear
                optionFilterProp="children"

                onChange={(value, secondvalue) => {
                  console.log("cityyy123", value, secondvalue
                  )
                  setIntroducerUuid(value)
                  // areasubmit(value)
                  setIntroducerReferal()

                  // if (value && secondvalue)
                  setIntroducerReferal(secondvalue.key)

                }}
                // value={city}
                // value={newcityuuid}
              >
                {introducer.map(item => (
                  
                  <Option key={item.referal_code } value={item.uuid}>
                    {item.introducer_name}
                    
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

        </Row>
        <Button className='login-form-button' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          type='primary'
          htmlType='submit'
          className='login-form-button ml--10'
        >
          Add
        </Button>
      </Form>
    </Modal>
  );
};

export default AddPharmacy;
