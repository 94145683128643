import actions from "actions";

const initState = {
  isLogin: localStorage.getItem("isLogin")
    ? localStorage.getItem("isLogin") === "true"
    : false,
  accessToken: localStorage.getItem("accessToken"),
  admin:null
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case actions.authActions.LOGIN_SUCCESS:
      return {
        ...state,
        isLogin: true,
        accessToken: action.accessToken,
        admin:action.admin
      };
    case actions.authActions.LOGOUT:
      return {
        ...state,
        isLogin: false,
        accessToken: null,
        admin:null
      };
    case actions.authActions.AUTH_ERROR:
      return {
        ...state,
        isLogin: false,
        accessToken: null,
        admin:null
      };
    default:
      return state;
  }
}
